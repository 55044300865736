import {
  FaroText,
  FaroTextVariant,
  NO_TRANSLATE_CLASS,
} from "@faro-lotv/flat-ui";
import { TreeData } from "@faro-lotv/project-source";
import { CaptureTreeNode } from "../tree/capture-tree/capture-tree-node";
import { TreeNodeProps } from "../tree/tree-node";
import {
  DATA_SESSION_FOLDER_ID,
  PANO_FOLDER_ID,
} from "./area-navigation-utils";

/**
 * @returns The component used to render the elements in the area navigation tree
 */
export function AreaNavigationTreeNode({
  node,
  style,
}: TreeNodeProps<TreeData>): JSX.Element {
  if (node.id === DATA_SESSION_FOLDER_ID || node.id === PANO_FOLDER_ID) {
    return (
      <FaroText
        variant={FaroTextVariant.heading12}
        className={NO_TRANSLATE_CLASS}
        sx={style}
      >
        {node.data.label}
      </FaroText>
    );
  }

  return <CaptureTreeNode node={node} style={style} collapseIcon />;
}
