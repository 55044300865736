import {
  blue,
  SyncCamerasOnWaypointIcon,
  ToggleIconButton,
} from "@faro-lotv/flat-ui";
import { Tooltip } from "@mui/material";

type SplitScreenSyncCamerasOnWaypointProps = {
  /** Indicates whether Splitscreen sync cameras on waypoint is active*/
  isActive: boolean;

  /** Callback called when the button is clicked */
  onClick(isActive: boolean): void;
};

/**
 * @returns A button to enable/disable split screen sync cameras on waypoint feature
 */
export function SplitscreenSyncCamerasOnWaypointButton({
  isActive,
  onClick,
}: SplitScreenSyncCamerasOnWaypointProps): JSX.Element {
  return (
    <Tooltip title="Follow to selected waypoint">
      {/** A span is needed to allow the tooltip to work on disabled buttons */}
      <span>
        <ToggleIconButton
          value="splitscreen-sync-cameras-on-waypoint"
          selected={isActive}
          onClick={() => onClick(isActive)}
          sx={{ position: "relative", borderRadius: "100%" }}
        >
          <SyncCamerasOnWaypointIcon
            sx={{ fill: isActive ? blue[500] : "none", color: "white" }}
          />
        </ToggleIconButton>
      </span>
    </Tooltip>
  );
}
