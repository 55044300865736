import { useLoadIElements } from "@/registration-tools/common/use-load-ielements";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericPointCloudStream,
  IElementType,
  isValid,
} from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { chunk, isEqual } from "lodash";
import { useMemo } from "react";
import { selectPointCloudStreamForScanEntity } from "../store/revision-selectors";

// Experimentally determined limit in which the IElement request URLs stay short enough.
// The limit is about 8k characters, this limit produces requests with about 7k character-URLs.
const ELEMENT_REQUEST_CHUNK_SIZE = 120;

/**
 *
 * @param scanEntities The scans in the revision to load the point cloud streams for.
 * @returns The point cloud streams corresponding to the given revision entities.
 *   If the data is still loading, `undefined` is returned.
 *   Note that some scans may not yet have a processed point cloud stream available.
 */
export function useLoadRevisionPointCloudStreams(
  scanEntities: RevisionScanEntity[],
): IElementGenericPointCloudStream[] | undefined {
  const chunkedRequests = useMemo(() => {
    return chunk(scanEntities, ELEMENT_REQUEST_CHUNK_SIZE).flatMap(
      (scanEntityChunk) => [
        {
          // Load ancestors for world pos calculation
          descendantIds: scanEntityChunk.map((entity) => entity.id),
        },
        {
          // Load descendants for scan rendering
          ancestorIds: scanEntityChunk.map((entity) => entity.id),
          types: [IElementType.pointCloudStream],
        },
      ],
    );
  }, [scanEntities]);

  const isLoading = useLoadIElements(chunkedRequests);

  const pointCloudStreams = useAppSelector(
    (state) =>
      scanEntities
        .map((entity) => selectPointCloudStreamForScanEntity(entity)(state))
        .filter(isValid),
    isEqual,
  );

  if (isLoading) return;

  return pointCloudStreams;
}
