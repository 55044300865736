import {
  selectActiveCadHasMesh,
  selectActiveCadId,
} from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  setWizardElementToAlignId,
  setWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-slice";
import { AppDispatch, store } from "@/store/store";
import { GUID } from "@faro-lotv/ielement-types";

export type AlignmentWizardArgs = {
  /** Id of the element in the project tree. */
  elementIdToAlign: GUID;

  /** Function to update the state. */
  dispatch: AppDispatch;
};

/**
 * Start Alignment Wizard
 */
export function openAlignmentWizard({
  dispatch,
  elementIdToAlign,
}: AlignmentWizardArgs): void {
  dispatch(setWizardElementToAlignId(elementIdToAlign));

  // current active CAD used as an initial suggested reference in Alignment Wizard
  const activeCadId = selectActiveCadId(store.getState());
  if (activeCadId && activeCadId !== elementIdToAlign) {
    const activeCadHasMesh = selectActiveCadHasMesh(store.getState());
    if (activeCadHasMesh) {
      dispatch(setWizardReferenceElementId(activeCadId));
    }
  }

  dispatch(changeMode("alignWizard"));
}
