import {
  FaroButton,
  FaroMenu,
  FaroText,
  SingleSelectItem,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
} from "@faro-lotv/flat-ui";
import { SvgIconProps } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

export enum AnnotationSortingOptions {
  /** Sort the annotation in descending chronological order based on the creation date */
  creationDateDown = "creationDateDown",
  /** Sort the annotation in ascending chronological order based on the creation date */
  creationDateUp = "creationDateUp",

  /** Sort the annotation in descending chronological order based on the due date */
  dueDateDown = "dueDateDown",
  /** Sort the annotation in ascending chronological order based on the due date */
  dueDateUp = "dueDateUp",

  /** Sort the annotation in alphabetical order based on the title */
  titleDown = "titleDown",
  /** Sort the annotation in reversed alphabetical order based on the title */
  titleUp = "titleUp",

  /** Sort the annotation in alphabetical order based on the assignee */
  assignee = "assignee",

  /** Sort the annotation in the natural order based on the assignee (unassigned -> todo -> in progress -> in review -> resolved) */
  status = "status",
}

/** The label showed in the menu based on the sorting option */
const SORTING_DISPLAY_NAME: Record<AnnotationSortingOptions, string> = {
  [AnnotationSortingOptions.creationDateDown]: "Creation date",
  [AnnotationSortingOptions.creationDateUp]: "Creation date",
  [AnnotationSortingOptions.dueDateDown]: "Due date",
  [AnnotationSortingOptions.dueDateUp]: "Due date",
  [AnnotationSortingOptions.titleDown]: "Title",
  [AnnotationSortingOptions.titleUp]: "Title",
  [AnnotationSortingOptions.assignee]: "Assignee",
  [AnnotationSortingOptions.status]: "Status",
};

type IconType = (props: SvgIconProps) => JSX.Element;

/** The icon showed in the menu based on the sorting option */
const ICONS_MAP: Record<AnnotationSortingOptions, IconType> = {
  [AnnotationSortingOptions.creationDateDown]: SortDownIcon,
  [AnnotationSortingOptions.creationDateUp]: SortUpIcon,
  [AnnotationSortingOptions.dueDateDown]: SortDownIcon,
  [AnnotationSortingOptions.dueDateUp]: SortUpIcon,
  [AnnotationSortingOptions.titleDown]: SortDownIcon,
  [AnnotationSortingOptions.titleUp]: SortUpIcon,
  [AnnotationSortingOptions.assignee]: SortIcon,
  [AnnotationSortingOptions.status]: SortIcon,
};

export type AnnotationSortMenuButtonProps = {
  /** Callback executed when the user changes the sorting criterion */
  onSortingChanged(option: AnnotationSortingOptions | undefined): void;
};

/** The default label of the button */
const DEFAULT_LABEL = "Sort by";

/** @returns the button and menu to select the annotation list sorting */
export function SortMenuButton({
  onSortingChanged,
}: AnnotationSortMenuButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [sortingOption, setSortingOption] =
    useState<AnnotationSortingOptions>();

  useEffect(
    () => onSortingChanged(sortingOption),
    [sortingOption, onSortingChanged],
  );
  const options = useMemo(
    () =>
      Object.values(AnnotationSortingOptions).map((option) => ({
        key: option,
        value: option,
        label: (
          <SingleSelectItem
            key={option}
            onClick={() =>
              setSortingOption(sortingOption === option ? undefined : option)
            }
            label={SORTING_DISPLAY_NAME[option]}
            value={option}
            selectedValue={sortingOption}
            Icon={ICONS_MAP[option]}
          />
        ),
      })),
    [sortingOption],
  );

  const buttonLabel = sortingOption
    ? SORTING_DISPLAY_NAME[sortingOption]
    : DEFAULT_LABEL;
  const ButtonIcon = sortingOption ? ICONS_MAP[sortingOption] : SortIcon;

  return (
    <>
      <FaroButton
        ref={setAnchorEl}
        sx={{ color: "black", minWidth: "150px" }}
        variant="ghost"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        icon={<ButtonIcon />}
      >
        {buttonLabel}
      </FaroButton>
      {anchorEl && (
        <FaroMenu
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
        >
          <FaroText
            variant="labelS"
            color="inherit"
            textTransform="uppercase"
            sx={{ paddingX: 1.5, paddingY: 1 }}
          >
            Sort by
          </FaroText>
          {options.map((o) => o.label)}
        </FaroMenu>
      )}
    </>
  );
}
