import { AttachmentIcon, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";

type CardAttachmentIconProps = {
  /** the number of attachments for this annotation */
  count: number;
};

/** @returns An icon with an indicator of the number of attachment */
export function CardAttachmentIcon({
  count,
}: CardAttachmentIconProps): JSX.Element {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ opacity: count > 0 ? 1 : 0 }}
    >
      <AttachmentIcon
        sx={{ color: neutral[500], width: "18px", height: "18px" }}
      />
      <FaroText variant="bodyM" sx={{ opacity: count > 0 ? 1 : 0 }}>
        {count}
      </FaroText>
    </Stack>
  );
}
