import {
  RenderDispatch,
  flagSubtree,
} from "@/modes/overview-mode/render-dispatch";
import { isSheetObject } from "@/object-cache-type-guard";
import {
  CopyToScreenPass,
  DesaturatePass,
  DesaturatePassProps,
  EffectPipeline,
  FilteredRenderPass,
} from "@faro-lotv/app-component-toolbox";
import { walkWithQueue } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { useFrame } from "@react-three/fiber";
import { Object3D } from "three";

interface Props extends DesaturatePassProps {
  /** ID of the element to desaturate */
  id: GUID;
}

/**
 * @returns a pipeline to apply desaturation to given element
 *
 * The enabled flag from the DesaturatePass should only be used when the R3f canvas or in a View
 * using DesaturationPipeline is sure to have only one EffectPipeline
 *
 * Having this EffectPipeline along with another one in a R3f canvas or in a View using DesaturationPipeline would conflict with each other
 */
export function DesaturationPipeline({ id, ...rest }: Props): JSX.Element {
  useFrame(({ scene }) => {
    const queue: Object3D[] = [scene];
    walkWithQueue(queue, (o, append) => {
      if (isSheetObject(o) && o.iElement.id === id) {
        flagSubtree(o, RenderDispatch.Floorplan);
        return true;
      }
      append(...o.children);
      return false;
    });
  }, 0);

  return (
    <EffectPipeline>
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type === RenderDispatch.Floorplan
        }
      />
      <DesaturatePass {...rest} />
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type !== RenderDispatch.Floorplan
        }
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipeline>
  );
}
