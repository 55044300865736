import {
  FaroMenuSeparator,
  FaroText,
  neutral,
  NO_TRANSLATE_CLASS,
} from "@faro-lotv/flat-ui";
import { List, ListItem } from "@mui/material";

type ListSupportedCadFormatsProps = {
  /**
   * If true, the table will be displayed in dark mode.
   */
  dark: boolean;
};

/**
 * List of most popular CAD formats supported by Sphere XG.
 * The list is basically the one provided by Autodesk APS (https://aps.autodesk.com/en/docs/model-derivative/v2/developers_guide/supported-translations/)
 * for formats supporting SVF output,
 * and when input file is a standalone monolithic file (as Sphere XG does not support yet multi-file CAD models).
 */
const POPULAR_SUPPORTED_CAD_FORMATS = [
  "NWD, NWC (Navisworks)",
  "RVT (Revit)",
  "IFC",
  "DWG (AutoCAD)",
];

/**
 * List of other CAD formats supported by Sphere XG.
 * The list is basically the one provided by Autodesk APS (https://aps.autodesk.com/en/docs/model-derivative/v2/developers_guide/supported-translations/)
 * for formats supporting SVF output,
 * and when input file is a standalone monolithic file (as Sphere XG does not support yet multi-file CAD models).
 */
const SUPPORTED_CAD_FORMATS = [
  "3DM (Rhino)",
  "3DS (3ds Max)",
  "A",
  "ASM (Creo)",
  "ASM (Solid Edge)",
  "ASM, ASM0, ASM1...",
  "BRD",
  "CATPART (CATIA)",
  "CGR",
  "COLLABORATION",
  "DAE",
  "DGN",
  "DLV3",
  "DWF",
  "DWFX",
  "DWG",
  "DWT",
  "DXF",
  "EMODE",
  "EXP",
  "F3D",
  "FBX",
  "G",
  "GBXML",
  "GLB",
  "IAM (Inventor)",
  "IDW (Inventor)",
  "IGE",
  "IGES",
  "IGS",
  "IPT (Inventor)",
  "IWM",
  "JT",
  "MAX",
  "MODEL",
  "MPF",
  "MSR",
  "NEU, NEU0, NEU1...",
  "NWC",
  "NWD",
  "OBJ",
  "PAR",
  "PDF",
  "PMLPRJ",
  "PMLPRJZ",
  "PRT (Creo)",
  "PRT (NX)",
  "PRT0, PRT1...",
  "PSM",
  "PSMODEL",
  "RVM",
  "RVT",
  "SAB (ACIS)",
  "SAT (ACIS)",
  "SCH",
  "SESSION",
  "SKP",
  "SLDPRT (SOLIDWORKS)",
  "STE",
  "STEP",
  "STL",
  "STLA",
  "STLB",
  "STP (STEP)",
  "STPZ (Compressed STEP)",
  "VUE",
  "WIRE (Alias)",
  "X_B (Parasolid)",
  "X_T (Parasolid)",
  "XAS",
  "XPR",
];

type SubListCadFormatsProps = {
  /** list of CAD formats */
  listFormats: string[];
  /**
   * If true, the table will be displayed in dark mode.
   */
  dark: boolean;
};

/**
 * Display a subset of the list of supported CAD formats.
 *
 * @returns The a list of supported CAD formats as a MUI's List
 */
function SubListCadFormats({
  listFormats,
  dark,
}: SubListCadFormatsProps): JSX.Element {
  const textColor = dark ? neutral[100] : neutral[800];
  return (
    <List disablePadding dense sx={{ marginBottom: 1, marginTop: 1 }}>
      {listFormats.map((format) => (
        <ListItem key={format}>
          <FaroText
            variant="bodyM"
            color={textColor}
            className={NO_TRANSLATE_CLASS}
          >
            {format}
          </FaroText>
        </ListItem>
      ))}
    </List>
  );
}
/**
 * Display the list of supported CAD formats as a plain text.
 *
 * @returns The list of supported CAD formats as a MUI's List
 */
export function ListSupportedCadFormats({
  dark,
}: ListSupportedCadFormatsProps): JSX.Element {
  return (
    <>
      <SubListCadFormats listFormats={POPULAR_SUPPORTED_CAD_FORMATS} dark />
      <FaroMenuSeparator dark={dark} />
      <SubListCadFormats listFormats={SUPPORTED_CAD_FORMATS} dark />
    </>
  );
}
