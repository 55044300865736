import { Box, Stack } from "@mui/material";
import { CanvasToolbar } from "./canvas-toolbar";
import { ModeSwitch } from "./mode-switch";
import { ViewSettingsToolbar } from "./view-settings-toolbar";

/**
 * @returns Overlay to render for all modes
 */
export function AllModesOverlay(): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        p: 2,
      }}
    >
      <Stack direction="column" justifyContent="end">
        <ModeSwitch />
      </Stack>

      <Stack
        direction="column"
        sx={{ height: "100%", py: 6 }}
        alignSelf="center"
      >
        <Stack direction="column" height="20%">
          <ViewSettingsToolbar />
        </Stack>
        <Stack direction="column" flexGrow={1} justifyContent="center">
          <CanvasToolbar />
        </Stack>
        {/** box used to ensure the CanvasToolbar is centered in the column */}
        <Box component="div" height="20%" />
      </Stack>
    </Stack>
  );
}
