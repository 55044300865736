import { useEffect } from "react";
import { Object3D } from "three";

/**
 * for some workflows (for example CAD alignment) we need to force objects visibility/non-visibility
 * then at cleanup restore original visibility
 *
 * @param object to apply visibility
 * @param visible visibility status to be applied (true - visible)
 */
export function useObjectVisibility(object: Object3D, visible: boolean): void {
  useEffect(() => {
    const originalVisibility = object.visible;
    object.visible = visible;
    return () => {
      object.visible = originalVisibility;
    };
  }, [object, visible]);
}
