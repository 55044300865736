import { PointCloudAnalysisLabel } from "@/store/point-cloud-analysis-tool-slice";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { MutableRefObject, useMemo } from "react";
import { Plane, Vector3 } from "three";
import { MeasureLabel } from "../measurements/measure-label";

type AnalysisLableProps = {
  /** Analysis label object  */
  label: PointCloudAnalysisLabel;

  /** Reference plane to compute distance value */
  referencePlane: Plane;

  /** Unit of measure to use for the labels */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;
};

/**
 * @returns Analysis label displays a deviation and located and the label position in 3D space.
 */
export function AnalysisLabel({
  label,
  referencePlane,
  unitOfMeasure,
  parentRef,
}: AnalysisLableProps): JSX.Element | null {
  const position = useMemo(
    () => new Vector3().fromArray(label.position),
    [label.position],
  );
  const distance = useMemo(
    () => referencePlane.distanceToPoint(position),
    [position, referencePlane],
  );

  return (
    <MeasureLabel
      index={0}
      visible
      position={position}
      parentRef={parentRef}
      distance={distance}
      unitOfMeasure={unitOfMeasure}
      active
      transparent={false}
      pointerEvents="none"
    />
  );
}
