import { useAppSelector } from "@/store/store-hooks";
import {
  FaroIconButton,
  FaroIconButtonSizes,
  FaroText,
  OpenLinkIcon,
  neutral,
} from "@faro-lotv/flat-ui";
import { isIElementImageCommand } from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { AnnotationWrapper } from "../annotation-wrapper";
import { AnnotationProps } from "../annotations-types";

/** @returns A renderer for an image annotation element */
export function ImageAnnotationRenderer({
  iElement,
  worldTransform,
}: AnnotationProps): JSX.Element | null {
  const linkToImage = useAppSelector(
    selectChildDepthFirst(iElement, isIElementImageCommand),
  );

  // If there is no image uri in the annotation element, do not render anything
  if (!linkToImage?.uri) return null;

  return (
    <AnnotationWrapper
      iElement={iElement}
      worldTransform={worldTransform}
      style={{ padding: 2 }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FaroText variant="heading14" sx={{ color: neutral[0] }} noWrap>
          {linkToImage.fileName}
        </FaroText>
        <FaroIconButton
          target="_blank"
          href={linkToImage.uri}
          color={neutral[0]}
          size={FaroIconButtonSizes.s}
        >
          <OpenLinkIcon sx={{ width: 20, height: 20 }} />
        </FaroIconButton>
      </Stack>
      <img
        src={linkToImage.uri}
        alt={linkToImage.name}
        loading="lazy"
        width={400}
      />
    </AnnotationWrapper>
  );
}
