import { CloudToSheetAlignmentStepNames } from "@/alignment-tool/alignment-steps/steps";
import { RootState } from "@/store/store";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericPointCloudStream,
  IElementSection,
  isIElementAreaSection,
  isIElementGenericPointCloudStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { AlignmentTransform, assertAlignmentMounted } from "./alignment-slice";

/**
 * @returns The active step of the alignment tool
 * @param root The root state
 */
export function selectActiveStep(
  root: RootState,
): CloudToSheetAlignmentStepNames | undefined {
  assertAlignmentMounted(root.alignment);

  return root.alignment.activeStep;
}

/**
 * @returns The ID of the current active step
 * @param root The root state
 */
export function selectActiveStepId(root: RootState): number | undefined {
  assertAlignmentMounted(root.alignment);

  const { activeStep } = root.alignment;

  if (!activeStep) {
    return;
  }

  return Object.values(CloudToSheetAlignmentStepNames).indexOf(activeStep);
}

/**
 *
 * @param root The root state
 * @returns The cached element to align transform
 */
export function selectElementToAlignTransform(
  root: RootState,
): AlignmentTransform | undefined {
  return root.alignment.elementToAlignTransform;
}

/**
 * @param root The root state
 * @returns The cached element current area selected for alignment
 *          In case of cloud alignment alignmentArea is a reference area for selected cloud
 *          In case of CAD alignment alignmentArea is the area/sheet being aligned to the CAD
 *          'alignmentArea' can be set in alignment slice by setAlignmentArea()
 */
export function selectAlignmentArea(
  root: RootState,
): IElementSection | undefined {
  if (!root.alignment.alignmentArea) {
    return;
  }

  const areaElement = selectIElement(root.alignment.alignmentArea)(root);
  if (areaElement && isIElementAreaSection(areaElement)) return areaElement;
}
/**
 * @returns The IElement for the pointcloud that the user want to align
 * @param root the current application state
 */
export function selectCloudToAlign(
  root: RootState,
): IElementGenericPointCloudStream | undefined {
  if (!root.alignment.cloudToAlign) {
    return;
  }
  const element = selectIElement(root.alignment.cloudToAlign)(root);
  if (!element) {
    return;
  }
  if (isIElementSectionDataSession(element)) {
    return selectChildDepthFirst(
      element,
      isIElementGenericPointCloudStream,
    )(root);
  }
  if (!isIElementGenericPointCloudStream(element)) {
    return;
  }
  return element;
}

/**
 * @returns true if the alignment tool is busy and should show a full page modal spinner
 * @param root state of the application
 */
export function selectIsAlignmentToolBusy(root: RootState): boolean {
  return root.alignment.isBusy;
}

/**
 * @returns true if it's possible to change also the scale during the alignment
 * @param root state of the application
 */
export function selectIsScaleEnabled(root: RootState): boolean {
  return root.alignment.isScaleEnabled;
}

/**
 * @returns true if the user is currently scaling during the alignment
 * @param root state of the application
 */
export function selectIsScaling(root: RootState): boolean {
  return root.alignment.isScaling;
}

/**
 * @returns true if the alignment process has been completed successfully
 * @param root state of the application
 */
export function selectIsAlignmentCompleted(root: RootState): boolean {
  return root.alignment.isAlignmentCompleted;
}

/**
 * @returns true if the alignment tool should show a warning dialog on window close
 * @param root state of the application
 */
export function selectShouldAlignmentWarnOnWindowClose(
  root: RootState,
): boolean {
  return (
    root.alignment.isAlignmentToolMounted &&
    !root.alignment.isAlignmentCompleted
  );
}
