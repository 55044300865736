import { neutral } from "@faro-lotv/flat-ui";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { useTheme } from "@mui/material";
import { HtmlProps } from "@react-three/drei/web/Html";
import { useThree } from "@react-three/fiber";
import { MutableRefObject } from "react";
import { Material } from "three";
import { LabelsScreenPositionsComputer } from "../../utils/labels-screen-positions-computer";
import { MeasureDepthOffsets } from "./measure-constants";
import { MeasureLabel } from "./measure-label";
import { SegmentData } from "./measure-types";
import { SegmentRenderer } from "./segment-renderer";

const LINE_WIDTH = 2;
const LINE_SHADOW_FACTOR = 2;
const AXIS_OPACITY = 0.6;

type TwoPointMeasureSegmentBaseProps = Pick<
  TwoPointMeasureSegmentProps,
  "start" | "end" | "main" | "visible" | "dashed" | "depthTest"
>;

/** @returns A component to render a moving measure segment */
function TwoPointMeasureSegmentBase({
  start,
  end,
  main,
  visible,
  dashed,
  depthTest,
}: TwoPointMeasureSegmentBaseProps): JSX.Element {
  const dpr = useThree((state) => state.gl.getPixelRatio());
  const baseWidth = Math.max(LINE_WIDTH / dpr, 1);
  const theme = useTheme();

  return (
    <>
      <SegmentRenderer
        visible={visible}
        start={start}
        end={end}
        color={main ? theme.palette.magenta : neutral[0]}
        opacity={main ? 1 : AXIS_OPACITY}
        width={baseWidth}
        zOffset={MeasureDepthOffsets.line}
        dashed={dashed}
        depthTest={depthTest}
      />
      <SegmentRenderer
        visible={visible}
        start={start}
        end={end}
        color={neutral[1000]}
        opacity={0.2}
        width={baseWidth * LINE_SHADOW_FACTOR}
        zOffset={MeasureDepthOffsets.lineShadow}
        dashed={dashed}
        depthTest={depthTest}
      />
    </>
  );
}

type TwoPointMeasureSegmentProps = SegmentData & {
  /** Index of the segment used for the onClick callback */
  index: number;

  /** true to notify this is the main segment and not one of the axis decomposition ones */
  main?: boolean;

  /** True if the user is editing this segment live */
  live: boolean | undefined;

  /** True if the user selected this measurement as the active one */
  isMeasurementActive: boolean | undefined;

  /** True if this label is the active one */
  isLabelActive: boolean | undefined;

  /** True if the label should be rendered */
  isLabelVisible?: boolean;

  /** Reference to the HTMLElement to use as a parent for the labels */
  labelContainer: MutableRefObject<HTMLElement>;

  /** Unit of measure to use to render the distances in the labels */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Support class to optimize the actual position of the labels so they do not overlap */
  labelsScreenPositionsComputer?: LabelsScreenPositionsComputer;

  /** The pointer events allowed on the labels */
  labelsPointerEvents: HtmlProps["pointerEvents"];

  /** Callback called when the user click on the segment label */
  onClick(index: number): void;

  /** Make the line dashed */
  dashed?: boolean;

  /** Whether depth testing should be used to render the segment */
  depthTest?: Material["depthTest"];
};

/** @returns the advanced rendering of two point measurement segment  */
export function TwoPointMeasureSegment({
  index,
  main,
  prefix,
  live,
  isMeasurementActive = false,
  isLabelActive = false,
  isLabelVisible = true,
  labelContainer,
  visible,
  depthTest,
  start,
  end,
  length,
  labelPosition,
  unitOfMeasure,
  labelsScreenPositionsComputer,
  labelsPointerEvents,
  dashed,
  onClick,
}: TwoPointMeasureSegmentProps): JSX.Element | null {
  const transparent = !(isMeasurementActive || live);

  return (
    <>
      <TwoPointMeasureSegmentBase
        start={start}
        end={end}
        main={main}
        visible={visible}
        dashed={dashed}
        depthTest={depthTest}
      />
      <MeasureLabel
        index={index}
        visible={visible && isLabelVisible}
        position={labelPosition}
        parentRef={labelContainer}
        distance={length}
        symbol={prefix}
        unitOfMeasure={unitOfMeasure}
        labelsScreenPositionsComputer={labelsScreenPositionsComputer}
        active={isLabelActive}
        onClick={(ev) => {
          ev.stopPropagation();
          onClick(index);
        }}
        transparent={transparent}
        pointerEvents={labelsPointerEvents}
      />
    </>
  );
}
