import { roundedCalculatePosition } from "@faro-lotv/app-component-toolbox";
import { PolygonPoint } from "@faro-lotv/ielement-types";
import { clamp, memberWithPrivateData } from "@faro-lotv/lotv";
import {
  Box3,
  Camera,
  Matrix4,
  Mesh,
  Object3D,
  OrthographicCamera,
  Vector3,
  Vector3Tuple,
} from "three";
import { AlignAt } from "./annotations-types";

export type CanvasSize = {
  /** Width of the canvas */
  width: number;

  /** Height of the canvas */
  height: number;
};

const CACHED_GEO_BBOX = new Box3();
const DEFAULT_GEO_BBOX = new Box3(
  new Vector3(-0.5, -0.5, 0),
  new Vector3(0.5, 0.5, 0),
);
const CACHED_DISPLACEMENT_VECTOR = new Vector3();

type MoveToCornerProps = {
  /** Element which is used as reference for placing the HTML element */
  el: Object3D;

  /** the annotation object on the scene */
  annotationObject: Object3D;

  /** Current camera */
  camera: Camera;

  /**  Current size of the canvas*/
  canvasSize: CanvasSize;

  /** position where the label should be aligned to */
  alignAt: AlignAt;
};

/**
 * Custom position calculation for placing HTML elements in the given corner of an annotation object
 *
 * @returns The coordinates of the annotation object to which the label should align to
 */
export function moveToCorner({
  el,
  annotationObject,
  camera,
  canvasSize,
  alignAt,
}: MoveToCornerProps): [number, number] {
  /** Bounding box of the annotations */
  let geoBox;

  // Otherwise get the bounding box from the Mesh
  const meshes = annotationObject.getObjectsByProperty("type", "Mesh");
  const mesh = meshes[0] ?? annotationObject;

  if (!(mesh instanceof Mesh)) {
    return roundedCalculatePosition(el, camera, canvasSize);
  }

  if (mesh.geometry.boundingBox) {
    geoBox = CACHED_GEO_BBOX.copy(mesh.geometry.boundingBox);
  } else {
    // Sometimes the bounding box is not present on the geometry, then we use some defaults we know to work
    geoBox = CACHED_GEO_BBOX.copy(DEFAULT_GEO_BBOX);
  }

  // If the flag is set use the right corner otherwise use the left one
  const displacementX =
    alignAt === AlignAt.boundingBoxTopRight ? geoBox.max.x : geoBox.min.x;

  // Vector for moving from center to top left in local coordinates
  const displacement = CACHED_DISPLACEMENT_VECTOR.set(
    displacementX,
    geoBox.max.y,
    geoBox.max.z,
  );

  // now object pos contains the obj pos in global coordinates
  const anchorPoint = displacement.applyMatrix4(mesh.matrixWorld);

  return roundedCalculatePosition(anchorPoint, camera, canvasSize);
}

interface MoveToAnchorPointProps
  extends Omit<MoveToCornerProps, "el" | "alignAt" | "annotationObject"> {
  /** The anchor point in world space of the object for the label to align to */
  anchorPointWorld: Vector3;
}

/**
 * Custom position calculation for placing HTML elements using a specific anchor point
 *
 * @returns The anchor point coordinates of the annotation
 */
export function moveToAnchorPoint({
  camera,
  canvasSize,
  anchorPointWorld,
}: MoveToAnchorPointProps): [number, number] {
  return roundedCalculatePosition(anchorPointWorld, camera, canvasSize);
}

/** Possible visibility states of a measurement */
export enum AnnotationVisibility {
  /** The measurement should be fully visible */
  Visible = 0,

  /** Only an icon should be visible */
  Minified = 2,

  /** The measurement should be hidden */
  NotVisible = 3,
}

/** The distance threshold over which annotations are collapsed to an icon (in meters) */
const COLLAPSE_THRESHOLD = 8;

/** The distance threshold over which annotations are hidden (in meters) */
const DEFAULT_HIDE_THRESHOLD = 30;

/** The distance threshold after which annotations start fading (in meters) */
const DEFAULT_FADE_THRESHOLD = 20;

/** The orthogonal camera size threshold over which annotations are collapsed to an icon (in meters) */
const COLLAPSE_THRESHOLD_ORTHO = 40;

/**
 * Threshold for computing the occupancy in Normalized Device Coordinates, that is equal to the 50% of the screen sizes.
 * In NDC, the camera frustum is always 2x2x2, so if we want a threshold of 50%, we get 0.5 * 2 = 1.
 */
const EXPANDED_THRESHOLD_NDC = 1;

interface AnnotationVisibilityResult {
  /** The visibility of the annotation */
  visibility: AnnotationVisibility;

  /** fade value [0,1] that can be used to animate e.g. the opacity or scale for a smooth fade */
  fade: number;

  /** Heuristic to determine which annotation is most important to show. (Higher value = more important)*/
  importance: number;
}

export const computeAnnotationVisibility = memberWithPrivateData(() => {
  const TEMP_VEC3 = new Vector3();
  const TEMP_VEC3_2 = new Vector3();
  const TEMP_MAT4 = new Matrix4();
  const TEMP_BOX3_1 = new Box3();
  const TEMP_BOX3_2 = new Box3();

  return (
    camera: Camera,
    annotation: Vector3Tuple[] | { points: PolygonPoint[] },
    worldTransform: Matrix4 = TEMP_MAT4,
    fadeThreshold: number = DEFAULT_FADE_THRESHOLD,
    hideThreshold: number = DEFAULT_HIDE_THRESHOLD,
  ): AnnotationVisibilityResult => {
    const boxNDC = TEMP_BOX3_1.makeEmpty();
    const boxWorld = TEMP_BOX3_2.makeEmpty();

    const fadeDistance = hideThreshold - fadeThreshold;

    // Compute the bounding box in world coordinates and normalized device coordinates
    const points = Array.isArray(annotation) ? annotation : annotation.points;
    for (const p of points) {
      if (Array.isArray(p)) {
        TEMP_VEC3.set(p[0], p[1], p[2]);
      } else {
        TEMP_VEC3.set(p.x, p.y, -p.z);
      }
      TEMP_VEC3.applyMatrix4(worldTransform);
      boxWorld.expandByPoint(TEMP_VEC3);

      TEMP_VEC3.project(camera);
      boxNDC.expandByPoint(TEMP_VEC3);
    }

    // If the box is larger than the threshold and intersects the camera frustum,
    // show the annotation
    const sizeNDC = boxNDC.getSize(TEMP_VEC3);
    if (
      (sizeNDC.x > EXPANDED_THRESHOLD_NDC ||
        sizeNDC.y > EXPANDED_THRESHOLD_NDC) &&
      ((boxNDC.min.z > -1 && boxNDC.min.z < 1) ||
        (boxNDC.max.z > -1 && boxNDC.max.z < 1))
    ) {
      return {
        visibility: AnnotationVisibility.Visible,
        fade: 1,
        importance: Math.max(sizeNDC.x, sizeNDC.y, sizeNDC.z),
      };
    }

    // Cull annotations outside viewport so they don't count towards the global annotation limit
    if (
      boxNDC.max.x < -1 ||
      boxNDC.min.x > 1 ||
      boxNDC.max.y < -1 ||
      boxNDC.min.y > 1
    ) {
      return {
        visibility: AnnotationVisibility.NotVisible,
        fade: 0,
        importance: 0,
      };
    }

    const boxCenter = boxWorld
      .getCenter(TEMP_VEC3)
      .applyMatrix4(camera.matrixWorldInverse);

    // Assign higher importance to annotations close to the center
    const ndcCenter = boxNDC.getCenter(TEMP_VEC3_2);
    let importance = 1 - ndcCenter.x * ndcCenter.x - ndcCenter.y * ndcCenter.y;

    // If camera is orthographic, just take into account the frustum
    if (camera instanceof OrthographicCamera) {
      const orthoSize =
        Math.min(camera.top - camera.bottom, camera.right - camera.left) /
        camera.zoom;

      return {
        visibility:
          orthoSize > COLLAPSE_THRESHOLD_ORTHO
            ? AnnotationVisibility.Minified
            : AnnotationVisibility.Visible,
        fade: 1,
        importance,
      };
    }

    // Check the distance of the object from the camera and its max distance on the camera plane.
    const d = boxCenter.length() * -Math.sign(boxCenter.z);
    importance = 1 - d / hideThreshold;
    if (d <= 0 || d > hideThreshold) {
      return {
        visibility: AnnotationVisibility.NotVisible,
        fade: 0,
        importance: 0,
      };
    } else if (d < COLLAPSE_THRESHOLD) {
      return {
        visibility: AnnotationVisibility.Visible,
        fade: 1,
        importance,
      };
    }
    return {
      visibility: AnnotationVisibility.Minified,
      fade:
        fadeDistance === 0
          ? 1
          : clamp(1 - (d - fadeThreshold) / fadeDistance, 0, 1),
      importance,
    };
  };
});
