import { ProjectIntegrationProviderName } from "@/store/integrations/integrations-selectors";
import { assert } from "@faro-lotv/foundation";
import {
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

type IntegrationsDataAssociations = {
  /** The "BCF Services" integration type */
  bcfIntegrationType: BcfServicesIntegrationType;
  /** The iElementType corresponding to the "BCF Services" integration type */
  iElementType: ExternalMarkupIElement["type"];
  /** The name of the project integration provider */
  providerName: ProjectIntegrationProviderName;
};

// The associations between "BCF Services" integration types and the other related data adn types defined elsewhere
const integrationsDataAssociations: IntegrationsDataAssociations[] = [
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccIssues,
    iElementType: IElementType.markupAccIssue,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccRfis,
    iElementType: IElementType.markupAccRfi,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskBim360Issues,
    iElementType: IElementType.markupBim360,
    providerName: ProjectIntegrationProviderName.autodeskBim360,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreObservations,
    iElementType: IElementType.markupProcoreObservation,
    providerName: ProjectIntegrationProviderName.procore,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreRfis,
    iElementType: IElementType.markupProcoreRfi,
    providerName: ProjectIntegrationProviderName.procore,
  },
];

/**
 * @param providerName The name of the integration provider
 * @returns all "BCF Services" integration types for the given project integration provider
 */
export function getAllBcfServicesIntegrationTypes(
  providerName: ProjectIntegrationProviderName,
): BcfServicesIntegrationType[] {
  return integrationsDataAssociations
    .filter((association) => association.providerName === providerName)
    .map((association) => association.bcfIntegrationType);
}

/**
 * @param bcfIntegrationType  The bcf integration type for which the we need the corresponding iElement type
 * @returns The iElement type corresponding to the given bcf integration type
 */
export function getIElementTypeForBcfIntegrationType(
  bcfIntegrationType: BcfServicesIntegrationType,
): ExternalMarkupIElement["type"] {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.bcfIntegrationType === bcfIntegrationType,
  );
  assert(
    valueFound,
    `The bcf service integration type ${bcfIntegrationType} is not found`,
  );
  return valueFound.iElementType;
}

/**
 * @param iElementType The iElement type for which the we need the corresponding BCF Services integration type
 * @returns The BCF Services integration type corresponding to the given iElement type
 */
export function getBcfIntegrationTypeForIElementType(
  iElementType: ExternalMarkupIElement["type"],
): BcfServicesIntegrationType {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.iElementType === iElementType,
  );
  assert(valueFound, `The iElementType ${iElementType} is not found`);
  return valueFound.bcfIntegrationType;
}
