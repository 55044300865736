import { AppDispatch, RootState } from "@/store/store";
import { ProjectApi } from "@faro-lotv/service-wires";
import { Camera } from "@react-three/fiber";
import { CubeTexture, OrthographicCamera } from "three";

/** The  steps for aligning cloud to sheet */
export enum CloudToSheetAlignmentStepNames {
  alignIn2d = "alignIn2d",
  setElevation = "setElevation",
}

export type StepTransitionProps = {
  /** The previous mode name we're coming from */
  previousMode: CloudToSheetAlignmentStepNames;

  /** The last environment map generated before the transition */
  previousEnvMap: CubeTexture;

  /** A callback to signal this transition is finished */
  onCompleted(): void;

  /** The default camera that by design is used in all transitions */
  defaultCamera: OrthographicCamera;

  /** The mode custom camera if present, defaultCamera if not present */
  modeCamera: Camera;
};

export type CameraRestorer = (
  custom: Camera,
  global: OrthographicCamera,
) => void;

/**
 * Base layout of a mode
 */
export type Step = {
  /** The mode name, used in url or logging */
  name: CloudToSheetAlignmentStepNames;

  /** The R3F scene to render */
  Scene(): JSX.Element | null;

  /** The 2D overlay */
  Overlay?(): JSX.Element | null;

  /** A transition to animate from another mode to this mode */
  Transition?(props: StepTransitionProps): JSX.Element | null;

  /**
   * A mode can optionally specify a custom camera, but it also needs
   * to specify how to restore the global camera.
   *
   * If not specified, the default camera is used.
   */
  customCamera?: {
    camera(): Camera;
    restoreGlobalCamera: CameraRestorer;
  };

  /**
   * Function called before moving to the next step to allow the current step
   * to finalize or update the app state
   *
   * @param client the client to talk to the project api
   * @param state current state
   * @param dispatch dispatch function to update the current state
   */
  onBeforeNext?(
    client: ProjectApi,
    state: RootState,
    dispatch: AppDispatch,
  ): Promise<void>;

  /**
   * Function called when this step has started moving back in the workflow
   *
   * @param client the client to talk to the project api
   * @param state current state
   * @param dispatch dispatch function to update the current state
   */
  onStartingFromBack?(
    client: ProjectApi,
    state: RootState,
    dispatch: AppDispatch,
  ): Promise<void>;
};
