import { useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import {
  ComputeHiddenPlaceholdersState,
  OverviewWaypointsRenderer,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import { useCallback, useRef } from "react";
import { Vector3 } from "three";
import PointSvg from "./VM_WaypointDefault.svg?url";
import HoverSvg from "./VM_WaypointHover.svg?url";
import SelectedSvg from "./VM_WaypointSelected.svg?url";

/** Waypoint size in default state */
const BASE_SIZE = 0.1;

type WalkPathPlaceholdersProps = {
  /** The currently active placeholder id. */
  activeId?: number;

  /** All placeholders on the path. */
  placeholders: Vector3[];

  /** Whether to enable a fade off in the distance. */
  fadeOff?: boolean;

  /** Whether the active placeholder should be highlighted. */
  shouldHighlightActive?: boolean;

  /** A callback for when a placeholder is being clicked. */
  onPlaceholderClick(id: number): void;

  /** Callback executed when the hovered placeholder changes */
  onPlaceholderHovered?(id?: number): void;
};

/**
 * @returns The placeholders for 360 images on the video mode walk path.
 */
export function WalkPathPlaceholders({
  activeId,
  placeholders,
  onPlaceholderClick,
  onPlaceholderHovered,
  fadeOff,
  shouldHighlightActive,
}: WalkPathPlaceholdersProps): JSX.Element | null {
  const defaultTexture = useSvg(PointSvg, 256, 256);
  const hoveredTexture = useSvg(HoverSvg, 256, 256);
  const selectedTexture = useSvg(SelectedSvg, 256, 256);

  const visiblePoints = useRef<Vector3[]>([]);

  // Computes the hidden placeholders based on their world-space distance
  // Prioritizes showing the active pano on the path
  const computeHiddenPlaceholders = useCallback(
    ({
      previousHidden,
      positions,
      selectedIndex,
    }: ComputeHiddenPlaceholdersState) => {
      // no need to re-compute the hidden placeholders if they are calculated once already and the selectedIndex isn't in them
      if (
        previousHidden.length !== 0 &&
        (!selectedIndex ||
          previousHidden.find((index) => index === selectedIndex) === undefined)
      ) {
        return previousHidden;
      }

      const minDistance = BASE_SIZE * 2;
      const minDistanceSq = minDistance * minDistance;
      const hidden = [];
      visiblePoints.current.length = 0;
      for (let idx = 0; idx < positions.length; ++idx) {
        // Always making the selected, start and end placeholders visible
        if (
          idx === selectedIndex ||
          idx === 0 ||
          idx === positions.length - 1
        ) {
          visiblePoints.current.push(positions[idx]);
          continue;
        }

        const toHide =
          (selectedIndex !== undefined &&
            positions[selectedIndex].distanceToSquared(positions[idx]) <
              minDistanceSq) ||
          visiblePoints.current.some(
            (point) => point.distanceToSquared(positions[idx]) < minDistanceSq,
          );

        if (toHide) {
          hidden.push(idx);
        } else {
          visiblePoints.current.push(positions[idx]);
        }
      }
      return hidden;
    },
    [],
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);

  return (
    <OverviewWaypointsRenderer
      waypoints={placeholders}
      baseSize={BASE_SIZE}
      hoveredSizeFactor={1.5}
      selectedSizeFactor={2}
      selectedId={shouldHighlightActive ? activeId : undefined}
      onWaypointClicked={onPlaceholderClick}
      onWaypointHovered={onPlaceholderHovered}
      fadeOffDistance={fadeOff ? visibilityDistance : undefined}
      computeHidden={computeHiddenPlaceholders}
      textures={{
        defaultTexture,
        hoveredTexture,
        selectedTexture,
      }}
    />
  );
}
