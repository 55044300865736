import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { useAppSelector } from "@/store/store-hooks";
import {
  isFileUploadTask,
  isGenericRegistrationTask,
  isProcessingTask,
} from "@/utils/background-tasks";
import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  ProgressApiSupportedTaskTypes,
} from "@faro-lotv/service-wires";
import { ErrorCard } from "./other-cards/error-card";
import { ProcessingTaskCard } from "./tasks-cards/processing-task-card";
import { RegistrationTaskCard } from "./tasks-cards/registration-task-card";
import { UploadTaskCard } from "./tasks-cards/upload-task-card";

type TaskCardProps = {
  /** Id of the task to show a task card for */
  taskID: GUID;
};

/** @returns the task menu card to notify the user of a background task */
export function TaskCard({ taskID }: TaskCardProps): JSX.Element {
  const task = useAppSelector(selectBackgroundTask(taskID));
  assert(task, "a TaskCard need to be created on an existing BackgroundTask");
  assert(
    isFileUploadTask(task) ||
      isProcessingTask(task) ||
      isGenericRegistrationTask(task),
    "a TaskCard expects a file upload task or a Processing task",
  );

  if (task.state === BackgroundTaskState.failed) {
    return <ErrorCard task={task} />;
  }

  switch (task.type) {
    case "FileUpload":
      return <UploadTaskCard task={task} />;
    case ProgressApiSupportedTaskTypes.c2cRegistration:
    case ProgressApiSupportedTaskTypes.registerMultiCloudDataSet:
    case ProgressApiSupportedTaskTypes.mergePointClouds:
      return <RegistrationTaskCard task={task} />;
    default:
      return <ProcessingTaskCard task={task} />;
  }
}
