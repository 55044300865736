import { Backdrop, CircularProgress, SxProps, Theme } from "@mui/material";

export type ModalSpinnerProps = {
  /** The style of the backdrop */
  sx?: SxProps<Theme>;
  /** The flag used to show/hide the backdrop */
  open: boolean;
};

/**
 * @returns A modal spinner for loading screen
 */
export function ModalSpinner({ sx, open }: ModalSpinnerProps): JSX.Element {
  return (
    <Backdrop sx={sx} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
