import { selectActiveCadModel } from "@/store/cad/cad-slice";
import {
  selectCloudAndCadAlignReference,
  selectCloudCrossSectionEnabled,
  selectCloudForCadAlignment,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentSplitDirection,
  selectCloudToCadAlignmentStep,
  selectModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  AlignmentReference,
  CloudToCadAlignmentStep,
  setCloudCrossSectionEnabled,
  setCloudToCadAlignmentSplitDirection,
  setModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { SplitDirection } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectAncestor } from "@faro-lotv/app-component-toolbox";
import { isIElementSectionDataSession } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import {
  AlignmentModeSplitScreenOverlay,
  CrossSectionProps,
} from "../alignment-modes-commons/alignment-mode-split-screen-overlay";
import { ModelElevationOverlay } from "./model-elevation-overlay";

/**
 * @returns Renderer of split screen for aligning cloud data with CAD models.
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side comparison of point cloud data and CAD models.
 * - Facilitates accurate alignment with full viewport visibility and interaction.
 * - Users can manipulate and view both data sources simultaneously for effective comparison.
 */
export function CloudToCadAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const alignmentStep = useAppSelector(selectCloudToCadAlignmentStep);
  const alignmentSplitDirection = useAppSelector(
    selectCloudToCadAlignmentSplitDirection,
  );
  // in the first step, always split into left and right
  const splitDirection = useMemo(() => {
    return alignmentStep === CloudToCadAlignmentStep.setElevations
      ? SplitDirection.horizontalSplit
      : alignmentSplitDirection;
  }, [alignmentSplitDirection, alignmentStep]);

  const isModelSectionEnabled = useAppSelector(selectModelCrossSectionEnabled);
  const isCloudSectionEnabled = useAppSelector(selectCloudCrossSectionEnabled);

  const modelElevation = useAppSelector(
    selectCloudToCadAlignmentModelElevation,
  );
  const cloudElevation = useAppSelector(
    selectCloudToCadAlignmentCloudElevation,
  );

  const activeCad = useAppSelector(selectActiveCadModel);

  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  const activeCloud = useAppSelector(selectIElement(activeCloudID));
  const pointCloudSection = useAppSelector(
    selectAncestor(activeCloud, isIElementSectionDataSession),
  );

  const reference = useAppSelector(selectCloudAndCadAlignReference);

  // callback function for changing split-screen direction in the second step
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setCloudToCadAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const { cloudCrossSectionProps, cadCrossSectionProps } = useMemo(() => {
    if (alignmentStep !== CloudToCadAlignmentStep.alignIn2d) {
      return {};
    }

    const cloudCrossSectionProps: CrossSectionProps = {
      enabled: isCloudSectionEnabled,
      elevation: cloudElevation,
      onToggleCrossSection: () => {
        dispatch(setCloudCrossSectionEnabled(!isCloudSectionEnabled));
      },
    };
    const cadCrossSectionProps: CrossSectionProps = {
      enabled: isModelSectionEnabled,
      elevation: modelElevation,
      onToggleCrossSection: () => {
        dispatch(setModelCrossSectionEnabled(!isModelSectionEnabled));
      },
    };
    return { cloudCrossSectionProps, cadCrossSectionProps };
  }, [
    alignmentStep,
    cloudElevation,
    dispatch,
    isCloudSectionEnabled,
    isModelSectionEnabled,
    modelElevation,
  ]);

  return (
    <>
      <AlignmentModeSplitScreenOverlay
        splitDirection={splitDirection}
        onChangeSplitDirection={
          alignmentStep === CloudToCadAlignmentStep.alignIn2d
            ? changeSplitDirection
            : undefined
        }
        firstScreenCrossSection={
          reference === AlignmentReference.bimModel
            ? cloudCrossSectionProps
            : cadCrossSectionProps
        }
        secondScreenCrossSection={
          reference === AlignmentReference.bimModel
            ? cadCrossSectionProps
            : cloudCrossSectionProps
        }
        alignedElement={
          reference === AlignmentReference.bimModel
            ? pointCloudSection
            : activeCad
        }
        referenceElement={
          reference === AlignmentReference.bimModel
            ? activeCad
            : pointCloudSection
        }
      />
      {alignmentStep === CloudToCadAlignmentStep.setElevations && (
        <ModelElevationOverlay />
      )}
    </>
  );
}
