import { BackgroundTask } from "@/utils/background-tasks";
import { LinearProgress } from "@mui/material";

type CardProgressProps = {
  /** Label for the progress notification */
  label: string;

  /** Task to show the progress of */
  task?: BackgroundTask | null;
};

/** @returns a label or a linear progress to notify a BackgroundTask state in the upload menu card */
export function CardProgress({ task }: CardProgressProps): JSX.Element | null {
  if (!task) {
    return null;
  }

  return (
    <LinearProgress
      value={task.progress}
      variant={task.progress > 0 ? "determinate" : "indeterminate"}
    />
  );
}
