import { clearStore } from "@faro-lotv/project-source";
import { RegisteredUserInfo } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type UserState = {
  /** This property is defined if the user is logged in, undefined for un-logged users */
  user?: RegisteredUserInfo;
};

const initialState: UserState = {};

/** Slice to store and manage the current user information */
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    /**
     * Update the current user data stored in the slice
     *
     * @param state current state
     * @param action the new user info or undefined if the user logged-out
     */
    setCurrentUser(
      state,
      action: PayloadAction<RegisteredUserInfo | undefined>,
    ) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const userReducer = userSlice.reducer;

export const { setCurrentUser } = userSlice.actions;
