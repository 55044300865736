import { EventType } from "@/analytics/analytics-events";
import { selectRegistrationJobId } from "@/registration-tools/common/store/registration-selectors";
import { resetRegistrationJob } from "@/registration-tools/common/store/registration-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { Banner, FaroDialog, useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useCallback, useState } from "react";

/** Info about current alignment and callbacks for events */
export type PairwiseRegistrationBannerProps = {
  /** The user is requesting to cancel the current registration job */
  onRegistrationCancelClick(): Promise<string | undefined>;
};

/**
 * @returns Utility bar that provides buttons for registration refinement and confirming the result
 */
export function PairwiseRegistrationBanner({
  onRegistrationCancelClick,
}: PairwiseRegistrationBannerProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();

  const [isRefinementCancelDialogOpen, setIsRefinementCancelDialogOpen] =
    useState(false);

  const activeRegJobId = useAppSelector(selectRegistrationJobId);

  const cancelRegistration = useCallback(async (): Promise<void> => {
    setIsRefinementCancelDialogOpen(false);
    Analytics.track(EventType.cancelPairwiseAutomaticRegistrationTask);
    const cancelledJobId = await onRegistrationCancelClick();
    if (!cancelledJobId || cancelledJobId !== activeRegJobId) {
      openToast({
        title: "Could not cancel automatic registration.",
        variant: "error",
      });
      return;
    }

    openToast({
      title: "Automatic registration cancelled.",
      variant: "success",
    });
    dispatch(resetRegistrationJob());
  }, [activeRegJobId, dispatch, onRegistrationCancelClick, openToast]);

  return (
    <>
      <Banner
        variant="info"
        title="Registration in progress"
        action={{
          label: "Cancel",
          // Only show cancel button once the backend job can be canceled
          action: activeRegJobId
            ? () => setIsRefinementCancelDialogOpen(true)
            : undefined,
        }}
      >
        Your automatic registration is in progress, manipulation is not
        possible.
      </Banner>
      <FaroDialog
        open={isRefinementCancelDialogOpen}
        title="Cancel automatic registration?"
        onConfirm={cancelRegistration}
        onCancel={() => setIsRefinementCancelDialogOpen(false)}
      >
        By confirming, the automatic registration will be aborted, any progress
        will be lost.
      </FaroDialog>
    </>
  );
}
