import { useSvg } from "@faro-lotv/app-component-toolbox";
import { Texture } from "three";
import FlashDefaultSvg from "./VM_ScanMarkerFlash.svg?url";
import FlashHoveredSvg from "./VM_ScanMarkerFlash_hovered.svg?url";

export type FlashScanIcons = {
  /** Texture representing the default flash scan icon */
  flashDefault: Texture;
  /** Texture representing the hovered flash scan icon */
  flashHovered: Texture;
};

/**
 *
 * @returns Textures to use to represent flash scan waypoints
 */
export function useFlashScanIcons(): FlashScanIcons {
  const flashDefault = useSvg(FlashDefaultSvg, 64, 64);
  const flashHovered = useSvg(FlashHoveredSvg, 64, 64);

  return {
    flashDefault,
    flashHovered,
  };
}
