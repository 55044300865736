import { TypedEvent } from "@faro-lotv/foundation";
import { createContext, useContext, useState } from "react";
import { Matrix4Tuple } from "three";
import { Perspective } from "./store/registration-datatypes";

// TODO: Refactor callbacks and setting callbacks: https://faro01.atlassian.net/browse/NRT-443
export type AlignmentOverlay = {
  /** Signal emitted to reset model cloud transformation */
  modelCloudTransformReset: TypedEvent<void>;

  /** The initial model cloud transform when the user enters the alignment workflows */
  initialModelTransform?: Matrix4Tuple;

  /**
   * Set the initial model cloud transform that will be applied on the model cloud when modelCloudTransformReset is called
   */
  setModelCloudInitialTransform(transform: Matrix4Tuple): void;

  /** Signal emitted to show the registration results dialog */
  showRegistrationResultsDialog: TypedEvent<void>;

  /** Signal emitted to center camera on the point clouds, based on the selected perspective */
  centerCameraOnPerspective: TypedEvent<Perspective | null>;
};

/** A context for the overlay in the alignment workflows */
export const AlignmentOverlayContext = createContext<
  AlignmentOverlay | undefined
>(undefined);

/**
 * @returns The registered overlay context to be used in the alignment workflows
 */
export function useAlignmentOverlay(): AlignmentOverlay {
  const ctx = useContext(AlignmentOverlayContext);
  if (!ctx) {
    throw Error("useAlignmentOverlay called outside an AlignmentOverlay");
  }
  return ctx;
}

/**
 * @returns The initial state for the AlignmentOverlayContext
 */
export function useAlignmentOverlayInitialState(): AlignmentOverlay {
  const [initialModelTransform, setModelCloudInitialTransform] =
    useState<Matrix4Tuple>();

  const modelCloudTransformReset = new TypedEvent<void>();
  const showRegistrationResultsDialog = new TypedEvent<void>();
  const centerCameraOnPerspective = new TypedEvent<Perspective | null>();

  return {
    initialModelTransform,
    setModelCloudInitialTransform,
    modelCloudTransformReset,
    showRegistrationResultsDialog,
    centerCameraOnPerspective,
  };
}
