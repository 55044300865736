import { useToast } from "@faro-lotv/flat-ui";
import { ModelAboutToLoad } from "@faro-lotv/lotv";
import { useCallback } from "react";

/**
 * @returns callback function to report that GPU is about to freeze to load 3D model
 */
export function useModelAboutToLoad(): ModelAboutToLoad {
  const { openToast } = useToast();

  return useCallback(async (): Promise<void> => {
    openToast({
      title: "Preparing 3D Model...",
      variant: "info",
    });

    // sleep 1 second to allow toast popup before potential freezing
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }, [openToast]);
}
