import { TREE_NODE_HEIGHT } from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
} from "@faro-lotv/service-wires";
import { useMemo } from "react";
import { Tree } from "react-arborist";
import { selectSelectedEntityId } from "../../store/data-preparation-ui/data-preparation-ui-selectors";
import { setSelectedEntityId } from "../../store/data-preparation-ui/data-preparation-ui-slice";
import { ScanTreeNode } from "./scan-tree-node";

type ScanTreeProps = {
  /** The entities to show in the tree. */
  entities: CaptureTreeEntityRevision[];
};

/** @returns A tree of scans in clusters. */
export function ScanTree({ entities }: ScanTreeProps): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedEntityId = useAppSelector(selectSelectedEntityId);

  // Map for a quick children lookup by id
  const entityChildrenMap = useEntityChildrenMap(entities);

  // The children of the root entity
  const rootEntities = useMemo(() => {
    const rootEntityId = entities.find(
      (e) => e.type === CaptureTreeEntityType.root,
    )?.id;

    if (!rootEntityId) return [];

    return entityChildrenMap.get(rootEntityId);
  }, [entities, entityChildrenMap]);

  return (
    <TreeWrapper>
      <Tree
        data={rootEntities}
        width="100%"
        disableDrag
        disableDrop
        rowHeight={TREE_NODE_HEIGHT}
        indent={24}
        disableMultiSelection
        selection={selectedEntityId}
        onSelect={(nodes) => {
          dispatch(setSelectedEntityId(nodes[0]?.data.id));
        }}
        childrenAccessor={(node) => entityChildrenMap.get(node.id) ?? null}
      >
        {ScanTreeNode}
      </Tree>
    </TreeWrapper>
  );
}

/**
 * @returns a map for quick children lookup of entities
 * @param entities the entities to create the map for
 */
function useEntityChildrenMap(
  entities: CaptureTreeEntityRevision[],
): Map<GUID, CaptureTreeEntityRevision[]> {
  return useMemo(() => {
    const map = new Map<GUID, CaptureTreeEntityRevision[]>();

    for (const entity of entities) {
      if (!entity.parentId) continue;

      let childrenOfParent = map.get(entity.parentId);

      if (!childrenOfParent) {
        childrenOfParent = [];
        map.set(entity.parentId, childrenOfParent);
      }

      childrenOfParent.push(entity);
    }

    return map;
  }, [entities]);
}
