import { HelpBanner } from "@faro-lotv/flat-ui";
import { Box } from "@mui/system";

interface RegistrationEditHelpBannerProps {
  /** Whether the edit registration tool is enabled */
  isEditRegistrationEnabled: boolean;

  /** Whether an entity to edit is currently selected */
  isEntitySelected: boolean;
}

/** @returns a help banner to explain the registration edit tool */
export function RegistrationEditHelpBanner({
  isEditRegistrationEnabled,
  isEntitySelected,
}: RegistrationEditHelpBannerProps): JSX.Element | null {
  if (!isEditRegistrationEnabled) return null;

  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      sx={{ position: "absolute", top: 0, zIndex: 1, width: "100%" }}
    >
      <HelpBanner
        sx={{
          display: "inline-block",
          margin: 1,
        }}
      >
        {isEntitySelected
          ? "Confirm & run a refinement after aligning all scans"
          : "Select a scan or cluster to move from the list"}
      </HelpBanner>
    </Box>
  );
}
