import {
  ProjectPermissions,
  RegisteredUserInfo,
} from "@faro-lotv/service-wires";
import { RootState } from "./store";

/**
 * @returns the current logged in user info if available or undefined if there's no logged in user
 * @param state app state
 */
export function selectCurrentUser(
  state: RootState,
): RegisteredUserInfo | undefined {
  return state.user.user;
}

/**
 * @returns true if the user is logged in and has write permission
 * @param state app state
 */
export function selectHasWritePermission(state: RootState): boolean {
  if (!state.user.user?.projectPermissions) return false;
  return state.user.user.projectPermissions.includes(ProjectPermissions.write);
}
