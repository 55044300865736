import { EnsureWriteAccess } from "@/permissions/ensure-project-access";
import { FaroText, FontWeights } from "@faro-lotv/flat-ui";
import { Stack, SxProps, Theme, useTheme } from "@mui/system";
import { CSSProperties, useMemo } from "react";
import { CreateAreaMainBody } from "../create-area-mode/create-area-main-body";
import { LimitedAccess } from "../create-area-mode/limited-access";

/** @returns The overlay for the ImportSheet mode */
export function ImportSheetModeOverlay(): JSX.Element {
  /** Style used to fit the entire page */
  const entirePageStyle = useMemo<CSSProperties>(
    () => ({
      position: "relative",
      // Set the size of the element to the entire page, minus the header bar
      width: "100%",
      height: "100%",
      overflow: "scroll",
    }),
    [],
  );

  return (
    <Stack sx={entirePageStyle}>
      <ImportSheetModeBody />
    </Stack>
  );
}

/**
 * @returns a component containing the entire body of the CreateArea mode Overlay
 */
function ImportSheetModeBody(): JSX.Element {
  const theme = useTheme();

  /** Style shared by the body of the overlay */
  const commonStyle = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: `${theme.palette.gray100}80`,
      width: "100%",
      py: 1.5,
      px: { xs: 2, md: 5 },
    }),
    [theme.palette.gray100],
  );

  return (
    <Stack alignSelf="center" width={{ xs: "90%", md: "80%" }}>
      <ImportSheetTitle />
      <EnsureWriteAccess
        fallback={<LimitedAccess sx={{ ...commonStyle, mt: "0.625rem" }} />}
      >
        <CreateAreaMainBody sx={{ ...commonStyle, mt: "24px" }} />
      </EnsureWriteAccess>
    </Stack>
  );
}

function ImportSheetTitle(): JSX.Element {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        justifyContent: {
          xs: "center",
          sm: "flex-start",
          mt: { xs: "0.5rem", md: "2.75rem" },
          paddingBottom: "0.5rem",
          paddingTop: "60px",
        },
        borderBottom: ({ palette }) => ({
          sm: `2px solid ${palette.gray100}80`,
        }),
      }}
    >
      <FaroText variant="bodyS" sx={{ fontWeight: FontWeights.SemiBold }}>
        Import sheet
      </FaroText>
    </Stack>
  );
}
