import { Camera } from "@react-three/fiber";
import { Map2DControls } from "@faro-lotv/app-component-toolbox";

interface IProps {
  /** The camera to be moved by the controls */
  camera: Camera;
  /** Height of the plane we want to navigate to, used to compute the correct panning sensitivity */
  referencePlaneHeight?: number;
  /** True if the controls are enabled otherwise false  */
  enabled?: boolean;
}

/**
 * @returns Controls in sheet mode.
 */
export function SheetModeControls({
  camera,
  referencePlaneHeight,
  enabled = true,
}: IProps): JSX.Element {
  return (
    <Map2DControls
      camera={camera}
      referencePlaneHeight={referencePlaneHeight}
      enabled={enabled}
    />
  );
}
