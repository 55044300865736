import { useUnitOfMeasureContext } from "@/components/common/unit-of-measure-context";
import { MeasureLabel } from "@/components/r3f/renderers/measurements/measure-label";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import {
  addAnalysisLabel,
  PointCloudAnalysis,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  computeLabelPosition,
  getAnalysisReferencePlane,
  POLYGON_SELECTION_PLANE_THRESHOLD,
} from "@/utils/colormap-analysis-utils";
import {
  computeNdcCoordinates,
  getPickedPoint,
  useOverrideCursor,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { generateGUID } from "@faro-lotv/foundation";
import {
  createProjectedPolygon2d,
  isPointInProjectedPolygon,
  ProjectedPolygon2d,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Plane, Raycaster, Vector3 } from "three";

type PointCloudAnalysisLabelToolProps = {
  /** True if this tool is the active one */
  isActive: boolean;

  /** The active analysis object */
  activeAnalysis: PointCloudAnalysis;

  /** The active point cloud object */
  activePointCloud: PointCloudObject;
};

/** @returns The tool used to create labels on an existing analysis */
export function PointCloudAnalysisLabelTool({
  isActive,
  activeAnalysis,
  activePointCloud,
}: PointCloudAnalysisLabelToolProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const referencePlane = useMemo(() => {
    const plane = getAnalysisReferencePlane(activeAnalysis);
    if (!plane) return;
    return new Plane().setFromNormalAndCoplanarPoint(plane.normal, plane.point);
  }, [activeAnalysis]);

  const [labelPosition, setLabelPosition] = useState<Vector3>();
  const [labelDistance, setLabelDistance] = useState<number>(0.0);

  const [projectedPolygon2d, setProjectedPolygon2d] =
    useState<ProjectedPolygon2d>();
  useEffect(() => {
    const computePolygon = async (): Promise<void> => {
      const polygon = activeAnalysis.polygonSelection.map((p) =>
        new Vector3().fromArray(p),
      );
      const projPolygon = await createProjectedPolygon2d(polygon);
      setProjectedPolygon2d(projPolygon);
    };
    computePolygon().catch((error) => {
      console.error(error);
      setProjectedPolygon2d(undefined);
    });
  }, [activeAnalysis.polygonSelection]);

  const eventTarget = useThreeEventTarget();
  const [raycaster] = useState(() => new Raycaster());
  const camera = useThree((state) => state.camera);

  const pickLabelPositionOnAnalysis = useCallback(
    (event: PointerEvent | MouseEvent): Vector3 | undefined => {
      if (!isActive || !projectedPolygon2d) return;

      const mouse = computeNdcCoordinates(
        event.clientX,
        event.clientY,
        eventTarget,
      );
      raycaster.setFromCamera(mouse, camera);
      const oldRealTimeRaycasting = activePointCloud.realTimeRaycasting;
      activePointCloud.realTimeRaycasting = false;
      const hits = raycaster.intersectObject(activePointCloud);
      activePointCloud.realTimeRaycasting = oldRealTimeRaycasting;
      if (!hits.length) return;

      const pickedPoint = getPickedPoint(hits[0]);
      const distanceThreshold = POLYGON_SELECTION_PLANE_THRESHOLD / 2.0;
      if (
        isPointInProjectedPolygon(
          pickedPoint,
          projectedPolygon2d,
          distanceThreshold,
        )
      ) {
        return computeLabelPosition(activePointCloud, pickedPoint);
      }
    },
    [
      activePointCloud,
      camera,
      eventTarget,
      isActive,
      projectedPolygon2d,
      raycaster,
    ],
  );

  const pointHovered = useCallback(
    (event: PointerEvent): void => {
      if (!referencePlane) {
        setLabelPosition(undefined);
        return;
      }
      const labelPosition = pickLabelPositionOnAnalysis(event);
      if (!labelPosition) {
        setLabelPosition(undefined);
        return;
      }

      event.stopPropagation();
      setLabelPosition(labelPosition);
      const distance = referencePlane.distanceToPoint(labelPosition);
      setLabelDistance(distance);
    },
    [pickLabelPositionOnAnalysis, referencePlane],
  );

  const pointClicked = useCallback(
    (event: MouseEvent): void => {
      const labelPosition = pickLabelPositionOnAnalysis(event);
      if (!labelPosition) return;

      event.stopPropagation();
      // remove preview so it won't show two labels at the same time
      setLabelPosition(undefined);
      dispatch(
        addAnalysisLabel({
          analysisId: activeAnalysis.id,
          label: {
            id: generateGUID(),
            position: labelPosition.toArray(),
          },
        }),
      );
    },
    [activeAnalysis.id, dispatch, pickLabelPositionOnAnalysis],
  );

  useEffect(() => {
    eventTarget.addEventListener("pointermove", pointHovered);
    eventTarget.addEventListener("click", pointClicked);
    return () => {
      eventTarget.removeEventListener("pointermove", pointHovered);
      eventTarget.removeEventListener("click", pointClicked);
    };
  }, [eventTarget, pickLabelPositionOnAnalysis, pointClicked, pointHovered]);

  useOverrideCursor("crosshair", isActive);

  const { unitOfMeasure } = useUnitOfMeasureContext();
  const labelContainer = useViewOverlayRef();

  if (!isActive) return null;

  return (
    <MeasureLabel
      index={0}
      visible
      position={labelPosition}
      parentRef={labelContainer}
      distance={labelDistance}
      unitOfMeasure={unitOfMeasure}
      active
      transparent
      pointerEvents="none"
      placement="top"
    />
  );
}
