import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useCached3DObject } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { selectSheetToCadAlignmentStep } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { SheetToCadAlignmentStep } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import { useEffect } from "react";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { AlignSheetToCadScene } from "./align-sheet-to-cad-scene";
import { SetHeightScene } from "./set-height-scene";

/** @returns the scene for the sheet to cad alignment mode */
export function SheetToCadAlignmentModeScene(): JSX.Element {
  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const activeCad = useAppSelector(selectActiveCadModel);
  assert(activeCad, "CAD Alignment Mode requires a CAD model");

  const cadModel = useCached3DObject(activeCad);

  // get the cad model bounding box in world
  const cadBox = useObjectBoundingBox(cadModel, cadModel.iElement.id);
  assert(cadBox, "Expected CAD model has bounding box computed on loading");

  useEffect(() => {
    // reset any highlight state from the cadModel
    for (const objectId of cadModel.objectIds) {
      cadModel.setObjectHighlighted(objectId, false);
    }
  }, [cadModel]);

  const step = useAppSelector(selectSheetToCadAlignmentStep);
  switch (step) {
    case SheetToCadAlignmentStep.setElevation:
      return <SetHeightScene activeCad={activeCad} cadBox={cadBox} />;
    case SheetToCadAlignmentStep.alignSheet:
      return (
        <AlignSheetToCadScene
          activeSheet={sheet}
          activeCad={activeCad}
          cadBox={cadBox}
        />
      );
  }
}
