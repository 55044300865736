import { alignmentStep } from "./alignment";
import { heightSettingStep } from "./height-setting";
import { CloudToSheetAlignmentStepNames, Step } from "./steps";

/**
 * Get the step from the step name
 *
 * @param name The name of the step we want
 * @returns The step instance to use
 */
export function getStep(name: CloudToSheetAlignmentStepNames): Step {
  switch (name) {
    case CloudToSheetAlignmentStepNames.alignIn2d:
      return alignmentStep;
    case CloudToSheetAlignmentStepNames.setElevation:
      return heightSettingStep;
  }
}
