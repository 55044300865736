import { FaroText, FaroTextProps } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

type IconTextProps = {
  /** An descriptor for the text. */
  label?: string;

  /** The text to display next to the icon. */
  text?: ReactNode;

  /** The `FaroText` variant to use. */
  variant: FaroTextProps["variant"];

  /** The icon to display next to the text. */
  icon: JSX.Element;
};

/**
 * @returns Text rendered together with an icon and an optional label.
 */
export function IconText({
  icon,
  text,
  variant,
  label,
}: IconTextProps): JSX.Element {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {icon}
      <FaroText variant={variant}>
        {label ? (
          <Box component="span" sx={{ opacity: 0.7 }}>
            {label}
            {": "}
          </Box>
        ) : null}
        {text ? <FaroText variant={variant}>{text}</FaroText> : null}
      </FaroText>
    </Stack>
  );
}
