import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { resetAlignmentWizard } from "@/store/modes/alignment-wizard-mode-slice";
import { AlignWizardModeOverlay } from "./align-wizard-mode-overlay";
import { AlignWizardModeScene } from "./align-wizard-mode-scene";

export const alignWizardMode: Mode = {
  name: "alignWizard",
  ModeScene: AlignWizardModeScene,
  ModeOverlay: AlignWizardModeOverlay,
  exclusive: {
    title: "Alignment",
    onBack({ dispatch }) {
      dispatch(resetAlignmentWizard());
      dispatch(changeMode("sheet"));
      return Promise.resolve();
    },
  },
};
