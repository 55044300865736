import { useCurrentArea } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import { isValid } from "@faro-lotv/ielement-types";
import { TreeData, selectAllIElements } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { CaptureTree } from "../tree/capture-tree/capture-tree";
import { TreeWrapper } from "../tree/tree-wrapper";
import { AreaNavigationTreeNode } from "./area-navigation-tree-node";
import { generateAreaNavigationTree } from "./area-navigation-utils";

/**
 * @returns A list of all point clouds in the active area (all if there is no active area)
 */
export function AreaNavigationTree(): JSX.Element | null {
  const iElements = useAppSelector(selectAllIElements);
  const { dataSessions, roomsTimeSeries } = useCurrentArea();
  const rooms = useMemo(
    () =>
      roomsTimeSeries
        .map((e) => {
          if (e.parentId) {
            return iElements[e.parentId];
          }
        })
        .filter(isValid),
    [iElements, roomsTimeSeries],
  );

  const tree = useMemo<TreeData[]>(
    () => generateAreaNavigationTree(dataSessions, rooms, iElements),
    [rooms, dataSessions, iElements],
  );

  if (!tree.length) return null;

  return (
    <TreeWrapper>
      <CaptureTree tree={tree}>{AreaNavigationTreeNode}</CaptureTree>
    </TreeWrapper>
  );
}
