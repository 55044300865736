import { useLotvDispose } from "@faro-lotv/app-component-toolbox";
import { CubeMapPano } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import { CubeTexture, Matrix4 } from "three";

export type EnvMapRendererProps = {
  /** The cube map to use to render the environment */
  texture: CubeTexture;
};

/**
 * @returns A component that will render a CubeTexture as the scene environment
 */
export function EnvMapBackgroundRenderer({
  texture,
}: EnvMapRendererProps): null {
  const scene = useThree((s) => s.scene);
  // To render a cube map is enough to set it as the background of the scene
  useLayoutEffect(() => {
    const origBackground = scene.background;
    scene.background = texture;
    return () => {
      scene.background = origBackground;
    };
  }, [scene, texture]);
  return null;
}

export type EnvMapCubeMapRendererProps = {
  /** The cubemap to render */
  texture: CubeTexture;

  /** The opacity */
  opacity?: number;
};

/**
 * @returns A component that will renderer a CubeTexture using Lotv CubeMapPano to get opacity support
 */
export function EnvMapCubeMapRenderer({
  texture,
  opacity,
}: EnvMapCubeMapRendererProps): JSX.Element {
  const camera = useThree((s) => s.camera);
  const [pano] = useState(() => {
    const pano = new CubeMapPano(texture, new Matrix4());
    pano.position.copy(camera.position);
    return pano;
  });

  useLotvDispose(pano);

  return (
    <primitive object={pano} opacity={opacity} material-depthTest={false} />
  );
}
