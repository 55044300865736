import { selectIsScaleEnabled } from "@/alignment-tool/store/alignment-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { IElementSection } from "@faro-lotv/ielement-types";
import { useMemo } from "react";

/**
 *
 * @param activeArea The active alignment area
 * @returns True if the scale can be modified during the alignment
 */
export function useIsScaleEnabled(
  activeArea: IElementSection | undefined,
): boolean {
  const isScaleEnabled = useAppSelector(selectIsScaleEnabled);

  const shouldScaleBeEnabled = useMemo(() => {
    return !activeArea?.pose?.scale || isScaleEnabled;
  }, [activeArea, isScaleEnabled]);

  return shouldScaleBeEnabled;
}
