import { selectProjectUsers } from "@/store/project-selector";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementMarkup,
  isIElementMarkupAssignee,
} from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import {
  GenericUserInfo,
  isRegisteredUserInfo,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

/**
 * @param markup to get the assignee for
 * @returns all the users assigned to this annotation
 */
export function useAnnotationAssignees(
  markup: IElementMarkup | undefined,
): GenericUserInfo[] {
  const assignee = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupAssignee),
  );
  const projectUsers = useAppSelector(selectProjectUsers);
  const [assignees, setAssignees] = useState(
    computeAssignees(projectUsers, assignee?.values),
  );
  useEffect(() => {
    setAssignees(computeAssignees(projectUsers, assignee?.values));
  }, [assignee, projectUsers]);

  return assignees;
}

function computeAssignees(
  users: GenericUserInfo[],
  assignee?: string[] | null,
): GenericUserInfo[] {
  return users
    .filter(isRegisteredUserInfo)
    .filter((info) => assignee?.includes(info.id));
}
