import { Box } from "@mui/material";
import { RowRendererProps } from "react-arborist";
import { CadModelTreeNodeData } from "./cad-model-tree-data";

/**
  @returns A row renderer for the CAD model tree. This row won't display outlines when it has the focus.
  The default implementation will display black outlines when the row is no longer selected but has focus.
 */
export function CadModelTreeRow({
  node,
  innerRef,
  attrs,
  children,
}: RowRendererProps<CadModelTreeNodeData>): JSX.Element {
  return (
    <Box
      component="div"
      ref={innerRef}
      {...attrs}
      style={node.isFocused ? { ...attrs.style, outline: "none" } : attrs.style}
      onClick={node.handleClick}
    >
      {children}
    </Box>
  );
}
