import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { FaroButton, FaroTooltip } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";

interface ToggleEditRegistrationButtonProps {
  /** The currently active revision state. Used to determine whether editing is possible. */
  revisionState: RegistrationState;

  /** Whether registration editing is currently enabled */
  isEditRegistrationEnabled: boolean;

  /** A callback to execute when the user toggles the registration editing */
  onToggleEditRegistration(): void;
}

/** @returns a button to toggle the visual registration mode */
export function ToggleEditRegistrationButton({
  revisionState,
  isEditRegistrationEnabled: isVisualRegistrationEnabled,
  onToggleEditRegistration: onToggleVisualRegistration,
}: ToggleEditRegistrationButtonProps): JSX.Element {
  const disabledMessage = useEditButtonDisabledMessage(revisionState);

  return (
    <FaroTooltip title={disabledMessage}>
      <FaroButton
        variant="secondary"
        onClick={onToggleVisualRegistration}
        disabled={!!disabledMessage}
        fullWidth
      >
        {isVisualRegistrationEnabled
          ? "Discard registration edit"
          : "Edit registration"}
      </FaroButton>
    </FaroTooltip>
  );
}

/**
 * @param revisionState The current state of the revision
 * @returns A message to display when the edit button is disabled, or undefined if the button is enabled.
 */
function useEditButtonDisabledMessage(
  revisionState: RegistrationState,
): string | undefined {
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  if (!hasWritePermission) {
    return "You do not have permission to edit, contact the project administrator.";
  }

  // Using a switch to check if all states are covered
  switch (revisionState) {
    case RegistrationState.canceled:
      return "Cannot edit a canceled registration.";
    case RegistrationState.merged:
      return "Cannot edit a merged registration.";
    case RegistrationState.cloudRegistrationStarted:
      return "Cannot edit while a registration is in progress.";
    case RegistrationState.started:
    case RegistrationState.userModified:
    case RegistrationState.registered:
      // Editing is allowed
      break;
  }
}
