import { useCurrentAreaIfAvailable } from "@/modes/mode-data-context";
import {
  IElementGenericImgSheet,
  isIElementOverviewImage,
} from "@faro-lotv/ielement-types";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { StoreApi, createStore, useStore } from "zustand";

/**
 * A simple global object shared between sliders in the UI in overview and walk
 * mode, and the rendering pipelines in overview and walk modes.
 * Since using React states or the store has very bad performance, the slider
 * updates directly this object when its value changes. These values are directly
 * loaded to the FBO composing shader at each frame.
 */
export type ObjectsOpacity = {
  /** Opacity to use for overview maps/floor plans, from zero to one */
  mapOpacity: number;

  /** Opacity to use for the Cad models, from zero to one */
  cadOpacity: number;

  /** Opacity to use for Point Clouds, from zero to one */
  pcOpacity: number;
};

/** The default opacity value for scene elements */
const DEFAULT_OPACITIES: ObjectsOpacity = {
  mapOpacity: 1.0,
  cadOpacity: 1.0,
  pcOpacity: 1.0,
};

export type TransparencySettingsContext = {
  /** An object that contains the transparency settings. */
  objectsOpacity: ObjectsOpacity;

  /**
   * Resets the context data to the default values that express full opacity everywhere.
   * Called when the user exits a mode, in order to prevent settings specific of a mode
   * to bleed out to another mode.
   */
  resetOpacities(): void;

  /**
   * Change the values of the objects opacities
   *
   * @param opacities new objects opacities
   */
  setOpacities(opacities: ObjectsOpacity): void;

  /**
   * @returns if the opacity values match the default values
   * @param opacities to check
   */
  areDefaultOpacities(opacities: ObjectsOpacity): boolean;
};

/** The actual context for the transparency settings */
const Context = createContext<StoreApi<TransparencySettingsContext> | null>(
  null,
);

type TransparencySettingsProviderProps = PropsWithChildren<{
  /** The current active sheet */
  sheet: IElementGenericImgSheet | undefined;
}>;

/** @returns The provider for the TransparencySettings context */
export function TransparencySettingsProvider({
  sheet,
  children,
}: TransparencySettingsProviderProps): JSX.Element | null {
  const [store] = useState<StoreApi<TransparencySettingsContext>>(() =>
    createStore((set) => ({
      objectsOpacity: {
        ...DEFAULT_OPACITIES,
        mapOpacity: isIElementOverviewImage(sheet) ? 0 : 1,
      },

      resetOpacities: () =>
        set({
          objectsOpacity: {
            ...DEFAULT_OPACITIES,
            mapOpacity: isIElementOverviewImage(sheet) ? 0 : 1,
          },
        }),

      setOpacities: (objectsOpacity) => set({ objectsOpacity }),

      areDefaultOpacities: (objectsOpacity) =>
        objectsOpacity.cadOpacity === DEFAULT_OPACITIES.cadOpacity &&
        objectsOpacity.mapOpacity === DEFAULT_OPACITIES.mapOpacity &&
        objectsOpacity.pcOpacity === DEFAULT_OPACITIES.pcOpacity,
    })),
  );

  // Update the reset function since it depends on the current active sheet
  useEffect(() => {
    store.setState({
      resetOpacities: () =>
        store.setState({
          objectsOpacity: {
            ...DEFAULT_OPACITIES,
            mapOpacity: isIElementOverviewImage(sheet) ? 0 : 1,
          },
        }),
    });
  }, [sheet, store]);

  // Reset the values in the store when the area changes
  const area = useCurrentAreaIfAvailable();
  const [referenceArea, setReferenceArea] = useState(area?.area.id);
  useEffect(() => {
    if (area?.area.id === referenceArea) return;
    store.setState({
      objectsOpacity: {
        ...DEFAULT_OPACITIES,
        mapOpacity: isIElementOverviewImage(sheet) ? 0 : 1,
      },
    });
    setReferenceArea(area?.area.id);
  }, [area, referenceArea, sheet, store]);

  return <Context.Provider value={store}>{children}</Context.Provider>;
}

export function useTransparencySettingsContext(): TransparencySettingsContext;
export function useTransparencySettingsContext<T>(
  selector: (state: TransparencySettingsContext) => T,
): T;
/**
 * @returns The result of the selector after parsing the state
 * @param selector The function used to parse the state. If no selector is defined, the whole context is returned
 */
export function useTransparencySettingsContext(
  selector = (state: TransparencySettingsContext) => state,
): TransparencySettingsContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error("TransparencySettingsContext is not initialized.");
  }
  return useStore(context, selector);
}
