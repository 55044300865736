import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import { IElementAttachment } from "@faro-lotv/ielement-types";
import {
  MutationAddAttachment,
  MutationDeleteElement,
  createMutationAddAttachment,
  createMutationDeleteElement,
} from "@faro-lotv/service-wires";
import { NewAttachment } from "./annotation-props";

/**
 * Compute the mutations necessary to add the attachments to the project
 *
 * @param rootId the unique id of the root of the project
 * @param markupId The id of the parent markup
 * @param attachments The list of attachments to add to the markup
 * @param maybeGroupId The id of the Group(Attachments) child of the markup, if it exists
 * @returns The array of mutations
 */
export function createAttachments(
  rootId: GUID,
  markupId: GUID,
  attachments: NewAttachment[],
  maybeGroupId?: GUID,
): MutationAddAttachment[] {
  if (attachments.length === 0) return [];

  // Split mutations in two groups, so that the first one will create the group if needed
  const [attachment0, ...otherAttachments] = attachments;

  const mutation = createMutationAddAttachment({
    rootId,
    newAttachmentId: generateGUID(),
    markupId,
    groupId: maybeGroupId,
    name: attachment0.fileName,
    fileName: attachment0.fileName,
    fileSize: attachment0.fileSize,
    md5Hash: attachment0.md5,
    uri: attachment0.downloadUrl,
    ...(typeof attachment0.thumbnailUrl === "string" && {
      thumbnailUri: attachment0.thumbnailUrl,
    }),
  });

  const groupId = maybeGroupId ?? mutation.group?.id;
  assert(groupId, "An attachment group should exist");

  const mutations = otherAttachments.map((attachment) =>
    createMutationAddAttachment({
      rootId,
      newAttachmentId: generateGUID(),
      markupId,
      groupId,
      name: attachment.fileName,
      fileName: attachment.fileName,
      fileSize: attachment.fileSize,
      md5Hash: attachment.md5,
      uri: attachment.downloadUrl,
      ...(typeof attachment.thumbnailUrl === "string" && {
        thumbnailUri: attachment.thumbnailUrl,
      }),
    }),
  );

  return [mutation, ...mutations];
}

/**
 * Compute the mutations necessary to delete the attachments from the project
 *
 * @param attachmentsToRemove The list of attachments to remove
 * @returns The array of mutations
 */
export function deleteAttachments(
  attachmentsToRemove: IElementAttachment[],
): MutationDeleteElement[] {
  return attachmentsToRemove.map((el) => createMutationDeleteElement(el.id));
}
