import {
  isLoading,
  LOADING,
  MaybeLoading,
  SignedUrl,
} from "@faro-lotv/foundation";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import {
  makeIElementUriSigner,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useMemo } from "react";

/**
 * @param img360 The panorama image to get a preview of.
 * @returns The signed URL (if available) to a low-resolution preview image for the panorama.
 */
export function useImg360PreviewSignedUrl(
  img360: MaybeLoading<IElementImg360>,
): MaybeLoading<SignedUrl> {
  const { projectApiClient } = useApiClientContext();

  return useMemo(() => {
    if (!img360) return;
    if (isLoading(img360)) return LOADING;

    if (img360.thumbnailUri) {
      return new SignedUrl(
        img360.thumbnailUri,
        makeIElementUriSigner(projectApiClient, img360.id),
        img360.signedThumbnailUri && img360.signedThumbnailUriExpiresAt
          ? {
              signedUrl: img360.signedThumbnailUri,
              expiresOn: new Date(img360.signedThumbnailUriExpiresAt),
            }
          : undefined,
      );
    }

    if (img360.levelsOfDetail) {
      const firstLevel = img360.levelsOfDetail.find(
        (lod) => lod.level === 0 && lod.sources.length === 1,
      );

      if (firstLevel) {
        return new SignedUrl(
          firstLevel.sources[0],
          makeIElementUriSigner(projectApiClient, img360.id),
          {
            signedUrl: firstLevel.signedSources[0],
            expiresOn: new Date(firstLevel.signedSourcesExpiresOn),
          },
        );
      }
    }
  }, [img360, projectApiClient]);
}
