import { useLoadIElements } from "@/registration-tools/common/use-load-ielements";
import { useAppSelector } from "@/store/store-hooks";
import { LOADING, MaybeLoading } from "@faro-lotv/foundation";
import { IElementImg360, IElementType } from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { useEffect, useMemo, useState } from "react";
import { selectImg360ForScanEntity } from "../store/revision-selectors";

/**
 * @param scanEntity The entity to load the img360 for.
 * @param shouldLoad Setting this to `true` will initiate the loading of the img360.
 * @returns The img360 of the scan if loaded, `undefined` if it doesn't exist
 *   or `LOADING` if the img360 is being loaded.
 */
export function useLazyLoadRevisionImg360(
  scanEntity: RevisionScanEntity,
  shouldLoad: boolean,
): MaybeLoading<IElementImg360> {
  const img360 = useAppSelector(selectImg360ForScanEntity(scanEntity));
  const [loadingInitiated, setLoadingInitiated] = useState(shouldLoad);

  useEffect(() => {
    if (shouldLoad) {
      setLoadingInitiated(true);
    }
  }, [shouldLoad]);

  const requests = useMemo(() => {
    if (!loadingInitiated) return [];
    return [
      {
        ancestorIds: [scanEntity.id],
        types: [IElementType.img360],
      },
    ];
  }, [scanEntity, loadingInitiated]);

  const isLoading = useLoadIElements(requests);

  return isLoading ? LOADING : img360;
}
