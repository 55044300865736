import { ProjectIntegrations } from "@faro-lotv/ielement-types";
import { clearStore } from "@faro-lotv/project-source";
import { UserTokenData, UserTokensPayload } from "@faro-lotv/service-wires";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type IntegrationsState = {
  /** Stores the available workspace's integrations and their scopes */
  workspaceIntegrations?: UserTokenData[];

  /** Stores the available project's integration and their associated data */
  projectIntegrations?: ProjectIntegrations;
};

const initialState: Readonly<IntegrationsState> = Object.freeze({
  workspaceIntegrations: undefined,
  projectIntegrations: undefined,
});

const integrationsSlice = createSlice({
  initialState,
  name: "integrations",
  reducers: {
    setWorkspaceIntegrations(
      state,
      action: PayloadAction<UserTokensPayload | undefined>,
    ) {
      state.workspaceIntegrations = action.payload?.data;
    },

    setProjectIntegrations(
      state,
      action: PayloadAction<ProjectIntegrations | undefined>,
    ) {
      state.projectIntegrations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const { setWorkspaceIntegrations, setProjectIntegrations } =
  integrationsSlice.actions;

export const integrationsReducer = integrationsSlice.reducer;
