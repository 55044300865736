import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GUID, assert } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import {
  addAreaDataSets,
  fetchProjectIElements,
  selectAreaCaptureTreeDataSetIds,
  selectIsSubtreeLoading,
} from "@faro-lotv/project-source";
import {
  DataSetAreaInfo,
  ProjectApi,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

/** After how many seconds to consider a sub-tree stale and in needs to be re-fetched */
const STALE_TIME = 300;

/** Collection of requests to get IElements */
export type IElementFetchRequests = Array<Promise<IElement[]>>;

/** String that represent a specific ProjectApi fetch request encoding the subtree parameters */
type SubTreeRequestHash = string;

/** Information about the fetching of a sub-tree */
type SubTreeFetchData = {
  /** True if this fetch is in progress */
  isInProgress: boolean;

  /** Timeout handle for the setTimeout used to schedule the abort of this fetch  */
  abortTimeout?: number;

  /** Date of when this fetch was successfully executed */
  lastSuccessfulFetch?: Date;
};

/** Project context data provided by the ProjectProvider */
type ProjectLoadingContext = {
  /** The authenticated client to fetch data from the ProjectAPI */
  client: ProjectApi;

  /** Map to cache of all the sub-tree fetches */
  subTreeFetches: MutableRefObject<Map<SubTreeRequestHash, SubTreeFetchData>>;
};

export const ProjectLoadingContext = createContext<
  ProjectLoadingContext | undefined
>(undefined);

/** @returns a context that offers functionality to incrementally load areas and sub-trees of a project */
export function ProjectLoadingContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { projectApiClient: client } = useApiClientContext();

  const subTreeFetches = useRef(
    new Map<SubTreeRequestHash, SubTreeFetchData>(),
  );

  // When the client changes we need to clear the cache for all the fetches as
  // they're client specific
  useEffect(() => {
    subTreeFetches.current.clear();
  }, [client, subTreeFetches]);

  return (
    <ProjectLoadingContext.Provider value={{ client, subTreeFetches }}>
      {children}
    </ProjectLoadingContext.Provider>
  );
}

/**
 * @returns the current bound ProjectProvider context
 */
function useProjectLoadingContext(): ProjectLoadingContext {
  const ctx = useContext(ProjectLoadingContext);

  assert(
    ctx,
    "useProjectProviderContext can be used only inside a ProjectProvider",
  );

  return ctx;
}

/**
 * @returns The current ProjectApi client instance used to fetch project data or undefined in a demo project
 */
export function useCurrentProjectApiClient(): ProjectApi {
  return useProjectLoadingContext().client;
}

/**
 * Fetch a project api sub-tree from the backend
 *
 * @param id of the node to download the subtrees for
 * @returns true if the loading is in progress
 */
export function useProjectSubTree(id: GUID | undefined): boolean {
  return useProjectSubTrees(id ? [id] : undefined);
}

/**
 * @param areaId of the area to load the BI sub-tree and all the datasets
 * @returns true if the area or some datasets inside the area are still loading
 */
export function useLoadProjectArea(areaId: GUID | undefined): boolean {
  const dataSetIds = useAppSelector((state) =>
    selectAreaCaptureTreeDataSetIds(state, areaId),
  );

  const isAreaLoading = useProjectSubTree(areaId);
  const areDataSetLoading = useProjectSubTrees(dataSetIds);
  const isVolumeQueryRunning = useLoadAreaDataSets(areaId);

  return isVolumeQueryRunning || isAreaLoading || areDataSetLoading;
}

/**
 * Load from the backend all the datasets that are contained in an area volume
 *
 * @param areaId to query the volume for datasets
 * @returns true while the volume query is running
 */
function useLoadAreaDataSets(areaId: GUID | undefined): boolean {
  const [isLoading, setIsLoading] = useState(!!areaId);
  const projectApi = useCurrentProjectApiClient();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!areaId) return;
    const ac = new AbortController();

    projectApi
      .queryAreaVolume(areaId, ac.signal)
      .then((dataSets: DataSetAreaInfo[]) => {
        dispatch(addAreaDataSets({ areaId, dataSets }));
      })
      .catch((error) => {
        // TODO: Remove this when the new volume queries are available in all environments (https://faro01.atlassian.net/browse/SWEB-4461)
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      setIsLoading(false);
      ac.abort();
    };
  }, [areaId, dispatch, projectApi]);

  return isLoading;
}

/**
 * Fetch a set of project api sub-trees from the backend
 *
 * @param ids of the nodes to download the subtrees for
 * @returns true if the loading is in progress
 */
function useProjectSubTrees(ids: GUID[] | undefined): boolean {
  const dispatch = useAppDispatch();
  const { handleErrorWithPage } = useErrorHandlers();
  const ctx = useProjectLoadingContext();
  // On the first render, the fetch has not started yet, but it needs to be indicated that the loading is in progress
  const [hasStarted, setHasStarted] = useState(false);

  useLayoutEffect(() => {
    // Skip the fetch if there are no ids
    if (!ids?.length) {
      return;
    }
    const now = new Date();

    // Compute the hash for this specific request
    const fetchId = ids.join("-");

    const { canSkip, lastValidFetch } = checkFetchStatus(fetchId, ctx, now);
    if (canSkip) {
      return;
    }

    // Create an object to store information on the new fetch
    const fetchData: SubTreeFetchData = {
      isInProgress: true,
      abortTimeout: undefined,
      lastSuccessfulFetch: undefined,
    };
    ctx.subTreeFetches.current.set(fetchId, fetchData);

    // Here we need to start a new fetch
    const abortController = new AbortController();

    dispatch(
      fetchProjectIElements({
        fetcher: () =>
          ctx.client.getAllIElements({
            signal: abortController.signal,
            ancestorIds: ids,
            changedAfter: lastValidFetch,
          }),
        loadingIds: ids,
      }),
    )
      .then((elements) => {
        // If fetch is successful store fetch time in the context cache
        fetchData.lastSuccessfulFetch = now;
        return elements;
      })
      .finally(() => {
        fetchData.isInProgress = false;
      })
      .catch(handleErrorWithPage);

    setHasStarted(true);

    return () => {
      // When unmount schedule abort after a second so if we re-fetch this same request soon
      // we just keep the previous fetch alive (thanks React double mounts)
      fetchData.abortTimeout = window.setTimeout(() => {
        abortController.abort.bind(abortController);
      }, 1000);
    };
  }, [ctx, dispatch, ids, handleErrorWithPage]);

  const isSubtreeLoading = !!useAppSelector((state) =>
    ids?.some((id) => selectIsSubtreeLoading(id)(state)),
  );

  // If the passed ids are empty we consider the loading finished
  return !!ids?.length && (!hasStarted || isSubtreeLoading);
}

/**
 * Check if a new fetch can be skipped
 *
 * @param hash The hash of the new subTree fetch
 * @param ctx The project fetching context
 * @param now The current timestamp
 * @returns A boolean indicating if we can skip this fetch and the last valid fetch for this query if it exists
 */
function checkFetchStatus(
  hash: SubTreeRequestHash,
  ctx: ProjectLoadingContext,
  now: Date,
): { canSkip: boolean; lastValidFetch?: Date } {
  // If the data was already fetched and is not stale skip this fetch
  const fetchData = ctx.subTreeFetches.current.get(hash);
  if (!fetchData) {
    return { canSkip: false };
  }

  const { lastSuccessfulFetch } = fetchData;
  if (
    lastSuccessfulFetch &&
    now.getSeconds() - lastSuccessfulFetch.getSeconds() < STALE_TIME
  ) {
    return { canSkip: true, lastValidFetch: lastSuccessfulFetch };
  }

  // If the same fetch is already in progress skip this fetch
  if (fetchData.isInProgress) {
    // If the already in flight fetched was scheduled to abort
    // stop the timeout and keep it alive
    if (fetchData.abortTimeout) {
      clearTimeout(fetchData.abortTimeout);
    }
    return { canSkip: true, lastValidFetch: lastSuccessfulFetch };
  }

  return { canSkip: false, lastValidFetch: lastSuccessfulFetch };
}
