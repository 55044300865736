import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { RootState } from "@/store/store";
import {
  BackgroundTask,
  isCaptur3dFloorplanGenerationTask,
  isFileUploadTask,
  isGenericRegistrationTask,
  isOrthophotoTask,
  isPointCloudExportTask,
  isProcessingTask,
  isSceneConversionTask,
} from "@/utils/background-tasks";
import { compareDateTimes } from "@faro-lotv/foundation";
import {
  GUID,
  ISOTimeString,
  isGeoReferencedElement,
  isIElementBimModelSection,
  isIElementGenericStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
  selectIsElementAligned,
  selectLoadedIElements,
} from "@faro-lotv/project-source";

/** The source of information about a process in progress */
export type ProcessInfoSource = {
  /** Id of the source, can be a Section ID or a BackgroundTask ID */
  id: GUID;

  /**
   * A tag to know if the ID is from a section of the element to align, an export task,
   * or a generic background task
   */
  type: "ElementToAlignSection" | "Export" | "Task";

  /** The creation of the process (either of the task or the iElement) */
  createdAt: ISOTimeString;
};

/** Process' info sources splitted in imports and exports processes */
type ProcessInfoSources = {
  imports: ProcessInfoSource[];
  exports: ProcessInfoSource[];
  registrations: ProcessInfoSource[];
};

/**
 * @returns true if the task is related to an iElement which is currently present in the project
 * @param task task to be checked for validity related to an iElement
 * @param state root state
 */
function isValidTaskWithIElement(
  task: BackgroundTask,
  state: RootState,
): boolean {
  const iElement = task.iElementId
    ? selectIElement(task.iElementId)(state)
    : undefined;
  return !!iElement;
}
/**
 * @returns the list of processes we want to show in the progress overview menu, divided in imports, exports and registrations
 * @param state current app state
 */
export function selectProcesses(state: RootState): ProcessInfoSources {
  // Recover all the pc or cad sections that are still not aligned
  const sections = selectLoadedIElements(state)
    .filter(
      (el) => !isGeoReferencedElement(el) && !selectIsElementAligned(el)(state),
    )
    .filter(
      (el) => isIElementSectionDataSession(el) || isIElementBimModelSection(el),
    )
    .filter(
      (el) => !!selectChildDepthFirst(el, isIElementGenericStream)(state),
    );

  const sources: ProcessInfoSources = {
    imports: [],
    exports: [],
    registrations: [],
  };

  for (const section of sections) {
    sources.imports.push({
      id: section.id,
      type: "ElementToAlignSection",
      createdAt: section.createdAt,
    });
  }

  for (const task of state.backgroundTasks.backgroundTasks) {
    if (isFileUploadTask(task) || isProcessingTask(task)) {
      const isCadUpload =
        !!task.metadata &&
        task.metadata.uploadElementType === UploadElementType.cad;
      // CAD upload task is not related to any area, so it shouldn't be validated with any IElement
      // for other uploads we do validation of IElement
      if (isCadUpload || !!task.iElementId || isSceneConversionTask(task)) {
        sources.imports.push({
          id: task.id,
          type: "Task",
          createdAt: task.createdAt,
        });
      }
    } else if (
      (isPointCloudExportTask(task) ||
        isOrthophotoTask(task) ||
        isCaptur3dFloorplanGenerationTask(task)) &&
      isValidTaskWithIElement(task, state)
    ) {
      sources.exports.push({
        id: task.id,
        type: "Export",
        createdAt: task.createdAt,
      });
    } else if (
      isGenericRegistrationTask(task) &&
      isValidTaskWithIElement(task, state)
    ) {
      sources.registrations.push({
        id: task.id,
        type: "Task",
        createdAt: task.createdAt,
      });
    }
  }

  // Sort the processes, from newest to oldest
  sources.imports.sort((a, b) => compareDateTimes(b.createdAt, a.createdAt));
  sources.exports.sort((a, b) => compareDateTimes(b.createdAt, a.createdAt));
  sources.registrations.sort((a, b) =>
    compareDateTimes(b.createdAt, a.createdAt),
  );

  return sources;
}
