import { useCached3DObjectIfReady } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { useToast, useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { StreamCadModel } from "@faro-lotv/lotv";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * Parameters for useCadModelLoadingProgress hook.
 */
type CadModelLoadingProgressProps = {
  /* Current scene filter value */
  value: SceneFilter;

  /* True if support for CAD is enabled; false is user cannot display CAD models */
  cadEnabled: boolean;
};

type CadModelLoadingProgressReturn = {
  /* True if the CAD model has completed preload and is ready to be displayed */
  isCadModelReady: boolean;

  /* Tip displayed on the Cad Model button showing the progress status (empty when loading is successfully complete) */
  tooltipMessage: string;

  // Loading progress in % from 0 = "nothing loaded yet" to 100 = whole model has been loaded.
  // Undefined when loading is not in progress.
  cadLoadingProgress: number | undefined;
};

/**
 * Hook to report loading progress of CAD model.
 *
 * @returns See CadModelLoadingProgressReturn
 */
export function useCadModelLoadingProgress({
  value,
  cadEnabled,
}: CadModelLoadingProgressProps): CadModelLoadingProgressReturn {
  const { openToast } = useToast();
  const activeCad = useAppSelector(selectActiveCadModel);
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  const isCadModelReady = !(
    cadModelObject instanceof Error || cadModelObject === null
  );

  const isSplitMode = useAppSelector(selectModeName) === "split";

  // CAD model loading progress in %, or undefined when not loading
  const [cadLoadingProgress, setCadLoadingProgress] = useState<
    undefined | number
  >();
  // true when CAD model loading failed due to insufficient memory
  const [cadLoadingFailed, setCadLoadingFailed] = useState(false);

  // Compute setCadLoadingProgress from current loading state
  const setLoadingProgress = useCallback(() => {
    if (cadModelObject instanceof StreamCadModel) {
      const [chunksLoaded, chunksToBeLoaded] = cadModelObject.loadingProgress;
      if (chunksLoaded === chunksToBeLoaded) {
        setCadLoadingProgress(undefined);
      } else {
        setCadLoadingProgress((100 * chunksLoaded) / chunksToBeLoaded);
      }
      setCadLoadingFailed(
        cadModelObject.isCompleted && chunksLoaded !== chunksToBeLoaded,
      );
    } else {
      setCadLoadingProgress(undefined);
      setCadLoadingFailed(false);
    }
  }, [cadModelObject]);

  // Initialize loading progress when the component is mounted or when the active model changed
  useEffect(() => {
    setLoadingProgress();
  }, [setLoadingProgress]);

  // Event listening for StreamCadModel loading a new chunk of data
  useTypedEvent<void>(
    cadModelObject instanceof StreamCadModel
      ? cadModelObject.chunkLoaded
      : undefined,
    () => {
      setLoadingProgress();
    },
  );

  // Event listening for StreamCadModel stopping the load
  // (either because the model is complete in memory, or there is not enough space t load it)
  useTypedEvent<void>(
    cadModelObject instanceof StreamCadModel
      ? cadModelObject.streamCompleted
      : undefined,
    () => {
      if (cadModelObject instanceof StreamCadModel) {
        const [chunksLoaded, chunksToBeLoaded] = cadModelObject.loadingProgress;
        setCadLoadingFailed(chunksLoaded !== chunksToBeLoaded);
        if (chunksLoaded !== chunksToBeLoaded) {
          openToast({
            title: `Model render stopped at ${getCadLoadingProgressString((100 * chunksLoaded) / chunksToBeLoaded)}`,
            variant: "warning",
            persist: true,
            message:
              "Try reducing the details of the model to ensure that it can be loaded completely.",
          });
        }
      }
    },
  );

  // Tooltip message to be displayed on the Cad Model button
  const tooltipMessage = useMemo(() => {
    if (isSplitMode && !cadEnabled && isCadModelReady) {
      return "3D Model can only be displayed in left panel";
    }
    const progressDisplay = getCadLoadingProgressString(cadLoadingProgress);
    if (
      progressDisplay &&
      (value === SceneFilter.Cad || value === SceneFilter.Overlay)
    ) {
      if (cadLoadingFailed) {
        return `Model stopped loading at ${progressDisplay}`;
      }
      return `Loading model (${progressDisplay})`;
    }
    return "";
  }, [
    isSplitMode,
    cadEnabled,
    isCadModelReady,
    cadLoadingFailed,
    value,
    cadLoadingProgress,
  ]);

  return { isCadModelReady, tooltipMessage, cadLoadingProgress };
}

/**
 * @returns Loading progress as a string (e.g. "22%"), or undefined if not loading
 * @param cadLoadingProgress loading progress in %, or undefined when not loading
 */
function getCadLoadingProgressString(
  cadLoadingProgress: number | undefined,
): string | undefined {
  return cadLoadingProgress ? `${Math.round(cadLoadingProgress)}%` : undefined;
}
