import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { getCameraParameters } from "@/components/r3f/utils/camera-parameters";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { PointCloudWithOpacity } from "@/modes/overview-mode/overview-point-cloud";
import { useCached3DObject } from "@/object-cache";
import { prepareExport } from "@/store/modes/export-mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { WalkOrbitControls } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import { Vector3 } from "three";

/** @returns The transition to the export mode */
export function ExportModeTransition({
  onCompleted,
  previousMode,
}: ModeTransitionProps): JSX.Element {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "Export Mode requires a PointCloud as the main element",
  );

  const camera = useThree((s) => s.camera);
  const pointCloudObject = useCached3DObject(main);
  const target = useExportCameraTarget();

  const dispatch = useAppDispatch();
  // The export state should be computed before the camera/target is changed by any user interaction
  useNonExhaustiveEffect(() => {
    dispatch(
      prepareExport({
        returnMode: previousMode,
        returnCamera: getCameraParameters(camera, target),
        initialTarget: target?.toArray(),
      }),
    );
    onCompleted();
  }, []);

  return <PointCloudWithOpacity pointCloud={pointCloudObject} visible />;
}

/**
 * @returns the camera target for the export mode, computed from the state of the controls of the previous mode
 */
function useExportCameraTarget(): Vector3 | undefined {
  const viewContext = useViewRuntimeContext();
  return useMemo(() => {
    const controls = viewContext.controls?.[0];
    const target =
      controls instanceof WalkOrbitControls ? controls.target : undefined;
    return target;
  }, [viewContext.controls]);
}
