import { RefObject, useEffect, useRef, useState } from "react";

/** Pixel sizes defining the minimum values for a specific layout */
export enum TypeToggleButtonsLayout {
  Large = 500,
  Medium = 350,
  Small = 0,
}

/**
 * @returns The preferred layout based on the current width
 * @param width The current width of the stack
 */
function computeLayout(width: number): TypeToggleButtonsLayout {
  if (width < TypeToggleButtonsLayout.Medium) {
    return TypeToggleButtonsLayout.Small;
  } else if (width < TypeToggleButtonsLayout.Large) {
    return TypeToggleButtonsLayout.Medium;
  }
  return TypeToggleButtonsLayout.Large;
}

export type WalkOverlayResponsiveLayoutResponse = {
  /** Ref to attach to the main overlay div to track its width */
  overlayRef: RefObject<HTMLDivElement>;

  /** The button layout to use for the current width */
  buttonLayout: TypeToggleButtonsLayout;
};

/**
 * Compute the layout to use for the walk overlay based on the width available
 *
 * @returns the ref to attach to the main overlay div and the layout to use
 */
export function useWalkOverlayResponsiveLayout(): WalkOverlayResponsiveLayoutResponse {
  const [buttonLayout, setButtonLayout] = useState(
    TypeToggleButtonsLayout.Small,
  );
  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!overlayRef.current) return;

    // Compute the initial layout
    setButtonLayout(computeLayout(overlayRef.current.offsetWidth));

    const observer = new ResizeObserver((entries) => {
      if (!overlayRef.current) return;
      const entry = entries.find((e) => e.target === overlayRef.current);
      if (!entry) return;
      setButtonLayout(computeLayout(overlayRef.current.offsetWidth));
    });

    // Attach the resize observer to the stack
    observer.observe(overlayRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return { buttonLayout, overlayRef };
}
