import { FaroToast } from "@faro-lotv/flat-ui";
import { Slide, SlideProps, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type WalkDepthLoadingToastProps = {
  isDepthReady: boolean;
  trackingElement?: HTMLElement | null;
};

/** @returns A toast showed at the center of the reference html element if the pano is waiting for the depth information */
export function WalkDepthLoadingToast({
  isDepthReady,
  trackingElement,
}: WalkDepthLoadingToastProps): JSX.Element {
  // Change the cursor while we are showing the toast
  useEffect(() => {
    if (!trackingElement) return;
    const shape = trackingElement.style.cursor;

    if (!isDepthReady) {
      trackingElement.style.cursor = "progress";
      return () => {
        trackingElement.style.cursor = shape;
      };
    }
  }, [trackingElement, isDepthReady]);

  // Rest the toast state once depths are ready
  const [toastEnabled, setToastEnabled] = useState(true);
  useEffect(() => {
    if (isDepthReady) {
      setToastEnabled(true);
    }
  }, [isDepthReady]);

  // Close the toast when clicking on the X button
  const onToastClosed = useCallback(() => setToastEnabled(false), []);

  return (
    <Snackbar
      open={!isDepthReady && toastEnabled}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={ToastTransition}
      sx={{
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
      }}
      autoHideDuration={null}
    >
      <FaroToast
        variant="info"
        title="Preparing the measurement tool"
        message="To enable accurate measurements, the highest resolution images are being downloaded. This may take a few seconds."
        onClose={onToastClosed}
      />
    </Snackbar>
  );
}

function ToastTransition(props: SlideProps): JSX.Element {
  return <Slide {...props} direction="down" />;
}
