import {
  EventType,
  SelectCaptureProperties,
} from "@/analytics/analytics-events";
import { SceneFilterToggle } from "@/components/common/scene-filter-toggle";
import { AvailableAnnotationTools } from "@/components/r3f/renderers/annotations/annotations-types";
import { DataSessionsDropDown } from "@/components/ui/data-sessions-dropdown";
import { ToolsHelpBanners } from "@/components/ui/help-banners/tools-help-banners";
import { SplitScreenButton } from "@/components/ui/splitscreen-button";
import { useCurrentArea } from "@/modes/mode-data-context";
import { splitMode } from "@/modes/split-mode/split-mode";
import { selectModeName } from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { setCompareSceneFilter } from "@/store/modes/walk-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCanReadPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import { setIsProjectOverviewExpanded } from "@/store/ui/ui-slice";
import { SceneFilter } from "@/types/scene-filter";
import {
  selectAncestor,
  selectIElementChildren,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { includes } from "@faro-lotv/foundation";
import {
  compareById,
  GUID,
  IElementImg360,
  IElementModel3dStream,
  IElementSection,
  isIElementSection,
} from "@faro-lotv/ielement-types";
import { Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";
import { useIsPanoWithDepthAndReady } from "./walk-mode-hooks";
import {
  TypeToggleButtonsLayout,
  useWalkOverlayResponsiveLayout,
} from "./walk-overlay-responsive-layout";
import { WalkSceneActiveElement } from "./walk-types";

type WalkOverlayProps = {
  /** Current main element the user is navigating in walk mode */
  activeWalkElement: WalkSceneActiveElement;

  /** The current reference element for the active element (Eg. DataSession, VideoRecording, Room) */
  referenceElement?: IElementSection;

  /** True if there are panos available in the current scene */
  hasPanos: boolean;

  /** The current cad model */
  cad?: IElementModel3dStream;

  /**
   * If `true`, the user is currently using the measurement tool.
   *
   * The Overlay will add a help banner indicating that measurements are
   * not possible in 360 images when in panorama mode.
   */
  isMeasuring: boolean;

  /** The current filtering of the scene of scene */
  sceneFilter: SceneFilter;

  /** Call back for when the scene filter is changed */
  onSceneFilterChanged(sceneFilter: SceneFilter): void;

  /** Call back for when the active element is changed */
  onActiveElementChanged(id: GUID): void;

  /** Function called when the pano type selected in the menu changed */
  onPanoTypeChanged?(showIntensity: boolean, siblingPano: IElementImg360): void;

  /**
   * False if the measure help banner doesn't need to be handled in walk overlay
   *
   * @default true
   */
  shouldHandleMeasureHelpBanner?: boolean;

  /** Additional styles */
  sx?: SxProps<Theme>;

  /** True if the CAD is being rendered in other split screen, in split mode */
  isOtherViewUsingCad?: boolean;
};

/**
 * @returns renderer for the walk mode overlay
 */
export function WalkOverlay({
  activeWalkElement,
  referenceElement,
  hasPanos,
  cad,
  isMeasuring,
  sceneFilter,
  onSceneFilterChanged,
  onActiveElementChanged,
  onPanoTypeChanged,
  shouldHandleMeasureHelpBanner = true,
  isOtherViewUsingCad,
  sx,
}: WalkOverlayProps): JSX.Element | null {
  const currentArea = useCurrentArea();

  const { isPanoWithDepth, isDepthReady } =
    useIsPanoWithDepthAndReady(activeWalkElement);

  // 'cannotMeasure' is true when the user is in splitscreen mode, has the
  // point cloud on one screen and a pano on the other, and has the measuring tool
  // active. In this case, she can measure on the point cloud, but measurement on the
  // pano is possible only if the pano has depth information.
  const cannotMeasure =
    isMeasuring && sceneFilter === SceneFilter.Pano && !isPanoWithDepth;

  const { buttonLayout, overlayRef } = useWalkOverlayResponsiveLayout();

  const { dataSessions2d, dataSessions3d, dataSessions5d, roomsSections } =
    useCurrentArea();

  const referenceRoom = useAppSelector(
    selectAncestor(activeWalkElement, (el) =>
      includes(roomsSections, el, compareById),
    ),
  );
  const store = useAppStore();

  const options = useMemo(() => {
    if (sceneFilter === SceneFilter.Pano) {
      const state = store.getState();
      const rooms = referenceRoom?.parentId
        ? selectIElementChildren(referenceRoom.parentId)(state).filter(
            isIElementSection,
          )
        : [];
      return [...dataSessions2d, ...dataSessions5d, ...rooms];
    }
    if (
      sceneFilter === SceneFilter.PointCloud ||
      sceneFilter === SceneFilter.Overlay
    ) {
      return [...dataSessions3d, ...dataSessions5d];
    }
    return [];
  }, [
    sceneFilter,
    dataSessions2d,
    dataSessions3d,
    dataSessions5d,
    referenceRoom,
    store,
  ]);

  const hasPcReadSupport = useAppSelector(selectCanReadPointCloud);
  const isSplitMode = useAppSelector(selectModeName) === "split";

  const canEnablePointCloud =
    hasPcReadSupport &&
    (currentArea.dataSessions3d.length > 0 ||
      currentArea.dataSessions5d.length > 0);

  const canEnableCad = !!cad && !isOtherViewUsingCad;

  const isOverlayEnabled = canEnableCad && !isSplitMode && canEnablePointCloud;

  function determineDisabledTextForOverlayButton(): string | undefined {
    if (isOverlayEnabled) return;
    return isSplitMode
      ? "Overlay feature unavailable in split screen"
      : "Overlay feature unavailable: missing required data.";
  }

  const waitingForDepths = useMemo(() => {
    return isPanoWithDepth && !isDepthReady && isMeasuring;
  }, [isDepthReady, isMeasuring, isPanoWithDepth]);

  // Determine the available variants of annotation tool for the current scene
  const availableAnnotationTool = useMemo(() => {
    if (sceneFilter === SceneFilter.Pano) {
      return isDepthReady
        ? AvailableAnnotationTools.all
        : AvailableAnnotationTools.rectangle;
    }
    return AvailableAnnotationTools.singlePoint;
  }, [isDepthReady, sceneFilter]);

  return (
    <Stack direction="column" spacing={1} sx={sx}>
      <Stack
        direction={
          buttonLayout === TypeToggleButtonsLayout.Small ? "column" : "row"
        }
        spacing={1}
        justifyContent={options.length ? "space-between" : "end"}
        ref={overlayRef}
      >
        <Stack direction="row" gap={1.25}>
          {!!options.length && (
            <DataSessionsDropDown
              referenceElement={referenceRoom ?? referenceElement}
              datasets={options}
              onDataSessionChanged={(id) => {
                if (sceneFilter === SceneFilter.Pano) {
                  Analytics.track(EventType.selectCapture, {
                    via: SelectCaptureProperties.dropdown,
                  });
                }
                onActiveElementChanged(id);
              }}
            />
          )}
        </Stack>
        <SceneFilterToggle
          value={sceneFilter}
          onValueChanged={onSceneFilterChanged}
          onActiveElementChanged={onActiveElementChanged}
          onPanoTypeChanged={onPanoTypeChanged}
          currentMainElement={activeWalkElement}
          panoVisible={hasPanos}
          pointCloudEnabled={canEnablePointCloud}
          cadEnabled={canEnableCad}
          overlayEnabled={isOverlayEnabled}
          overlayDisabledText={determineDisabledTextForOverlayButton()}
          layout={buttonLayout}
        />
      </Stack>

      <ToolsHelpBanners
        measureHelpBanner={{
          cannotMeasure,
          enabled:
            !cannotMeasure &&
            shouldHandleMeasureHelpBanner &&
            !waitingForDepths,
        }}
        annotationHelpBanner={{ enabled: true, availableAnnotationTool }}
      />
    </Stack>
  );
}

type WalkOverlayBottomButtonsProps = {
  /** Reference to the currently selected pano or point cloud element */
  activeElement?: WalkSceneActiveElement;

  /** Current scene type */
  walkSceneFilter: SceneFilter;
};

/**
 * @returns the buttons at the bottom of the WalkOverlay to enable the split screen
 */
export function WalkOverlayBottomButtons({
  activeElement,
  walkSceneFilter,
}: WalkOverlayBottomButtonsProps): JSX.Element {
  const dispatch = useAppDispatch();

  // Find the rightCandidateSection and if it doesn't exist, then disable the splitscreen
  const canStartSplitScreen =
    useAppSelector(
      (state) =>
        activeElement && splitMode.canBeStartedWith?.(activeElement, state),
    ) && walkSceneFilter !== SceneFilter.Overlay;

  const validCompareFilters = [SceneFilter.Pano, SceneFilter.PointCloud];

  return (
    <SplitScreenButton
      enabled={canStartSplitScreen ?? false}
      checked={false}
      onClick={() => {
        Analytics.track(EventType.turnSplitscreenOn);
        dispatch(changeMode("split"));
        dispatch(setIsProjectOverviewExpanded(false));
        dispatch(
          setCompareSceneFilter(
            validCompareFilters.includes(walkSceneFilter)
              ? walkSceneFilter
              : SceneFilter.Pano,
          ),
        );
      }}
    />
  );
}
