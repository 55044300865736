import { RenderingSettingsContext } from "@/components/common/rendering-settings-context";
import { SuspenseLoadNotifier } from "@/components/r3f/utils/suspense-load-notifier";
import { DEFAULT_CANVAS_OPTIONS } from "@faro-lotv/app-component-toolbox";
import { AuthContext } from "@faro-lotv/gate-keepers";
import { PerformanceMonitor, useContextBridge } from "@react-three/drei";
import { createRoot, useThree } from "@react-three/fiber";
import {
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactReduxContext } from "react-redux";
import { ModeDataContext } from "../modes/mode-data-context";

type RootType = ReturnType<typeof createRoot>;

/**
 * @param component The component to take a screenshot of
 * @param width The width of the screenshot
 * @param height The height of the screenshot
 * @param onComponentRendered Callback called when the component has been rendered onto the canvas
 * @returns a function that when called will take a screenshot of the component
 */
export function useComponentScreenshot(
  component: ReactNode,
  width: number,
  height: number,
  onComponentRendered?: (canvas: HTMLCanvasElement) => void,
): () => void {
  const Bridge = useContextBridge(
    AuthContext,
    ReactReduxContext,
    RenderingSettingsContext,
    ModeDataContext,
  );

  // Canvas where to render the image and take the screenshot
  const canvas = useMemo(() => document.createElement("canvas"), []);

  // Resize the canvas when the width or height changes
  // This is done separately from the canvas creation to avoid re-creating the canvas
  useEffect(() => {
    canvas.width = width;
    canvas.height = height;
  }, [canvas, width, height]);

  const [root, setRoot] = useState<RootType>();

  useEffect(() => {
    // Create a new root for the canvas
    // On Firefox, this line could print the warning "R3F.createRoot should only be called once!"
    // This happens because the unmount is done after a timeout (???) of 500ms and, when running
    // the app in dev mode, this hook is mounted, unmounted and mounted again very quickly,
    // faster than the timeout. This warning should not impact the PROD build of the app and it's
    // not causing issues on the DEV one.
    const r = createRoot(canvas);
    setRoot(r);

    // Unmount the root when the component is unmounted
    return () => {
      r.unmount();
    };
  }, [canvas]);

  useEffect(() => {
    if (!root) return;
    root.configure({
      // We need to enable the same color management flags as the main canvas,
      // otherwise they will conflict, because the ColorManagement is a global object shared
      // among all r3f canvases
      ...DEFAULT_CANVAS_OPTIONS,
      frameloop: "never",
      size: { width, height, top: 0, left: 0 },
    });
  }, [height, root, width]);

  const actions = useRef<ScreenshotComponentActions>(null);

  useEffect(() => {
    if (!root) return;
    // Render the component in the root
    root.render(
      <Bridge>
        <PerformanceMonitor>
          <SuspenseLoadNotifier>
            <ScreenshotComponent
              canvas={canvas}
              actions={actions}
              onComponentRendered={onComponentRendered}
            >
              {component}
            </ScreenshotComponent>
          </SuspenseLoadNotifier>
        </PerformanceMonitor>
      </Bridge>,
    );
  }, [root, Bridge, component, canvas, onComponentRendered]);

  // Return a function that when called will take a screenshot of the component
  return useCallback(() => {
    actions.current?.takeScreenshot();
  }, []);
}

type ScreenshotComponentActions = {
  /**
   * Function that when called will take a screenshot of the canvas
   * The screenshot will be taken after 60 frames
   */
  takeScreenshot(): void;
};

type ScreenshotComponentProps = {
  /** A handle on the action objects to modify the TwoPointAlignments */
  actions?: RefObject<ScreenshotComponentActions>;
  /** The canvas to take a screenshot of */
  canvas: HTMLCanvasElement;
  /** The callback to be called when component has been rendered onto the canvas */
  onComponentRendered?(canvas: HTMLCanvasElement): void;
};

function ScreenshotComponent({
  actions,
  canvas,
  children,
  onComponentRendered,
}: ScreenshotComponentProps & PropsWithChildren): JSX.Element {
  const three = useThree();

  const [takeScreenshot, setTakeScreenshot] = useState(false);

  useImperativeHandle(
    actions,
    () => ({
      takeScreenshot: () => {
        let frames = 0;
        // Let 60 frame pass before taking the screenshot
        function draw(): void {
          // TODO: Wait for the pointcloud to be finished downloading all the points
          // https://faro01.atlassian.net/browse/SWEB-5421
          if (frames >= 60) {
            setTakeScreenshot(true);
            return;
          }
          requestAnimationFrame(() => draw());
          three.advance(Date.now());
          ++frames;
        }

        draw();
      },
    }),
    [three],
  );

  useEffect(() => {
    if (!takeScreenshot) return;

    onComponentRendered?.(canvas);

    setTakeScreenshot(false);
  }, [takeScreenshot, canvas, onComponentRendered]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
