import { MountNotifier } from "@/components/common/mount-notifier";
import { setModeIsLoadingData } from "@/store/mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { PropsWithChildren, Suspense, useCallback } from "react";
import { SnapshotRenderer } from "../renderers/snapshot-renderer";

/**
 * @returns A react suspense that will render the last valid scene and notify the app that data is loading
 */
export function SuspenseLoadNotifier({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();
  const onMountChanged = useCallback(
    (isLoading: boolean) => {
      dispatch(setModeIsLoadingData(isLoading));
    },
    [dispatch],
  );

  return (
    <Suspense
      fallback={
        <MountNotifier onMountChanged={onMountChanged}>
          <SnapshotRenderer />
        </MountNotifier>
      }
    >
      {children}
    </Suspense>
  );
}
