import { EventType } from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { selectIElement } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const ADJUST_TRAJECTORY_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.adjustTrajectory,
  label: "Adjust Trajectory",
  icon: ElementIconType.ToolIcon,
  // eslint-disable-next-line require-await -- FIXME
  handler: async ({ elementID, state, dispatch }) => {
    const odometryPath = selectIElement(elementID)(state);
    if (!odometryPath) return;

    Analytics.track(EventType.startVideoModeEditor);

    dispatch(setActiveElement(odometryPath.id));
    dispatch(changeMode("pathAlignment"));
  },
};
