import { RootState } from "@/store/store";
import { SceneFilter } from "@/types/scene-filter";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type OverviewModeState = {
  sceneFilter: SceneFilter;
};

const initialState: OverviewModeState = {
  sceneFilter: SceneFilter.PointCloud,
};

export const overviewModeSlice = createSlice({
  initialState,
  name: "overviewMode",
  reducers: {
    setOverviewSceneFilter(state, action: PayloadAction<SceneFilter>) {
      state.sceneFilter = action.payload;
    },
  },
});

export const { setOverviewSceneFilter } = overviewModeSlice.actions;

export const overviewModeReducer = overviewModeSlice.reducer;

/**
 * @returns the current scene filter for overview mode.
 */
export function selectOverviewSceneFilter({
  overviewMode,
}: RootState): SceneFilter {
  return overviewMode.sceneFilter;
}

/** @returns whether CAD models are currently selected in the current overview mode scene filter */
export function selectOverviewIsCadSelected({
  overviewMode,
}: RootState): boolean {
  return (
    overviewMode.sceneFilter === SceneFilter.Cad ||
    overviewMode.sceneFilter === SceneFilter.Overlay
  );
}

/** @returns whether point clouds are currently selected in the current overview mode scene filter */
export function selectOverviewIsPointCloudSelected({
  overviewMode,
}: RootState): boolean {
  return (
    overviewMode.sceneFilter === SceneFilter.PointCloud ||
    overviewMode.sceneFilter === SceneFilter.Overlay
  );
}
