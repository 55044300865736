import { useAppSelector } from "@/store/store-hooks";
import { PointCloudExportTask } from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import { NoTranslate } from "@faro-lotv/flat-ui";
import {
  isIElementGenericDataset,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { CardProgress } from "../../card-progress";
import { GenericCardLayout } from "../../layouts/generic-card-layout";

type PointCloudExportCardProps = {
  /** The export task */
  task: PointCloudExportTask;
};

/** @returns The card for the point cloud export tasks */
export function PointCloudExportCard({
  task,
}: PointCloudExportCardProps): JSX.Element {
  const refElement = useAppSelector((state) =>
    selectAncestor(
      selectIElement(task.iElementId ?? "")(state),
      (e) => isIElementSectionDataSession(e) || isIElementGenericDataset(e),
    )(state),
  );

  const downloadExportedVolume = useCallback(() => {
    if (task.metadata.downloadUrl !== undefined) {
      downloadFile(task.metadata.downloadUrl);
    }
  }, [task.metadata.downloadUrl]);

  let cardName: string | JSX.Element = "Export";
  if (refElement?.name) {
    cardName = <NoTranslate>{refElement.name}</NoTranslate>;
  }

  return (
    <GenericCardLayout
      name={cardName}
      subText="Point cloud"
      startTime={task.createdAt}
      action={
        task.metadata.downloadUrl
          ? {
              name: "Download",
              callback: downloadExportedVolume,
            }
          : undefined
      }
    >
      {isBackgroundTaskActive(task.state) && (
        <CardProgress label="Exporting..." task={task} />
      )}
    </GenericCardLayout>
  );
}
