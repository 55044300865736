import {
  USER_AVATAR_STYLE_MAP,
  UserAvatar,
  UserAvatarProps,
} from "@faro-lotv/flat-ui";

/** @returns the UserAvatar to use in the card that handle the undefined case retaining the expected size */
export function CardUserAvatar({
  userDisplayInfo,
}: Pick<UserAvatarProps, "userDisplayInfo">): JSX.Element {
  if (!userDisplayInfo) {
    return (
      <div
        style={{
          width: USER_AVATAR_STYLE_MAP.xs.wrapperSize,
          height: USER_AVATAR_STYLE_MAP.xs.wrapperSize,
        }}
      />
    );
  }

  return <UserAvatar size="xs" userDisplayInfo={userDisplayInfo} />;
}
