import JSZip from "jszip";

/** A zip-file entry. */
interface IFileEntry {
  /** The name of the file. */
  fileName: string;

  /**
   * The data URL of the file.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs}
   */
  dataUrl: string;
}

/** Data related to a 3D model. */
export type Model3dDataZip = {
  /** The data URL of the .obj file. */
  objUrl: string | undefined;
  /** The data URL of the .mtl file. */
  mtlUrl: string | undefined;
  /** The texture files. */
  texturesData: IFileEntry[];
};

/**
 * Fetch a 3D model zip-file and extract the relevant data.
 *
 * @param uri - The URI to a zip file containing the relevant data.
 *  Must contain a model.obj file for the mesh, a modelmaterial.mtl file
 *  for the material and .png files for the textures.
 * @param signal The signal to abort the fetching
 * @returns The data urls to the resources inside the zip
 */
export async function fetchModel3DZip(
  uri: string | undefined,
  signal: AbortSignal,
): Promise<Model3dDataZip | undefined> {
  const zip = await fetchZipFile(uri, signal);
  if (!zip) {
    return;
  }

  const texturesData = await extractFiles(zip, (fileName) =>
    fileName.endsWith(".png"),
  );

  const mtlUrl =
    "modelmaterial.mtl" in zip.files
      ? await fileToDataURL(zip.files["modelmaterial.mtl"])
      : undefined;
  const objUrl =
    "model.obj" in zip.files
      ? await fileToDataURL(zip.files["model.obj"])
      : undefined;

  return {
    objUrl,
    mtlUrl,
    texturesData,
  };
}

/**
 * Fetch the zip file with the given URI.
 *
 * @param uri URI to fetch from
 * @param signal signal to abort the fetching
 * @throws Errors if uri is undefined.
 * @throws Errors if the fetching of the zip file failed.
 * @returns a JSZip element containing the resources in the zip
 */
async function fetchZipFile(
  uri: string | undefined,
  signal: AbortSignal,
): Promise<JSZip | undefined> {
  if (!uri) {
    throw new Error("No URI provided!");
  }

  const response = await fetch(uri, { signal });
  if (signal.aborted) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `Failed getting .zip file ${uri}!\n${JSON.stringify(response)}`,
    );
  }

  return JSZip.loadAsync(await response.blob());
}

/**
 * Extract the needed files from the zip.
 *
 * @param zip The zip-file to extract the files from.
 * @param filterFn A function to decide which files to extract, based on the file name.
 * @returns The extracted files, which file name and data URL.
 */
// eslint-disable-next-line require-await -- FIXME
async function extractFiles(
  zip: JSZip,
  filterFn: (fileName: string) => boolean,
): Promise<IFileEntry[]> {
  return Promise.all(
    // Look at all the files in the zip-file
    Object.keys(zip.files)
      // Extract the needed files
      .filter(filterFn)
      // Convert them to their data URL
      .map(async (fileName) => ({
        fileName,
        dataUrl: await fileToDataURL(zip.files[fileName]),
      })),
  );
}

/**
 * Convert a JSZIP file to a data URL.
 * This allows them to be loaded in three-js.
 *
 * @param file File to convert
 * @returns The data url of the blob
 */
async function fileToDataURL(file: JSZip.JSZipObject): Promise<string> {
  const blob = await file.async("blob");
  return blobToDataURL(blob);
}

/**
 * @returns Converted blob as a data URL.
 *    We need the data URL to render the object via three-js.
 * @param blob Blob to convert
 */
function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
        return;
      }
      reject(new Error("Invalid data read"));
    };
    reader.onerror = () => reject(reader.error);
    reader.onabort = () => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}
