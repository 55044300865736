import { DeepLinkProperties, EventType } from "@/analytics/analytics-events";
import { computeDeepLink } from "@/components/common/deep-link/deep-link-utils";
import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { selectModeName } from "@/store/mode-selectors";
import { selectActiveElementId } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectProjectAccessLevel } from "@faro-lotv/app-component-toolbox";
import {
  Alert,
  FaroButton,
  FaroDialog,
  FaroIconButton,
  FaroText,
  ShareLinkIcon,
  TextField,
  TextFieldProps,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { ProjectAccessLevel } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useCallback, useState } from "react";

/**
 * @returns an icon button that opens a share dialog to copy a deep link
 */
export function ShareLinkButton(): JSX.Element | null {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [link, setLink] = useState("");

  const projectAccessLevel = useAppSelector(selectProjectAccessLevel);
  const { openToast } = useToast();
  const viewContext = useViewRuntimeContext();

  const modeName = useAppSelector(selectModeName);
  const activeElementId = useAppSelector(selectActiveElementId);
  const store = useAppStore();

  /** Compute the link at the moment the user open the share dialog */
  const computeLinkAndOpenDialog = useCallback(() => {
    assert(activeElementId, "Active element is required to create deep links");

    setLink(computeDeepLink(store.getState(), viewContext).toString());
    setIsDialogOpen(true);
  }, [activeElementId, store, viewContext]);

  const copyLinkAndCloseDialog = useCallback<CopyTextFieldProps["onCopyLink"]>(
    (ev) => {
      // If the user selected only a part of the url prevent the default copy logic
      // so we can save in the clipboard the entire url
      ev.preventDefault();

      Analytics.track<DeepLinkProperties>(EventType.copyDeepLink, {
        mode: modeName,
        access: projectAccessLevel ?? ProjectAccessLevel.private,
      });

      // When the Copy button is clicked, copy the deep link, to the clipboard
      navigator.clipboard.writeText(link);

      // Close the dialog
      setIsDialogOpen(false);

      openToast({ title: "Link copied" });
    },
    [link, modeName, openToast, projectAccessLevel],
  );

  const closeLinkDialog = useCallback(() => {
    Analytics.track<DeepLinkProperties>(EventType.closeDeepLink, {
      mode: modeName,
      access: projectAccessLevel ?? ProjectAccessLevel.private,
    });

    setIsDialogOpen(false);
  }, [modeName, projectAccessLevel]);

  // Don't show the share button if the view is not ready as we need the camera
  if (!viewContext.cameras.length) {
    return null;
  }

  return (
    <>
      <FaroIconButton onClick={computeLinkAndOpenDialog} aria-label="deep link">
        <ShareLinkIcon />
      </FaroIconButton>
      <FaroDialog
        title="Share direct link"
        isConfirmDisabled
        open={isDialogOpen}
        onClose={closeLinkDialog}
        showXButton
      >
        <Stack gap="0.625rem">
          <FaroText variant="bodyM">
            Share a link to your current location.
          </FaroText>
          {projectAccessLevel === ProjectAccessLevel.private && (
            <Alert
              variant="info"
              title="Users must have access to the project to view it. If the project is public, anyone on the Internet can view it."
            />
          )}
          <CopyTextField
            onCopyLink={copyLinkAndCloseDialog}
            link={link}
            fullWidth
          />
        </Stack>
      </FaroDialog>
    </>
  );
}

type CopyTextFieldProps = TextFieldProps & {
  /** Callback called when the user want to copy the link, from the button or the text field */
  onCopyLink(event: React.MouseEvent | React.ClipboardEvent): void;

  /** The deep link to show to the user in the text field */
  link: string;
};

/**
 * @returns a text field with a button to copy its content
 */
function CopyTextField({
  onCopyLink,
  link,
  ...rest
}: CopyTextFieldProps): JSX.Element {
  return (
    <TextField
      {...rest}
      text={link}
      label="Project link"
      onCopy={onCopyLink}
      onFocus={(ev) => {
        ev.target.select();
      }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <FaroButton
            variant="primary"
            size="m"
            onClick={onCopyLink}
            aria-label="copy link"
          >
            Copy
          </FaroButton>
        ),
      }}
      sx={{
        padding: 0,
        height: "2.5rem",
        // Use && to have more priority over other mui classes
        "&& >input": {
          py: 0,
          height: "inherit",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        // Make the button the same height of the TextField
        "& >button": {
          height: "inherit",
          // Border only on the right
          borderRadius: "0 4px 4px 0",
        },
      }}
    />
  );
}
