import {
  FaroButton,
  FaroRadio,
  FaroRadioGroup,
  NoTranslate,
  PointCloudIcon,
  dataComparisonColorsSorted,
  neutral,
} from "@faro-lotv/flat-ui";
import { IElementSectionDataSession } from "@faro-lotv/ielement-types";
import { Box, Stack } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { IconText } from "./icon-text";

type MultiRegistrationSideBarProps = DataSessionListProps & {
  /** A callback to execute when the user toggles the visual registration. */
  onToggleVisualRegistration(): void;
};

/**
 * @returns The side bar of the multi cloud view, showing the list of data sessions.
 */
export function MultiRegistrationSideBar({
  dataSessions,
  selectedDataSession,
  onSelectDataSession,
  onToggleVisualRegistration,
  isVisualRegistrationEnabled,
}: MultiRegistrationSideBarProps): JSX.Element {
  return (
    <Stack
      gap={2}
      sx={{
        minWidth: "16em",
        height: "100%",
        p: 2,
        borderRight: `1px solid ${neutral[200]}`,
      }}
    >
      <FaroButton variant="ghost" onClick={onToggleVisualRegistration}>
        {isVisualRegistrationEnabled ? "Disable" : "Enable"} Visual Registration
      </FaroButton>

      <Box
        component="div"
        sx={{ width: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        <DataSessionList
          dataSessions={dataSessions}
          selectedDataSession={selectedDataSession}
          onSelectDataSession={onSelectDataSession}
          isVisualRegistrationEnabled={isVisualRegistrationEnabled}
        />
      </Box>
    </Stack>
  );
}

type DataSessionListProps = {
  /** All data sessions in the multi cloud view. */
  dataSessions: IElementSectionDataSession[];

  /** Whether the user selection of a data session is enabled. */
  isVisualRegistrationEnabled: boolean;

  /** The currently selected data session by the user. */
  selectedDataSession: IElementSectionDataSession;

  /** The action to execute when the user changes the selected data session. */
  onSelectDataSession(dataSession: IElementSectionDataSession): void;
};

/**
 * @returns An overview of all data sessions in the multi cloud view.
 *  Additionally allows the selection of the focused data session.
 */
function DataSessionList({
  dataSessions,
  selectedDataSession,
  onSelectDataSession,
  isVisualRegistrationEnabled,
}: DataSessionListProps): JSX.Element {
  const selectDataSession = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDataSession = dataSessions.find(
        (dataSession) => dataSession.id === event.target.value,
      );
      if (newDataSession) {
        onSelectDataSession(newDataSession);
      }
    },
    [dataSessions, onSelectDataSession],
  );

  return (
    <NoTranslate>
      <FaroRadioGroup
        value={selectedDataSession.id}
        onChange={selectDataSession}
        disabled={!isVisualRegistrationEnabled}
        // If the visual registration is disabled, hide the radio buttons
        // Easier than creating a different structure for this case with the same layout
        radioGroupSx={{
          "& .MuiRadio-root": isVisualRegistrationEnabled
            ? {}
            : {
                visibility: "hidden",
                width: 0,
              },
          "& .MuiFormControlLabel-root.Mui-disabled": {
            opacity: 1,
          },
        }}
      >
        {dataSessions.map((dataSession, index) => (
          <FaroRadio
            key={dataSession.id}
            value={dataSession.id}
            disabled={!isVisualRegistrationEnabled}
            label={
              <IconText
                variant="bodyS"
                icon={
                  <PointCloudIcon
                    sx={{
                      color:
                        dataComparisonColorsSorted[
                          index % dataComparisonColorsSorted.length
                        ],
                    }}
                  />
                }
                text={dataSession.name}
              />
            }
          />
        ))}
      </FaroRadioGroup>
    </NoTranslate>
  );
}
