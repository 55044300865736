import {
  QualityProps,
  QualityStatus,
  RegistrationDetails,
  RegistrationMetricsData,
  getMetricScaleIcon,
  getQualityIconDetails,
} from "@/registration-tools/utils/metrics";
import {
  FaroSvgIcon,
  FaroText,
  FontWeights,
  NO_TRANSLATE_CLASS,
} from "@faro-lotv/flat-ui";
import { Box, Card, Stack, useTheme } from "@mui/material";
import { FunctionComponent, SVGAttributes, useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ReactComponent as MetricHistogram } from "../../icons/metrics-histogram-graphic.svg";
import { ReactComponent as RegistrationOverlap } from "../../icons/metrics-overlap-graphic.svg";
import { ReactComponent as RegistrationPointsDistance } from "../../icons/metrics-point-distance-graphic.svg";
import { UnitType, displayUnitValue } from "../../utils/units";
import { RegistrationCard } from "./registration-card";
import {
  MetricThresholds,
  MetricThresholdsView,
  RegistrationThresholdSet,
} from "./registration-thresholds";
import { ThresholdSetSwitch } from "./threshold-set-switch";

type RegistrationMetricsProps = Pick<
  RegistrationMetricsData,
  "overlap" | "rlyHistogram"
> & {
  /** The thresholds to use to determine the quality of the metric values. */
  thresholdSet: RegistrationThresholdSet;
};

/** params for registration metric card display */
interface RegCardProps extends QualityProps {
  /** icon representing the registration metric */
  icon: FunctionComponent<SVGAttributes<SVGElement>>;
  /** name of the metric */
  metricName: string;
  /** description for the metric */
  description: string;
  /** value of the metric */
  value?: number;
  /** measure unit of metric */
  unit: UnitType;
  /** threshold conditions for the metric */
  thresholds?: MetricThresholds;
}

/**
 * @returns The registration result when refinement is successful
 * Allows user to see the registration result metrics
 */
export function RegistrationMetrics({
  overlap,
  rlyHistogram,
  thresholdSet,
}: RegistrationMetricsProps): JSX.Element | null {
  const registration = new RegistrationDetails(
    overlap,
    thresholdSet,
    rlyHistogram,
  );

  return (
    <Stack spacing={1}>
      <ThresholdSetSwitch />
      <TotalQualityCard quality={registration.registrationQuality} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <RegistrationMetricCard
          icon={RegistrationOverlap}
          metricName="Overlap"
          description="Overlap between the registered point clouds in percent"
          thresholds={registration.overlapDetails.thresholds}
          value={registration.overlapDetails.value}
          unit={registration.overlapDetails.thresholds.unit}
          quality={registration.overlapDetails.quality}
        />
        <RegistrationMetricCard
          icon={RegistrationPointsDistance}
          metricName="Points Distance"
          description="Average Distance over all matching points in the registered point clouds"
          thresholds={registration.pointsDistanceDetails.thresholds}
          value={registration.pointsDistanceDetails.value}
          unit={registration.pointsDistanceDetails.thresholds.unit}
          quality={registration.pointsDistanceDetails.quality}
        />
      </Stack>
      {rlyHistogram?.bins.length && (
        <RegistrationHistogram
          bins={rlyHistogram.bins}
          resolution={rlyHistogram.resolution}
          limits={rlyHistogram.limits}
        />
      )}
    </Stack>
  );
}

type TotalQualityCardProps = {
  /** The total quality of the registration. */
  quality: QualityStatus;
};

/** @returns a card displaying the total quality of a pairwise registration. */
function TotalQualityCard({ quality }: TotalQualityCardProps): JSX.Element {
  return (
    <RegistrationCard
      title="Total Quality"
      cardIcon={
        <FaroSvgIcon
          source={getMetricScaleIcon(quality)}
          sx={{ width: "100%", height: "100%" }}
        />
      }
      statusIcon={<QualityIcon quality={quality} />}
    >
      <Stack direction="row" spacing={2}>
        <FaroText flexGrow={1} variant="helpText">
          Overall quality computed by the metrics below.
        </FaroText>
        <FaroText
          variant="heading32"
          sx={{
            lineHeight: "1em",
            alignSelf: "flex-end",
            flexShrink: 0,
          }}
        >
          {quality}
        </FaroText>
      </Stack>
    </RegistrationCard>
  );
}

/**
 * There are 2 styles to display a registration metric card which depends on whether the
 * thresholds are provided for the metric. If a metric has to be displayed with thresholds
 * then its width spans only half of the dialog width otherwise it spans entire width
 *
 * @returns the card to display a registration metrics
 */
export function RegistrationMetricCard({
  icon,
  metricName,
  description,
  unit,
  value,
  thresholds,
  quality,
}: RegCardProps): JSX.Element {
  return (
    <RegistrationCard
      title={metricName}
      cardIcon={
        <FaroSvgIcon source={icon} sx={{ width: "100%", height: "100%" }} />
      }
      statusIcon={<QualityIcon quality={quality} />}
    >
      <Stack direction={thresholds ? "column" : "row"} spacing={2}>
        <FaroText flexGrow={1} variant="helpText">
          {description}
        </FaroText>
        {thresholds && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <FaroText
              variant="labelS"
              sx={{ color: "gray500", fontWeight: FontWeights.Regular }}
            >
              Thresholds:
            </FaroText>
            <MetricThresholdsView {...thresholds} />
          </Stack>
        )}
        <FaroText
          variant="heading32"
          className={NO_TRANSLATE_CLASS}
          sx={{
            lineHeight: "1em",
            alignSelf: thresholds ? "flex-start" : "flex-end",
            flexShrink: 0,
          }}
        >
          {displayUnitValue(value, unit)}
        </FaroText>
      </Stack>
    </RegistrationCard>
  );
}

interface QualityIconsProps extends QualityProps {
  /** Overwrite the width of the icon. */
  width?: string;
  /** Overwrite the height of the icon. */
  height?: string;
}

/**
 * @returns the icon that represents the quality of connection or metric
 */
export function QualityIcon({
  quality,
  width,
  height,
}: QualityIconsProps): JSX.Element | null {
  const qualityIconDetails = getQualityIconDetails(quality);
  if (!qualityIconDetails) return null;
  return (
    <FaroSvgIcon
      sx={{
        color: qualityIconDetails.color,
        width: width ?? "100%",
        height: height ?? "100%",
      }}
      source={qualityIconDetails.quality}
    />
  );
}

/** params to plot the histogram */
interface HistogramMetrics {
  /** values of the histogram bins */
  bins: number[];
  /** width of the histogram bin */
  resolution: number;
  /** lowest and highest values on X axis of histogram  */
  limits: number[];
}

/**
 * @param histogram histogram metrics to plot
 * @returns the histogram card
 */
export function RegistrationHistogram(
  histogram: HistogramMetrics,
): JSX.Element {
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        minWidth: 400,
        maxHeight: 320,
        background: theme.palette.gray50,
      })}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1em",
          p: "1em",
        }}
      >
        <FaroSvgIcon
          source={MetricHistogram}
          sx={{ width: "42px", height: "42px" }}
        />
        <Stack width="100%">
          <FaroText
            variant="heading24"
            sx={{ color: "info.main", fontWeight: FontWeights.Regular }}
          >
            Distance Histogram
          </FaroText>
          <FaroText variant="helpText">
            Distribution of point distances over all points in the registered
            point clouds
          </FaroText>
          <PointsDistanceHistogram {...histogram} />
        </Stack>
      </Box>
    </Card>
  );
}

const HISTOGRAM_WIDTH = 550;
const HISTOGRAM_HEIGHT = 250;
const HISTOGRAM_MARGIN = {
  top: 15,
  right: 5,
  bottom: 25,
  left: 15,
};

/**
 * Allows the user to analyze the distribution of points across distance ranges
 *
 * @returns the distance histogram, where
 *  - X axis represents the distance range
 *  - Y axis represents the percentage of points that fall in that range
 */
function PointsDistanceHistogram({
  bins,
  resolution,
  limits,
}: HistogramMetrics): JSX.Element | null {
  const theme = useTheme();
  const histogramColorConfig = {
    // color of the axis line
    axisColor: theme.palette.gray850,
    // color of the axis label
    axisLabelColor: theme.palette.gray850,
    // color of the values on the axis
    axisTickTextColor: theme.palette.gray500,
    // color of histogram bin
    barColor: theme.palette.blue500,
  };

  const histogramData = useMemo(() => {
    const binTotal = bins.reduce((bin1, bin2) => bin1 + bin2);
    const percentageBins = bins.map((x) => (100 * x) / binTotal);
    const data = percentageBins.map((bin, index) => {
      const binWidth = Math.round(resolution * 1000);
      // bin represents X axis ticks and "% of points" represents Y axis data
      return {
        bin: `${index * binWidth}-${(index + 1) * binWidth}`,
        "% of points": bin.toFixed(1),
      };
    });

    return {
      maxXLimit: Math.round(limits[1] * 1000),
      maxYLimit: Math.ceil(Math.max(...percentageBins)),
      data,
    };
  }, [resolution, limits, bins]);

  return (
    <BarChart
      width={HISTOGRAM_WIDTH}
      height={HISTOGRAM_HEIGHT}
      data={histogramData.data}
      margin={HISTOGRAM_MARGIN}
      barCategoryGap={0}
    >
      <CartesianGrid vertical={false} strokeOpacity={0.5} />
      <XAxis
        // key of X-axis ticks
        dataKey="bin"
        domain={[0, histogramData.maxXLimit]}
        tick={{ fontSize: 10, fill: histogramColorConfig.axisTickTextColor }}
        className={NO_TRANSLATE_CLASS}
      >
        <Label
          value="Distance [mm]"
          position="bottom"
          style={{
            fontSize: "0.75rem",
            fontWeight: FontWeights.Bold,
            fill: histogramColorConfig.axisLabelColor,
          }}
        />
      </XAxis>
      <YAxis
        type="number"
        domain={[0, histogramData.maxYLimit]}
        axisLine={false}
        tickLine={false}
        tick={{ fontSize: 10, fill: histogramColorConfig.axisTickTextColor }}
        className={NO_TRANSLATE_CLASS}
      >
        <Label
          value="Points [%]"
          angle={-90}
          position="insideLeft"
          style={{
            fontSize: "0.75rem",
            fontWeight: FontWeights.Bold,
            fill: histogramColorConfig.axisLabelColor,
          }}
        />
      </YAxis>
      <Tooltip wrapperClassName={NO_TRANSLATE_CLASS} />
      <Bar
        // key for Y-axis data
        dataKey="% of points"
        fill={histogramColorConfig.barColor}
        fillOpacity={0.5}
        stroke={histogramColorConfig.barColor}
      />
    </BarChart>
  );
}
