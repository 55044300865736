import {
  useComputeValidModeElement,
  useCurrentArea,
} from "@/modes/mode-data-context";
import { changeMode } from "@/store/mode-slice";
import { selectOverviewSceneFilter } from "@/store/modes/overview-mode-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { GUID, includes, validatePrimitive } from "@faro-lotv/foundation";
import {
  IElementGenericStream,
  isIElementGenericStream,
} from "@faro-lotv/ielement-types";
import {
  DEFAULT_INITIAL_STATE,
  InitialStateProps,
  SceneFilterLookAtInitialState,
} from "../mode";
import { selectIsElementViewable } from "../mode-selectors";

/**
 * Analyze the current scene and adjust it so it can work for overview mode
 * Makes sure the active element is one of the valid sessions for overview scene
 *
 * @returns the main object overview mode can render (active cloud or active cad)
 */
export function useComputeValidOverviewScene():
  | IElementGenericStream
  | undefined {
  const { dataSessions3d, dataSessions5d } = useCurrentArea();

  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  return useComputeValidModeElement(
    [...dataSessions3d, ...dataSessions5d],
    (el): el is IElementGenericStream =>
      isIElementGenericStream(el) && selectIsElementViewable(el)(getState()),
    () => {
      dispatch(changeMode("sheet"));
    },
  );
}

/** An object to compute and parse a state object to create and restore the overview mode states from deep links */
export const OVERVIEW_MODE_INITIAL_STATE: InitialStateProps<SceneFilterLookAtInitialState> =
  {
    compute(state, viewContext) {
      return {
        ...DEFAULT_INITIAL_STATE.compute(state, viewContext),
        scene: selectOverviewSceneFilter(state),
      };
    },
    parse(appState, modeState) {
      const scene = includes(Object.values(SceneFilter), modeState.scene)
        ? modeState.scene
        : SceneFilter.PointCloud;
      let lookAtId: GUID | undefined;
      if (validatePrimitive(modeState, "lookAtId", "string")) {
        ({ lookAtId } = modeState);
      }
      return {
        ...DEFAULT_INITIAL_STATE.parse(appState, modeState),
        scene,
        lookAtId,
      };
    },
  };
