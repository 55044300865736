import {
  EventType,
  OpenTagManagementProperties,
} from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIsProjectOverviewExpanded } from "@/store/ui/ui-selectors";
import { setIsProjectOverviewExpanded } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { APP_VERSION } from "@/utils/env-constants";
import {
  ArrowDownIcon,
  FaroIconButton,
  FaroTooltip,
  NO_TRANSLATE_CLASS,
  NoTranslate,
  TagsIcon,
  TruncatedFaroText,
  useBreakpointMdUp,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { selectProjectName } from "@faro-lotv/project-source";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DevToolsPanel } from "../devtools/dev-tools-panel";
import { ProjectStatusWarningIcon } from "./project-status-warning-icon";
import { ProjectView } from "./project-view";
import { HorizontalResizeContainer } from "./resize-container";
import { TagsManagementDialog } from "./tags/tags-management-dialog";

/**
 * @returns A wrapper around the IElementTreeView component, which allows to collapse it to the side
 */
export function ProjectOverview(): JSX.Element {
  const dispatch = useAppDispatch();
  const isProjectViewExpanded = useAppSelector(selectIsProjectOverviewExpanded);

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundColor: "gray50",
          width: 40,
        }}
      >
        <FaroIconButton
          onClick={() => {
            dispatch(setIsProjectOverviewExpanded(!isProjectViewExpanded));
          }}
          title={
            isProjectViewExpanded
              ? "Collapse project overview"
              : "Expand project overview"
          }
        >
          <ArrowDownIcon
            sx={{
              transform: isProjectViewExpanded
                ? "rotate(90deg)"
                : "rotate(-90deg)",
              transition: "transform 0.1s linear",
            }}
          />
        </FaroIconButton>
      </Box>

      <HorizontalResizeContainer
        initialWidth={320}
        minWidth={320}
        maxWidth={700}
        handleSide="right"
        sx={{
          display: isProjectViewExpanded ? undefined : "none",
        }}
      >
        <ProjectOverviewBase />
      </HorizontalResizeContainer>
    </>
  );
}

/**
 *  @returns The base project overview component without the resize handle
 */
export function ProjectOverviewBase(): JSX.Element {
  // TODO: Evaluate translations
  // See https://faro01.atlassian.net/browse/SWEB-5177
  return (
    <Stack sx={{ width: "100%", flex: 1, overflow: "auto" }}>
      <NoTranslate>
        <SidebarTitle />
      </NoTranslate>

      <ProjectView />

      <NoTranslate>
        <DevToolsPanel />
      </NoTranslate>

      <Typography
        variant="caption"
        className={NO_TRANSLATE_CLASS}
        sx={{
          color: "black40",
          ml: 0.8,

          // Make sure that long dev-only version strings don't break design
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        ©FARO v{APP_VERSION}
      </Typography>
    </Stack>
  );
}

/**
 * @returns Title for the sidebar showing the project name and potential warning indicators.
 */
function SidebarTitle(): JSX.Element {
  const projectName = useAppSelector(selectProjectName);
  const isMdUp = useBreakpointMdUp();

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  return (
    <Stack
      alignItems="stretch"
      sx={{
        maxWidth: "100%",
        p: 2,
        pl: isMdUp ? 2 : 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={0.5}
      >
        <Stack direction="row" alignItems="center" gap={1.5} minWidth={0}>
          <TruncatedFaroText variant="heading14" sx={{ py: 1 }}>
            {projectName ?? "Loading..."}
          </TruncatedFaroText>
          <ProjectStatusWarningIcon
            sx={{
              fontSize: "14px",
            }}
          />
        </Stack>

        {hasWritePermission && <TagManagementButton />}
      </Stack>

      <Divider />
    </Stack>
  );
}

/** @returns an icon button that shows the tag management dialog when clicked */
function TagManagementButton(): JSX.Element {
  const [isTagsManagementOpen, setIsTagsManagementOpen] = useState(false);

  return (
    <>
      <FaroTooltip title="Tag management">
        <FaroIconButton
          aria-label="open tag management"
          size="s"
          onClick={() => {
            Analytics.track<OpenTagManagementProperties>(
              EventType.openTagManagement,
              {
                via: "project overview",
              },
            );
            setIsTagsManagementOpen(true);
          }}
        >
          <TagsIcon />
        </FaroIconButton>
      </FaroTooltip>

      <TagsManagementDialog
        open={isTagsManagementOpen}
        onClose={() => setIsTagsManagementOpen(false)}
      />
    </>
  );
}
