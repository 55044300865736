import { TypeToggleButtonsLayout } from "@/modes/walk-mode/walk-overlay-responsive-layout";
import { SceneFilter } from "@/types/scene-filter";
import {
  FaroMenu,
  Img360SmallIcon,
  IntensityPanoIcon,
  SingleSelectItem,
} from "@faro-lotv/flat-ui";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useCallback, useRef, useState } from "react";
import { TOGGLE_ICON_SX, TypeToggleButton } from "../ui/type-toggle-button";

type PanoFilterSelectorProps = {
  /** Current value of the scene filter in walk mode */
  filter: SceneFilter;

  /** Current layout used by the filter buttons, it's used to properly style them */
  buttonLayout: TypeToggleButtonsLayout;

  /** If true the current pano used in walk mode, is an intensity image */
  isPanoWithIntensity?: boolean;

  /** It's the pano element, of different typeHint, that is a sibling of the current active element*/
  siblingPano?: IElementImg360;

  /** Function to call when the button is clicked */
  onClick?(): void;

  /** Function called when the pano type selected in the menu changed */
  onPanoTypeChanged?(showIntensity: boolean, siblingPano: IElementImg360): void;
};

/** @returns a button with a menu to select the pano types, when available. Otherwise it returns a filter button. */
export function PanoFilterSelector({
  filter,
  buttonLayout,
  isPanoWithIntensity,
  siblingPano,
  onClick,
  onPanoTypeChanged,
}: PanoFilterSelectorProps): JSX.Element {
  // Current selected option in the menu
  const panoType = isPanoWithIntensity ? "intensity" : "color";

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const buttonRef = useRef(null);

  const togglePanoVariant = useCallback(
    (showIntensity: boolean) => {
      if (!siblingPano) return;

      // Update the store only if the new pano type selected if different from the current one
      const shouldUpdateStore =
        (panoType === "color" && showIntensity) ||
        (panoType === "intensity" && !showIntensity);

      if (shouldUpdateStore) {
        onPanoTypeChanged?.(showIntensity, siblingPano);
      }

      setIsMenuOpen(false);
    },
    [onPanoTypeChanged, panoType, siblingPano],
  );

  const showPanoTypeMenu = filter === SceneFilter.Pano && siblingPano;

  const handleButtonClick = useCallback(() => {
    if (showPanoTypeMenu) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      onClick?.();
    }
  }, [isMenuOpen, onClick, showPanoTypeMenu]);

  return (
    <>
      <TypeToggleButton
        ref={buttonRef}
        value={SceneFilter.Pano}
        aria-label="360 photo"
        selected={filter === SceneFilter.Pano}
        onClick={handleButtonClick}
        sx={{
          gap: 1,
          borderRadius: "6px 0 0 6px",
        }}
      >
        {/** Use a specific icon based on the available pano type */}
        {isPanoWithIntensity ? (
          <IntensityPanoIcon sx={TOGGLE_ICON_SX} />
        ) : (
          <Img360SmallIcon sx={TOGGLE_ICON_SX} />
        )}
        {buttonLayout === TypeToggleButtonsLayout.Large && "360° Photo"}
        {/** Only show the arrow icon if there are multiple pano types to select */}
        {showPanoTypeMenu && (
          <ExpandMoreOutlinedIcon
            sx={{ transform: isMenuOpen ? "rotate(180deg)" : "none" }}
          />
        )}
      </TypeToggleButton>
      {/** If there is a color and an intensity pano, then show the options in a menu */}
      {showPanoTypeMenu && (
        <FaroMenu
          open={isMenuOpen}
          anchorEl={buttonRef.current}
          dark
          onClose={() => setIsMenuOpen(false)}
          sx={{ ".MuiPaper-root": { minWidth: "fit-content" } }}
        >
          <SingleSelectItem
            value="color"
            label="360° Photo"
            selectedValue={panoType}
            Icon={Img360SmallIcon}
            onClick={() => togglePanoVariant(false)}
            dark
          />
          <SingleSelectItem
            value="intensity"
            label="360° Photo - Intensity"
            selectedValue={panoType}
            Icon={IntensityPanoIcon}
            onClick={() => togglePanoVariant(true)}
            dark
          />
        </FaroMenu>
      )}
    </>
  );
}
