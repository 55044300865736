import { runtimeConfig } from "@/runtime-config";
import { HelpCenterMenu, codeToLanguageCodes } from "@faro-lotv/flat-ui";
import { useLocalizeLanguage } from "@faro-lotv/foreign-observers";

/**
 * @returns the Faro HelpCenterMenu configured for the Sphere Viewer app
 */
export function ViewerHelpCenterMenu(): JSX.Element {
  const language = useLocalizeLanguage();

  const helpCenterLanguage = codeToLanguageCodes(language.code);

  return (
    <HelpCenterMenu
      helpCenterLanguages={helpCenterLanguage}
      sendFeedback={
        runtimeConfig.externalLinks.feedbackForm
          ? () => {
              window.open(runtimeConfig.externalLinks.feedbackForm, "blank");
            }
          : undefined
      }
    />
  );
}
