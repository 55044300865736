import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIsQuickHelpOpen } from "@/store/ui/ui-selectors";
import { setIsQuickHelpOpen } from "@/store/ui/ui-slice";
import { FaroButton, QuestionMarkInCircleIcon } from "@faro-lotv/flat-ui";
import { useCallback } from "react";

/**
 * @returns A button handling the state of quick help drawer
 */
export function QuickHelpButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const isQuickHelpOpen = useAppSelector(selectIsQuickHelpOpen);

  const toggleQuickHelp = useCallback(
    () => dispatch(setIsQuickHelpOpen(!isQuickHelpOpen)),
    [dispatch, isQuickHelpOpen],
  );

  return (
    <FaroButton
      variant="ghost"
      size="xs"
      onClick={toggleQuickHelp}
      sx={{
        color: () => (isQuickHelpOpen ? "primary.main" : "secondary.main"),
      }}
    >
      <QuestionMarkInCircleIcon />
    </FaroButton>
  );
}
