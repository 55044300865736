import { FontWeights } from "@faro-lotv/flat-ui";
import { ToggleButton, ToggleButtonProps } from "@mui/material";
import { forwardRef } from "react";

/** The height of the scene filter toggle. */
const TOGGLE_HEIGHT = "2.25rem";

/** The size of the icons displayed on the toggle. */
const TOGGLE_ICON_SIZE = "18px";

/** Shared style for the icons used in the buttons */
export const TOGGLE_ICON_SX = Object.freeze({
  width: TOGGLE_ICON_SIZE,
  height: TOGGLE_ICON_SIZE,
  lineHeight: 0,
} as const);

/** @returns A styled toggle button */
export const TypeToggleButton = forwardRef<
  HTMLButtonElement,
  ToggleButtonProps
>(function TypeToggleButton({ children, sx, ...rest }, ref): JSX.Element {
  return (
    <ToggleButton
      sx={{
        textTransform: "none",
        fontSize: "0.875em",
        fontWeight: FontWeights.SemiBold,
        whiteSpace: "nowrap",
        border: 0,
        lineHeight: 0,
        "&.MuiButtonBase-root.MuiToggleButton-root": {
          border: "none",
          m: 0,
          ml: 0,
        },
        height: TOGGLE_HEIGHT,
        ...sx,
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </ToggleButton>
  );
});
