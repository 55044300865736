import {
  FaroStatusText,
  FaroText,
  FilterMenuOptionType,
  blue,
  green,
  neutral,
  yellow,
} from "@faro-lotv/flat-ui";
import { AnnotationStatus } from "@faro-lotv/ielement-types";
import { isAnnotationStatus } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ChipFilterWithSearch } from "./chip-filter-with-search";

export type AnnotationStatusFilterProps = {
  /** Callback executed when the selection changes */
  onSelectedStatusChanged(statuses: AnnotationStatus[]): void;
};

/** @returns A chip with a popup that can be used to select a list of users */
export function StatusFilter({
  onSelectedStatusChanged,
}: AnnotationStatusFilterProps): JSX.Element {
  const [selectedStatus, setSelectedStatus] = useState<FilterMenuOptionType[]>(
    [],
  );
  useEffect(() => {
    onSelectedStatusChanged(
      selectedStatus.map((o) => o.key).filter(isAnnotationStatus),
    );
  }, [onSelectedStatusChanged, selectedStatus]);

  const options = useMemo<FilterMenuOptionType[]>(
    () =>
      [
        AnnotationStatus.Unclassified,
        AnnotationStatus.Open,
        AnnotationStatus.InProgress,
        AnnotationStatus.ToReview,
        AnnotationStatus.Resolved,
      ].map((s) => {
        return {
          key: s,
          value: FaroStatusText[s],
          label: (
            <Stack direction="row" gap={0.5} alignItems="center">
              <StatusIcon status={s} />
              <FaroText variant="bodyS">{FaroStatusText[s]}</FaroText>
            </Stack>
          ),
        };
      }),
    [],
  );

  return (
    <ChipFilterWithSearch
      label="Status"
      selectedOptions={selectedStatus}
      setSelectedOptions={setSelectedStatus}
      options={options}
      headingLabel="Select members"
    />
  );
}

type StatusIconProps = {
  /** The status to represent */
  status: AnnotationStatus;
};

type Colors = {
  /** The color of the outer border */
  border: string;
  /** The color of the background of the icon */
  background: string;
  /** The color of the central dot */
  dot: string;
};

// TODO: Consolidate colors with chip-status (https://faro01.atlassian.net/browse/SWEB-5640)
/** The color scheme based on the status */
const STATUS_ICON_COLORS: Record<AnnotationStatus, Colors> = {
  [AnnotationStatus.Open]: {
    border: neutral[300],
    background: neutral[100],
    dot: neutral[700],
  },
  [AnnotationStatus.InProgress]: {
    border: blue[100],
    background: blue[50],
    dot: blue[500],
  },
  [AnnotationStatus.ToReview]: {
    border: yellow[100],
    background: yellow[50],
    dot: yellow[500],
  },
  [AnnotationStatus.Resolved]: {
    border: green[100],
    background: green[50],
    dot: green[700],
  },
  [AnnotationStatus.Unclassified]: {
    border: neutral[300],
    background: neutral[0],
    dot: neutral[500],
  },
};

/** @returns A colored icon to represent a specific status */
function StatusIcon({ status }: StatusIconProps): JSX.Element {
  return (
    <div
      style={{
        width: "24px",
        height: "24px",
        borderRadius: "12px",
        outlineColor: STATUS_ICON_COLORS[status].border,
        outlineWidth: "1px",
        outlineStyle: "solid",
        outlineOffset: -1,
        backgroundColor: STATUS_ICON_COLORS[status].background,
        padding: "8px",
        overflow: "visible",
      }}
    >
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "4px",
          backgroundColor: STATUS_ICON_COLORS[status].dot,
        }}
      />
    </div>
  );
}
