import { FaroText, FolderSmallIcon } from "@faro-lotv/flat-ui";
import { Box, Stack, SxProps, Theme } from "@mui/material";

type InformationBoxProps = {
  /** Optional styling applied to the component */
  sx?: SxProps<Theme>;
};

/**
 * @returns a component with an image and some informative text on the status of the project
 */
export function InformationBox({ sx }: InformationBoxProps): JSX.Element {
  return (
    <Stack
      flexDirection="row"
      sx={sx}
      gap={{ xs: "0.5rem", md: "1.625rem" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack gap={1}>
        <FaroText variant="labelM">
          Get started setting up your project
        </FaroText>
        <FaroText variant="bodyS" color="gray600">
          Create your first sheet and add relevant data. <br />
          You can import a point cloud, or use JobWalk and Stream to upload data
          directly from the field.
        </FaroText>
      </Stack>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "3.75rem",
          height: "3.75rem",
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      >
        <FolderSmallIcon sx={{ fontSize: "1.25rem" }} />
      </Box>
    </Stack>
  );
}
