import { selectReportTimeseriesDataSession } from "@/registration-tools/utils/multi-registration-report";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  GenericRegistrationTask,
  isC2CRegistrationTask,
  isRegisterMultiCloudDataSetTask,
} from "@/utils/background-tasks";
import { redirectToMultiRegistrationTool } from "@/utils/redirects";
import { NoTranslate } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
} from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import {
  BackgroundTaskState,
  isBackgroundTaskActive,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { iconForElement } from "../../icons";
import { CardProgress } from "../card-progress";
import { CardAction, GenericCardLayout } from "../layouts/generic-card-layout";

type RegistrationTaskCardProps = {
  /** The task to show a registration card for */
  task: GenericRegistrationTask;
};

/**
 * @returns A card to track the progress of a registration task.
 */
export function RegistrationTaskCard({
  task,
}: RegistrationTaskCardProps): JSX.Element | null {
  const { projectApiClient } = useApiClientContext();

  const taskElement = useAppSelector(selectIElement(task.iElementId ?? ""));
  const dataSessionSection = useAppSelector((state) =>
    selectAncestor(taskElement, isIElementSectionDataSession)(state),
  );
  const dataSessionTimeSeries = useAppSelector((state) =>
    selectAncestor(taskElement, isIElementTimeseriesDataSession)(state),
  );

  const displayElement = isC2CRegistrationTask(task)
    ? dataSessionSection
    : dataSessionTimeSeries;
  const activeArea = useAppSelector(selectActiveAreaOrThrow(displayElement));

  const icon = displayElement
    ? iconForElement(displayElement.type, displayElement.typeHint)
    : undefined;

  const store = useAppStore();

  // Redirect to the multi cloud view for the registered dataset
  const action: CardAction | undefined =
    isRegisterMultiCloudDataSetTask(task) &&
    task.state === BackgroundTaskState.succeeded
      ? {
          name: "View Results",
          callback: () => {
            const registrationReport = task.result;
            assert(
              registrationReport,
              "Registration report missing or with invalid format",
            );

            const reportDatasession = selectReportTimeseriesDataSession(
              registrationReport,
            )(store.getState());
            assert(
              reportDatasession,
              "No datasession detected for registration report",
            );

            redirectToMultiRegistrationTool({
              projectId: projectApiClient.projectId,
              datasetId: reportDatasession.id,
              registrationTaskId: task.id,
            });
          },
        }
      : undefined;

  // The "4D Sessions" name is hard-coded for these elements in the project tree
  // We follow the same format for consistency
  let cardName: string | JSX.Element = "Registration";
  if (displayElement && isIElementTimeseriesDataSession(displayElement)) {
    cardName = "4D Sessions";
  } else if (displayElement?.name) {
    cardName = <NoTranslate>{displayElement.name}</NoTranslate>;
  }

  return (
    <GenericCardLayout
      name={cardName}
      icon={icon}
      startTime={task.createdAt}
      subText={<NoTranslate>{activeArea.name}</NoTranslate>}
      action={action}
    >
      {isBackgroundTaskActive(task.state) && (
        <CardProgress label="Registering Point Clouds" task={task} />
      )}
    </GenericCardLayout>
  );
}
