import { UploadedFile } from "@/components/common/file-upload-context/use-file-uploader";
import { ErrorHandlers } from "@/errors/components/error-handling-context";
import { Tag } from "@/store/tags/tags-slice";
import { FileDetails } from "@faro-lotv/flat-ui";
import {
  BcfServicesIntegrationType,
  PostTopic,
  TopicCustomField,
} from "@faro-lotv/service-wires";
import { DateTime } from "luxon";

/** The default text to suggest/display as the title of a new external annotation */
export const EXTERNAL_ANNOTATION_DEFAULT_TITLE = "Title";

/** The text to display as tooltip when the user haven't filled all mandatory fields */
export const FILL_MANDATORY_FIELDS_TOOLTIP =
  "The fields name ending with a star (*) are mandatory.";

/** The detailed information about an Annotation */
export type AnnotationProps = {
  /** The annotation title */
  title: string;

  /** The annotation description */
  description?: string;

  /** The user assigned to this Annotation */
  assignee?: string;

  /** The current status of this Annotation */
  status?: string;

  /** The due date for the Annotation */
  dueDate?: Date;

  /** The labels marking the annotation */
  tags?: Tag[];
};

type AnnotationPropsHandlers = {
  /** callback function called when the title has changed */
  onTitleChange?(title: string): void;

  /** callback function called when the value of the assignee has changed */
  onAssigneeChange?(assignee: string): void;

  /** callback function called when the value of the description has changed */
  onDescriptionChange?(description: string): void;

  /** callback function called when the value of the status has changed */
  onStatusChange?(status: string): void;

  /** callback function called when the value of the due date has changed */
  onDueDateChange?(dueDate: Date): void;
};

/** The type of a new attachment for the annotation */
export type NewAttachment = UploadedFile & FileDetails;

/** Defines the props required for the creation of a Sphere XG annotation */
export type SphereXGAnnotationData = AnnotationProps & {
  /** The list of new attachments for this annotation */
  newAttachments: NewAttachment[];
};

/**
 * Defines the fields that are common to all the external annotations. For fields description, see the BCF API documentation at:
 * https://github.com/buildingSMART/BCF-API/?tab=readme-ov-file#322-post-topic-service
 */
export type CommonExternalAnnotationData = Omit<AnnotationProps, "tags"> & {
  /** The external annotation type that should be created */
  externalAnnotationType: BcfServicesIntegrationType;

  /** The priority of a topic */
  priority?: string;

  /** The type of a topic */
  topicType?: string;

  /** Stage this topic is part of */
  stage?: string;
};

type CommonExternalAnnotationDataHandlers = AnnotationPropsHandlers & {
  /** callback function called when the value of the stage has changed */
  onStageChange?(stage: string): void;

  /** callback function called when the value of the priority has changed */
  onPriorityChange?(priority: string): void;

  /** callback function called when the value of the type has changed */
  onTopicTypeChange?(topicType: string): void;
};

export type CommonExternalAnnotationCreationFormProps =
  CommonExternalAnnotationData &
    CommonExternalAnnotationDataHandlers & {
      /** Indicates if some changes to annotation's values should be allowed */
      allowEdition: boolean;

      /** callback function called when an error occurs */
      onError?: ErrorHandlers["handleErrorWithToast"];

      /** function that indicates if all the required fields values have been filled */
      setAreMandatoryFieldsFilled?(valid: boolean): void;
    };

/**
 * Defines the fields contained in the companion fields of Autodesk Construction Cloud (ACC).
 */
export type AccCompanionFields = {
  /** The selected people that should watch the issue */
  selectedWatchers?: Tag[];

  /** The root cause of the issue */
  rootCause?: string;

  /** The start date of the issue */
  startDate?: Date;

  /**
   * The "published" status of the issue. If `false`, the issue will be set as draft
   * and will not be visible to other users until it is published.
   */
  published?: boolean;
};

export type AccComponentFieldsHandlers = {
  /** callback function called when the value of the root cause has changed */
  onRootCauseChange?(rootCause: string): void;

  /** callback function called when the value of the start date has changed */
  onStartDateChange?(startDate: Date): void;

  /** callback function called when the selected watchers have changed */
  onSelectedWatchersChange?(selectedWatchers: Tag[]): void;
};

/**
 * Defines the data required for the creation of an ACC annotation.
 */
export type AccAnnotationData = Omit<CommonExternalAnnotationData, "stage"> & {
  accCompanionFields: AccCompanionFields;
};

/**
 * @returns format the companion fields of an ACC annotation for a PostTopic object
 */
function buildCompanionFields({
  selectedWatchers,
  startDate,
  rootCause,
  published,
}: AccCompanionFields): TopicCustomField[] | undefined {
  const companionFields: TopicCustomField[] = [];
  if (selectedWatchers && selectedWatchers.length > 0) {
    companionFields.push({
      id: "watchers",
      values: selectedWatchers.map((watcher) => watcher.id),
    });
  }
  if (startDate) {
    companionFields.push({
      id: "startDate",
      values: [DateTime.fromJSDate(startDate).toISODate() ?? ""],
    });
  }
  if (rootCause) {
    companionFields.push({
      id: "rootCause",
      values: [rootCause],
    });
  }
  if (published) {
    companionFields.push({
      id: "published",
      values: [published.toString()],
    });
  }
  return companionFields.length > 0 ? companionFields : undefined;
}

/**
 * Defines the data required for the creation of an external annotation
 */
export type ExternalAnnotationData =
  | CommonExternalAnnotationData
  | AccAnnotationData;

/**
 * Type guard function to check if the given data is of type `ExternalAnnotationData`.
 *
 * @param data - The data to check, which can be either `SphereXGAnnotationData` or `ExternalAnnotationData`.
 * @returns A boolean indicating whether the data is of type `ExternalAnnotationData`.
 */
export function isExternalAnnotationData(
  data: SphereXGAnnotationData | ExternalAnnotationData,
): data is ExternalAnnotationData {
  return "externalAnnotationType" in data;
}

/**
 * Type guard function to check if the given data is of type `AccAnnotationData`.
 *
 * @param data - The data to check, which can be either `CommonExternalAnnotationData` or `AccAnnotationData`.
 * @returns A boolean indicating whether the data is of type `AccAnnotationData`.
 */
export function isAccAnnotationData(
  data: ExternalAnnotationData,
): data is AccAnnotationData {
  return "accCompanionFields" in data;
}

/** Defines a type containing data required for the creation of an annotation */
export type AnnotationCreationData =
  | SphereXGAnnotationData
  | ExternalAnnotationData;

type CreatePostTopicData = {
  /** The external annotation data to store in a PostTopic */
  data: ExternalAnnotationData;
  /** The id of the annotation */
  id: string;
};

/**
 *
 * @returns a PostTopic object containing the data needed for the creation of the external annotation
 */
export function createPostTopic({ data, id }: CreatePostTopicData): PostTopic {
  const finalDescription = data.description ?? undefined;

  const commonPostTopicData = {
    title: data.title,
    guid: id,
    priority: data.priority ? { id: data.priority } : undefined,
    due_date: data.dueDate
      ? DateTime.fromJSDate(data.dueDate).toISODate() ?? undefined
      : undefined,
    assigned_to: data.assignee ? { id: data.assignee } : undefined,
    topic_status: data.status ? { id: data.status } : undefined,
    description: finalDescription,
    topic_type: data.topicType ? { id: data.topicType } : undefined,
  };

  if (isAccAnnotationData(data)) {
    return {
      ...commonPostTopicData,
      companion_fields: buildCompanionFields(data.accCompanionFields),
    };
  }

  return {
    ...commonPostTopicData,
    stage: data.stage ? { id: data.stage } : undefined,
  };
}
