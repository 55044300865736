import {
  CreateAnnotationShareLinkEventProperties,
  DeleteAnnotationEventProperties,
  EditAnnotationEventProperties,
  EventType,
  OpenAnnotationDetailsContextMenuEventProperties,
} from "@/analytics/analytics-events";
import { MeasurementValuesField } from "@/components/r3f/renderers/annotations/annotation-renderers/measurement-values-field";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { useDeleteAnnotation } from "@/hooks/use-delete-annotation";
import { useShareAnnotationOrAnalysis } from "@/hooks/use-share-annotation-or-analysis";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroIconButton,
  FaroMenu,
  FaroMenuItem,
  ThreeDotsIcon,
  red,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  AnnotationStatus,
  IElementMarkup,
  isIElementMeasurePolygon,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectExternalSyncedMarkupFor,
} from "@faro-lotv/project-source";
import { GenericUserInfo } from "@faro-lotv/service-wires";
import { Dialog, Divider } from "@mui/material";
import { useRef, useState } from "react";
import { EditAnnotation } from "../../annotations/edit-annotation";

export type CardContextMenuProps = {
  /** The annotation attached to this button */
  markup: IElementMarkup;
  /** The current state of the annotation */
  state: AnnotationStatus;
  /** The user assigned to this annotation */
  mainAssignee: GenericUserInfo | undefined;
  /** The due date of the markup */
  dueDate: string | undefined;
};

/** @returns An icon with a submenu containing operations that can be done on a markup */
export function CardContextMenu({
  markup,
  mainAssignee,
  state,
  dueDate,
}: CardContextMenuProps): JSX.Element {
  const { canWriteAnnotations } = useAnnotationPermissions();
  const externalMarkup = useAppSelector(selectExternalSyncedMarkupFor(markup));

  const canEdit = canWriteAnnotations && !externalMarkup;

  const [isOpen, setIsOpen] = useState(false);

  const button = useRef<HTMLButtonElement>(null);

  const shareAnnotation = useShareAnnotationOrAnalysis(markup.id);

  const { removeAnnotation } = useDeleteAnnotation(markup.id);

  const [isEditing, setIsEditing] = useState(false);

  const measurement = useAppSelector(
    selectAncestor(markup, isIElementMeasurePolygon),
  );

  return (
    <>
      <FaroIconButton
        size="s"
        margin={0}
        ref={button}
        onClick={() => {
          Analytics.track<OpenAnnotationDetailsContextMenuEventProperties>(
            EventType.openAnnotationDetailsContextMenu,
            {
              via: "annotation list",
            },
          );

          setIsOpen(true);
        }}
      >
        <ThreeDotsIcon />
      </FaroIconButton>
      <FaroMenu
        anchorEl={button.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        autoFocus={false}
      >
        <FaroMenuItem
          onClick={() => {
            Analytics.track<CreateAnnotationShareLinkEventProperties>(
              EventType.createAnnotationShareLink,
              {
                via: "annotation list",
              },
            );

            shareAnnotation();
            setIsOpen(false);
          }}
          label="Share"
        />
        {!canEdit && <Divider />}
        {canEdit && (
          <div>
            <FaroMenuItem
              onClick={() => {
                Analytics.track<EditAnnotationEventProperties>(
                  EventType.editAnnotation,
                  {
                    via: "annotation list",
                  },
                );

                setIsEditing(true);
                setIsOpen(false);
              }}
              label="Edit"
            />
            <Divider />
            <FaroMenuItem
              onClick={() => {
                Analytics.track<DeleteAnnotationEventProperties>(
                  EventType.deleteAnnotation,
                  {
                    via: "annotation list",
                  },
                );

                removeAnnotation();
                setIsOpen(false);
              }}
              color={red[300]}
              highlightColor={red[300]}
              label="Delete"
            />
          </div>
        )}
      </FaroMenu>
      {isEditing && (
        <Dialog
          open
          PaperProps={{
            sx: {
              padding: 0,
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "none",
            },
          }}
        >
          <EditAnnotation
            iElement={markup}
            title={markup.name}
            description={markup.descr ?? undefined}
            assignee={mainAssignee?.id}
            status={state}
            dueDate={dueDate ? new Date(dueDate) : undefined}
            onClose={() => setIsEditing(false)}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </EditAnnotation>
        </Dialog>
      )}
    </>
  );
}
