import {
  isSupportedLanguageObject,
  LanguageSelectOption,
} from "@faro-lotv/flat-ui";
import { useLocalize } from "@faro-lotv/foreign-observers";
import { useMemo } from "react";

/** @returns a sub menu to change the current language to use in header bars */
export function LanguageSelector(): JSX.Element {
  const { availableLanguages, setLanguage, currentLanguage } = useLocalize();

  const languages = useMemo(
    () => availableLanguages.filter(isSupportedLanguageObject),
    [availableLanguages],
  );

  return (
    <LanguageSelectOption
      selectedLanguage={currentLanguage.code}
      languages={languages}
      onLanguageChange={(newCode) => {
        setLanguage(newCode);
      }}
    />
  );
}
