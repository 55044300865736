import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { ElementIconType } from "@/components/ui/icons";
import { changeMode } from "@/store/mode-slice";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { setActiveElement } from "@/store/selections-slice";
import { addIElements, selectIElement } from "@faro-lotv/app-component-toolbox";
import { createMutationDeleteVideoModeData } from "@faro-lotv/service-wires";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const DELETE_VIDEO_RECORDING_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.deleteVideoRecording,
  label: "Delete",
  icon: ElementIconType.DeleteIcon,
  handler: async ({
    elementID,
    state,
    apiClients,
    dispatch,
    createDialog,
    errorHandlers,
  }) => {
    const { projectApiClient } = apiClients;
    const videoRecordings = selectIElement(elementID)(state);
    const activeArea = selectActiveAreaOrThrow(videoRecordings)(state);
    if (!videoRecordings) return;

    // Create a delete confirmation dialog
    await createDialog({
      title: "Delete Video Recording?",
      confirmText: "Delete",
      variant: "danger",
      content: (
        <DeleteElementDialogContent
          name={videoRecordings.name}
          extra="This will also delete all images generated from this video recording."
        />
      ),
      onConfirm: async () => {
        try {
          await projectApiClient.applyMutations([
            createMutationDeleteVideoModeData(elementID, true),
          ]);

          // update the entire area, as the mutation also deletes the Img360s
          const updateArea = await projectApiClient.getAllIElements({
            ancestorIds: [activeArea.id],
          });
          dispatch(addIElements(updateArea));

          // reset to to a state that is known to not rely on deleted elements
          dispatch(setActiveElement(activeArea.id));
          dispatch(changeMode("overview"));

          return true;
        } catch (error) {
          errorHandlers.handleErrorWithDialog({
            title: "Failed to delete video recording",
            error,
          });
          return false;
        }
      },
    });
  },
};
