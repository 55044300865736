import { SceneFilter } from "@/types/scene-filter";
import {
  IElement,
  IElementGenericStream,
  IElementImg360,
  IElementModel3dStream,
  isIElementGenericPointCloudStream,
  isIElementGenericStream,
  isIElementImg360,
} from "@faro-lotv/ielement-types";

/** An element that can be rendered as the active element of a walk scene */
export type WalkSceneActiveElement = IElementImg360 | IElementGenericStream;

/**
 * @param element to check
 * @returns true if it's a valid WalkSceneActiveElement
 */
export function isWalkSceneActiveElement(
  element: IElement,
): element is WalkSceneActiveElement {
  return isIElementImg360(element) || isIElementGenericStream(element);
}

/** An element that can be rendered in overlay in walk mode */
export type WalkSceneOverlayElement = IElementModel3dStream;

/** Types that a view can have */
export enum ViewType {
  /** The single view in walk mode */
  MainView = "MainView",
  /** The left view in splitscreen mode */
  LeftView = "LeftView",
  /** The right view in splitscreen mode */
  RightView = "RightView",
}

export function sceneFilterFromElement(
  main: WalkSceneActiveElement,
): SceneFilter;
export function sceneFilterFromElement(
  main: WalkSceneActiveElement | undefined,
): SceneFilter | undefined;
/**
 * @param main element to check
 * @returns the scene filter for that element
 */
export function sceneFilterFromElement(
  main: WalkSceneActiveElement | undefined,
): SceneFilter | undefined {
  if (!main) {
    return;
  }
  if (isIElementImg360(main)) return SceneFilter.Pano;
  if (isIElementGenericPointCloudStream(main)) {
    return SceneFilter.PointCloud;
  }
  return SceneFilter.Cad;
}
