/** A factor used to convert floats to integer with a fixed precision */
const URL_ENCODING_PRECISION_FACTOR = 1000;

/** The radix used to convert numbers to url strings to make them shorter */
const URL_ENCODING_RADIX = 36;

/**
 * @param value to encode
 * @returns a string that encode that value in a url compatible way
 */
export function urlEncodeNumber(value: number): string {
  return Math.round(value * URL_ENCODING_PRECISION_FACTOR).toString(
    URL_ENCODING_RADIX,
  );
}

/**
 * @param value to decode
 * @returns the value that was encoded by the urlEncodeNumber function
 * @see urlEncodeNumber
 */
export function urlDecodeNumber(value: string): number {
  return parseInt(value, URL_ENCODING_RADIX) / URL_ENCODING_PRECISION_FACTOR;
}
