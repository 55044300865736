import { Banner } from "@faro-lotv/flat-ui";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { useState } from "react";

interface PointCloudsProcessingBannerProps {
  /** The scanEntities to check for point clouds in processing */
  scanEntities: RevisionScanEntity[];

  /** The point cloud streams for the scan entities. undefined if they are still loading. */
  pointCloudStreams?: IElementGenericPointCloudStream[];
}

/**
 * @returns a banner that shows a warning when point clouds are still processing
 */
export function PointCloudsProcessingBanner({
  scanEntities,
  pointCloudStreams,
}: PointCloudsProcessingBannerProps): JSX.Element | undefined {
  const [isDismissed, setIsDismissed] = useState(false);

  if (
    isDismissed ||
    // Don't show a banner, while the point cloud iElements are loading
    !pointCloudStreams ||
    // If point clouds are processing, their point cloud streams are not available yet
    pointCloudStreams.length === scanEntities.length
  ) {
    return;
  }

  return (
    <Banner
      variant="warning"
      title="Data sets in processing"
      onClose={() => setIsDismissed(true)}
    >
      Some data sets are being processed. They are not visible in this view and
      will not be included in the merged point cloud.
    </Banner>
  );
}
