import { UserSubscriptionRole } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SubscriptionsState = {
  /** List of all the enabled features */
  subscriptions?: UserSubscriptionRole[];
};

const initialState: Readonly<SubscriptionsState> = Object.freeze({
  subscriptions: undefined,
});

const subscriptionsSlice = createSlice({
  initialState,
  name: "subscriptions",
  reducers: {
    initSubscriptions(state, action: PayloadAction<UserSubscriptionRole[]>) {
      state.subscriptions = action.payload;
    },
  },
});

export const { initSubscriptions } = subscriptionsSlice.actions;

export const subscriptionsReducer = subscriptionsSlice.reducer;
