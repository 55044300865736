import { generateGUID } from "@faro-lotv/foundation";
import { ILabel } from "@faro-lotv/ielement-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Tag = Pick<ILabel, "id" | "name">;

/** The custom tag used for the "untagged" value in the filter menu */
export const UNTAGGED: Tag = {
  id: generateGUID(),
  name: "Untagged",
};

export type TagsState = {
  /** The list of tags in the project */
  tags: Tag[];

  /** The list of tags selected by the user */
  selectedTags: Tag[];
};

export const initialState: Readonly<TagsState> = Object.freeze({
  tags: [UNTAGGED],
  selectedTags: [],
});

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    /**
     * Add new tags to the store
     *
     * @param state The current state
     * @param action The list of new tags to add
     */
    addNewTags(state, action: PayloadAction<Tag[]>) {
      for (const tag of action.payload) {
        if (!state.tags.find((t) => t.id === tag.id)) {
          state.tags.push(tag);
        }
      }
    },
    /**
     * Set which tags the user selected
     *
     * @param state The current state
     * @param action The list of tags to set as selected
     */
    setSelectedTags(state, action: PayloadAction<Tag[]>) {
      state.selectedTags = [...action.payload];
    },
  },
});

export const tagsReducer = tagsSlice.reducer;

export const { addNewTags, setSelectedTags } = tagsSlice.actions;
