import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { Mode, ModeSceneProps, ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { selectIsPanoWithDepth } from "@/modes/walk-mode/walk-scene-selectors";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import {
  selectBestCompareElement,
  selectWalkTypeDefaultElement,
  setCompareElementId,
  setCompareSceneFilter,
  setWalkSceneFilter,
} from "@/store/modes/walk-mode-slice";
import { selectActiveElement } from "@/store/selections-selectors";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import {
  useDeactivateToolOnUnmount,
  useToggleToolVisibility,
} from "@/tools/use-toggle-tool-visibility";
import { SceneFilter } from "@/types/scene-filter";
import { assert } from "@faro-lotv/foundation";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import { useIsPanoWithDepthAndReady } from "../walk-mode/walk-mode-hooks";
import { SplitOverlay } from "./split-overlay";
import { SplitScene } from "./split-scene";
import {
  SPLIT_MODE_INITIAL_STATE,
  SplitInitialState,
  useSplitState,
} from "./split-state";

/**
 * Scene, Overlay and Transition for the Split Screen comparison mode
 */
export const splitMode: Mode<SplitInitialState> = {
  name: "split",

  initialState: SPLIT_MODE_INITIAL_STATE,

  ModeScene({ initialState }: ModeSceneProps<SplitInitialState>) {
    const splitState = useSplitState();

    useSplitModeToolsVisibility(
      splitState.mainScene.main,
      splitState.compareScene.main,
      splitState.mainSceneFilter,
      splitState.compareSceneFilter,
    );

    useDeactivateToolOnUnmount();

    return <SplitScene initialState={initialState} {...splitState} />;
  },

  ModeOverlay() {
    const splitState = useSplitState();

    return <SplitOverlay {...splitState} />;
  },

  ModeTransition({
    onCompleted,
    initialState,
  }: ModeTransitionProps<SplitInitialState>) {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const { main } = useCurrentScene();
    assert(main, "A main rendering element is required for split screen");

    const camera = useThree((s) => s.camera);
    /** Store the initial camera position to use it during the computations in the hook */
    const [initialCameraPosition] = useState(camera.position);

    // Compute initial split state
    useLayoutEffect(() => {
      const state = store.getState();
      const activeElement = selectActiveElement(state);
      if (!activeElement) {
        throw new Error(
          "Unable to start split mode without a valid element to render",
        );
      }
      const mainElement = selectWalkTypeDefaultElement(activeElement)(state);
      if (!mainElement) {
        throw new Error(
          "Unable to start split mode without a valid element to render",
        );
      }

      const compareElement = selectBestCompareElement(
        mainElement,
        initialCameraPosition,
      )(state);
      if (!compareElement) {
        throw new Error(
          "Unable to start split mode without a valid compare element",
        );
      }
      dispatch(setActiveElement(mainElement.id));
      if (initialState) {
        dispatch(setCompareElementId(initialState.rightId));
        dispatch(setCompareSceneFilter(initialState.rightScene));
        if (initialState.scene) {
          dispatch(setWalkSceneFilter(initialState.scene));
        }
      } else {
        dispatch(setCompareElementId(compareElement.id));
      }
      onCompleted();
    }, [initialCameraPosition, dispatch, onCompleted, store, initialState]);

    return <SnapshotRenderer />;
  },

  canBeStartedWith(activeElement, state) {
    const mainElement = selectWalkTypeDefaultElement(activeElement)(state);
    if (!mainElement) {
      return false;
    }
    return selectBestCompareElement(mainElement)(state) !== undefined;
  },
};

/**
 * Compute what tools should be visible and what not for the split mode
 *
 * @param mainActiveElement the main model iElement
 * @param compareActiveElement the comparison model iElement
 * @param mainSceneFilter the scene filter for the left view
 * @param compareSceneFilter the scene filter for the right view
 */
function useSplitModeToolsVisibility(
  mainActiveElement?: WalkSceneActiveElement,
  compareActiveElement?: WalkSceneActiveElement,
  mainSceneFilter?: SceneFilter,
  compareSceneFilter?: SceneFilter,
): void {
  const isMainPanoWithDepth = useAppSelector(
    selectIsPanoWithDepth(mainActiveElement),
  );
  const isComparePanoWithDepth = useAppSelector(
    selectIsPanoWithDepth(compareActiveElement),
  );

  const { canWriteAnnotations } = useAnnotationPermissions();

  const isMainScenePanoWithDepth =
    mainSceneFilter === SceneFilter.Pano && isMainPanoWithDepth;
  const isCompareScenePanowithDepth =
    compareSceneFilter === SceneFilter.Pano && isComparePanoWithDepth;

  const hasActivePanoWithDepth =
    isMainScenePanoWithDepth || isCompareScenePanowithDepth;

  const hasActivePc =
    mainSceneFilter === SceneFilter.PointCloud ||
    compareSceneFilter === SceneFilter.PointCloud;

  const canMeasure = hasActivePanoWithDepth || hasActivePc;

  const { isDepthReady: isMainDepthReady } =
    useIsPanoWithDepthAndReady(mainActiveElement);
  const { isDepthReady: isCompareDepthReady } =
    useIsPanoWithDepthAndReady(compareActiveElement);

  useToggleToolVisibility(
    ToolName.measurement,
    canMeasure,
    true,
    (isMainScenePanoWithDepth && !isMainDepthReady) ||
      (isCompareScenePanowithDepth && !isCompareDepthReady),
  );
  useToggleToolVisibility(
    ToolName.annotation,
    canWriteAnnotations,
    true,
    false,
  );
}
