import { ObjectsOpacity } from "@/components/common/transparency-sliders/transparency-settings-context";
import { ComposeFramebuffersPass as LotvComposeFramebuffersPass } from "@faro-lotv/lotv";
import { RefObject } from "react";

/**
 * A small object that support CAD/cloud fade-in animations.
 */
export class AnimationDuration {
  /** Current point in time of the point cloud fade-in animation, zero if no animation is running */
  cloud = ANIMATION_DURATION;
  /** Current point in time of the Cad fade-in animation, zero if no animation is running */
  cad = ANIMATION_DURATION;
}

/** Duration of opacity animation, in seconds */
const ANIMATION_DURATION = 0.7;

function updateCloudOpacity(
  animationDuration: AnimationDuration,
  composer: LotvComposeFramebuffersPass,
  delta: number,
  s2m: ObjectsOpacity,
): void {
  if (animationDuration.cloud < ANIMATION_DURATION) {
    animationDuration.cloud += delta;
    const opacity = Math.min(1.0, animationDuration.cloud / ANIMATION_DURATION);
    composer.opacity2 = s2m.pcOpacity * opacity;
  } else {
    composer.opacity2 = s2m.pcOpacity;
  }
}

function updateCadOpacity(
  animationDuration: AnimationDuration,
  composer: LotvComposeFramebuffersPass,
  delta: number,
  s2m: ObjectsOpacity,
): void {
  if (animationDuration.cad < ANIMATION_DURATION) {
    animationDuration.cad += delta;
    const opacity = Math.min(1.0, animationDuration.cad / ANIMATION_DURATION);
    composer.opacity3 = s2m.cadOpacity * opacity;
  } else {
    composer.opacity3 = s2m.cadOpacity;
  }
}

export type UpdateOpacityProps = {
  /** An object storing for how many second is the model opacity being animated */
  animationDuration: AnimationDuration;
  /** A reference to the ComposeFramebuffersPass object */
  composerRef: RefObject<LotvComposeFramebuffersPass>;
  /** Duration since the last frame, in seconds */
  delta: number;
  /** Transparency settings object */
  opacity: ObjectsOpacity;
};

/**
 * Updates sheet/model opacities to the rendering pipeline in overview mode.
 *
 * This function is meant to be called at each frame, as it updates the floorplan/CAD/cloud opacity values
 * directly into the FBO composing shader. These values may have changed because the user moved the
 * input slider, and also because an animation is transitioning the models' opacity from zero to its
 * slider value.
 */
export function updateOpacityOverlay({
  animationDuration,
  composerRef,
  delta,
  opacity,
}: UpdateOpacityProps): void {
  if (!composerRef.current) return;
  composerRef.current.opacity1 = opacity.mapOpacity;

  updateCloudOpacity(animationDuration, composerRef.current, delta, opacity);
  updateCadOpacity(animationDuration, composerRef.current, delta, opacity);
}

/**
 * Updates to GPU only the opacity value of the slider for the point cloud.
 */
export function updateOpacityCloudOnly({
  animationDuration,
  composerRef,
  delta,
  opacity,
}: UpdateOpacityProps): void {
  if (!composerRef.current) return;
  composerRef.current.opacity1 = opacity.mapOpacity;

  updateCloudOpacity(animationDuration, composerRef.current, delta, opacity);
}

/**
 * Updates to GPU only the value of the CAD slider. Note that the slider value is sent
 * to the 'opacity2' prop of the ComposeFramebuffersPass, and not to 'opacity3' as when
 * also the cloud subscene is enabled. In fact, when this function is called, the second subscene
 * (the point cloud) is disable and therefore the CAD subscene counts as second.
 */
export function updateOpacityCadOnly({
  animationDuration,
  composerRef,
  delta,
  opacity,
}: UpdateOpacityProps): void {
  if (!composerRef.current) return;
  composerRef.current.opacity1 = opacity.mapOpacity;

  if (animationDuration.cad < ANIMATION_DURATION) {
    animationDuration.cad += delta;
    const target = Math.min(1.0, animationDuration.cad / ANIMATION_DURATION);
    composerRef.current.opacity2 = opacity.cadOpacity * target;
  } else {
    composerRef.current.opacity2 = opacity.cadOpacity;
  }
}
