import {
  AddAnnotationAttachmentsEventProperties,
  DeleteAnnotationAttachmentEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import {
  AnnotationFormVariant,
  FileAttachment,
  FileDetails,
  getFileExtension,
  useAnnotationAttachments as useAnnotationAttachmentsFUI,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useCallback } from "react";

/**
 * @returns the list of attachments and a function to add a new ones to the list, by taking care of all Analytics events
 * @param fileAttachments IElements list of the annotation's attachments
 * @param allowDeletion if true the deletion of attachments in the list will be allowed
 * @param formVariant type of the annotation's form
 */
export function useAnnotationAttachments(
  fileAttachments: FileAttachment[],
  allowDeletion: boolean,
  formVariant: AnnotationFormVariant,
): {
  attachments: FileDetails[];
  addNewAttachment(attachment: FileDetails, viaDrag: boolean): void;
} {
  const onDeleteAttachment = useCallback(
    (fileType: string, isNewAttachment: boolean) =>
      Analytics.track<DeleteAnnotationAttachmentEventProperties>(
        EventType.deleteAnnotationAttachment,
        {
          fileType,
          isNewAttachment,
        },
      ),
    [],
  );

  const onConfirmAttachmentDeletion = useCallback(
    () => Analytics.track(EventType.confirmAnnotationDeletion),
    [],
  );

  const onCancelAttachmentDeletion = useCallback(
    () => Analytics.track(EventType.cancelAnnotationDeletion),
    [],
  );

  const { attachments, addNewAttachment } = useAnnotationAttachmentsFUI(
    fileAttachments,
    allowDeletion,
    formVariant,
    onDeleteAttachment,
    onConfirmAttachmentDeletion,
    onCancelAttachmentDeletion,
  );

  const onNewAttachmentAdded = useCallback(
    (attachment: FileDetails, viaDrag: boolean) => {
      Analytics.track<AddAnnotationAttachmentsEventProperties>(
        EventType.addAnnotationAttachments,
        {
          viaDrag,
          fileType: getFileExtension(attachment.fileName) ?? "unknown",
        },
      );
      addNewAttachment(attachment);
    },
    [addNewAttachment],
  );

  return { attachments, addNewAttachment: onNewAttachmentAdded };
}
