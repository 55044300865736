import { CadModelData, CadModelTreeNodeData } from "./cad-model-tree-data";

/**
 * Check the visiblity of one node in the CAD model tree.
 *
 * @param node - CAD model tree node data
 * @returns true if the node is visible, false otherwise
 */
export function isNodeVisible(node: CadModelTreeNodeData): boolean {
  // `CadModelData` may be null when CAD model is not loaded yet,
  // in this case the node is not visible.
  return node.cadModelData ? node.visibility : false;
}

/**
 * Highlight a node in the CAD model tree.
 *
 * @param node - CAD model tree node data
 * @param cadObjects - Output CAD objectIds, newly highlighted object ids will be appended to this array
 */
export function highlightModelTreeNode(
  node: CadModelTreeNodeData,
  cadObjects: number[],
): void {
  const { cadModelData } = node;
  if (!cadModelData) return;
  forEachChildNode(node, (n) => {
    const id = n.cadObjectId;
    cadObjects.push(id);
    cadModelData.cadModel.setObjectHighlighted(id, true);
    cadModelData.cadModel.setObjectVisible(id, true);
  });
}

/**
 * Unhighlight CAD objects.
 *
 * @param cadModelData - CAD model object
 * @param highlighted - highlighted CAD object ids
 */
export function unhighlightCadObjects(
  cadModelData: CadModelData | null,
  highlighted: number[],
): void {
  if (!cadModelData) return;

  for (const objectId of highlighted) {
    cadModelData.cadModel.setObjectHighlighted(objectId, false);
    // restore the visibility of the object before highlighted
    const visible = cadModelData.visibilities.get(objectId);
    if (visible !== undefined) {
      cadModelData.cadModel.setObjectVisible(objectId, visible);
    }
  }
  // need to restore selected objects to highlighted
  for (const objectId of cadModelData.selectedObjects) {
    cadModelData.cadModel.setObjectHighlighted(objectId, true);
  }
}

/**
 * Unselect all the current selected objects
 *
 * @param cadModelData - CAD model data
 */
export function unselectAllNodes(cadModelData: CadModelData | null): void {
  if (!cadModelData) return;

  // remove previous selected objects
  for (const objectId of cadModelData.selectedObjects) {
    cadModelData.cadModel.setObjectHighlighted(objectId, false);
  }

  cadModelData.selectedObjects.length = 0;
}

/**
 * Set CAD mesh associated with a CAD model tree node to selected state.
 *
 * @param node - CAD model tree node data
 */
export function selectModelTreeNode(node: CadModelTreeNodeData): void {
  const { cadModelData } = node;
  if (!cadModelData) return;

  unselectAllNodes(cadModelData);

  forEachChildNode(node, (n) => {
    const id = n.cadObjectId;
    cadModelData.cadModel.setObjectHighlighted(id, true);
    cadModelData.selectedObjects.push(id);
  });
}

/**
 * Set the visibility of one node in the CAD model tree.
 *
 * @param node - CAD model tree node to change
 * @param visibility - visibility to set
 */
export function setNodeVisibility(
  node: CadModelTreeNodeData,
  visibility: boolean,
): void {
  const { cadModelData } = node;
  if (!cadModelData) return;

  forEachChildNode(node, (n) => {
    n.visibility = visibility;
    const id = n.cadObjectId;
    cadModelData.cadModel.setObjectVisible(id, visibility);
    if (cadModelData.visibilities.has(id)) {
      cadModelData.visibilities.set(id, visibility);
    }
  });
}

// Execute a callback function for a node and all its children.
function forEachChildNode(
  node: CadModelTreeNodeData,
  callback: (n: CadModelTreeNodeData) => void,
): void {
  const stack = [];
  stack.push(node);
  while (stack.length > 0) {
    const curNode = stack.pop();
    if (curNode) {
      callback(curNode);
      if (curNode.children) stack.push(...curNode.children);
    }
  }
}
