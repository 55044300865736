/** A single enum to define the render orders of several objects that appear in sheet mode and in the minimap. */
export enum SheetModeRenderOrders {
  /** Render order for the start and end waypoints of an odometry path */
  StartEndWaypoints = 1,
  /** Render order for the intermediate waypoints of an odometry path */
  PathWaypoints = 2,
  /** Render order for map waypoints that do not belong to an odometry path*/
  Waypoints = 3,
  /** Render order for measurements and annotations, which are rendered without depth testing. */
  MeasurementsAndAnnotations = 4,
  /** Render order for the minimap user marker, that should always be on top of the placeholders. */
  MinimapUserMarker = 5,
}
