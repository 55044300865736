import { useAppSelector } from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import {
  FaroText,
  FilterMenuOptionType,
  NO_TRANSLATE_CLASS,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { useEffect, useMemo, useState } from "react";
import { ChipFilterWithSearch } from "./chip-filter-with-search";

export type AnnotationTagFilterProps = {
  /** Callback executed when the selection changes */
  onSelectedTagsChanged(ids: GUID[]): void;
};

/** @returns A chip with a popup that can be used to select a list of tags */
export function TagFilter({
  onSelectedTagsChanged,
}: AnnotationTagFilterProps): JSX.Element {
  const tags = useAppSelector(selectTags);

  const options = useMemo<FilterMenuOptionType[]>(
    () =>
      tags.map((t) => ({
        key: t.id,
        value: t.name,
        label: (
          <FaroText variant="bodyS" className={NO_TRANSLATE_CLASS}>
            {t.name}
          </FaroText>
        ),
      })),
    [tags],
  );

  const [selectedTags, setSelectedTags] = useState<FilterMenuOptionType[]>([]);
  useEffect(() => {
    onSelectedTagsChanged(selectedTags.map((t) => t.key));
  }, [onSelectedTagsChanged, selectedTags]);

  return (
    <ChipFilterWithSearch
      label="Tags"
      selectedOptions={selectedTags}
      setSelectedOptions={setSelectedTags}
      options={options}
      headingLabel="Select tags"
    />
  );
}
