import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { FileUploadTask } from "./background-tasks";

export interface CombinedUploadProgress {
  /** total progress of all uploads [0,100]. undefined if no task is currently uploading */
  progress?: number;

  /** The expected end of all uploads (ms epoch). undefined if end cannot be estimated */
  expectedEnd?: number;
}

/**
 * @returns the combined progress for a set of upload tasks
 * @param tasks all upload tasks in the current operation, undefined if no task is available or they're all done
 */
export function combinedUploadProgress(
  tasks?: FileUploadTask[],
): CombinedUploadProgress | undefined {
  if (!tasks) return;

  const allDone = tasks.every((task) => !isBackgroundTaskActive(task.state));
  if (allDone) return;

  const progress = tasks.reduce<number | undefined>((prev, task) => {
    return (prev ?? 0) + task.progress / tasks.length;
  }, undefined);

  const expectedEnd = tasks.reduce<number | undefined>((prev, task) => {
    return (task.expectedEnd ?? 0) > (prev ?? 0) ? task.expectedEnd : prev;
  }, undefined);

  return {
    progress,
    expectedEnd,
  };
}
