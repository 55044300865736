import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { DataPreparationStepKey } from "../data-preparation-stepper";
import { selectRevisionEntities } from "../store/revision-selectors";
import { ScanTree } from "./scan-tree/scan-tree";

type DataPreparationSidebarProps = {
  /** currently active step in the data-preparation page */
  activeStepKey: DataPreparationStepKey;
};

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar({
  activeStepKey,
}: DataPreparationSidebarProps): JSX.Element {
  return (
    <ScanTreeSidebar
      title={
        activeStepKey === DataPreparationStepKey.registration
          ? "Uploaded data"
          : "Registered scans"
      }
      description={
        activeStepKey === DataPreparationStepKey.registration
          ? "Register uploaded scans."
          : "Publish the registration result as a merged project point to your SphereXG project."
      }
    />
  );
}

type ScanTreeSidebarProps = {
  /** The title of the current step. */
  title: ReactNode;

  /** The description of the current step. */
  description: ReactNode;
};

/** @returns a sidebar with containing a ScanTree with customizable title and action buttons */
function ScanTreeSidebar({
  title,
  description,
}: ScanTreeSidebarProps): JSX.Element {
  const entities = useAppSelector(selectRevisionEntities);

  return (
    <HorizontalResizeContainer
      initialWidth={320}
      minWidth={200}
      maxWidth={500}
      handleSide="right"
    >
      <Stack
        justifyContent="space-between"
        flexShrink={0}
        sx={{
          width: "100%",
          height: "100%",
          px: 1.5,
          py: 3,
        }}
      >
        <Stack gap={2} height="100%">
          <Stack gap={1.5}>
            <FaroText variant="heading16">{title}</FaroText>
            <FaroText variant="bodyM">{description}</FaroText>
          </Stack>

          <ScanTree entities={entities} />
        </Stack>
      </Stack>
    </HorizontalResizeContainer>
  );
}
