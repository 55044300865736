import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { PropsWithChildren } from "react";
import { HBEnsureProjectAccess } from "./hb-ensure-project-access";

interface Props {
  /** The project to ensure access for */
  projectId: string;
}

/**
 * Ensures that the current user (may be anonymous) has access to the specified project
 *
 * @returns the children if the user has access to the project or a login form otherwise.
 */
export function EnsureProjectAccess({
  projectId,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <HBEnsureProjectAccess projectId={projectId}>
      {children}
    </HBEnsureProjectAccess>
  );
}

interface EnsureWriteAccessProps {
  // The fallback to render when the user does not have write access to the current project
  fallback: JSX.Element;
}

/**
 * @returns the children if the user has write access on the current project or the fallback otherwise
 */
export function EnsureWriteAccess({
  fallback,
  children,
}: PropsWithChildren<EnsureWriteAccessProps>): JSX.Element {
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hasWritePermission ? children : fallback}</>;
}
