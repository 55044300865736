import { CameraParameters } from "@/components/r3f/utils/camera-parameters";
import { ExportModeTab } from "@/modes/export-mode/export-mode-types";
import { ModeNames } from "@/modes/mode";
import { BoxGizmoSide } from "@faro-lotv/lotv";
import { Vector3Tuple } from "three";
import { RootState } from "../store";

/**
 * @returns the mode that the app should return to after an export
 */
export function selectReturnMode({ exportMode }: RootState): ModeNames {
  return exportMode.returnMode ?? "overview";
}

/**
 * @returns the camera to restore after the export if available
 */
export function selectReturnCamera({
  exportMode,
}: RootState): CameraParameters | undefined {
  return exportMode.returnCamera;
}

/**
 * @returns the initial camera target to use for the export workflow
 */
export function selectInitialExportTarget({
  exportMode,
}: RootState): Vector3Tuple | undefined {
  return exportMode.initialTarget;
}

/** @returns the selected export mode tab */
export function selectSelectedExportModeTab({
  exportMode,
}: RootState): ExportModeTab {
  return exportMode.selectedTab;
}

/** @returns the selected export mode tab */
export function selectSelectedOrthophotoSide({
  exportMode,
}: RootState): BoxGizmoSide {
  return exportMode.selectedOrthophotoSide;
}
