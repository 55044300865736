import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useAppDispatch } from "@/store/store-hooks";
import {
  IElementGenericPointCloudStream,
  IElementSection,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { fetchProjectIElements } from "@faro-lotv/project-source";
import { useEffect, useState } from "react";

/**
 * Hook that fetches the required extra project data for the alignment workflow
 *
 * @param alignArea  current area from which we want to fetch the sub tree
 * @param cloudToAlign cloud used to validation the fetched sub tree
 * @returns true while the fetching is in progress
 */
export function useAlignmentToolSubTree(
  alignArea: IElementSection | undefined,
  cloudToAlign: IElementGenericPointCloudStream | undefined,
): boolean {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const projectApi = useCurrentProjectApiClient();

  useEffect(() => {
    if (!alignArea || !cloudToAlign) {
      setIsLoading(false);
      return;
    }

    const ac = new AbortController();
    dispatch(
      fetchProjectIElements({
        fetcher: () =>
          projectApi.getAllIElements({
            signal: ac.signal,
            ancestorIds: [alignArea.id],
            types: [IElementType.timeSeries],
            typeHints: [IElementTypeHint.dataSession],
          }),
      }),
    ).finally(() => {
      setIsLoading(false);
    });

    return () => {
      ac.abort();
    };
  }, [alignArea, cloudToAlign, dispatch, projectApi]);

  return isLoading;
}
