import { selectShouldAlignmentWarnOnWindowClose } from "@/alignment-tool/store/alignment-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { useBeforeUnload } from "react-router-dom";

/**
 * The logic to prevent the browser tab from closing if there are any blocking background tasks.
 */
export function useHandleWindowClose(): void {
  const shouldAlignmentWarnOnWindowClose = useAppSelector(
    selectShouldAlignmentWarnOnWindowClose,
  );

  useBeforeUnload((ev) => {
    if (shouldAlignmentWarnOnWindowClose) {
      ev.preventDefault();
      // All browsers prevent custom messages, so we just have to pass an empty string here to get the dialog to appear.
      ev.returnValue = "";
    }
  });
}
