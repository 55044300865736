import { SheetModeRenderOrders } from "@/modes/sheet-mode/sheet-mode-render-orders";
import {
  isMapPlaceholdersUpdated,
  MapWaypointsRenderer,
  MayWaypointsRendererRef,
  PrecisePoints,
  SinglePointGeometry,
  useMapPlaceholderTextures,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import { isAncestor, PlaceholdersTexture } from "@faro-lotv/lotv";
import { useCursor } from "@react-three/drei";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Group, Intersection, Points, Raycaster, Vector3 } from "three";
import EndPointSvg from "./VM_EndPoint.svg?url";
import StartPointSvg from "./VM_StartPoint.svg?url";
import PointSvg from "./VM_WaypointDefault.svg?url";
import HoverSvg from "./VM_WaypointHover.svg?url";
import LockedSvg from "./VM_WaypointLocked.svg?url";
import { useSubSamplePlaceholders } from "./use-subsample-placeholders";

/** Default pixel size of the path waypoints */
const PATH_WAYPOINTS_BASE_SIZE = 8;

const LOCKED_WAYPOINT_SIZE = 16;
const LOCKED_HOVERED_WAYPOINT_SIZE = 24;

const START_END_POINT_SIZE = 20;
const START_END_POINT_HOVERED_SIZE = 30;

/** The multiplication factor for the size of an hovered placeholder */
const PLACEHOLDER_HOVER_FACTOR = 2.0;

const LOCKED_WAYPOINTS_LABEL = "Locked waypoints";

const POI_HI_RES_LABEL = "POI high res label";
const DEFAULT_HI_RES_POI_SIZE = 20;
const HOVERED_HI_RES_POI_SIZE = 30;

interface CommonProps {
  /** Which placeholder should currently be hovered */
  hoveredId?: number;

  /** Callback executed when a placeholder is clicked */
  onPlaceholderClick?(id: number): void;
}

interface OdometryPathPlaceholdersProps extends CommonProps {
  /** The set of placeholders to render */
  positions: Vector3[];

  /** Whether to show all placeholders, instead of just the start and end ones.*/
  showAll: boolean;

  /** List of indices within the 'position' array that represent points of interest */
  poiIndices: number[];

  /** A list containing for each placeholder a flag specifying if it should be considered "locked" */
  locked: boolean[];

  /** Callback executed when the hovered placeholder changes */
  onHoveredPlaceholderChanged(id: number | undefined): void;
}

/**
 * @param intersection the intersected object
 * @returns the placeholder index of the intersected object
 */
export function computeRaycastedIndex(
  intersection?: Intersection,
): number | undefined {
  // We explicitly defined the object name of the first and last placeholders
  // to be their index
  if (intersection?.object.name) {
    return Number.parseInt(intersection.object.name, 10);
  }
  // If we reach here we hit one of the other placeholders, the proper index is the
  // point index in the buffer + 1 for the starting point
  if (intersection?.index !== undefined) {
    return intersection.index + 1;
  }
}

/**
 * @param raycaster to cast a ray on the placeholders
 * @param group that contains the OdometryPathPlaceholders
 * @returns the index of the hit placeholder or undefined
 */
export function raycastOnPlaceholdersGroup(
  raycaster: Raycaster,
  group: Group,
): number | undefined {
  const points: Points[] = [];
  group.traverse((o) => {
    if (o instanceof Points) {
      points.push(o);
    }
  });
  const hit = raycaster.intersectObjects(points)[0];
  return computeRaycastedIndex(hit);
}

/**
 * @returns the placeholders for an odometry path
 */
export function OdometryPathPlaceholders({
  positions,
  showAll,
  poiIndices,
  locked,
  hoveredId,
  onHoveredPlaceholderChanged,
  onPlaceholderClick,
}: OdometryPathPlaceholdersProps): JSX.Element | null {
  const [start, end, rest] = useMemo(() => {
    return [positions.at(0), positions.at(-1), positions.slice(1, -1)];
  }, [positions]);

  const restLocked = useMemo(() => locked.slice(1, -1), [locked]);

  const restPois = useMemo(() => {
    if (poiIndices.length === 0) {
      return [];
    }
    const ret = [...poiIndices];
    if (poiIndices[0] === 0) {
      ret.shift();
    }
    if (poiIndices[poiIndices.length - 1] === positions.length - 1) {
      ret.pop();
    }
    for (let i = 0; i < ret.length; i++) {
      ret[i]--;
    }
    return ret;
  }, [positions, poiIndices]);

  const groupRef = useRef<Group>(null);

  const updateHovered = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      ev.stopPropagation();
      const point = ev.intersections.find(
        (i) =>
          i.object instanceof Points &&
          groupRef.current &&
          isAncestor(i.object, groupRef.current),
      );
      onHoveredPlaceholderChanged(computeRaycastedIndex(point));
    },
    [onHoveredPlaceholderChanged],
  );

  if (!start || !end) {
    return null;
  }

  return (
    <group
      ref={groupRef}
      position-y={0.1}
      onPointerEnter={updateHovered}
      onPointerMove={updateHovered}
      onPointerLeave={updateHovered}
    >
      {rest.length > 0 && showAll && (
        <OtherPlaceholders
          positions={rest}
          references={[start, end]}
          locked={restLocked}
          poiIndices={restPois}
          hoveredId={hoveredId}
          onPlaceholderClick={onPlaceholderClick}
        />
      )}

      <StartEndPlaceholders
        position={start}
        index={0}
        hoveredId={hoveredId}
        onPlaceholderClick={onPlaceholderClick}
      />

      <StartEndPlaceholders
        position={end}
        index={positions.length - 1}
        hoveredId={hoveredId}
        onPlaceholderClick={onPlaceholderClick}
      />
    </group>
  );
}

interface StartEndPlaceholdersProps extends CommonProps {
  /** The position of the placeholder */
  position: Vector3;

  /** The index of this placeholder in the list */
  index: number;
}

/**
 * @returns the placeholders to be used at the start or end of a path
 */
function StartEndPlaceholders({
  position,
  index,
  hoveredId,
  onPlaceholderClick,
}: StartEndPlaceholdersProps): JSX.Element | null {
  const texture = useSvg(index === 0 ? StartPointSvg : EndPointSvg, 256, 256);

  const onClick = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      if (onPlaceholderClick) {
        ev.stopPropagation();
        onPlaceholderClick(index);
      }
    },
    [onPlaceholderClick, index],
  );

  const isHovered = hoveredId === index;

  useCursor(isHovered);

  return (
    <PrecisePoints
      position={position}
      material-size={
        isHovered ? START_END_POINT_HOVERED_SIZE : START_END_POINT_SIZE
      }
      onClick={onClick}
      material-map={texture}
      material-transparent
      material-sizeAttenuation={false}
      material-depthTest={false}
      material-depthWrite
      name={`${index}`}
      renderOrder={SheetModeRenderOrders.StartEndWaypoints}
    >
      <SinglePointGeometry />
    </PrecisePoints>
  );
}

interface OtherPlaceholdersProps extends CommonProps {
  /** The set of placeholders to render */
  positions: Vector3[];

  /** The indices of the POIs within the positions array */
  poiIndices: number[];

  /** Additional placeholders that are rendered in the path and should be accounted for in the hiding logic. */
  references: Vector3[];

  /** The flags specifying which placeholders are locked */
  locked: boolean[];
}

/**
 * @returns placeholders to be used in the middle of a path. Automatically hides cluttering waypoints.
 */
function OtherPlaceholders({
  positions,
  poiIndices,
  references,
  locked,
  onPlaceholderClick,
  hoveredId,
}: OtherPlaceholdersProps): JSX.Element | null {
  const defaultTexture = useSvg(PointSvg, 256, 256);
  const hoveredTexture = useSvg(HoverSvg, 256, 256);
  const lockedTexture = useSvg(LockedSvg, 256, 256);

  const alwaysVisible = useMemo(() => {
    const ret = [...locked];
    for (const poiIndex of poiIndices) {
      ret[poiIndex] = true;
    }
    return ret;
  }, [poiIndices, locked]);

  const { groupRef, computeHiddenPlaceholders } = useSubSamplePlaceholders(
    references,
    PATH_WAYPOINTS_BASE_SIZE,
    alwaysVisible,
  );

  const localHovered = useMemo(() => {
    if (hoveredId !== undefined && hoveredId - 1 < positions.length) {
      return hoveredId - 1;
    }
  }, [hoveredId, positions.length]);

  const clickOnPlaceholder = useCallback(
    (index: number | undefined) => {
      if (!onPlaceholderClick || index === undefined) return;
      onPlaceholderClick(index + 1);
    },
    [onPlaceholderClick],
  );

  const lockedPlaceholders = useMemo(() => {
    const v = [];
    for (let i = 0; i < locked.length; ++i) {
      if (locked[i]) {
        v.push(i);
      }
    }
    return v;
  }, [locked]);

  const [mapPlaceholders, setMapPlaceholders] =
    useState<MayWaypointsRendererRef | null>();

  const gl = useThree((s) => s.gl);

  // Assigning special sizes and textures to the locked waypoints
  useEffect(() => {
    if (!isMapPlaceholdersUpdated(mapPlaceholders, positions)) return;
    mapPlaceholders.setLabeledPlacehoders(
      LOCKED_WAYPOINTS_LABEL,
      lockedPlaceholders,
    );
    mapPlaceholders.setLabeledMap(
      LOCKED_WAYPOINTS_LABEL,
      PlaceholdersTexture.Default,
      lockedTexture,
    );
    mapPlaceholders.setLabeledMap(
      LOCKED_WAYPOINTS_LABEL,
      PlaceholdersTexture.Hovered,
      lockedTexture,
    );
    mapPlaceholders.setLabeledMap(
      LOCKED_WAYPOINTS_LABEL,
      PlaceholdersTexture.Selected,
      lockedTexture,
    );
    const dpr = gl.getPixelRatio();
    mapPlaceholders.setLabelSizes(LOCKED_WAYPOINTS_LABEL, {
      default: LOCKED_WAYPOINT_SIZE * dpr,
      hovered: LOCKED_HOVERED_WAYPOINT_SIZE * dpr,
      selected: LOCKED_HOVERED_WAYPOINT_SIZE * dpr,
    });
  }, [mapPlaceholders, lockedPlaceholders, gl, lockedTexture, positions]);

  const defaultTextures = useMapPlaceholderTextures(512);

  // Assigning special sizes and textures to the POI waypoints
  useEffect(() => {
    if (!isMapPlaceholdersUpdated(mapPlaceholders, positions)) return;
    mapPlaceholders.setLabeledPlacehoders(POI_HI_RES_LABEL, poiIndices);
    mapPlaceholders.setLabeledMap(
      POI_HI_RES_LABEL,
      PlaceholdersTexture.Default,
      defaultTextures.defaultTexture,
    );
    mapPlaceholders.setLabeledMap(
      POI_HI_RES_LABEL,
      PlaceholdersTexture.Hovered,
      defaultTextures.hoveredTexture,
    );
    mapPlaceholders.setLabeledMap(
      POI_HI_RES_LABEL,
      PlaceholdersTexture.Selected,
      defaultTextures.selectedTexture,
    );
    const dpr = gl.getPixelRatio();
    mapPlaceholders.setLabelSizes(POI_HI_RES_LABEL, {
      default: DEFAULT_HI_RES_POI_SIZE * dpr,
      hovered: HOVERED_HI_RES_POI_SIZE * dpr,
      selected: HOVERED_HI_RES_POI_SIZE * dpr,
    });
  }, [mapPlaceholders, poiIndices, gl, defaultTextures, positions]);

  return (
    <group ref={groupRef}>
      <MapWaypointsRenderer
        waypoints={positions}
        baseSize={PATH_WAYPOINTS_BASE_SIZE}
        hoveredSizeFactor={PLACEHOLDER_HOVER_FACTOR}
        hoveredId={localHovered}
        onPlaceholderClick={clickOnPlaceholder}
        computeHidden={computeHiddenPlaceholders}
        textures={{
          defaultTexture,
          hoveredTexture,
        }}
        renderOrder={SheetModeRenderOrders.PathWaypoints}
        ref={setMapPlaceholders}
      />
    </group>
  );
}
