import {
  LayoutSheetSmallIcon,
  NoTranslate,
  PointCloudIcon,
  dataComparisonColorsSorted,
} from "@faro-lotv/flat-ui";
import Stack from "@mui/material/Stack";
import { IconText } from "../common/icon-text";

/** Info displayed in the alignment bar of the manual alignment tool */
export type AlignmentBarInfoProps = {
  /** Name of the current sheet  */
  areaName?: string;
  /** Names of the pointclouds to be displayed in the bar */
  pointCloudNames?: string[];
  /** Labels of the pointclouds to be displayed in front of the names */
  pointCloudLabels?: string[];
};

/**
 * @returns Styled info about the selected project and the point clouds
 */
export function PairwiseRegistrationBarInfo({
  areaName,
  pointCloudNames,
  pointCloudLabels,
}: AlignmentBarInfoProps): JSX.Element {
  return (
    <Stack direction="row" alignItems="center" justifyContent="left" gap={2}>
      <IconText
        variant="bodyS"
        // Will change this once we switch from sheet to area in all user-facing strings
        label="Sheet"
        text={<NoTranslate sx={{ display: "inline" }}>{areaName}</NoTranslate>}
        icon={<LayoutSheetSmallIcon />}
      />
      {pointCloudNames?.map((pointCloudName, index) => {
        return (
          <IconText
            variant="bodyS"
            label={pointCloudLabels?.at(index)}
            text={
              <NoTranslate sx={{ display: "inline" }}>
                {pointCloudName}
              </NoTranslate>
            }
            icon={
              <PointCloudIcon
                sx={{
                  color:
                    dataComparisonColorsSorted[index % pointCloudNames.length],
                }}
              />
            }
            key={pointCloudName}
          />
        );
      })}
    </Stack>
  );
}
