import { assert, includes } from "@faro-lotv/foundation";

/** List of all deployment environments */
export const ENVIRONMENTS = ["prod", "staging", "dev"] as const;
/** List of all deployment regions */
export const REGIONS = ["eu", "com"] as const;

/** A type with all the possible deployment environments */
export type Env = (typeof ENVIRONMENTS)[number];
/** A type with all the possible deployment regions */
export type Region = (typeof REGIONS)[number];

/**
 * @param envString A string in the format env:region to parse
 * @returns the environment and region defined in the envString
 * @throws if the envString is not a valid env:region string
 */
export function getEnvAndRegionFromString(envString: string): [Env, Region] {
  const [env, region] = envString.split(":");
  assert(
    includes(ENVIRONMENTS, env),
    `Failed to extract environment from the custom env string ${envString}`,
  );
  assert(
    includes(REGIONS, region),
    `Failed to extract the region from the custom env string ${envString}`,
  );
  return [env, region];
}

/**
 * @param origin from where the app is been served (Eg: https://viewer.dev.holobuilder.com)
 * @returns the env, region from where the app is served parsed from the origin
 * @throws if the env and region can't be inferred by the origin url
 */
export function getEnvAndRegionFromOrigin(origin: string): [Env, Region] {
  const matches = origin.match(
    /.*viewer\.?(staging|dev)?\.holobuilder\.(eu|com)/,
  );
  assert(
    matches,
    `Failed to compute the environment/region from the app origin ${origin}`,
  );
  const env = matches.at(1) ?? "prod";
  const region = matches.at(2);
  assert(
    includes(ENVIRONMENTS, env),
    `Found invalid environment ${env} from the app origin ${origin}`,
  );
  assert(
    includes(REGIONS, region),
    `Found invalid region ${region} from the app origin ${origin}`,
  );
  return [env, region];
}

interface DevDeploymentType {
  /** True when the app is currently running on localhost. */
  isLocalhost: boolean;

  /**
   * True when the app is currently deployed on a dev environment for testing.
   * e.g. https://dev1.viewer.dev.holobuilder.com/ or https://dev3.viewer.staging.holobuilder.eu/
   */
  isDeployedDevEnvironment: boolean;
}

/**
 * @returns the type of dev deployment based on the hostname
 * @param hostname the current hostname
 */
export function getDevDeploymentTypeFromHostname(
  hostname: string,
): DevDeploymentType {
  const isLocalhost = hostname === "localhost";

  const isDeployedDevEnvironment =
    /.*dev[0-9]*\.viewer\.?(staging|dev)?\.holobuilder\.(eu|com)/.test(
      hostname,
    );

  return {
    isLocalhost,
    isDeployedDevEnvironment,
  };
}
