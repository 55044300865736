/**
 * NDC (Normalized Device Coordinates) zOffset values to use for the various parts of a measurement line
 * so they appear in front of the data and ordered properly with the shadow behind
 * then the line, then the handlers on top
 */
export enum MeasureDepthOffsets {
  lineShadow = -0.01,
  line = -0.011,
  handler = -0.012,
}

/** Length of the measure collapse/expand animation in seconds */
export const MEASURE_ANIMATION_LENGTH = 0.2;

/** Line opacity of an inactive measure */
export const INACTIVE_MEASURES_OPACITY = 0.6;

/** Pixel size of the measurement handlers while the measurement tool is inactive */
export const SMALL_HANDLER_SIZE = 4;

/** Pixel size of the measurement handlers while the measurement tool is active */
export const BIG_HANDLER_SIZE = 8;
