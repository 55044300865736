import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectChildDepthFirst } from "@faro-lotv/app-component-toolbox";
import {
  Alert,
  DatePicker,
  Dropdown,
  Option as DropdownOption,
  FaroDialog,
  FaroText,
  NO_TRANSLATE_CLASS,
  TextField,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  IElementGenericDataset,
  IElementType,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementPointCloudLaz,
  isIElementTimeseries,
  isIElementTimeseriesDataSession,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  State,
  selectAllIElementsOfType,
  selectAncestor,
} from "@faro-lotv/project-source";
import {
  RegistrationStartedJobResponse,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { CircularProgress, Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLoadIElements } from "./use-load-ielements";

type MergeAndPublishDialogProps = {
  /** The datasets containing the point clouds which should be merged. */
  pointCloudDatasets: IElementGenericDataset[];

  /** Whether the dialog is shown to the user. */
  isOpen: boolean;

  /** This action is executed before merge & publish job has been started.  */
  onBeforePublish?(): void | Promise<void>;

  /** The action to execute after the merge & publish job has been started.  */
  onPublish(response: RegistrationStartedJobResponse): void | Promise<void>;

  /** The action to execute when the user closes the dialog without publishing. */
  onClose(): void;
};

/** @returns Dialog which allows the user to publish multiple point clouds into a merged dataset. */
export function MergeAndPublishDialog({
  pointCloudDatasets,
  isOpen,
  onClose,
  onBeforePublish,
  onPublish,
}: MergeAndPublishDialogProps): JSX.Element {
  const { registrationApiClient } = useApiClientContext();
  assert(
    registrationApiClient,
    "Registration API client must be available to merge point clouds.",
  );
  const [pointCloudName, setPointCloudName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [creationDate, setCreationDate] = useState(new Date());

  const isLoadingAreas = useLoadMergeAndPublishData();

  const supportedDataSets = useAppSelector(
    selectDataSetsSupportedForMerge(pointCloudDatasets),
    isEqual,
  );
  const notSupportedDataSets = useMemo(() => {
    return pointCloudDatasets.filter(
      (dataSet) => !supportedDataSets.some((item) => item.id === dataSet.id),
    );
  }, [pointCloudDatasets, supportedDataSets]);

  const hasDatasetsToMerge = supportedDataSets.length > 1;
  const hasExcludedDatasets = notSupportedDataSets.length > 0;

  const mergeParentOptions = useAppSelector(
    selectOutputTimeseriesOptions(),
    isEqual,
  );

  const defaultOutputTimeSeries = useAppSelector(
    selectAncestor(supportedDataSets[0], isIElementTimeseriesDataSession),
    isEqual,
  );
  const [outputTimeSeriesId, setOutputTimeSeriesId] = useState(
    defaultOutputTimeSeries?.id,
  );

  const isPointCloudNameInvalid = useMemo(
    () => !pointCloudName.trim().length,
    [pointCloudName],
  );

  const { handleErrorWithDialog } = useErrorHandlers();

  // Start the backend job to merge & publish, then execute the onPublish callback
  const onConfirm = useCallback(async () => {
    if (!outputTimeSeriesId) return;

    setIsLoading(true);
    try {
      if (onBeforePublish) {
        await onBeforePublish();
      }

      const response = await registrationApiClient.startPointCloudMerge({
        outputTimeSeriesId,
        outputDate: creationDate,
        outputSectionName: pointCloudName.trim(),
        pointCloudIds: supportedDataSets.map((dataset) => dataset.id),
      });

      await onPublish(response);
    } catch (error) {
      handleErrorWithDialog({
        title: "Could not publish registration result. Please try again.",
        error,
      });
    }

    setIsLoading(false);
  }, [
    onBeforePublish,
    registrationApiClient,
    outputTimeSeriesId,
    creationDate,
    pointCloudName,
    supportedDataSets,
    onPublish,
    handleErrorWithDialog,
  ]);

  return (
    <FaroDialog
      title="Publish registered point cloud"
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      showSpinner={isLoading}
      confirmText="Publish"
      isConfirmDisabled={
        isPointCloudNameInvalid ||
        !hasDatasetsToMerge ||
        isLoading ||
        !outputTimeSeriesId
      }
    >
      <Stack gap={1}>
        {hasExcludedDatasets && (
          <Alert
            key="not-supported-warning"
            variant={hasDatasetsToMerge ? "warning" : "error"}
            title={getMergeErrorTitle(supportedDataSets.length)}
          >
            <FaroText variant="bodyM">
              <p>Currently, only the following data types can be published:</p>
              <p>
                <i>FARO Orbis Scans, LAZ Point Clouds</i>
              </p>
              <p>
                The following datasets are not supported
                {hasDatasetsToMerge && " and will be excluded"}:
              </p>
              <ul className={NO_TRANSLATE_CLASS}>
                {notSupportedDataSets.map((dataset) => (
                  <li key={dataset.id}>{dataset.name}</li>
                ))}
              </ul>
            </FaroText>
          </Alert>
        )}

        <FaroText variant="bodyM">
          By publishing the result, your registration results will be saved and
          the point clouds will be merged into one single point cloud. The
          original point clouds will remain in the project. Please specify a
          name for the new point cloud.
        </FaroText>

        <TextField
          label="Point cloud name"
          text={pointCloudName}
          onTextChanged={setPointCloudName}
          error={isPointCloudNameInvalid ? "Name must not be empty" : undefined}
        />

        <DatePicker
          label="Time Point"
          date={creationDate}
          formSx={{ width: "fit-content" }}
          onChange={(value) => {
            if (value) {
              setCreationDate(value);
            }
          }}
        />

        <Dropdown
          label={
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <span>Associated sheet</span>
              {isLoadingAreas && <CircularProgress size={9} />}
            </Stack>
          }
          options={mergeParentOptions}
          value={outputTimeSeriesId}
          onChange={(ev) => setOutputTimeSeriesId(ev.target.value)}
        />
      </Stack>
    </FaroDialog>
  );
}

/**
 * For the Dialog we want to differentiate between different error messages and warnings,
 * in case of only one pointcloud being up for merging we instead want to disable the button
 * and display a custom warning to prevent the worker from returning an error as it can not
 * merge a single pointcloud
 *
 * @param supportedDatasetLength the length of all the supported pointcloud datasets
 * @returns a string title for the dialogue
 */
function getMergeErrorTitle(supportedDatasetLength: number): string {
  const hasDatasetsToMerge = supportedDatasetLength > 1;
  const hasOnlyOneMerge = supportedDatasetLength === 1;
  if (!hasDatasetsToMerge) {
    return hasOnlyOneMerge
      ? "One or more datasets on this sheet can not be published, publishing requires at least two datasets of compatible type"
      : "All datasets on this sheet can not be published.";
  }
  return "One or more datasets on this sheet can not be published.";
}

/**
 * For a given list of datasets, this selector returns a subset of datasets that are supported for merging.
 * Currently we only support GeoSlamDatasets and PointClouds that were uploaded in LAZ format.
 *
 * @param dataSets a list of datasets that should be filtered
 * @returns a subset of dataSets which are supported for merging
 */
function selectDataSetsSupportedForMerge(dataSets: IElementGenericDataset[]) {
  return (state: State): IElementGenericDataset[] => {
    return dataSets.filter((dataSet) => {
      return (
        isIElementWithTypeAndHint(
          dataSet,
          IElementType.section,
          IElementTypeHint.dataSetGeoSlam,
        ) ||
        (isIElementWithTypeAndHint(
          dataSet,
          IElementType.section,
          IElementTypeHint.dataSetPCloudUpload,
        ) &&
          selectChildDepthFirst(dataSet, isIElementPointCloudLaz, 1)(state) !==
            undefined)
      );
    });
  };
}

/** @returns The options for the user to select the sheet where the merged point cloud will be uploaded */
function selectOutputTimeseriesOptions() {
  return (state: State): DropdownOption[] => {
    const allAreas = selectAllIElementsOfType(isIElementAreaSection)(state);

    const empty: DropdownOption[] = [];
    const options = allAreas.reduce((list, area) => {
      const timeSeries = selectChildDepthFirst(
        area,
        isIElementTimeseries,
        1,
      )(state);
      if (timeSeries !== undefined) {
        list.push({
          key: timeSeries.id,
          value: timeSeries.id,
          label: area.name,
        });
      }
      return list;
    }, empty);

    return options;
  };
}

/**
 * Use the data needed for the merge & publish dialog.
 *
 * @returns Whether the data is still being loaded.
 */
function useLoadMergeAndPublishData(): boolean {
  const isLoadingAreas = useLoadIElements([
    { type: IElementType.section, typeHints: [IElementTypeHint.area] },
  ]);
  const areas = useAppSelector(
    selectAllIElementsOfType(isIElementAreaSection),
    isEqual,
  );
  const isLoadingTimeSeries = useLoadIElements(
    isLoadingAreas
      ? []
      : [
          {
            type: IElementType.timeSeries,
            typeHints: [IElementTypeHint.dataSession],
            ancestorIds: areas.map((area) => area.id),
          },
        ],
  );

  return isLoadingAreas || isLoadingTimeSeries;
}
