import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { CursorStyle } from "@faro-lotv/flat-ui";
import { TypedEvent } from "@faro-lotv/foundation";
import { Intersection, MOUSE, Matrix4, Object3D, Vector3 } from "three";

/**
 * An enum to label which object is being clicked/dragged by the pointer
 */
enum MouseDownObject {
  /** mouse hover */
  mouseUp = "MouseUp",
  /** mouse down on Pin */
  pin = "Pin",
  /** mouse down on other than pin */
  outsidePin = "OutsidePin",
}

/** The cloud-to-cloud interaction mode */
export enum C2CMode {
  /** state when pin is not available*/
  default = "Default",
  /** pin available, mouse hovering elements but nothing happening, map2d controls on */
  passive = "Passive",
  /** pin available, mouse being dragged, map2d controls off */
  active = "Active",
  /** Point cloud being translated by pointer drag */
  translation = "Translation",
  /** Point cloud being rotated by pointer drag */
  rotation = "Rotation",
}

/**
 * Maps a pointer interaction state to the suitable mouse cursor.
 *
 * @param mdo The object being clicked by the pointer
 * @param pinHit Whether the pointer is hovering the pin
 * @returns The style the mouse cursor should have
 */
function mapStateToCursor(mdo: MouseDownObject, pinHit: boolean): CursorStyle {
  if (mdo === MouseDownObject.outsidePin) {
    return CursorStyle.rotation;
  } else if (mdo === MouseDownObject.pin || pinHit) {
    return CursorStyle.translation;
  }
  return CursorStyle.rotation;
}

/**
 * A class containing variables used only to compute the cloud rotation around the pin
 */
class CloudRotation {
  /** Axis around which the cloud is rotating */
  rotAxis = new Vector3(0, 1, 0);
  /** Normalized direction from pin position to pointer down point */
  X = new Vector3();
  /** Normalized direction to form an orthonormal basis with X and rotAxis direction */
  Y = new Vector3();
  /** First translation matrix of the chained transform */
  T1 = new Matrix4();
  /** Second pure rotation matrix of the chained transform */
  R = new Matrix4();
  /** Third translation matrix of the chained transform */
  T2 = new Matrix4();
  /** Direction from the pin position to the current pointer position */
  mouseVec = new Vector3();

  /**
   * Removes the component of v that is along the rotation axis,
   * guaranteeing that v is perpendicular to the rotation axis.
   *
   * @param v The input vector
   * @returns The same vector without its component along the rotation axis
   */
  removeVerticalComponent(v: Vector3): Vector3 {
    const vcomp = v.dot(this.rotAxis);
    v.sub(this.rotAxis.clone().multiplyScalar(vcomp));
    return v;
  }
}

/** Private variables used to translate the point cloud on pointer drag */
class CloudTranslation {
  /** Delta translation of the pointer between drag start and drag current position */
  deltaTrasl = new Vector3();
  /** Same translation as before in matricial form */
  T = new Matrix4();
}

/** For touch interaction. */
export const NO_MOUSE_BUTTON = -1;

/**
 * Implements the logic for the PointCloudManipulator frontend to connect to
 */
export class PointCloudManipulatorLogic {
  /** Result of raycast pointer->pin */
  pinIntersects: Array<Intersection<Object3D>> = [];
  /** Result of raycast pointer->moving point cloud */
  ptCloudIntersects: Array<Intersection<Object3D>> = [];
  /** The current camera view: top, front or side */
  #perspective = Perspective.topView;

  /** Signal emitted whenever the mouse cursor should change appereance */
  cursorToShowChanged = new TypedEvent<CursorStyle>();
  /** */
  controlsActiveChanged = new TypedEvent<boolean>();
  /** Signal emitted when the interaction mode changes */
  modeChanged = new TypedEvent<C2CMode>();
  /** Signal emitted when the pin position changes */
  pinPosChanged = new TypedEvent<Vector3>();
  /** Signal emitted when the cloud pose changes */
  cloudPoseChanged = new TypedEvent<Matrix4>();
  /**
   * Signal emitted when the user finished an action to change the point cloud pose.
   * E.g. the user finished dragging the point cloud to change its position.
   *
   * This event is emitted less often than cloudPoseChanged.
   */
  cloudPoseChangeEnded = new TypedEvent<Matrix4>();

  /** The object being clicked/dragged by the mouse: none, the pin, or the point cloud */
  #mouseDownObject = MouseDownObject.mouseUp;
  /** The current style of the cursor */
  #currentCursorStyleObject = CursorStyle.default;
  /** Whether the pointer is being dragged */
  #dragged = false;
  /** Whether the pointer is being pressed */
  #down = false;
  /** Track if the user is performing a long press to distinguish it from normal clicks. */
  #isLongPress = false;
  /** Interaction mode */
  #mode = C2CMode.default;

  /** Vector from the pin position to the point that is being dragged by the pointer */
  #pinOffset = new Vector3();
  /** Pin position in 3D coordinates while the pointer is dragging*/
  #pinPos = new Vector3();
  /** Pin position in 3D coordinates before last pointer drag */
  #initialPinPos = new Vector3();
  /** Moving cloud pose before last pointer down */
  #pointCloudMatrix = new Matrix4();
  /** Moving cloud pose while the pointer is translating/rotating the cloud */
  #currentCloudMatrix = new Matrix4();
  /** Variables used to rotate the point cloud around the pin on pointer drag */
  #cloudRotation = new CloudRotation();
  /** Variables used to translate the point cloud on pointer drag */
  #cloudTranslation = new CloudTranslation();

  /**
   * Creates a new point cloud manipulator logic
   * initializing it with the moving point cloud pose.
   *
   * @param T The point cloud world pose
   */
  constructor(T: Matrix4) {
    this.updateTransform(T);
  }

  /**
   * Updates the transform for the model point cloud
   *
   * @param T point cloud matric of the model point cloud
   */
  updateTransform(T: Matrix4): void {
    this.#pointCloudMatrix.copy(T);
    this.#currentCloudMatrix.copy(T);
    this.cloudPoseChanged.emit(this.#currentCloudMatrix);
  }

  /**
   * @param mouseDownPos Position at which the pointer was pressed, in 3D coordinates
   */
  #initCloudRotation(mouseDownPos: Vector3): void {
    this.#currentCloudMatrix.copy(this.#pointCloudMatrix);
    this.#cloudRotation.X.subVectors(mouseDownPos, this.#pinPos);
    this.#cloudRotation.removeVerticalComponent(this.#cloudRotation.X);
    this.#cloudRotation.X.normalize();
    this.#cloudRotation.Y.crossVectors(
      this.#cloudRotation.rotAxis,
      this.#cloudRotation.X,
    );
    this.#cloudRotation.Y.normalize();
    this.#cloudRotation.T1.makeTranslation(
      -this.#pinPos.x,
      -this.#pinPos.y,
      -this.#pinPos.z,
    );
    this.#cloudRotation.T2.makeTranslation(
      this.#pinPos.x,
      this.#pinPos.y,
      this.#pinPos.z,
    );
  }

  /**
   * Initialized translation of the pin and the cloud
   *
   * @param pointerPos Current pointer position in 3D space
   */
  #initTranslation(pointerPos: Vector3): void {
    this.#currentCloudMatrix.copy(this.#pointCloudMatrix);
    if (this.#mode === C2CMode.default) {
      // if we enter here the pin is not visible
      // so it should appear on the clicked point and the offset should be zero
      this.#pinPos.copy(pointerPos);
      this.#pinOffset.set(0, 0, 0);
    } else {
      // if we enter here the pin is already visible and the pin offset
      // is the vector from the pin position to the point dragged on the pin.
      this.#pinOffset.subVectors(pointerPos, this.#pinPos);
    }
    this.#initialPinPos.copy(this.#pinPos);
  }

  /**
   * preset state on mouse/touch down events to be able to switch to correct mode in move
   * and have correct initial pointer hit
   *
   * @param pointerPos Current pointer position in 3D coordinates
   * @param button [button = -1] clicked mouse button(optional parameter in case of a touch event)
   */
  handlePointerDown(pointerPos: Vector3, button: number): void {
    // Find out if the user is dragging
    this.#dragged = false;
    this.#down = true;

    // left click should either initiate a translation or rotation
    if (button === MOUSE.LEFT || button === NO_MOUSE_BUTTON) {
      if (this.pinIntersects.length) {
        this.#mouseDownObject = MouseDownObject.pin;
        this.#initTranslation(pointerPos);
      } else {
        this.#mouseDownObject = MouseDownObject.outsidePin;
        this.#initCloudRotation(pointerPos);
      }
    }
    this.#emitOnCursorChange();

    // disable the controls with left mouse button click
    if (button === MOUSE.LEFT || button === NO_MOUSE_BUTTON) {
      // when pin is active and we are manipulating the cloud
      if (this.#mode === C2CMode.passive) {
        this.#mode = C2CMode.active;
        this.modeChanged.emit(C2CMode.active);
      }
      this.controlsActiveChanged.emit(false);
    }
  }

  /**
   * on mouse move we need to change to the appropriate mode depending on the mouse down object
   * only when the mouse drags the object and the pin is active we need to manipulate.
   *
   * @param currentMousePos The current pointer position
   * @param pinOffset Pointer offset inside the pin image
   */
  #translatePin(currentMousePos: Vector3, pinOffset: Vector3): void {
    this.#pinPos.subVectors(currentMousePos, pinOffset);
    this.pinPosChanged.emit(this.#pinPos.clone());
  }

  /**
   * Translates the point cloud
   */
  #translatePointCloud(): void {
    this.#cloudTranslation.deltaTrasl.subVectors(
      this.#pinPos,
      this.#initialPinPos,
    );
    this.#currentCloudMatrix.copy(this.#pointCloudMatrix);
    const t = this.#cloudTranslation.deltaTrasl;
    this.#cloudTranslation.T.makeTranslation(t.x, t.y, t.z);
    this.#currentCloudMatrix.premultiply(this.#cloudTranslation.T);
    this.cloudPoseChanged.emit(this.#currentCloudMatrix);
  }

  /**
   * Rotates the point cloud on mouse drag, around the pin position
   *
   * @param newMousePos The new mouse position
   */
  #rotatePointCloud(newMousePos: Vector3): void {
    this.#cloudRotation.mouseVec.subVectors(newMousePos, this.#pinPos);
    this.#cloudRotation.removeVerticalComponent(this.#cloudRotation.mouseVec);
    const yCoord = this.#cloudRotation.Y.dot(this.#cloudRotation.mouseVec);
    const xCoord = this.#cloudRotation.X.dot(this.#cloudRotation.mouseVec);
    const rotAngle = Math.atan2(yCoord, xCoord);
    this.#cloudRotation.R.makeRotationAxis(
      this.#cloudRotation.rotAxis,
      rotAngle,
    );
    this.#currentCloudMatrix.copy(this.#pointCloudMatrix);
    this.#currentCloudMatrix.premultiply(this.#cloudRotation.T1);
    this.#currentCloudMatrix.premultiply(this.#cloudRotation.R);
    this.#currentCloudMatrix.premultiply(this.#cloudRotation.T2);
    this.cloudPoseChanged.emit(this.#currentCloudMatrix);
  }

  /**
   * On mouse move the correct mode is selected and mouse cursor updated
   *
   * @param pointerPos current pointer position in 3D coordinates
   */
  handlePointerMove(pointerPos: Vector3): void {
    if (this.#down) {
      this.#dragged = true;
    }
    if (this.#mode === C2CMode.rotation) {
      this.#rotatePointCloud(pointerPos);
    } else if (this.#mode === C2CMode.translation) {
      this.#translatePin(pointerPos, this.#pinOffset);
      this.#translatePointCloud();
    } else if (this.#dragged && this.#mode === C2CMode.active) {
      if (this.#mouseDownObject === MouseDownObject.pin) {
        this.#mode = C2CMode.translation;
        this.modeChanged.emit(C2CMode.translation);
      } else if (this.#mouseDownObject === MouseDownObject.outsidePin) {
        this.#mode = C2CMode.rotation;
        this.modeChanged.emit(C2CMode.rotation);
      }
    }
    this.#emitOnCursorChange();
  }

  /**
   * We need to return to the default state and reset our state and conditionally place the pin
   * based on the mouse or touch events
   *
   * @param button  [button = -1] clicked mouse button(optional parameter in case of a touch event)
   */
  handlePointerUp(button: number): void {
    this.#pointCloudMatrix.copy(this.#currentCloudMatrix);
    this.#mouseDownObject = MouseDownObject.mouseUp;
    this.#down = false;
    this.controlsActiveChanged.emit(true);
    const oldMode = this.#mode;
    this.#mode =
      this.#mode === C2CMode.default ? C2CMode.default : C2CMode.passive;
    this.modeChanged.emit(this.#mode);
    this.#emitOnCursorChange();

    if (this.#dragged) {
      if (oldMode !== C2CMode.passive && oldMode !== C2CMode.default) {
        // The user finished manipulating the point cloud via drag
        // Emit another change event that is less frequent, e.g. for persistence
        this.cloudPoseChangeEnded.emit(this.#currentCloudMatrix);
      }
      this.#isLongPress = false;
      return;
    }

    // when the pin is not available and when user does a left click on the point cloud
    // then create the pin at the clicked position
    if (
      this.ptCloudIntersects.length &&
      (button === MOUSE.LEFT || button === NO_MOUSE_BUTTON) &&
      this.#mode === C2CMode.default &&
      !this.#isLongPress
    ) {
      this.#mode = C2CMode.passive;
      this.modeChanged.emit(this.#mode);
      // This is needed in case of pointer click
      this.#changePinPos(this.ptCloudIntersects[0].point);
    }

    // Right click on the pin should remove the pin
    if (this.pinIntersects.length && button === MOUSE.RIGHT) {
      this.#mode = C2CMode.default;
      this.modeChanged.emit(this.#mode);
    }

    this.#isLongPress = false;
  }

  /** When the user presses on the pin for a long time, remove it. */
  handleLongPress(): void {
    this.#isLongPress = true;

    if (this.pinIntersects.length) {
      this.#mode = C2CMode.default;
      this.modeChanged.emit(this.#mode);
    }
  }

  /**
   * event cursorToShowChanged should emit only when there is a change in cursor style
   */
  #emitOnCursorChange(): void {
    // When in default mode, the cursor style is always default
    if (this.#mode === C2CMode.default) return;

    const cursorToShow = mapStateToCursor(
      this.#mouseDownObject,
      this.pinIntersects.length >= 1,
    );

    if (this.#currentCursorStyleObject !== cursorToShow) {
      this.cursorToShowChanged.emit(cursorToShow);
      this.#currentCursorStyleObject = cursorToShow;
    }
  }

  /**
   * @param newPos New pin coordinates
   */
  #changePinPos(newPos: Vector3): void {
    this.#pinPos.copy(newPos);
    this.pinPosChanged.emit(this.#pinPos.clone());
  }

  /** @returns the current camera view: top, front or side */
  get perspective(): Perspective {
    return this.#perspective;
  }

  /** Sets the current camera view: top, front or side */
  set perspective(p: Perspective) {
    if (this.#perspective !== p) {
      this.#perspective = p;
      switch (this.#perspective) {
        case Perspective.topView:
          this.#cloudRotation.rotAxis.set(0, 1, 0);
          break;
        case Perspective.frontView:
          this.#cloudRotation.rotAxis.set(0, 0, 1);
          break;
        case Perspective.sideView:
          this.#cloudRotation.rotAxis.set(1, 0, 0);
          break;
      }
    }
  }
}
