import { Vector3 } from "three";

/**
 * Compare two Vector3 array for equality
 *
 * @param a first array
 * @param b second array
 * @returns true if they're the same
 */
export function areVector3ArraysEqual(a: Vector3[], b: Vector3[]): boolean {
  if (a.length !== b.length) return false;
  for (let index = 0; index < b.length; index++) {
    if (!a[index].equals(b[index])) return false;
  }
  return true;
}
