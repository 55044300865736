import { BackgroundTask } from "@/utils/background-tasks";
import { TypedEvent } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  CoreApiClient,
  CoreFileUploader,
  FinalizerCallback,
} from "@faro-lotv/service-wires";
import { uniqueId } from "lodash";
import { UploadElementType } from "../point-cloud-file-upload-context/use-upload-element";

/**
 * Callback type reporting that the upload has completed successfully
 *
 * @param id is the ID the upload task has in the store.
 * @param downloadUrl is the URL of the uploaded file at the remote location
 * @param md5 the hash of the uploaded file
 */
export type UploadCompletedCallback = (
  id: GUID,
  downloadUrl: string,
  md5: string,
) => void;

/**
 * Callback type reporting that the upload has failed
 *
 * @param id is the ID the upload task has in the store.
 * @param error is the exception that was raised and made the upload fail.
 */
export type UploadFailedCallback = (id: GUID, error: Error) => void;

/**
 * Callback type reporting that the upload has progressed
 *
 * @param id is the ID the upload task has in the store.
 * @param progress is the new progress of the upload, measured from 0 to 100.
 */
export type UploadUpdatedCallback = (id: GUID, progress: number) => void;

/**
 * An object to manage a specific CoreFileUploader object and to connect it to the
 * object managing all uploads
 */
class UploadController {
  uploader: CoreFileUploader;
  abortController = new AbortController();

  uploadCompleted = new TypedEvent<{
    id: GUID;
    downloadUrl: string;
    md5: string;
  }>();

  uploadFailed = new TypedEvent<{ id: GUID; error: Error }>();

  uploadUpdated = new TypedEvent<{
    id: GUID;
    progress: number;
    expectedEnd: number;
  }>();

  constructor(
    public id: GUID,
    file: File,
    projectId: string,
    coreApi: CoreApiClient,
  ) {
    this.uploader = new CoreFileUploader(file, projectId, coreApi);
    this.uploader.uploadCompleted.on(({ downloadUrl, md5 }) => {
      this.uploadCompleted.emit({
        id: this.id,
        downloadUrl,
        md5,
      });
    });
    this.uploader.uploadFailed.on((error: Error) => {
      this.uploadFailed.emit({ id: this.id, error });
    });
    this.uploader.progressChanged.on(({ percentage, expectedEnd }) => {
      this.uploadUpdated.emit({
        id: this.id,
        progress: percentage,
        expectedEnd,
      });
    });
  }
}

/**
 *  Function used to notify an upload started to the React context to update the store
 *
 * @param id is the id of the task in the store
 * @param projectId is the id of the project
 * @param file is the handle to the file to upload
 * @param uploadElementType is type of element to upload
 * @param silent whether progress toast notifications should be hidden
 * @param floorId is an optional id to the floor this file will be part of
 */
type StartUploadFn = (
  id: GUID,
  projectId: string,
  file: File,
  uploadElementType: UploadElementType,
  silent: boolean,
  floorId?: GUID,
) => void;

/**
 * A type collecting the background task's properties that we may want
 * to update in the store
 */
export type UpdateTaskProps = Partial<
  Pick<BackgroundTask, "progress" | "expectedEnd" | "state">
>;

/** Function used to notify an upload was updated to the React context to update the store */
type UpdateTaskFn = (id: GUID, propsToUpdate: UpdateTaskProps) => void;

/** Function used to notify an upload finished to the React context to remove the task from the store */
type RemoveTaskFn = (id: GUID) => void;

export type FileUploadParams = {
  /** The file to upload */
  file: File;

  /** type of upload: cad, cloud or areaImage*/
  uploadElementType: UploadElementType;

  /** The project to add the file to */
  projectId: GUID;

  /** The area we're going to add this file to, to mark the area as changing */
  areaId?: GUID;

  /** Whether to hide progress notifications to the user */
  silent?: boolean;

  /** Core API client to perform the upload */
  coreApiClient: CoreApiClient;

  /** Callback called bu the upload to finalize an upload. If it fails the upload is considered failed */
  finalizer?: FinalizerCallback;

  /**
   * Function called when a given file upload completes successfully.
   * This is executed after the finalizer.
   */
  onUploadCompleted?: UploadCompletedCallback;

  /** Function called when a given file upload fails */
  onUploadFailed?: UploadFailedCallback;

  /** Function called when the progress of a given upload changes */
  onUploadUpdated?: UploadUpdatedCallback;
};

/**
 * The interface of the object contained in the FileUploadsContext.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUploader and useCancelUpload.
 */
export interface UploadManagerInterface {
  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload(params: FileUploadParams): void;

  /**
   * @returns Whether the task existed and was correctly canceled, i.e. was in progress.
   * @param id Id of the task to be canceled
   */
  cancelFileUpload(id: GUID): boolean;
}

/**
 * A manager that stores all ongoing uploads.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUploader and useCancelUpload.
 */
export class UploadManager implements UploadManagerInterface {
  #uploads = new Map<GUID, UploadController>();

  /**
   * @param startTaskInStore Function to start a background task in the store
   * @param updateTaskInStore Function to update the task in the store corresponding to the upload
   * @param removeTaskInStore Function to remove a task from the store.
   */
  constructor(
    public startTaskInStore: StartUploadFn,
    public updateTaskInStore: UpdateTaskFn,
    public removeTaskInStore: RemoveTaskFn,
  ) {
    this.uploadCompleted = this.uploadCompleted.bind(this);
    this.uploadFailed = this.uploadFailed.bind(this);
    this.uploadUpdated = this.uploadUpdated.bind(this);
  }

  /**
   *
   * @param id ID of the upload to remove
   */
  #removeUpload(id: GUID): void {
    const uploadController = this.#uploads.get(id);
    if (!uploadController) return;
    uploadController.uploadCompleted.off(this.uploadCompleted);
    uploadController.uploadFailed.off(this.uploadFailed);
    uploadController.uploadUpdated.off(this.uploadUpdated);
    this.#uploads.delete(id);
  }

  /**
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.downloadUrl URL containing the uploaded file at the remote location.
   */
  private uploadCompleted(arg: { id: GUID; downloadUrl: string }): void {
    this.updateTaskInStore(arg.id, { state: BackgroundTaskState.succeeded });
    this.#removeUpload(arg.id);
  }

  /**
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.error Error thrown that made the upload to fail.
   */
  private uploadFailed(arg: { id: GUID; error: Error }): void {
    this.updateTaskInStore(arg.id, { state: BackgroundTaskState.failed });
    this.#removeUpload(arg.id);
  }

  /**
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.progress Current progress of the given upload, from 0 to 100
   * @param arg.expectedEnd Expected end timestamp of this task
   */
  private uploadUpdated(arg: {
    id: GUID;
    progress: number;
    expectedEnd: number;
  }): void {
    this.updateTaskInStore(arg.id, {
      progress: arg.progress,
      state: BackgroundTaskState.started,
      expectedEnd: arg.expectedEnd,
    });
  }

  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload({
    projectId,
    file,
    uploadElementType,
    areaId,
    silent = false,
    coreApiClient,
    finalizer,
    onUploadCompleted,
    onUploadFailed,
    onUploadUpdated,
  }: FileUploadParams): void {
    const id = uniqueId();
    const controller = new UploadController(id, file, projectId, coreApiClient);
    if (onUploadCompleted) {
      controller.uploadCompleted.on(
        (arg: { id: GUID; downloadUrl: string; md5: string }) =>
          onUploadCompleted(arg.id, arg.downloadUrl, arg.md5),
      );
    }
    if (onUploadFailed) {
      controller.uploadFailed.on((arg: { id: GUID; error: Error }) =>
        onUploadFailed(arg.id, arg.error),
      );
    }
    if (onUploadUpdated) {
      controller.uploadUpdated.on((arg: { id: GUID; progress: number }) =>
        onUploadUpdated(arg.id, arg.progress),
      );
    }
    controller.uploadCompleted.on(this.uploadCompleted);
    controller.uploadFailed.on(this.uploadFailed);
    controller.uploadUpdated.on(this.uploadUpdated);
    this.#uploads.set(id, controller);
    // Update the store with the new task
    this.startTaskInStore(
      id,
      projectId,
      file,
      uploadElementType,
      silent,
      areaId,
    );
    // Start the actual upload
    controller.uploader.doUpload(controller.abortController.signal, finalizer);
  }

  /**
   * Cancels a file upload
   *
   * @param id The ID of the corresponding background task in the store
   * @returns Whether the upload existed and was in progress, therefore canceled correctly.
   */
  cancelFileUpload(id: GUID): boolean {
    const controller = this.#uploads.get(id);
    if (
      !controller ||
      controller.uploader.state !== BackgroundTaskState.started
    ) {
      return false;
    }
    controller.abortController.abort();
    this.updateTaskInStore(id, { state: BackgroundTaskState.aborted });
    this.#uploads.delete(id);
    return true;
  }

  /**
   * Removes a file upload from the managed uploads
   *
   * @param id the ID of the upload
   * @returns whether the removal was successful or whether the upload was still in progress.
   */
  removeFileUpload(id: GUID): boolean {
    const controller = this.#uploads.get(id);
    if (!controller) return false;
    if (controller.uploader.state === BackgroundTaskState.started) return false;
    this.#removeUpload(id);
    this.removeTaskInStore(id);
    return true;
  }
}
