import { FaroChipFilter, FaroMenu, SingleSelectItem } from "@faro-lotv/flat-ui";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";

export enum Dates {
  NoDate = "No date" /** For annotations with no due date defined */,
  BeforeToday = "Before today",
  Today = "Today",
  Tomorrow = "Tomorrow",
  Next7Days = "Next 7 days",
  Next30Days = "Next 30 days",
  Next90Days = "Next 90 days",
}

export type DateFilterProps = {
  /** Callback executed when the selection changes */
  onSelectedDateChanged(date: Dates | undefined): void;
};

/** @returns A chip with a popup that can be used to select a due date option */
export function DateFilter({
  onSelectedDateChanged,
}: DateFilterProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [date, setDate] = useState<Dates>();
  useEffect(() => onSelectedDateChanged(date), [date, onSelectedDateChanged]);

  const options = useMemo(
    () =>
      Object.values(Dates).map((d) => ({
        key: d,
        value: d,
        label: (
          <SingleSelectItem
            key={d}
            onClick={() => setDate(date === d ? undefined : d)}
            label={d}
            value={d}
            selectedValue={date}
          />
        ),
      })),
    [date],
  );

  return (
    <Box ref={setAnchorEl} component="div">
      <FaroChipFilter
        label="Due date"
        count={date ? 1 : undefined}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      />
      {anchorEl && (
        <FaroMenu
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
        >
          {options.map((o) => o.label)}
        </FaroMenu>
      )}
    </Box>
  );
}
