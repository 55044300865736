export enum ResponsiveCardVariant {
  /** Card variant for large panels */
  large = "large",
  /** Card variant for medium panels */
  medium = "medium",
}

/**
 * The height of each card variant is fixed to these values, to allow react-window to properly compute
 * the visible cards before they're rendered
 */
export const RESPONSIVE_CARDS_HEIGHT: Record<ResponsiveCardVariant, number> = {
  [ResponsiveCardVariant.large]: 48,
  [ResponsiveCardVariant.medium]: 76,
};

/** For panel width larger than this, we switch to the large variant of the card */
const BREAK_POINT_LARGE = 500;

/**
 * @param width of the container to use as a reference
 * @returns the card variant to use for that width
 */
export function cardVariantFromWidth(width: number): ResponsiveCardVariant {
  if (width < BREAK_POINT_LARGE) return ResponsiveCardVariant.medium;
  return ResponsiveCardVariant.large;
}
