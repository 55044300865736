import { EventType } from "@/analytics/analytics-events";
import {
  selectIsRegistrationInProgress,
  selectLastRegistrationPoseUsed,
} from "@/registration-tools/common/store/registration-selectors";
import { ParameterMenuIcon } from "@/registration-tools/icons/manual-alignment-icons";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { FaroButton, FaroIconButton } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useRef, useState } from "react";
import { ParametersMenu } from "../common/parameters-menu";
import { PairwiseRegistrationBanner } from "./pairwise-registration-banner";
import {
  AlignmentBarInfoProps,
  PairwiseRegistrationBarInfo,
} from "./pairwise-registration-bar-info";

/** Info about current alignment and callbacks for events */
type PairwiseRegistrationBarProps = AlignmentBarInfoProps & {
  /** The user is requesting to start the automatic registration */
  onRegistrationStartClick?(): Promise<string | undefined>;
  /** The user is requesting to cancel the current registration job */
  onRegistrationCancelClick(): Promise<string | undefined>;
  /** The user confirmed the workflow */
  onConfirmClick(): void;
};

/**
 * @returns Utility bar that provides buttons for registration refinement and confirming the result
 */
export function PairwiseRegistrationBar({
  areaName,
  pointCloudNames,
  pointCloudLabels,
  onRegistrationStartClick,
  onRegistrationCancelClick,
  onConfirmClick,
}: PairwiseRegistrationBarProps): JSX.Element {
  const [isParametersMenuOpen, setIsParametersMenuOpen] =
    useState<boolean>(false);

  const parameterMenuButtonRef = useRef<HTMLButtonElement>(null);

  function toggleParameterMenuOpen(): void {
    setIsParametersMenuOpen(!isParametersMenuOpen);
  }

  const isRegistrationInProgress = useAppSelector(
    selectIsRegistrationInProgress,
  );

  const lastRegistrationPoseUsed = useAppSelector(
    selectLastRegistrationPoseUsed,
  );

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py: 1,
          px: 4,
          backgroundColor: "gray100",
        }}
      >
        <PairwiseRegistrationBarInfo
          areaName={areaName}
          pointCloudNames={pointCloudNames}
          pointCloudLabels={pointCloudLabels}
        />
        <Stack
          gap="1em"
          direction="row"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {hasRegistrationDevFeature && (
            <FaroIconButton
              ref={parameterMenuButtonRef}
              onClick={toggleParameterMenuOpen}
            >
              <ParameterMenuIcon />
            </FaroIconButton>
          )}

          <ParametersMenu
            open={isParametersMenuOpen}
            anchorEl={parameterMenuButtonRef.current}
            onClose={toggleParameterMenuOpen}
          />
          {onRegistrationStartClick && (
            <>
              <FaroButton
                onClick={() => {
                  Analytics.track(
                    EventType.startPairwiseAutomaticRegistrationTask,
                  );
                  onRegistrationStartClick();
                }}
                // It takes a few seconds until we get the jobId, but we need to block it right away
                disabled={isRegistrationInProgress}
                sx={{ minWidth: "fit-content" }}
                variant={lastRegistrationPoseUsed ? "secondary" : "primary"}
              >
                Run automatic registration
              </FaroButton>
              <Divider orientation="vertical" sx={{ height: "80%" }} />
            </>
          )}
          <FaroButton
            disabled={isRegistrationInProgress}
            onClick={onConfirmClick}
            variant={lastRegistrationPoseUsed ? "primary" : "secondary"}
          >
            Save & exit
          </FaroButton>
        </Stack>
      </Stack>
      {isRegistrationInProgress && (
        <PairwiseRegistrationBanner
          onRegistrationCancelClick={() => {
            Analytics.track(EventType.cancelPairwiseAutomaticRegistrationTask);
            return onRegistrationCancelClick();
          }}
        />
      )}
    </Stack>
  );
}
