import { FaroText, FontWeights, neutral } from "@faro-lotv/flat-ui";
import { Box, Card, Stack } from "@mui/material";
import { PropsWithChildren } from "react";

type RegistrationCardProps = {
  /** The icon of the card, displayed in the top left. */
  cardIcon: JSX.Element;

  /** The title of the card. */
  title: string;

  /** An optional status icon to display in the bottom right. */
  statusIcon?: JSX.Element;
};

// TODO: Use this card for the metrics as well to ensure a consistent design
// https://faro01.atlassian.net/browse/NRT-674
/**
 * @returns A card with an icon and title to display registration results.
 */
export function RegistrationCard({
  cardIcon,
  title,
  children,
  statusIcon,
}: PropsWithChildren<RegistrationCardProps>): JSX.Element {
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "100%",
        background: neutral[50],
      }}
    >
      <Stack direction="row" columnGap={2} p={1}>
        <Box
          component="div"
          flexShrink={0}
          sx={{ width: "2.5rem", height: "2.5rem" }}
        >
          {cardIcon}
        </Box>
        <Stack flexGrow={1} spacing={1}>
          <FaroText
            variant="heading24"
            sx={{ color: "info.main", fontWeight: FontWeights.Regular }}
          >
            {title}
          </FaroText>
          {children}
        </Stack>
        <Box
          component="div"
          flexShrink={0}
          alignSelf="flex-end"
          sx={{ width: "1.5rem", height: "1.5rem" }}
        >
          {statusIcon}
        </Box>
      </Stack>
    </Card>
  );
}
