import { RlyHistogram } from "@faro-lotv/service-wires";
import { FunctionComponent, SVGAttributes } from "react";
import {
  MetricThresholds,
  RegistrationThresholdSet,
} from "../common/registration-report/registration-thresholds";
import { ReactComponent as MetricsScaleGood } from "../icons/metrics-scale-good.svg";
import { ReactComponent as MetricsScaleMedium } from "../icons/metrics-scale-medium.svg";
import { ReactComponent as MetricsScalePoor } from "../icons/metrics-scale-poor.svg";
import { ReactComponent as MetricsScaleUnknown } from "../icons/metrics-scale-unknown.svg";
import { ReactComponent as QualityStatusGood } from "../icons/quality-status-good.svg";
import { ReactComponent as QualityStatusMedium } from "../icons/quality-status-medium.svg";
import { ReactComponent as QualityStatusPoor } from "../icons/quality-status-poor.svg";
import { UnitType } from "./units";

/** the list of registration metrics */
export type RegistrationMetricsData = {
  /** cloud to cloud distance (bidirectional) where bigger distances are capped */
  clipChamferDistance: number | null;
  /** % in common in volume occupancy between both pointclouds */
  overlap?: number;
  /** metric where positive occurrences are related with distance being less than a defined threshold. */
  fscore: number | null;
  /** values related to the histogram of the registration result */
  rlyHistogram?: RlyHistogram;
};

/** the different qualities under which a metric value can fall into */
export enum QualityStatus {
  POOR = "Poor",
  MEDIUM = "Medium",
  GOOD = "Good",
  UNKNOWN = "Unknown",
}

/** value, thresholds and quality of a metric */
export interface MetricDetails extends QualityProps {
  value?: number;
  unit: UnitType;
  thresholds: MetricThresholds;
}

/** params that represent the quality of metric/registration result */
export interface QualityProps {
  /** the quality for a specific metric (or) overall registration result */
  quality: QualityStatus;
}

/**
 * @param value value of the metric received from backend
 * @param thresholds The thresholds to use to determine the quality of the value
 * @returns quality of the metric based on the threshold values
 */
export function getQualityStatus(
  value: number | undefined,
  thresholds: MetricThresholds,
): QualityStatus {
  if (value === undefined) return QualityStatus.UNKNOWN;

  if (thresholds.good > thresholds.poor) {
    if (value > thresholds.good) {
      return QualityStatus.GOOD;
    } else if (value < thresholds.poor) {
      return QualityStatus.POOR;
    }
    return QualityStatus.MEDIUM;
  }
  if (thresholds.good < thresholds.poor) {
    if (value < thresholds.good) {
      return QualityStatus.GOOD;
    } else if (value > thresholds.poor) {
      return QualityStatus.POOR;
    }
    return QualityStatus.MEDIUM;
  }
  return QualityStatus.UNKNOWN;
}

/**
 * @param overlapQuality quality of the overlap metric based on the threshold values
 * @param pointsDistanceQuality quality of the points distance metrics based on the threshold values
 * @returns the overall quality based on overlap and points distance values
 */
export function getTotalQualityStatus(
  overlapQuality: QualityStatus,
  pointsDistanceQuality: QualityStatus,
): QualityStatus {
  if (
    overlapQuality === QualityStatus.POOR ||
    pointsDistanceQuality === QualityStatus.POOR
  ) {
    return QualityStatus.POOR;
  }
  if (
    overlapQuality === QualityStatus.MEDIUM ||
    pointsDistanceQuality === QualityStatus.MEDIUM
  ) {
    return QualityStatus.MEDIUM;
  }
  if (
    overlapQuality === QualityStatus.GOOD &&
    pointsDistanceQuality === QualityStatus.GOOD
  ) {
    return QualityStatus.GOOD;
  }
  return QualityStatus.UNKNOWN;
}

/**
 * @param qualityStatus the quality of the registration result
 * @returns the metricScale icon based on the quality parameter
 */
export function getMetricScaleIcon(
  qualityStatus: QualityStatus,
): FunctionComponent<SVGAttributes<SVGElement>> {
  switch (qualityStatus) {
    case QualityStatus.GOOD:
      return MetricsScaleGood;
    case QualityStatus.MEDIUM:
      return MetricsScaleMedium;
    case QualityStatus.POOR:
      return MetricsScalePoor;
    default:
      return MetricsScaleUnknown;
  }
}

/** Quality Icon and the corresponding color */
interface QualityIcon {
  quality: FunctionComponent<SVGAttributes<SVGElement>>;
  color: string;
}

/**
 * @param qualityStatus quality of the registration metric
 * @returns quality icon and corresponding color
 */
export function getQualityIconDetails(
  qualityStatus: QualityStatus,
): QualityIcon | undefined {
  switch (qualityStatus) {
    case QualityStatus.GOOD:
      return {
        quality: QualityStatusGood,
        color: "green500",
      };
    case QualityStatus.POOR:
      return { quality: QualityStatusPoor, color: "red500" };
    case QualityStatus.MEDIUM:
      return {
        quality: QualityStatusMedium,
        color: "yellow500",
      };
  }
}

/** different metrics of the registration result */
export class RegistrationDetails {
  overlap?: number;
  histogram?: RlyHistogram;
  thresholdSet: RegistrationThresholdSet;

  /**
   * @param overlap overlap metric of registration result
   * @param thresholdSet The thresholds to use to determine the quality of the metric values
   * @param histogram histogram related data of the registration result
   */
  constructor(
    overlap: number | undefined,
    thresholdSet: RegistrationThresholdSet,
    histogram?: RlyHistogram,
  ) {
    this.overlap = overlap;
    this.histogram = histogram;
    this.thresholdSet = thresholdSet;
  }

  /**
   * @returns the value, limits and quality of overlap metric of registration result
   */
  get overlapDetails(): MetricDetails {
    const thresholds = this.thresholdSet.overlap;

    return {
      value: this.overlap,
      unit: UnitType.fraction,
      thresholds,
      quality: getQualityStatus(this.overlap, thresholds),
    };
  }

  /**
   * @returns the value, limits and quality of pointsDistance metric of registration result
   */
  get pointsDistanceDetails(): MetricDetails {
    const pointsDistance = this.histogram ? this.histogram.median : 0;
    const thresholds = this.thresholdSet.pointDistance;
    const qualityStatus = this.histogram
      ? getQualityStatus(pointsDistance, thresholds)
      : QualityStatus.UNKNOWN;

    return {
      value: pointsDistance,
      unit: UnitType.length,
      thresholds,
      quality: qualityStatus,
    };
  }

  /**
   * @returns the overall quality of the registration data
   */
  get registrationQuality(): QualityStatus {
    return getTotalQualityStatus(
      this.overlapDetails.quality,
      this.pointsDistanceDetails.quality,
    );
  }
}
