import { OrientedBoundingBox } from "@/components/r3f/utils/oriented-bounding-box";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ClippingBoxState = {
  clippingBox: OrientedBoundingBox | undefined;
};

const initialState: ClippingBoxState = {
  clippingBox: undefined,
};

export const clippingBoxSlice = createSlice({
  initialState,
  name: "clippingBox",
  reducers: {
    setClippingBox(
      state,
      action: PayloadAction<OrientedBoundingBox | undefined>,
    ) {
      state.clippingBox = action.payload;
    },
  },
});

export const { setClippingBox } = clippingBoxSlice.actions;

export const clippingBoxReducer = clippingBoxSlice.reducer;
