import {
  CadError,
  CadErrorType,
  CadModelObject,
  loadCadModelTree,
  loadModel3dStreamDescription,
  useCached3DObjectIfReady,
} from "@/object-cache";
import {
  selectActiveCadModel,
  setActiveCadHasMesh,
  setActiveCadLoadingError,
  setCadLevelsInMeshCs,
  setCadSvfMetadata,
} from "@/store/cad/cad-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { loadCadModelMetadata } from "@/utils/cad-importer-ui-messages";
import { orange, useToast } from "@faro-lotv/flat-ui";
import {
  CadMessagesDescription,
  CadModelTreeObjectDescription,
  GUID,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { useCallback, useEffect, useState } from "react";
import { Color, Vector3 } from "three";

/**
 * Cad Model object with required data for changing and restoring
 * model node properties temporarily.
 */
export class CadModelData {
  /**
   * @param cadModel Reference to the CadModelObject
   */
  constructor(public cadModel: CadModelObject) {}

  /**
   * The visibility state of all CadModel objects, mapy key is the ObjectId.
   * Used to restore the visibility after highlighting a invisible node.
   */
  visibilities = new Map<number, boolean>();

  /**
   * CadModelTreeNodeData associated with each CadModel objects, map key is the ObjectId.
   */
  treeNodeData = new Map<number, CadModelTreeNodeData>();

  /**
   * Current selected CAD object Ids
   */
  selectedObjects = new Array<number>();
}

/**
 * Data associated with each node in the CAD model arborist tree.
 * Format defined by React-arborist TreeProps.initialData.
 */
export type CadModelTreeNodeData = {
  /** Tree node id */
  id: string;

  /** Cad object name */
  name: string;

  /** GUID of the CAD IElementModel3dStreamId for the root node (only for the root node); undefined for all node but the root one */
  idIElementModel3dStream?: GUID;

  /** cad model importer messages, only available for root node*/
  modelMessages?: CadMessagesDescription;

  /** Optional children of the object */
  children: CadModelTreeNodeData[] | null;

  /** Reference to the CAD model data */
  cadModelData: CadModelData | null;

  /** CAD objectId associated with the tree node */
  cadObjectId: number;

  /** Visibility state of the node */
  visibility: boolean;
};

/**
 * Generate the data for the React-arborist tree from the CadModelTreeObjectDescription[].
 *
 * @param modelTree model tree data to convert
 * @param cadModel cad mesh model to use for updating visibility
 * @param cadIElement - IElement associated with the CAD
 * @param modelMessages messages from cad model processing, only apply to the root; should be undefined for children
 * @returns an array of CadModelTreeNodeData that could be used as data for Tree
 */
function getArboristCadModelTreeData(
  modelTree: CadModelTreeObjectDescription[],
  cadModel: CadModelObject | null,
  cadIElement: IElementModel3dStream,
  modelMessages?: CadMessagesDescription,
): CadModelTreeNodeData[] {
  let cadModelData: CadModelData | null = null;
  if (cadModel) {
    const highlightColor = new Color(orange[500]);
    cadModel.highlightingColor = new Vector3(
      highlightColor.r,
      highlightColor.g,
      highlightColor.b,
    );
    for (const objectId of cadModel.objectIds) {
      // Make sure all nodes are visible.
      cadModel.setObjectVisible(objectId, true);
    }
    cadModelData = new CadModelData(cadModel);
  }
  const modelTreeData = getCadModelTreeData(modelTree, cadModelData);
  // assign the IElementModel3dStream and modelMessages in the first node
  if (modelTreeData.length > 0) {
    modelTreeData[0].idIElementModel3dStream = cadIElement.id;
    modelTreeData[0].modelMessages = modelMessages;
  }
  return modelTreeData;
}

/**
 * Recursively populate the array of all CadModelTreeNodeData elements for a specific branch of the CAD tree.
 *
 * @param modelTreeItems List of CAD objects for this branch of the tree
 * @param cadModelData Optional CAD data associated with this tree
 * @returns the arborist array of data for this specific branch
 */
function getCadModelTreeData(
  modelTreeItems: CadModelTreeObjectDescription[],
  cadModelData: CadModelData | null,
): CadModelTreeNodeData[] {
  const elements: CadModelTreeNodeData[] = [];
  for (const treeItem of modelTreeItems) {
    const cadObjectId = treeItem.ObjectId;
    const id = cadObjectId.toString();
    const newTreeNode: CadModelTreeNodeData = {
      id,
      name: treeItem.Name ?? "",
      children: treeItem.Children
        ? getCadModelTreeData(treeItem.Children, cadModelData)
        : null,
      cadModelData,
      cadObjectId,
      visibility: true,
    };
    elements.push(newTreeNode);
    if (cadModelData) {
      cadModelData.visibilities.set(cadObjectId, true);
      cadModelData.treeNodeData.set(cadObjectId, newTreeNode);
    }
  }
  return elements;
}

/**
 * @param name the cad model name
 * @returns a cad tree description to use for cad model without a tree description (eg glb file imports)
 */
function createSingleNodeTree(name: string): CadModelTreeObjectDescription[] {
  return [
    {
      ObjectId: 0,
      Name: name,
      PersistentId: null,
      Children: null,
    },
  ];
}

/** @returns the cad model tree data for the active cad model or an error string */
export function useCadModelTreeData(): CadModelTreeNodeData[] | string {
  const activeCad = useAppSelector(selectActiveCadModel);
  const dispatch = useAppDispatch();

  // This state object contains one of the following states:
  // - undefined if there is no active cad loaded
  // - a "Loading" sentinel state while the tree data is loading
  // - the current tree data used to interact with the loaded cad model
  // - the loading error if loading the cad tree data failed
  const [modelTreeData, setModelTreeData] = useState<
    undefined | "Loading" | CadModelTreeNodeData[] | Error
  >();

  // When the active cad changes change the current state to loading while we wait for the model to load
  useEffect(() => {
    if (activeCad) {
      setModelTreeData("Loading");
    } else {
      setModelTreeData(undefined);
    }
  }, [activeCad]);

  const { openToast } = useToast();

  // Load the 3d object for the active cad and track the loading state
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  // Define the async function that will load and compute the tree data
  const loadTreeData = useCallback(
    async (
      // loaded 3D model object or Error if failed to load
      cadModelObject: CadModelObject | Error,
      // 3D model stream node element used to load model tree
      model3DStreamElement: IElementModel3dStream,
      // AbortController signal used to stop model tree data loading
      signal: AbortSignal,
    ): Promise<void> => {
      // Load the model description
      const model3dStreamDescription =
        await loadModel3dStreamDescription(model3DStreamElement);
      if (signal.aborted) return;
      dispatch(
        setActiveCadHasMesh(
          !!model3dStreamDescription.meshGlbUrl ||
            !!model3dStreamDescription.subMeshesUrl,
        ),
      );

      // Load the messages and levels in the model metadata
      const metaData = await loadCadModelMetadata(
        model3dStreamDescription,
      ).catch((error) => {
        console.log(error);
      });

      // store the levels in store for future usage
      dispatch(
        setCadLevelsInMeshCs(
          metaData?.Levels.length ? metaData.Levels : undefined,
        ),
      );

      // store the other metadata for future usage
      dispatch(setCadSvfMetadata(metaData?.MetaDataFromSvf));

      const messages: CadMessagesDescription = metaData?.Messages ?? [];

      if (cadModelObject instanceof Error) {
        // failed to load cad model object = ...
        // 1- set the error message to be displayed on the top of the model tree
        if (
          cadModelObject instanceof CadError &&
          cadModelObject.CadErrorType === CadErrorType.EmptyModel
        ) {
          dispatch(
            setActiveCadLoadingError("Model has no geometry to display"),
          );
        } else if (
          cadModelObject instanceof CadError &&
          cadModelObject.CadErrorType === CadErrorType.NoMeshFile
        ) {
          dispatch(
            setActiveCadLoadingError(
              "Model has failed to convert. Check import messages for more details.",
            ),
          );
        } else {
          dispatch(setActiveCadLoadingError("Model is too big to render"));
        }
        // 2- display the error message as a toast
        openToast({
          title: "Rendering of the 3D Model failed",
          message: cadModelObject.message,
          variant: "warning",
          persist: true,
        });

        // 3- no model to render
        dispatch(setActiveCadHasMesh(false));
      }

      // abort flag may change every time an async function is called
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (signal.aborted) return;

      try {
        // Load the cad model tree
        const tree = await loadCadModelTree(model3DStreamElement);

        // abort flag may change every time an async function is called
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (signal.aborted) return;

        // Compute the new tree model data
        setModelTreeData(
          getArboristCadModelTreeData(
            tree ?? createSingleNodeTree(model3DStreamElement.name),
            cadModelObject instanceof Error ? null : cadModelObject,
            model3DStreamElement,
            messages,
          ),
        );
      } catch (error) {
        if (error instanceof Error) {
          setModelTreeData(error);
        } else if (typeof error === "string") {
          setModelTreeData(new Error(error));
        } else {
          setModelTreeData(new Error("Unknown Error"));
        }
      }
    },
    [dispatch, openToast],
  );

  // When the current cad model changed and finishes loading load the tree data
  useEffect(() => {
    if (!activeCad) {
      // No valid model for the current active cad model
      setModelTreeData(undefined);
      return;
    }

    // Try to load cad model tree data after 3D model object loading is finished.
    // In the progress of 3D model object loading, cadModelObject is null.
    // After 3D model object loading is finished, cadModelObject is valid object if successful or Error if failed to load.
    if (cadModelObject) {
      const ac = new AbortController();
      loadTreeData(cadModelObject, activeCad, ac.signal);
      // If the active cad model changes, stop the previous loading
      return () => {
        ac.abort();
      };
    }
  }, [activeCad, cadModelObject, loadTreeData]);

  if (modelTreeData instanceof Error) {
    return `Failed to get 3D model tree: ${modelTreeData.toString()}`;
  } else if (!modelTreeData) {
    return "No active 3D model tree";
  } else if (modelTreeData === "Loading") {
    return "3D model tree loading...";
  }
  return modelTreeData;
}
