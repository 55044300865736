import { BackgroundTask } from "@/utils/background-tasks";
import { FontWeights, RemainingTimeLabel } from "@faro-lotv/flat-ui";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/system";
import { CardProgress } from "../card-progress";
import {
  GenericCardLayout,
  GenericCardLayoutProps,
} from "./generic-card-layout";

// Padding used in the Progress card. This is needed because no magic numbers are allowed.
const TITLE_TO_PROGRESS_PADDING = 1.25;

export type BackgroundTaskCardProps<
  Task extends BackgroundTask = BackgroundTask,
> = {
  /** Task to show the progress state of in the upload menu card */
  task: Task;
};

type ProgressCardLayoutProps = Omit<
  GenericCardLayoutProps,
  "titleWrapperSx"
> & {
  /** Upload task to show progress of, or null if upload is finished */
  upload?: BackgroundTask | null;

  /** Processing task to show the progress of or null if processing is finished */
  processing?: BackgroundTask | null;
};

/** @returns the main layout of an upload menu card */
export function ProgressCardLayout({
  upload,
  processing,
  subText,
  ...rest
}: ProgressCardLayoutProps): JSX.Element {
  const expectedEnd = processing?.expectedEnd ?? upload?.expectedEnd;

  return (
    <GenericCardLayout
      subText={subText}
      // Add some space between the card's title and the progress bar
      titleWrapperSx={{ pb: subText ? 0.5 : TITLE_TO_PROGRESS_PADDING }}
      {...rest}
    >
      <CardProgress label="Uploading..." task={upload} />
      {processing && <CardProgress label="Processing" task={processing} />}

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          fontSize: "0.8em",
          color: ({ palette }) => `${palette.darkGrey}E6`,
        }}
      >
        {upload === null ? (
          <Box
            component="span"
            sx={{
              color: ({ palette }) => `${palette.primary.main}E6`,
              fontWeight: FontWeights.SemiBold,
            }}
          >
            Uploaded.
          </Box>
        ) : (
          "Uploading..."
        )}
        &nbsp;
        <ProcessingStateLabel task={processing} />
        {expectedEnd && (
          <>
            &nbsp;(
            <RemainingTimeLabel
              expectedEnd={expectedEnd}
              sx={{
                color: ({ palette }) => `${palette.gray850}E6`,
                fontWeight: FontWeights.SemiBold,
              }}
            />
            )
          </>
        )}
      </Stack>
    </GenericCardLayout>
  );
}

type ProcessingStateLabelProps = {
  /** Task to show the progress state label of */
  task: BackgroundTask | null | undefined;
};

/**
 * @returns the label for a task progress if available
 */
function ProcessingStateLabel({
  task,
}: ProcessingStateLabelProps): string | null {
  if (!task) {
    return null;
  }
  switch (task.state) {
    case BackgroundTaskState.created:
      return "Task created...";
    case BackgroundTaskState.scheduled:
      return "Task scheduled...";
    case BackgroundTaskState.started:
      return "Now processing...";
    case BackgroundTaskState.succeeded:
      return "Task completed";
    case BackgroundTaskState.aborted:
      return "Task canceled";
    case BackgroundTaskState.failed:
      return "Task failed";
  }
}
