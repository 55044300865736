import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { RootState } from "@/store/store";
import {
  AlignmentViewLayout,
  SheetToCadAlignmentStep,
  SplitDirection,
} from "./sheet-to-cad-alignment-mode-slice";

/**
 * @returns the current CAD alignment step
 */
export function selectSheetToCadAlignmentStep({
  sheetToCadAlignmentMode,
}: RootState): SheetToCadAlignmentStep {
  return sheetToCadAlignmentMode.step;
}

/**
 * @returns the current CAD alignment screen layout
 */
export function selectSheetToCadAlignmentLayout({
  sheetToCadAlignmentMode,
}: RootState): AlignmentViewLayout {
  return sheetToCadAlignmentMode.alignmentLayout;
}

/**
 * @returns the current CAD alignment vertical split flag
 */
export function selectSheetToCadAlignmentSplitDirection({
  sheetToCadAlignmentMode,
}: RootState): SplitDirection {
  return sheetToCadAlignmentMode.splitDirection;
}

/**
 * @returns true if Clipping box for first step of CAD alignment is enabled
 */
export function selectClippingBoxEnabledForCadAlignment({
  sheetToCadAlignmentMode,
}: RootState): boolean {
  return sheetToCadAlignmentMode.isClippingBoxEnabled;
}

/**
 * @returns true if cross section for CAD tomographic view is enabled
 */
export function selectCrossSectionEnabledForTomographicView({
  sheetToCadAlignmentMode,
}: RootState): boolean {
  return sheetToCadAlignmentMode.isCrossSectionEnabled;
}

/**
 * @param state  Current state
 * @returns incremental area-to-cad transform in CS of previous pose or undefined if position/scale was not modified
 */
export function selectIncrementalCadTransform(
  state: RootState,
): AlignmentTransform | undefined {
  return state.sheetToCadAlignmentMode.incrementalTransform;
}

/**
 * @param state  Current state
 * @returns  Elevation of sheet plane relatively to CAD origin in W.C.S., in meters, or undefined if elevation was not modified
 */
export function selectSheetElevation(state: RootState): number | undefined {
  return state.sheetToCadAlignmentMode.sheetElevation;
}

/**
 * @param state  Current state
 * @returns  id of sheet used in  current session of sheet-to-cad alignment
 */
export function selectSheetForCadAlignment(
  state: RootState,
): string | undefined {
  return state.sheetToCadAlignmentMode.sheetIdToAlignWithCad;
}

/**
 * @param state  Current state
 * @returns  anchor positions in split screen cad alignment
 */
export function selectAlignmentAnchorPositions(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.sheetToCadAlignmentMode.alignmentAnchorPositions;
}
