import { GUID, IQuat, IVec3 } from "@faro-lotv/ielement-types";
import {
  State,
  TransformOverrides,
  convertIElementToThreePosition,
  convertIElementToThreeRotation,
  selectIElementWorldMatrix,
} from "@faro-lotv/project-source";
import { Selector } from "react-redux";
import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * @returns The world transform of an IElement, as a Matrix4.
 * @param id ID of the IElement
 * @param transformOverrides Transforms to overwrite the persisted values with (only during the calculation).
 *  If defined, the global transform cache will be disabled.
 */
export function selectIElementWorldMatrix4(
  id?: GUID | undefined,
  transformOverrides?: TransformOverrides,
): Selector<State, Matrix4> {
  const matrix = new Matrix4();

  return (state: State) =>
    matrix.fromArray(selectIElementWorldMatrix(id, transformOverrides)(state));
}

/**
 * Convert from left-handed quaternion to right-handed quaternion
 *
 * @param rot The quaternion referred to a left-handed coordinate system
 * @param target optional pre-allocated quaternion to store the result in
 * @returns The input quaternion referred to a right-handed coordinate system
 */
export function convertIElementToThreeRotationParsed(
  rot: IQuat | null | undefined,
  target = new Quaternion(),
): Quaternion {
  return target.fromArray(convertIElementToThreeRotation(rot));
}

/**
 * Convert from left-handed position to right-handed position
 *
 * @param pos The position referred to a left-handed coordinate system
 * @returns The input position referred to a right-handed coordinate system
 */
export function convertIElementToThreePositionParsed(
  pos: IVec3 | null | undefined,
): Vector3 {
  return new Vector3().fromArray(convertIElementToThreePosition(pos));
}
