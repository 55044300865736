import {
  EventType,
  ToggleMultiLayerMenuProperties,
} from "@/analytics/analytics-events";
import { LayersIcon, ToolButton } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { PopoverProps } from "@mui/material";
import { useCallback, useState } from "react";
import { MultiLayerMenu } from "./multi-layer-menu";

export type MultiLayerToolProps = {
  /** Reference to the element to anchor the popover to */
  anchorEl: PopoverProps["anchorEl"];
};

/**
 * @returns an icon button that open up a menu that allows to change which sheet should be rendered
 */
export function MultiLayerTool({ anchorEl }: MultiLayerToolProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback((shouldOpen: boolean) => {
    Analytics.track<ToggleMultiLayerMenuProperties>(
      EventType.toggleMultiLayerMenu,
      {
        opening: shouldOpen,
      },
    );

    setIsMenuOpen(shouldOpen);
  }, []);

  return (
    <>
      <ToolButton onClick={() => toggleMenu(!isMenuOpen)} selected={isMenuOpen}>
        <LayersIcon />
      </ToolButton>
      <MultiLayerMenu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => toggleMenu(false)}
      />
    </>
  );
}
