import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { Toolbar, ToolButton, ToolGroup } from "@faro-lotv/flat-ui";

type AlignmentViewLayoutToggleProps = {
  /** The selected alignment view layout type */
  alignmentLayout: AlignmentViewLayout;

  /** callback function to change alignment view layout */
  changeAlignmentScreenLayout(newAlignmentLayout: AlignmentViewLayout): void;
};

/**
 * @returns Toggle buttons to switch between split screen and overlay preview
 */
export function AlignmentViewLayoutToggle({
  alignmentLayout,
  changeAlignmentScreenLayout,
}: AlignmentViewLayoutToggleProps): JSX.Element {
  return (
    <Toolbar
      sx={{
        position: "absolute",
        top: 75,
        right: 20,
        width: "auto",
      }}
    >
      <ToolGroup
        value={alignmentLayout}
        onChange={(_, layoutType: AlignmentViewLayout | null) => {
          // layoutType can be null when the user clicks on the already selected button
          if (!layoutType) return;

          changeAlignmentScreenLayout(layoutType);
        }}
        orientation="horizontal"
        exclusive
      >
        <ToolButton
          value={AlignmentViewLayout.splitScreen}
          selected={alignmentLayout === AlignmentViewLayout.splitScreen}
          sx={{
            height: 35,
          }}
        >
          Split screen
        </ToolButton>

        <ToolButton
          value={AlignmentViewLayout.overlay}
          selected={alignmentLayout === AlignmentViewLayout.overlay}
          sx={{
            height: 35,
          }}
        >
          Overlay
        </ToolButton>
      </ToolGroup>
    </Toolbar>
  );
}
