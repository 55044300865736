import { isPerspectiveCamera } from "@/types/threejs-type-guards";
import { CAMERA_SYNC_PRIORITY } from "@faro-lotv/app-component-toolbox";
import { SphereControls, isSyncableControls } from "@faro-lotv/lotv";
import { useFrame, useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import { Camera, Euler, MathUtils, Quaternion, Vector3 } from "three";

// Angle the SphereControls will be clamped at, so they don't overshoot the poles.
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const maxVerticalSyncAngle = Math.PI / 2 - 0.2;

/** Variables and computations to compute the camera synchronization */
class CameraSyncLogic {
  rotationSourceSinceStart = new Quaternion();
  rotationTarget = new Euler();
  cameraLookAt = new Vector3();
  cameraFocalAxis = new Vector3();

  computeLookAt(
    syncSourceStartRotation: Quaternion,
    sourceQuaternion: Quaternion,
    syncTargetStartRotation: Quaternion,
    cameraPosition: Vector3,
  ): void {
    this.rotationSourceSinceStart
      .copy(syncSourceStartRotation)
      .invert()
      .multiply(sourceQuaternion)
      .premultiply(syncTargetStartRotation);

    this.rotationTarget.setFromQuaternion(
      this.rotationSourceSinceStart,
      // Use an order with X as the last component, to make it possible to clamp it
      "YZX",
    );

    // Clamp the rotation so it doesn't overshoot the poles
    this.rotationTarget.x = MathUtils.clamp(
      this.rotationTarget.x,
      -maxVerticalSyncAngle,
      maxVerticalSyncAngle,
    );

    // Use a lookAt so the camera stays level with the horizon
    this.cameraLookAt.copy(cameraPosition);
    // Three js cameras look towards -z.
    this.cameraFocalAxis.set(0, 0, -1).applyEuler(this.rotationTarget);
    this.cameraLookAt.add(this.cameraFocalAxis);
  }
}

type CameraSyncProps = {
  /** active camera driving movement  */
  source: Camera;

  /** if true, camera rotation should be synchronized with active camera */
  shouldSync: boolean;

  /** if true, camera FOV should be synchronized with active camera */
  shouldSyncFov: boolean;
};

/**
 * Syncs the local camera rotation to the global camera.
 *
 * @returns null
 */
export function CameraSync({
  source,
  shouldSync,
  shouldSyncFov,
}: CameraSyncProps): null {
  const camera = useThree((state) => state.camera);
  const controls = useThree((state) => state.controls);

  // Rotation states at the start of the synced rotation.
  const [syncSourceStartRotation, setSyncSourceStartRotation] = useState(
    () => new Quaternion(),
  );
  const [syncTargetStartRotation, setSyncTargetStartRotation] = useState(
    () => new Quaternion(),
  );

  // Difference in fov of both cameras at the start of the synced rotation
  const [syncOffsetFov, setSyncOffsetFov] = useState(0);

  // LayoutEffect to lock-in the current offsets before useFrame overwrites them again
  useLayoutEffect(() => {
    setSyncSourceStartRotation(source.quaternion.clone());
    setSyncTargetStartRotation(camera.quaternion.clone());

    let newSyncOffsetFov = 0;
    if (isPerspectiveCamera(camera) && isPerspectiveCamera(source)) {
      newSyncOffsetFov = camera.fov - source.fov;
    }

    setSyncOffsetFov(newSyncOffsetFov);
  }, [camera, source, shouldSync]);

  const [cameraSyncLogic] = useState(new CameraSyncLogic());

  // Synchronizes the camera with the source camera on each frame, applying the locked-in offsets
  useFrame(() => {
    if (!shouldSync || camera === source || controls === null) return;

    if (!isSyncableControls(controls)) {
      console.warn(
        `Camera Sync not implemented for controls of class ${controls.constructor.name}`,
      );
      return;
    }

    cameraSyncLogic.computeLookAt(
      syncSourceStartRotation,
      source.quaternion,
      syncTargetStartRotation,
      camera.position,
    );
    camera.lookAt(cameraSyncLogic.cameraLookAt);

    // Makes sure the controls resume from the correct rotation on the next user interaction
    controls.updateFromCamera();

    // WalkOrbitControls do not support camera FOV change.
    if (isPerspectiveCamera(camera) && isPerspectiveCamera(source)) {
      if (shouldSyncFov) {
        camera.fov = source.fov;
      } else if (controls instanceof SphereControls) {
        camera.fov = MathUtils.clamp(
          source.fov + syncOffsetFov,
          controls.minPerspFov,
          controls.maxPerspFov,
        );
      }
    }

    // Calling this hook with CAMERA_SYNC_PRIORITY, so it is executed after the controls
    // have moved the main camera and before camera monitoring and lod visibility updating tasks.
  }, CAMERA_SYNC_PRIORITY);

  return null;
}
