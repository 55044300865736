import { Upload3dDataDropHandler } from "@/components/common/upload-3d-data-drop-handler";
import { DataSessionsDropDown } from "@/components/ui/data-sessions-dropdown";
import { ToolsHelpBanners } from "@/components/ui/help-banners/tools-help-banners";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { ConnectPointCloud } from "./import-point-cloud/connect-point-cloud";

/**
 * @returns the Overlay for SheetMode
 */
export function SheetOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const { dataSessions2d, dataSessions5d } = useCurrentArea();
  const { referenceElement } = useCurrentScene();

  const options = useMemo(
    () => [...dataSessions2d, ...dataSessions5d],
    [dataSessions2d, dataSessions5d],
  );

  return (
    <Upload3dDataDropHandler>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1.25}>
            <DataSessionsDropDown
              datasets={options}
              referenceElement={referenceElement}
              onDataSessionChanged={(id) => dispatch(setActiveElement(id))}
            />
          </Stack>
          <ConnectPointCloud />
        </Stack>
        <ToolsHelpBanners />
      </Stack>
    </Upload3dDataDropHandler>
  );
}
