import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { Mode, ModeTransitionProps } from "@/modes/mode";
import { PointCloudObject, useCached3DObject } from "@/object-cache";
import { changeMode } from "@/store/mode-slice";
import { selectCloudForSheetToCloudAlignment } from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  resetSheetToCloudAlignment,
  setSheetElevationForSheetToCloudAlignment,
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectAncestor,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import { useEffect } from "react";
import { Vector3 } from "three";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { SheetToCloudAlignmentModeOverlay } from "./sheet-to-cloud-alignment-mode-overlay";
import { SheetToCloudAlignmentModeScene } from "./sheet-to-cloud-alignment-mode-scene";

export const sheetToCloudAlignmentMode: Mode = {
  name: "sheetToCloudAlignment",
  ModeScene: SheetToCloudAlignmentModeScene,
  ModeOverlay: SheetToCloudAlignmentModeOverlay,
  ModeTransition: SheetToCloudAlignmentModeTransition,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align sheet to point cloud",
    onBack({ dispatch }) {
      // if user canceled alignment by pressing back we need to reset
      // temporary data to prevent reusing it in the next session of alignment
      dispatch(resetSheetToCloudAlignment());

      dispatch(changeMode("start"));

      return Promise.resolve();
    },
  },
};

/**
 * @returns point cloud object that is used for Sheet to cloud alignment
 */
export function useCloudForSheetToCloudAlignment(): PointCloudObject {
  const cloudId = useAppSelector(selectCloudForSheetToCloudAlignment);
  const cloud = useAppSelector(selectIElement(cloudId));
  assert(
    cloud && isIElementGenericPointCloudStream(cloud),
    "Sheet to cloud Alignment Mode requires a valid cloud",
  );

  const pointCloud = useCached3DObject(cloud);
  return pointCloud;
}

function SheetToCloudAlignmentModeTransition({
  onCompleted,
}: ModeTransitionProps): null {
  const sheet = useSheetSelectedForAlignment("sheetToCloud");
  const sectionArea = useAppSelector(
    selectAncestor(sheet, isIElementAreaSection),
  );
  const sheetWorldPosition = useAppSelector(
    selectIElementWorldPosition(sheet.id),
  );

  const cloud = useCloudForSheetToCloudAlignment();
  const cloudBox = useObjectBoundingBox(cloud, cloud.iElement.id);
  assert(cloudBox, "Expected cloud has bounding box computed on loading");

  const dispatch = useAppDispatch();

  useEffect(() => {
    // make sure to start with the first step (set elevation)
    dispatch(
      setStepForSheetToCloudAlignment(SheetToCloudAlignmentStep.setElevation),
    );

    // Initialize sheet elevation in store with current sheet position in world if it was aligned.
    // If it was not aligned yet, use cloud center position
    const cloudCenter = cloudBox.getCenter(new Vector3());
    dispatch(
      setSheetElevationForSheetToCloudAlignment(
        sheet.pose ?? sectionArea?.pose ? sheetWorldPosition[1] : cloudCenter.y,
      ),
    );

    onCompleted();
  }, [cloudBox, dispatch, onCompleted, sectionArea, sheet, sheetWorldPosition]);

  return null;
}
