import { GUID } from "@faro-lotv/ielement-types";
import {
  DEFAULT_INITIAL_STATE,
  DefaultModeInitialState,
  InitialStateProps,
} from "../mode";

export type SheetModeInitialState = DefaultModeInitialState & {
  /**
   * Optional parameter to specify from a deep link that the sheet mode camera
   * should be centered on a path or on a 360.
   */
  lookAtId?: GUID;
};

/** An object to compute and parse a state object to create and restore the sheet mode states from deep links */
export const SHEET_MODE_INITIAL_STATE: InitialStateProps<SheetModeInitialState> =
  {
    compute(state, viewContext) {
      return {
        ...DEFAULT_INITIAL_STATE.compute(state, viewContext),
      };
    },
    parse(appState, modeState) {
      return {
        ...DEFAULT_INITIAL_STATE.parse(appState, modeState),
        lookAtId: modeState.lookAtId,
      };
    },
  };
