import { FaroText } from "@faro-lotv/flat-ui";
import {
  Box,
  Divider,
  Drawer,
  Paper,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { StepIcon } from "./step-icon";

/**
 * @returns a styled drawer for the quick help
 */
export function HelpDrawer({ children }: PropsWithChildren): JSX.Element {
  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        ["& .MuiDrawer-paper"]: {
          color: "gray850",
          backgroundColor: "gray50",
          width: 334,
          position: "unset",
        },
      }}
    >
      {children}
    </Drawer>
  );
}

type HelpHeaderProps = {
  /** Number to show inside the icon */
  stepNumber?: number;
};

/**
 * @returns a help header with proper styling applied
 */
export function HelpHeader({
  children,
  stepNumber,
}: PropsWithChildren<HelpHeaderProps>): JSX.Element {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {stepNumber && <StepIcon active icon={stepNumber} />}

      <Typography variant="h6">{children}</Typography>
    </Stack>
  );
}

type HelpParagraph = {
  /** Header to render for the paragraph */
  header?: string;

  /** Additional style to the header */
  headerSx?: SxProps<Theme>;

  /** Additional styles to the paragraph */
  sx?: SxProps<Theme>;
};

/**
 * @returns a help paragraph with proper styling applied
 */
export function HelpParagraph({
  children,
  header,
  headerSx,
  sx,
}: PropsWithChildren<HelpParagraph>): JSX.Element {
  return (
    <Stack
      sx={{
        my: 2,
        ["> .FaroText-bodyS, .FaroText-bodyM"]: {
          color: "gray700",
        },
        ...sx,
      }}
    >
      {header && (
        <FaroText variant="heading12" sx={headerSx}>
          {header}
        </FaroText>
      )}

      {children}
    </Stack>
  );
}

type HelpAnimationProps = {
  /** Url of the video file to play  */
  src: string;
};

/**
 * @returns a little video to show the user how to use an alignment function
 */
export function HelpAnimation({ src }: HelpAnimationProps): JSX.Element {
  const [controls, setControls] = useState(false);

  return (
    <Paper
      sx={{ width: "300px", height: "166px", marginTop: 2 }}
      elevation={0}
      onPointerEnter={() => setControls(true)}
      onPointerLeave={() => setControls(false)}
    >
      <video
        style={{ width: "100%", height: "100%" }}
        src={src}
        controls={controls}
        controlsList="nofullscreen"
        muted
      />
    </Paper>
  );
}

/**
 * @returns Padded container for a section in the help panel to be used between dividers.
 */
export function HelpSection({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        ml: 2,
        pr: 2,
        mt: 2,
        mb: 3,
      }}
    >
      {children}
    </Box>
  );
}

/**
 * @returns a divider line for the quick help panel
 */
export function HelpDivider(): JSX.Element {
  return <Divider sx={{ bgcolor: "gray850", opacity: 0.1, mb: 1 }} />;
}
