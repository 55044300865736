import { UnrecoverableError, unknownError } from "@/errors/unrecoverable-error";
import { ErrorDetailsPage } from "@faro-lotv/flat-ui";
import { useMemo } from "react";

type AppErrorPageProps = {
  /** Application error from which to take information to display. */
  error: Error;
};

/**
 * @returns a page, with a specific layout, that renders an image and some text with general information.
 * If an error is passed then its information are displayed.
 */
export function AppErrorPage({ error }: AppErrorPageProps): JSX.Element {
  // If a generic error reached this boundary let's wrap it in a UnrecoverableError
  // so we can use the same logic in the page for all errors
  const appError = useMemo(
    () => (error instanceof UnrecoverableError ? error : unknownError(error)),
    [error],
  );

  return (
    <ErrorDetailsPage
      errorStatement={appError.errorStatement}
      helpText={appError.helpText}
      errorName={appError.name}
      errorMessage={appError.errorMessage}
      actions={appError.actions}
      specialAction={appError.specialAction}
    />
  );
}
