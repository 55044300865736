import { SceneFilterToggle } from "@/components/common/scene-filter-toggle";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import { TypeToggleButtonsLayout } from "@/modes/walk-mode/walk-overlay-responsive-layout";
import {
  selectOverviewSceneFilter,
  setOverviewSceneFilter,
} from "@/store/modes/overview-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, deactivateTool } from "@/store/ui/ui-slice";
import { SceneFilter } from "@/types/scene-filter";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import { useCallback, useEffect } from "react";

type OverviewModeSceneFilterToggleProps = {
  /** Either the active cloud, or the active CAD if there is no active cloud */
  model: IElement;

  /** The layout configuration for the button group */
  layout: TypeToggleButtonsLayout;
};

/**
 * @returns component to render a toggle button to switch the selected model type in overview mode
 */
export function OverviewModeSceneFilterToggle({
  model,
  layout,
}: OverviewModeSceneFilterToggleProps): JSX.Element {
  const dispatch = useAppDispatch();

  const currentArea = useCurrentArea();
  const hasPointClouds =
    !!currentArea.dataSessions3d.length || !!currentArea.dataSessions5d.length;

  const mainElement = useCurrentScene().main;
  const activeTool = useAppSelector(selectActiveTool);

  const sceneFilter = useAppSelector(selectOverviewSceneFilter);

  // Purpose of this useEffect hook is that, if the user has
  // selected only cad and the active point cloud changes,
  // the point cloud should anyway be visualized. It is a
  // 'non-exhaustive' hook so that it does not run every time
  // 'sceneFilter' is changed.
  useNonExhaustiveEffect(() => {
    if (
      isIElementGenericPointCloudStream(model) &&
      sceneFilter === SceneFilter.Cad
    ) {
      dispatch(setOverviewSceneFilter(SceneFilter.Overlay));
    }
  }, [model, dispatch]);

  const onTypeChanged = useCallback(
    (value: SceneFilter) => {
      dispatch(setOverviewSceneFilter(value));
      // When the scene filter changes, the measurement tool is disabled.
      // Therefore we avoid the bug where the user starts a measurement
      // on the CAD, clicks "point cloud", and she cannot complete the
      // measurement because cross-model measures are forbidden.
      if (activeTool === ToolName.measurement) {
        dispatch(deactivateTool());
      }
    },
    [dispatch, activeTool],
  );

  useEffect(() => {
    // if there is CAD in the project but no point cloud switch view filter to "3D Model" mode
    if (!hasPointClouds) {
      dispatch(setOverviewSceneFilter(SceneFilter.Cad));
    }
  }, [dispatch, hasPointClouds]);

  return (
    <SceneFilterToggle
      value={sceneFilter}
      onValueChanged={onTypeChanged}
      onActiveElementChanged={(id) => dispatch(setActiveElement(id))}
      currentMainElement={mainElement}
      overlayEnabled={hasPointClouds}
      cadEnabled
      pointCloudEnabled={hasPointClouds}
      panoVisible={false}
      layout={layout}
    />
  );
}
