import { PropsWithChildren, useEffect } from "react";

export type MountNotifierProps = {
  /** A callback called when the component is mounted or unmounted */
  onMountChanged?(isMounted: boolean): void;
};

/**
 * @returns A component that will notify when it is mounted and when it is unmounted
 */
export function MountNotifier({
  children,
  onMountChanged,
}: PropsWithChildren<MountNotifierProps>): JSX.Element {
  useEffect(() => {
    onMountChanged?.(true);
    return () => {
      onMountChanged?.(false);
    };
    // We explicitly want to notify only on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Need to just render the children
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
