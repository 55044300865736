import { walkWithQueue } from "@faro-lotv/foundation";
import {
  IElementBase,
  IElementSection,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  IElementsRecord,
  TreeData,
  sheetsFilter,
} from "@faro-lotv/project-source";

/** ID given to the data sessions folder node in the area navigation tree */
export const DATA_SESSION_FOLDER_ID = "data-session-folder";

/** ID given to the data pano capture folder node in the area navigation tree */
export const PANO_FOLDER_ID = "pano-folder";

/**
 * @returns Generate the nodes to provide to the area navigation tree
 * @param dataSessions The list of datasets that should part of the tree
 * @param timeSeries The list of rooms that should be part of the tree
 * @param iElements All the iElements in the store
 */
export function generateAreaNavigationTree(
  dataSessions: IElementSection[],
  timeSeries: IElementBase[],
  iElements: IElementsRecord,
): TreeData[] {
  const dataSessionNodes = generateDataSessionsNodes(dataSessions, iElements);
  const panoNodes = generatePanoNodes(timeSeries, iElements);

  return [...dataSessionNodes, ...panoNodes];
}

/**
 * @returns The list of node data necessary to render the data sessions in the tree
 * @param dataSessions The list of data sessions that have to be shown in the tree
 * @param iElements The record of all iElements in the store
 */
function generateDataSessionsNodes(
  dataSessions: IElementSection[],
  iElements: IElementsRecord,
): TreeData[] {
  if (dataSessions.length === 0) return [];

  const nodes: TreeData[] = [
    {
      id: DATA_SESSION_FOLDER_ID,
      label: "Datasets",
      children: null,
    },
  ];
  for (const dataSession of dataSessions) {
    nodes.push({
      id: dataSession.id,
      label: dataSession.name,
      element: dataSession,
      children: null,
      directParent: dataSession.parentId
        ? iElements[dataSession.parentId]
        : undefined,
    });
  }
  return nodes;
}

/** Utility type for traversing the project hierarchy using a queue */
type QueueElement = {
  /** The iElement in the queue */
  element: IElementBase;
  /** The parent node in the tree of this element, if it exists */
  parentNode: TreeData | null;
  /** The actual parent IElement of this element, if it exists */
  parentIElement: IElementBase | undefined;
};

/**
 * @returns The list of node data necessary to render the pano rooms in the tree
 * @param elements The list of time series that have to be shown in the tree
 * @param allIElements The record of all iElements in the store
 */
function generatePanoNodes(
  elements: IElementBase[],
  allIElements: IElementsRecord,
): TreeData[] {
  if (elements.length === 0) return [];

  const treeNodes: TreeData[] = [
    {
      id: PANO_FOLDER_ID,
      label: "Pano captures",
      children: null,
    },
  ];

  const queue: QueueElement[] = elements.map((e) => ({
    element: e,
    parentNode: null,
    parentIElement: e.parentId ? allIElements[e.parentId] : undefined,
  }));

  walkWithQueue(queue, (queueElement, append) => {
    const { element, parentNode, parentIElement } = queueElement;

    /** The actual parent of the element in the tree structure */
    let parentInTree = parentNode;
    const { displayName, shouldKeepChildren, shouldKeepElement } = sheetsFilter(
      element,
      parentNode?.element?.type,
    );

    if (shouldKeepElement) {
      const node: TreeData = {
        id: element.id,
        label: displayName ?? element.name,
        element,
        directParent: parentIElement
          ? {
              type: parentIElement.type,
              typeHint: parentIElement.typeHint,
            }
          : undefined,
        children: null,
      };
      parentInTree = node;
      if (parentNode) {
        if (parentNode.children) {
          parentNode.children.push(node);
        } else {
          parentNode.children = [node];
        }
      } else {
        treeNodes.push(node);
      }
    }

    if (element.childrenIds?.length && shouldKeepChildren) {
      append(
        ...element.childrenIds
          .map((childId) => allIElements[childId])
          .filter(isValid)
          .map((childElement) => ({
            element: childElement,
            parentNode: parentInTree,
            parentIElement: element,
          })),
      );
    }
  });

  return treeNodes;
}
