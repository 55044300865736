import { Box } from "@mui/material";
import { cloneElement, ReactElement, useEffect, useRef, useState } from "react";

type TreeWrapperProps = {
  /** The tree to render */
  children: ReactElement;
};

/**
 * @returns Wrapper component that makes sure that the height of the trees are adjusted on window resize
 */
export function TreeWrapper({
  children,
}: TreeWrapperProps): JSX.Element | null {
  const treeContainerRef = useRef<HTMLDivElement>(null);
  const [treeHeight, setTreeHeight] = useState<number>();

  /**
   * Update the height of the tree to take the whole parent height on resize
   */
  useEffect(() => {
    function onResize(): void {
      setTreeHeight(treeContainerRef.current?.offsetHeight);
    }

    if (!treeContainerRef.current) return;

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(treeContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const actualTreeHeight = treeHeight ?? treeContainerRef.current?.offsetHeight;

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        flex: "1 1 0",
      }}
      ref={treeContainerRef}
    >
      {/* Take the passed children and inject the height props */}
      {cloneElement(children, {
        // when one tree becomes invisible, tree other tree's height may become 0. In this case, set the height as undefined
        height: actualTreeHeight === 0 ? undefined : actualTreeHeight,
      })}
    </Box>
  );
}
