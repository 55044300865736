import {
  EventType,
  OpenProjectEventProperties,
} from "@/analytics/analytics-events";
import { useAppSelector } from "@/store/store-hooks";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectAllIElementsOfType } from "@faro-lotv/project-source";
import { isEqual } from "lodash";
import { PropsWithChildren, useEffect } from "react";
import { useDeepLinkModeIfAvailable } from "../deep-link/deep-link-context";
import { ProjectLoadingContextProvider } from "./project-loading-context";
import { useProjectInitialData } from "./use-project-initial-data";
import { useUpdateProjectMetadata } from "./use-project-metadata";

export type ProjectProviderProps = {
  /** Id of the project to provide */
  projectId: GUID;

  /** If defined make sure the sub-tree containing this item is loaded */
  requiredItem?: GUID | null;

  /** Callback to signal if the project is ready to be used or still undefined or loading  */
  onReady?(isReady: boolean): void;
};

/**
 * Wrap a page using a project from the ProjectAPI ensuring the initial data is loaded
 * and allowing efficient fetching on additional parts of the project
 *
 * Need to be used inside an already authenticated context and inside an `ApiClientContextProvider`
 * to have the proper token data to talk to the ProjectApi
 *
 * @returns a context provider with everything needed to access and fetch more parts of the project
 */
export function ProjectProvider({
  projectId,
  requiredItem,
  onReady,
  children,
}: PropsWithChildren<ProjectProviderProps>): JSX.Element | null {
  const isMetadataReady = useUpdateProjectMetadata(projectId);
  const isProjectReady = useProjectInitialData(projectId, requiredItem);

  useTrackOpenProject(isProjectReady);

  useEffect(
    () => onReady?.(isProjectReady && isMetadataReady),
    [isProjectReady, isMetadataReady, onReady],
  );

  // Make sure the active element is ready in the store before continuing
  if (!isProjectReady || !isMetadataReady) return null;

  return (
    <ProjectLoadingContextProvider>{children}</ProjectLoadingContextProvider>
  );
}

/**
 * Tracks the analytics for opening a project
 *
 * @param isProjectReady whether the project is loaded
 */
function useTrackOpenProject(isProjectReady: boolean): void {
  const customMode = useDeepLinkModeIfAvailable();

  const allAreas = useAppSelector(
    selectAllIElementsOfType(isIElementAreaSection),
    isEqual,
  );

  // Should only log once per project load
  useNonExhaustiveEffect(() => {
    if (isProjectReady) {
      Analytics.track<OpenProjectEventProperties>(EventType.openProject, {
        isEmpty: allAreas.length === 0,
        mode: customMode ?? "default",
      });
    }
  }, [isProjectReady]);
}
