import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { DesaturationPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { OdometryPathsRenderer } from "@/components/r3f/renderers/odometry-paths/odometry-paths-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { PlaceholderPreview } from "@/components/r3f/utils/placeholder-preview";
import { useCached3DObjectIfExists } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import { selectModeIsTransitioning } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { PickingTools } from "@/tools/picking-tools";
import { usePickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import {
  selectIElementWorldPosition,
  useOnClick,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
} from "@faro-lotv/ielement-types";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useCallback, useState } from "react";
import { Box3, Vector3 } from "three";
import { SheetModeRenderOrders } from "./sheet-mode-render-orders";
import { SheetWaypoints } from "./sheet-waypoints";

type SheetModeSceneBaseProps = {
  /** Sheet to render */
  sheetElement?: IElementGenericImgSheet;

  /** The current active path */
  pathElement?: IElement;

  /** The list of paths to render */
  paths: IElementSection[];

  /** The list of panoramas currently showed */
  panos: IElementImg360[];

  /** The list of annotations to render on the map */
  annotations?: IElementGenericAnnotation[];

  /** The list of measurements from the store, to render on the map */
  measurements?: Measurement[];

  /** Flag to make the sheet transparent */
  transparentSheet?: boolean;

  /** Callback when a placeholder is clicked */
  onPlaceholderClick?(target: IElementImg360): void;

  /** Callback on path activated */
  onPathActivated?(
    ev: ThreeEvent<MouseEvent>,
    path: IElementSection,
    boundingBox: Box3,
  ): void;

  /** Callback when the sheet is clicked which gives the clicked position */
  onSheetClick?(pos: Vector3): void;

  /** Boolean to show/hide the placeholder preview */
  isPlaceholderPreviewVisible?: boolean;

  /** if true, allow picking and measurements */
  isPickingAllowed?: boolean;
};

/**
 * @returns the basic scene to render in sheet mode
 */
export function SheetModeSceneBase({
  sheetElement,
  pathElement,
  paths,
  panos,
  annotations,
  measurements,
  onPlaceholderClick,
  onPathActivated,
  onSheetClick,
  isPlaceholderPreviewVisible = false,
  isPickingAllowed = false,
  transparentSheet,
}: SheetModeSceneBaseProps): JSX.Element {
  // Get sheet from the object cache or suspend loading it
  const sheet = useCached3DObjectIfExists(sheetElement);

  const camera = useThree((s) => s.camera);

  const isTransitioning = useAppSelector(selectModeIsTransitioning);

  const position = useAppSelector(
    selectIElementWorldPosition(sheetElement?.id),
  );

  const activeTool = useAppSelector(selectActiveTool);

  const [hoveredPlaceholder, setHoveredPlaceholder] =
    useState<IElementImg360>();

  const clickOnPlaceholder = useCallback(
    (el: number | IElementImg360) => {
      if (typeof el === "number") onPlaceholderClick?.(panos[el]);
      else onPlaceholderClick?.(el);
    },
    [onPlaceholderClick, panos],
  );

  const onPlaceholderHovered = useCallback(
    (el?: number | IElementImg360) => {
      if (typeof el === "number") {
        setHoveredPlaceholder(panos[el]);
      } else {
        setHoveredPlaceholder(el);
      }
    },
    [panos],
  );

  const { tools, onModelHovered, onModelClicked, onModelZoomed } =
    usePickingToolsCallbacks();

  const { onPointerDown, onClick } = useOnClick(
    (e) => onSheetClick?.(e.point),
    true,
  );

  const [isPickingToolEnabled, setIsPickingToolEnabled] = useState(false);
  return (
    <>
      {sheet && (
        <>
          <SheetRenderer
            sheet={sheet}
            transparent={transparentSheet}
            onPointerMove={
              activeTool
                ? (ev) => onModelHovered(ev, sheet.iElement.id)
                : undefined
            }
            onPointerDown={activeTool ? undefined : onPointerDown}
            onClick={
              activeTool
                ? (ev) => onModelClicked(ev, sheet.iElement.id)
                : onClick
            }
            onWheel={
              activeTool
                ? (ev) => onModelZoomed(ev, sheet.iElement.id)
                : undefined
            }
          />

          {isPickingAllowed && (
            // TODO: in https://faro01.atlassian.net/browse/CADBIM-929
            // PickingTools from here will be moved to SheetScene
            <PickingTools
              ref={tools}
              activeModels={[sheet]}
              onToolActiveChanged={setIsPickingToolEnabled}
            />
          )}
        </>
      )}
      <SheetModeControls camera={camera} referencePlaneHeight={position[1]} />
      {!isTransitioning && !isPickingToolEnabled && (
        <>
          <OdometryPathsRenderer
            paths={paths}
            activeSheet={sheetElement}
            activePath={pathElement}
            onPlaceholderHovered={onPlaceholderHovered}
            onPlaceholderClick={clickOnPlaceholder}
            onPathClick={onPathActivated}
          />
          {isPlaceholderPreviewVisible && (
            <PlaceholderPreview placeholder={hoveredPlaceholder} />
          )}
          {annotations && (
            <AnnotationsRenderer
              annotations={annotations}
              depthTest={false}
              renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
              fadeOff={false}
            />
          )}
          <SheetWaypoints
            paths={paths}
            panos={panos}
            sheetElement={sheetElement}
            clickOnPlaceholder={clickOnPlaceholder}
            onPlaceholderHovered={onPlaceholderHovered}
          />
        </>
      )}
      {!isTransitioning && measurements && (
        <MeasurementsRenderer
          measurements={measurements}
          isToolActive={isPickingToolEnabled}
          depthTest={false}
          renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
        />
      )}

      {/*
       * The enabled flag from DesaturationPipeline is not used here so that the components using SheetModeSceneBase
       * need not have an instance of a EffectPipeline when there is no desaturateSheet tool in them
       *
       * Having this EffectPipeline along with another one in a R3f canvas or in a View
       * using SheetModeSceneBase would conflict with each other
       */}
      {sheetElement && (
        <DesaturationPipeline
          id={sheetElement.id}
          enabled={activeTool === ToolName.desaturateSheet}
        />
      )}
    </>
  );
}
