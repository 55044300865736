import {
  CameraAnimation,
  CameraAnimationData,
} from "@/components/r3f/animations/camera-animation";
import { Camera } from "@react-three/fiber";
import { useCallback, useState } from "react";
import { PerspectiveCamera } from "three";

type CameraAnimationReturn = {
  /** Animation element that should be rendered by the using component. Is null when no animation plays */
  animationToRender: JSX.Element | null;

  /** Function to start an animation */
  startAnimation(from: PerspectiveCamera, to: CameraAnimationData): void;

  /** The camera used for animation. Should be enabled for rendering by the using component. */
  animationCamera: Camera;
};

/**
 * @returns a hook to manage a single camera animation
 */
export function useCameraAnimation(): CameraAnimationReturn {
  const [animationCamera, setAnimationCamera] = useState<Camera>(
    new PerspectiveCamera(),
  );
  const [activeAnimation, setActiveAnimation] = useState<CameraAnimationData>();

  const startAnimation = useCallback(
    (from: Camera, to: CameraAnimationData) => {
      setAnimationCamera(from.clone());
      setActiveAnimation(to);
    },
    [],
  );

  const animationToRender = activeAnimation ? (
    <CameraAnimation
      camera={animationCamera}
      onAnimationFinished={() => setActiveAnimation(undefined)}
      {...activeAnimation}
    />
  ) : null;

  return { animationToRender, startAnimation, animationCamera };
}
