import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  ExplorationControls,
  SphereControls,
} from "@faro-lotv/app-component-toolbox";
import { PointerRotates } from "@faro-lotv/lotv";

export type WalkControlsProps = {
  /** Callback for when the user interacts with the walk mode 3d controls */
  onUserInteracted?(): void;

  /** True if the controls can change the camera position and not only the orientation */
  canMove: boolean;

  /** Callback issued when the user has started moving the camera, either via the pointer or via keys. */
  onCameraStartedTranslating?(): void;

  onCameraStoppedTranslating?(): void;
};

/** @returns a wrapper for @see SphereControls use for walk mode */
export function WalkControls({
  onUserInteracted,
  canMove,
  onCameraStartedTranslating,
  onCameraStoppedTranslating,
}: WalkControlsProps): JSX.Element {
  const activeTool = useAppSelector(selectActiveTool);

  if (canMove) {
    return (
      <ExplorationControls
        target={undefined}
        obstacles={[]}
        enableClickToFocus={false}
        enableDoubleClickToZoom={false}
        pointerRotates={PointerRotates.Model}
        onUserInteracted={onUserInteracted}
        onCameraStartedTranslating={onCameraStartedTranslating}
        onCameraStoppedTranslating={onCameraStoppedTranslating}
      />
    );
  }
  return (
    <SphereControls
      enabled={activeTool !== ToolName.annotation}
      isDefault
      onUserInteracted={onUserInteracted}
    />
  );
}
