// This is the first time importing useDispatch and useSelector, hence disabling eslint
import {
  // eslint-disable-next-line no-restricted-imports
  useDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector,
  useStore,
} from "react-redux";
import type { AppDispatch, RootState } from "./store";

/**
 * @returns Custom useDispatch hook that is typed with the app's store
 *
 * Use throughout your app instead of plain `useDispatch`
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

/**
 * Custom useSelector hook that is typed with the app's store
 *
 * Use throughout your app instead of plain `useSelector`
 */
export const useAppSelector = useSelector.withTypes<RootState>();

/**
 * Custom useStore hook that is typed with our RootSTate
 *
 * Use instead of useStore, all caution about useStore reactivity still applies
 */
export const useAppStore = useStore<RootState>;
