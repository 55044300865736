import { getQualityStatus } from "@/registration-tools/utils/metrics";
import { Stack } from "@mui/material";
import { ReactComponent as MinOverlap } from "../../icons/metrics-overlap-min-graphic.svg";
import { ReactComponent as MaxPointDistance } from "../../icons/metrics-point-distance-max-graphic.svg";
import { ReactComponent as MeanPointDistance } from "../../icons/metrics-point-distance-mean-graphic.svg";
import { CombinedMetrics } from "../../utils/multi-registration-report";
import { UnitType } from "../../utils/units";
import { RegistrationMetricCard } from "./registration-metrics";
import { useThresholdSetContext } from "./threshold-set-context";

/**
 * @returns The metrics for multipoint cloud registration result
 * Allows user to see the multipoint cloud registration result metrics
 */
export function MultiCloudRegistrationMetrics({
  averagePointError,
  maxPointError,
  minOverlap,
}: CombinedMetrics): JSX.Element {
  const { thresholdSet } = useThresholdSetContext();

  return (
    <Stack direction="column" alignItems="center" spacing={1}>
      <RegistrationMetricCard
        icon={MaxPointDistance}
        metricName="Maximum Point Distance"
        description="The maximum point distance is the highest value of all the point distances in the connections below. The point distance is the average distance over all matching points in a registered point cloud pair."
        value={maxPointError}
        unit={UnitType.length}
        quality={getQualityStatus(maxPointError, thresholdSet.pointDistance)}
      />
      <RegistrationMetricCard
        icon={MeanPointDistance}
        metricName="Mean Point Distance"
        description="The mean point distance is the average value of all the point distances in the connections below. The point distance is the average distance over all matching points in a registered point cloud pair."
        value={averagePointError}
        unit={UnitType.length}
        quality={getQualityStatus(
          averagePointError,
          thresholdSet.pointDistance,
        )}
      />
      <RegistrationMetricCard
        icon={MinOverlap}
        metricName="Minimum Overlap"
        description="The minimum overlap is the smallest overlap value occuring in all the connections below. The overlap value shows the overlap between a registered point cloud pair in percent."
        value={minOverlap}
        unit={UnitType.fraction}
        quality={getQualityStatus(minOverlap, thresholdSet.overlap)}
      />
    </Stack>
  );
}
