import { useCached3DObject } from "@/object-cache";
import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  selectSheetToCloudAlignmentLayout,
  selectSheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import { SheetToCloudAlignmentStep } from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { AlignSheetToCloudOverlayScene } from "./align-sheet-to-cloud-overlay-scene";
import { AlignSheetToCloudSplitScene } from "./align-sheet-to-cloud-split-scene";
import { useCloudForSheetToCloudAlignment } from "./sheet-to-cloud-alignment-mode";
import { SheetToCloudElevationScene } from "./sheet-to-cloud-elevation-scene";

/** @returns The model scene for the sheet to cloud alignment mode */
export function SheetToCloudAlignmentModeScene(): JSX.Element {
  const step = useAppSelector(selectSheetToCloudAlignmentStep);
  const alignmentViewLayout = useAppSelector(selectSheetToCloudAlignmentLayout);

  const cloudObject = useCloudForSheetToCloudAlignment();
  const sheet = useSheetSelectedForAlignment("sheetToCloud");
  const sheetObject = useCached3DObject(sheet);

  switch (step) {
    case SheetToCloudAlignmentStep.setElevation:
      return <SheetToCloudElevationScene />;
    case SheetToCloudAlignmentStep.alignIn2d:
      if (alignmentViewLayout === AlignmentViewLayout.splitScreen) {
        return (
          <AlignSheetToCloudSplitScene
            cloudObject={cloudObject}
            sheetObject={sheetObject}
          />
        );
      }
      return (
        <AlignSheetToCloudOverlayScene
          cloudObject={cloudObject}
          sheetObject={sheetObject}
        />
      );
  }
}
