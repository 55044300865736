import { useSceneEvents } from "@/components/common/scene-events-context";
import { useThreeContext } from "@/components/common/three-context/three-context";
import { CadModelObject } from "@/object-cache";
import {
  FastSSAOPass,
  FilteredRenderPass,
  SSAOPass,
  SubScene,
  useOnResize,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import {
  BasicRenderingPolicy,
  CadRenderingMode,
  LotvRenderer,
  StreamCadModel,
} from "@faro-lotv/lotv";
import { useEffect, useMemo } from "react";
import { Object3D } from "three";
import { RenderDispatch, setCadModelRenderDispatch } from "./render-dispatch";

const cadFilter = (obj: Object3D): boolean =>
  obj.userData.type === RenderDispatch.CadModel;

type CadSubsceneProps = {
  /** The CAD model to be rendered */
  cadModel?: CadModelObject | null;
  /** Whether the CAD subscene is enabled for rendering or not */
  enabled: boolean;
  /** Whether render-on-demand is enabled */
  renderOnDemand?: boolean;
};

/** @returns a subscene with a pipeline designed to render a CAD model */
export function CadSubscene({
  cadModel,
  enabled,
  renderOnDemand = true,
}: CadSubsceneProps): JSX.Element {
  // If the GPU supports it, use high-quality SSAO for CAD
  const { renderer } = useThreeContext();

  // basic rendering policy to implement render-on-demand
  const renderingPolicy = useMemo(
    () => new BasicRenderingPolicy(cadModel ?? undefined),
    [cadModel],
  );

  useEffect(() => {
    if (renderOnDemand) {
      renderingPolicy.onCameraStoppedMoving();
    } else {
      renderingPolicy.onCameraStartedMoving();
    }
  }, [renderingPolicy, renderOnDemand]);

  const invalidate = (): void => renderingPolicy.subScene?.invalidate();

  const cadSceneEvents = useSceneEvents();
  useTypedEvent<void>(cadSceneEvents.invalidateCadScene, invalidate);

  useTypedEvent<void>(
    cadModel instanceof StreamCadModel ? cadModel.chunkLoaded : undefined,
    invalidate,
  );

  useOnResize(invalidate);

  // Restore cad model rendering mode to the default state
  useEffect(() => {
    const previousCadModel = cadModel;
    if (previousCadModel) {
      return () => {
        previousCadModel.renderingMode = CadRenderingMode.OpaqueScene;
      };
    }
  }, [cadModel]);

  // The subscene returned contains a pipeline structured in three steps.
  // First the opaque CAD parts are rendered, then SSAO is applied, then
  // the transparent CAD parts are rendered.
  return (
    <SubScene
      filter={cadFilter}
      enabled={enabled}
      onBeforeRender={() => {
        if (cadModel) {
          cadModel.renderingMode = CadRenderingMode.OpaqueScene;
          setCadModelRenderDispatch(cadModel);
        }
      }}
      renderingPolicy={renderingPolicy}
    >
      {renderer instanceof LotvRenderer && renderer.supportsHighQualitySSAO ? (
        <SSAOPass strength={1.5} radius={1.5} />
      ) : (
        <FastSSAOPass strength={3.0} radius={1.5} />
      )}
      <FilteredRenderPass
        clear={false}
        clearDepth={false}
        filter={cadFilter}
        onBeforeRender={() => {
          if (cadModel) {
            cadModel.renderingMode = CadRenderingMode.TransparentScene;
          }
        }}
      />
    </SubScene>
  );
}
