import { ToolGroup, Toolbar } from "@faro-lotv/flat-ui";
import { useState } from "react";
import { MultiLayerTool } from "./multi-layer-tool";
import { TagsFilterTool } from "./tags-filter-tool";
import { ViewOptionsTool } from "./view-options-tool";

/** @returns a toolbar that contains the tools to manage view settings */
export function ViewSettingsToolbar(): JSX.Element | null {
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);

  return (
    <Toolbar
      ref={setToolbarRef}
      sx={{ alignSelf: "flex-end", overflow: "visible" }}
    >
      <ToolGroup>
        <ViewOptionsTool anchorEl={toolbarRef} />
        <TagsFilterTool anchorEl={toolbarRef} />
        <MultiLayerTool anchorEl={toolbarRef} />
      </ToolGroup>
    </Toolbar>
  );
}
