import { ElementIconType } from "@/components/ui/icons";
import { redirectToMultiRegistrationTool } from "@/utils/redirects";
import { assert } from "@faro-lotv/foundation";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

/** Action to inspect all point clouds of a data session and to publish them as a merged dataset. */
export const INSPECT_AND_PUBLISH_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.inspectAndPublish,
  label: "Inspect and Publish",
  icon: ElementIconType.FindCheckmarkIcon,
  // eslint-disable-next-line require-await -- Required to make the types match
  handler: async ({ apiClients, elementID }) => {
    const registrationApi = apiClients.registrationApiClient;
    assert(registrationApi, "Registration API must be available");

    redirectToMultiRegistrationTool({
      projectId: apiClients.projectApiClient.projectId,
      datasetId: elementID,
    });
  },
};
