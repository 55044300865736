import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIsAnnotationExpanded } from "@/store/ui/ui-selectors";
import { setAnnotationExpansion } from "@/store/ui/ui-slice";
import type { AnnotationViewerVariants } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { useCallback, useEffect, useState } from "react";

export interface HandleAnnotationViewerVariantReturn {
  /** Current variant of the annotation details */
  AnnotationViewerVariant: AnnotationViewerVariants;

  /**
   * Callback to call when the annotation details is hovered
   * Toggles between "small" and "title" variants of the annotation details based on the hover state
   */
  onAnnotationViewerHovered(isHovered: boolean): void;

  /**
   * Callback to call when the annotation details expand button is clicked
   * Updates the annotation details variant to be "full"
   */
  onAnnotationViewerExpanded(): void;

  /**
   * Callback to call when the annotation details close button is clicked
   * Updates the annotation details variant to be "title"
   */
  onAnnotationViewerClosed(): void;
}

/**
 * @param id of the annotation
 * @param isCollapsed Whether the an annotation should be collapsed to its small icon state. Only applies when not hovered or expanded.
 * @param isHidden Whether the annotation should be completely hidden. Only applies when collapsed
 * @returns handles the variant state changes of the annotation details by providing appropriate event handlers
 */
export function useHandleAnnotationViewerVariant(
  id: GUID,
  isCollapsed: boolean,
  isHidden: boolean,
): HandleAnnotationViewerVariantReturn {
  const isExpanded = useAppSelector(selectIsAnnotationExpanded(id));
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const isHoverable = !isCollapsed && !isHidden;

  const onAnnotationViewerHovered = useCallback(
    (newIsHovered: boolean) => {
      setIsHovered(isHoverable && newIsHovered);
    },
    [isHoverable],
  );

  // This makes sure collapsed/hidden items are always un-hovered. As collapsing can mess up the pointer events on removed dom elements.
  useEffect(() => {
    if (!isHoverable && isHovered) {
      setIsHovered(false);
    }
  }, [isHoverable, isHovered]);

  const onAnnotationViewerExpanded = useCallback(
    () => dispatch(setAnnotationExpansion({ id, expanded: true })),
    [dispatch, id],
  );

  const onAnnotationViewerClosed = useCallback(
    () => dispatch(setAnnotationExpansion({ id, expanded: false })),
    [dispatch, id],
  );

  let AnnotationViewerVariant: AnnotationViewerVariants;
  if (isExpanded) {
    AnnotationViewerVariant = "full";
  } else if (isHovered) {
    AnnotationViewerVariant = "small";
  } else if (isHidden) {
    AnnotationViewerVariant = "hidden";
  } else if (isCollapsed) {
    AnnotationViewerVariant = "collapsed";
  } else {
    AnnotationViewerVariant = "title";
  }

  return {
    AnnotationViewerVariant,
    onAnnotationViewerHovered,
    onAnnotationViewerExpanded,
    onAnnotationViewerClosed,
  };
}
