import { selectAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import {
  addAnalysis,
  PointCloudAnalysis,
  PointCloudAnalysisLabel,
  PointCloudAnalysisPlane,
} from "@/store/point-cloud-analysis-tool-slice";
import { AppDispatch, RootState } from "@/store/store";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  GUID,
  PropOptional,
  PropRequired,
  robustFetch,
  validateArrayOf,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import { IElementAnalysis } from "@faro-lotv/ielement-types";
import { Matrix4, Quaternion, Vector3, Vector3Tuple } from "three";

/**
 * The point cloud color map analysis setting that will be saved in json file
 *
 * The difference between ColorMapAnalysisData and PointCloudAnalysis is as follows:
 * 1. id in PointCloudAnalysis will be used as analysis IElement id, no need to save it in json file
 * 2. everything in PointCloudAnalysis is in world coordinate system while in ColorMapAnalysisData,
 *    they are all in local coordinate relative to analysis IElement
 */
type ColorMapAnalysisData = Omit<
  PointCloudAnalysis,
  "id" | "isVisible" | "isDirty"
>;

/**
 * convert given PointCloudAnalysis into ColorMapAnalysisData and create the json file
 *
 * @param analysis the given PointCloudAnalysis object
 * @param worldTransform the world matrix of IElementAnalysis
 * @returns json file of the color map analysis data in local coordinate space
 */
export function createAnalysisJsonFile(
  analysis: PointCloudAnalysis,
  worldTransform: Matrix4,
): File {
  const colorMapAnalysisData = transformPointCloudAnalysisObject(
    analysis,
    worldTransform,
  );

  const jsonData = JSON.stringify(colorMapAnalysisData);

  return new File([jsonData], "analysis.json", {
    type: "text/plain",
  });
}

/**
 * @param iElements the given analysis ielement array
 *  @param appState The app state
 * @param dispatch the dispatch function to update the current state
 */
export function loadAnalysis(
  iElements: IElementAnalysis[],
  appState: RootState,
  dispatch: AppDispatch,
): void {
  iElements.forEach(async (iElement) => {
    // If the analysis is not in the store yet, fetch the json file and add the analysis in the store.
    // Do nothing if the analysis already exist in the store.
    if (selectAnalysis(iElement.id)(appState) === undefined) {
      const res = await robustFetch(iElement.uri);
      if (!res.ok) {
        console.error(res.statusText);
        throw new Error("Error while fetching analysis");
      }
      const analysisData = await res.json();

      if (isColorMapAnalysisData(analysisData)) {
        const worldMatrix = selectIElementWorldMatrix4(iElement.id)(appState);
        const analysis = transformColorMapAnalysisData(
          iElement.id,
          analysisData,
          worldMatrix,
        );
        dispatch(addAnalysis({ pointCloudID: analysis.parentId, analysis }));
      }
    }
  });
}

/**
 * transform a given PointCloudAnalysis object from world space to local space and construct
 * ColorMapAnalysisData object to be save in json file
 *
 * @param analysis the PointCloudAnalysis object to transform
 * @param worldTransform the world matrix used to transform the PointCloudAnalysis object
 * @returns the ColorMapAnalysisData object to be saved in json file in local coordinate space
 */
function transformPointCloudAnalysisObject(
  analysis: PointCloudAnalysis,
  worldTransform: Matrix4,
): ColorMapAnalysisData {
  const matrix = worldTransform.clone().invert();

  const transformedData = getTransformedData(
    matrix,
    analysis.polygonSelection,
    analysis.labels,
    analysis.fittedPlane,
    analysis.fittedPlumbPlane,
  );

  return {
    polygonSelection: transformedData.polygon,
    tolerance: analysis.tolerance,
    parentId: analysis.parentId,
    referencePlaneType: analysis.referencePlaneType,
    elevation: analysis.elevation,
    showReferencePlane: analysis.showReferencePlane,
    colormap: analysis.colormap,
    fittedPlane: transformedData.fittedPlane,
    fittedPlumbPlane: transformedData.fittedPlumbPlane,
    labels: transformedData.labels,
  };
}

/**
 * transform a given ColorMapAnalysisData object from local space to world space and construct
 * PointCloudAnalysis object to be used in app
 *
 * @param id the GUID of the PointCloudAnalysis object
 * @param analysisData the given ColorMapAnalysisData object fetched from json file
 * @param worldTransform the world matrix used to transform the ColorMapAnalysisData object
 * @returns the PointCloudAnalysis object in world space
 */
function transformColorMapAnalysisData(
  id: GUID,
  analysisData: ColorMapAnalysisData,
  worldTransform: Matrix4,
): PointCloudAnalysis {
  const transformedData = getTransformedData(
    worldTransform,
    analysisData.polygonSelection,
    analysisData.labels,
    analysisData.fittedPlane,
    analysisData.fittedPlumbPlane,
  );

  return {
    polygonSelection: transformedData.polygon,
    tolerance: analysisData.tolerance,
    parentId: analysisData.parentId,
    referencePlaneType: analysisData.referencePlaneType,
    elevation: analysisData.elevation,
    showReferencePlane: analysisData.showReferencePlane,
    colormap: analysisData.colormap,
    fittedPlane: transformedData.fittedPlane,
    fittedPlumbPlane: transformedData.fittedPlumbPlane,
    isDirty: false,
    isVisible: true,
    id,
    labels: transformedData.labels,
  };
}

type TransformedResult = {
  polygon: Vector3Tuple[];
  fittedPlane?: PointCloudAnalysisPlane;
  fittedPlumbPlane?: PointCloudAnalysisPlane;
  labels: PointCloudAnalysisLabel[];
};

/**
 * @param matrix transformation matrix to apply
 * @param polygon the given polygon to apply the given transformation matrix
 * @param labels the given deviation labels to apply the given transformation matrix
 * @param fittedPlane the optional fitted plane to apply the given transformation matrix
 * @param fittedPlumbPlane the optional fitted plumb plane to apply the given transformation matrix
 * @returns the transformed result after applying transform matrix to the given data
 */
export function getTransformedData(
  matrix: Matrix4,
  polygon: Vector3Tuple[],
  labels: PointCloudAnalysisLabel[],
  fittedPlane?: PointCloudAnalysisPlane,
  fittedPlumbPlane?: PointCloudAnalysisPlane,
): TransformedResult {
  const TEMP_VEC1 = new Vector3();
  const TEMP_VEC2 = new Vector3();
  const rotation = new Quaternion();
  matrix.decompose(TEMP_VEC1, rotation, TEMP_VEC2);

  const transformedPolygon = polygon.map((p) =>
    TEMP_VEC1.fromArray(p).applyMatrix4(matrix).toArray(),
  );

  const transformedFittedPlane = fittedPlane
    ? {
        normal: TEMP_VEC1.fromArray(fittedPlane.normal)
          .applyQuaternion(rotation)
          .toArray(),
        point: TEMP_VEC1.fromArray(fittedPlane.point)
          .applyMatrix4(matrix)
          .toArray(),
      }
    : undefined;

  const transformedFittedPlumbPlane = fittedPlumbPlane
    ? {
        normal: TEMP_VEC1.fromArray(fittedPlumbPlane.normal)
          .applyQuaternion(rotation)
          .toArray(),
        point: TEMP_VEC1.fromArray(fittedPlumbPlane.point)
          .applyMatrix4(matrix)
          .toArray(),
      }
    : undefined;

  const transformedLabels = labels.map((label) => ({
    ...label,
    position: TEMP_VEC1.fromArray(label.position)
      .applyMatrix4(matrix)
      .toArray(),
  }));

  return {
    polygon: transformedPolygon,
    fittedPlane: transformedFittedPlane,
    fittedPlumbPlane: transformedFittedPlumbPlane,
    labels: transformedLabels,
  };
}

/**
 * ColorMapAnalysisData type guard
 *
 * @param data The generic input record
 * @returns true if the generic input record describes ColorMapAnalysisData object
 */
function isColorMapAnalysisData(
  data: Record<string, unknown>,
): data is ColorMapAnalysisData {
  return (
    validateArrayOf({
      object: data,
      prop: "polygonSelection",
      elementGuard: isVector3Tuple,
    }) &&
    validatePrimitive(data, "tolerance", "number", PropRequired) &&
    validatePrimitive(data, "parentId", "string", PropRequired) &&
    validatePrimitive(data, "elevation", "number", PropRequired) &&
    validatePrimitive(data, "referencePlaneType", "string", PropRequired) &&
    validatePrimitive(data, "showReferencePlane", "boolean", PropOptional) &&
    validateArrayOf({
      object: data,
      prop: "colormap",
      elementGuard: (element) =>
        validatePrimitive(element, "value", "number", PropRequired) &&
        validatePrimitive(element, "color", "string", PropRequired),
    }) &&
    validateOfType(
      data,
      "fittedPlane",
      isPointCloudAnalysisPlane,
      PropOptional,
    ) &&
    validateOfType(
      data,
      "fittedPlumbPlane",
      isPointCloudAnalysisPlane,
      PropOptional,
    ) &&
    validateArrayOf({
      object: data,
      prop: "labels",
      elementGuard: (element) =>
        validatePrimitive(element, "id", "string", PropRequired) &&
        validateNotNullishObject(element, "position") &&
        validateOfType(element, "position", isVector3Tuple, PropRequired),
    })
  );
}

function isVector3Tuple(obj: unknown): obj is Vector3Tuple {
  if (!Array.isArray(obj) || obj.length !== 3) return false;

  return obj.every((child) => typeof child === "number");
}

function isPointCloudAnalysisPlane(
  data: unknown,
): data is PointCloudAnalysisPlane {
  return (
    validateNotNullishObject(data, "PointCloudAnalysisPlane") &&
    validateOfType(data, "normal", isVector3Tuple, PropOptional) &&
    validateOfType(data, "point", isVector3Tuple, PropOptional)
  );
}
