import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  ArrowDownIcon,
  FaroIconButton,
  neutral,
  selectIElement,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import {
  AlignmentElementLabel,
  LabelType,
} from "../alignment-modes-commons/alignment-element-label";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ReferenceSelectorTool } from "./align-wizard-reference-selector-tool";

/**
 * @returns Renderer of split screen for Alignment Wizard
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side views of aligned and reference elements
 * - Facilitates selection of appropriate elements for alignment
 */
export function AlignWizardSplitScreen(): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const alignedElement = useAppSelector(selectIElement(elementToAlignId));

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const referenceElement = useAppSelector(selectIElement(referenceElementId));

  const [isSelectorCollapsed, setCollapsed] = useState(false);

  return (
    <Stack
      direction="row"
      justifyItems="stretch"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {alignedElement && (
          <AlignmentElementLabel
            element={alignedElement}
            labelType={LabelType.alignedElement}
            sx={{
              top: "90px",
              left: "30px",
            }}
          />
        )}
      </ViewDiv>

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {referenceElement && isSelectorCollapsed && (
          <AlignmentElementLabel
            element={referenceElement}
            labelType={LabelType.referenceElement}
            sx={{
              top: "90px",
              left: "30px",
            }}
          />
        )}

        {!isSelectorCollapsed && alignedElement && <ReferenceSelectorTool />}
      </ViewDiv>

      <Box
        component="div"
        sx={{
          position: "absolute",
          left: "50%",
          top: "80px",
        }}
      >
        <FaroIconButton
          sx={{
            position: "absolute",
            left: "-20px",
            backgroundColor: neutral[0],
            "&:hover": {
              background: `${neutral[100]}`,
            },
          }}
          onClick={() => {
            setCollapsed(!isSelectorCollapsed);
          }}
          title={
            isSelectorCollapsed
              ? "Expand selector tool"
              : "Collapse selector tool"
          }
        >
          <ArrowDownIcon
            sx={{
              transform: isSelectorCollapsed
                ? "rotate(-90deg)"
                : "rotate(90deg)",
              transition: "transform 0.1s linear",
            }}
          />
        </FaroIconButton>
      </Box>
    </Stack>
  );
}
