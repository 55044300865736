import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  computeNdcCoordinates,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { Stack } from "@mui/material";
import { CalculatePosition } from "@react-three/drei/web/Html";
import { useThree } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { DoubleSide, Raycaster, Vector2, Vector3 } from "three";
import { AppAwareHtml } from "../r3f/renderers/app-aware-html";

/** Label for the three axis of position gizmo*/
const AXIS_LABELS = ["x:", "y:", "z:"];

/** Size of the position gizmo */
const GIZMO_SIZE = 0.05;

/** Object name for the position gizmo */
const GIZMO_NAME = "PointerFeedbackGizmo";

/** Minimum distance in pixel to consider a pointer interaction a drag and not a click */
const MIN_DRAG_LENGTH = 5;

/**
 * @returns a wrapper to track mouse clicks on the scene if enabled as a test feature
 */
export function PointerFeedback({
  children,
}: PropsWithChildren): JSX.Element | null {
  const isEnabled = useAppSelector(selectHasFeature(Features.PointerFeedback));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isEnabled) return <>{children}</>;

  return <PointerFeedbackImpl>{children}</PointerFeedbackImpl>;
}

/** @returns a component to track mouse events on the scene and report them */
function PointerFeedbackImpl({ children }: PropsWithChildren): JSX.Element {
  /** Last tracked pointer position */
  const [lastHit, setLastHit] = useState<Vector3>();

  /** True to track mouse movement and not only clicks */
  const [shouldTrackMovement, toggleTrackMovement] = useReducer(
    (val) => !val,
    false,
  );

  /** True to show a small sphere gizmo in the last tracked position */
  const [shouldShowGizmo, toggleShowGizmo] = useReducer((val) => !val, false);
  const target = useRef(useThreeEventTarget());

  // Objects needed for custom raycast logic
  const camera = useThree((s) => s.camera);
  const scene = useThree((s) => s.scene);
  const [raycaster] = useState(() => new Raycaster());
  const pointerDownPos = useRef(new Vector2());

  const trackMouseDown = useCallback((ev: DomEvent): void => {
    pointerDownPos.current.set(ev.clientX, ev.clientY);
  }, []);

  // Compute the new hit position from a mouse event on the main canvas
  const updateLastHit = useCallback(
    (ev: DomEvent): void => {
      const ndc = computeNdcCoordinates(ev.clientX, ev.clientY, target.current);
      raycaster.setFromCamera(ndc, camera);
      const hits = raycaster.intersectObject(scene);
      const hit = hits.find((hit) => hit.object.name !== GIZMO_NAME);
      if (hit) {
        setLastHit(hit.point);
      }
    },
    [camera, raycaster, scene],
  );

  const onClick = useCallback(
    (ev: DomEvent): void => {
      const clickPos = new Vector2(ev.clientX, ev.clientY);
      if (pointerDownPos.current.distanceTo(clickPos) > MIN_DRAG_LENGTH) return;
      updateLastHit(ev);
    },
    [updateLastHit],
  );

  // Attach the updateLastHit callback to canvas click events
  useEffect(() => {
    const canvas = target.current;
    canvas.addEventListener("pointerdown", trackMouseDown);
    canvas.addEventListener("click", onClick);
    return () => {
      canvas.removeEventListener("pointerdown", trackMouseDown);
      canvas.removeEventListener("click", onClick);
    };
  }, [onClick, trackMouseDown]);

  // Attach the updateLastHit callback to pointer move events if desired
  useEffect(() => {
    if (!shouldTrackMovement) return;
    const canvas = target.current;
    canvas.addEventListener("pointermove", updateLastHit);

    return () => {
      canvas.removeEventListener("pointermove", updateLastHit);
    };
  }, [shouldTrackMovement, updateLastHit]);

  /** Calculate the ui overlay position to be on the top right of the viewport */
  const calculatePosition = useCallback<CalculatePosition>(
    (el, camera, size) => {
      // Manually position the panel in the top right corner
      const WIDTH_FACTOR = 0.75;
      const HEIGHT_FACTOR = 0.1;
      return [size.width * WIDTH_FACTOR, size.height * HEIGHT_FACTOR];
    },
    [],
  );

  return (
    <>
      {children}
      <AppAwareHtml portal={target} calculatePosition={calculatePosition}>
        <Stack
          direction="column"
          sx={{ width: "100vw", background: "lightgray" }}
        >
          <legend>Pointer Feedback</legend>
          <Stack
            direction="row"
            alignItems="center"
            onClick={toggleTrackMovement}
          >
            <input checked={shouldTrackMovement} type="checkbox" /> Track
            Movement (not only clicks)
          </Stack>
          <Stack direction="row" alignItems="center" onClick={toggleShowGizmo}>
            <input checked={shouldShowGizmo} type="checkbox" /> Show Gizmo
          </Stack>
          {lastHit
            ?.toArray()
            .map((val, index) => (
              <div key={index}>{`${AXIS_LABELS[index]} ${val.toFixed(5)}`}</div>
            ))}
        </Stack>
      </AppAwareHtml>
      {shouldShowGizmo && lastHit && (
        <mesh position={lastHit} renderOrder={100} name={GIZMO_NAME}>
          <sphereGeometry args={[GIZMO_SIZE]} />
          <meshBasicMaterial color="red" depthTest={false} side={DoubleSide} />
        </mesh>
      )}
    </>
  );
}
