import { IElement } from "@faro-lotv/ielement-types";
import { RootState } from "../store";
import { Tag, UNTAGGED } from "./tags-slice";

/**
 * @returns All the tags in the project
 */
export function selectTags({ tags }: RootState): Tag[] {
  return tags.tags;
}

/**
 * @returns The list of tags selected by the user
 */
export function selectSelectedTags({ tags }: RootState): Tag[] {
  return tags.selectedTags;
}

/**
 *
 * @param elements list of elements to filter
 * @param selectReferenceElement function used to find an element of a specific type.
 * If defined it means that the tags are contained in an ancestor or child of the passed elements
 * @returns a filtered version of the passed list of element. It's filtered based on if any of the tags of the element is also present in
 * the list of selected tags by the user
 */
export function selectFilteredElementsWithTags<
  T extends IElement,
  K extends IElement,
>(
  elements: T[],
  selectReferenceElement?: (element: T) => (store: RootState) => K | undefined,
): (state: RootState) => T[] {
  return (state: RootState): T[] => {
    // If the predicate is defined then get the tagged IElement, otherwise use directly the passed list
    const elementsToFilter = selectReferenceElement
      ? elements.flatMap((el) => selectReferenceElement(el)(state))
      : elements;

    // Get the selected tags from the store
    const selectedTagsIds = selectSelectedTags(state).map((tag) => tag.id);

    // Filtered list of the passed elements
    let filteredElements: T[] = [];

    // If the store does not have any selected tags, then return the passed list of elements
    if (selectedTagsIds.length === 0) {
      filteredElements = elements;
    } else {
      for (const [idx, el] of elementsToFilter.entries()) {
        if (!el) {
          continue;
        }

        // Get the ids of the tags of the current element in the list
        const labelsIds = el.labels?.map((label) => label.id);

        // If the current element does not have tags (it's untagged) and the selected tags in the store
        // have the UNTAGGED tag, then keep the element so that it's returned.
        // Otherwise, if any tag present in the list of tags of the current element is also present in the list of
        // selected tags in the store, then keep the element so that it's returned.
        if (
          ((!el.labels || el.labels.length === 0) &&
            selectedTagsIds.includes(UNTAGGED.id)) ||
          labelsIds?.some((labelId) => selectedTagsIds.includes(labelId))
        ) {
          filteredElements.push(elements[idx]);
        }
      }
    }

    return filteredElements;
  };
}
