import { selectDefaultModeFor } from "@/modes";
import { ModeNames } from "@/modes/mode";
import { isMainModel } from "@/modes/mode-selectors";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericAnnotation,
  isIElementGenericAnnotation,
  isIElementImg360,
  isIElementSection,
  isIElementSectionDataSession,
  isSpaceAnnotation,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { ModeInitialState } from "./mode-slice";
import { RootState } from "./store";

/**
 * @returns the name of the current active mode
 * @param root The root state
 */
export function selectModeName(root: RootState): ModeNames {
  return root.mode.name;
}

/**
 * @returns true if the mode is suspended to load new data
 * @param root The root state
 */
export function selectModeIsLoadingData(root: RootState): boolean {
  return root.mode.isLoadingData;
}

/**
 * @returns true if the mode is suspended to load new data
 * @param root The root state
 */
export function selectModeIsTransitioning(root: RootState): boolean {
  return root.mode.isTransitioning;
}

/**
 * @returns the initial state for the next mode to transition to
 */
export function selectModeInitialState({
  mode,
}: RootState): ModeInitialState | undefined {
  return mode.initialState;
}

/**
 * @returns the best active element to render a scene to look at the given element id
 * @param lookAtId id of the element the user want to look at
 */
export function selectActiveElementFromLookAtId(lookAtId?: GUID) {
  return (state: RootState): IElement | undefined => {
    if (!lookAtId) return;

    const lookAt = selectIElement(lookAtId)(state);
    if (!lookAt) return;

    // If the element to look at is something we can render, set it as the active element
    if (isMainModel(lookAt)) {
      return lookAt;
    }

    // Check if the lookAt element is an annotation and compute a proper element to look at that annotation
    const annotation = selectAncestor(
      lookAt,
      isIElementGenericAnnotation,
    )(state);
    if (annotation) {
      return selectActiveElementForAnnotation(annotation)(state);
    }

    // If the lookAt element is neither an element that can be rendered nor an annotation, just select the parent data session if possible
    return selectAncestor(lookAt, isIElementSectionDataSession)(state);
  };
}

/**
 * @returns the best active element to use to check the passed in annotation
 * @param annotation the user want to look at
 */
export function selectActiveElementForAnnotation(
  annotation: IElementGenericAnnotation,
) {
  return (state: RootState): IElement | undefined => {
    // This can be an area, or a scan
    const parentSection = selectAncestor(annotation, isIElementSection)(state);

    // If the parentSection contains a pano image then treat it as a pano capture and set the pano as the active element
    const pano = selectChildDepthFirst(
      parentSection,
      isIElementImg360,
      1,
    )(state);
    if (pano) return pano;

    // If the parentSection does not contain a pano image use the section as the active element and allow the app to pick
    // the best rendering item for that context
    return parentSection;
  };
}

/**
 * @param activeElement for the mode
 * @param lookAtId id of th element to look at
 * @returns the best mode to render a scene with the specified active and lookAt elements
 */
export function selectModeFromElement(
  activeElement: IElement,
  lookAtId?: GUID,
) {
  return (state: RootState): ModeNames | undefined => {
    const lookAt = lookAtId && selectIElement(lookAtId)(state);
    // If the lookAt element is an annotation and the active element is not a 360s capture
    // the better mode to use depends on the type of the annotation
    if (
      isIElementSection(activeElement) &&
      lookAt &&
      isIElementGenericAnnotation(lookAt)
    ) {
      return isSpaceAnnotation(lookAt) ? "overview" : "sheet";
    }

    // Otherwise it depends only by the type of the active element
    return selectDefaultModeFor(activeElement)(state)?.targetMode;
  };
}
