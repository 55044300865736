import { OpenToastOptions } from "@faro-lotv/flat-ui";

/**
 * @param image to be used in toast based on the registration result
 * @param message message to be displayed based on the registration result
 * @param action function to be called on corresponding action click
 * @returns the feedback in toast when registration is successful
 */
export function getRegistrationFeedbackOnSuccess(
  image: React.FunctionComponent<React.SVGAttributes<SVGElement>>,
  message: string,
  action: () => void,
): OpenToastOptions {
  return {
    title: "Automatic registration successful",
    message: `Quality : ${message}`,
    variant: "success",
    persist: true,
    image,
    action: {
      label: "See Details",
      onClicked: action,
    },
  };
}

/**
 * @returns the feedback in toast when the registration is failed
 */
export function getRegistrationFeedbackOnFail(): OpenToastOptions {
  return {
    title: "Automatic registration failed",
    message:
      "Do a visual registration first, then run the automatic registration again",
    variant: "error",
  };
}

/**
 * @returns the feedback in toast when registration result is not as expected
 */
export function getRegistrationFeedbackOnError(): OpenToastOptions {
  return {
    title: "Error in automatic registration",
    message:
      "Unable to apply result. Please run the automatic registration again.",
    variant: "error",
  };
}
