import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  MeasurementMarkerIcon,
} from "@faro-lotv/app-component-toolbox";
import Box from "@mui/material/Box";
import { Html } from "@react-three/drei";
import { MutableRefObject } from "react";
import { Vector3 } from "three";
import { MEASURE_ANIMATION_LENGTH } from "./measure-constants";

/** Size, in pixels, of the icon used to render the collapsed measurement */
const ICON_SIZE = 30;

export type CollapsedMeasureRendererProps = {
  /** True to show this collapsed measure, will animate opacity on change */
  visible: boolean;

  /** True if this icon can receive events */
  enabled: boolean;

  /** The measurement starting point, in world coordinates */
  position: Vector3;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Callback when this icon is clicked */
  onClick(position: Vector3): void;
};

/** @returns an icon placed in 3D in the halfpoint between the first and second point. */
export function CollapsedMeasureRenderer({
  visible,
  enabled,
  position,
  parentRef,
  onClick,
}: CollapsedMeasureRendererProps): JSX.Element | null {
  const pointerEvents = visible && enabled ? "auto" : "none";

  return (
    <Html
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.collapsed}
      eps={-1}
      position={position}
      portal={parentRef}
      style={{ pointerEvents }}
    >
      <Box
        aria-label="measurement ruler"
        component="div"
        sx={{
          pointerEvents,
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          opacity: visible ? 1 : 0,
          transform: "translate(-50%, -50%)",
          cursor: "pointer",
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          onClick(position);
        }}
      >
        <MeasurementMarkerIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} />
      </Box>
    </Html>
  );
}
