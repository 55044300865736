import {
  AnalysisMarkerIcon,
  ANNOTATION_ZINDEX_RANGE_MAP,
} from "@faro-lotv/app-component-toolbox";
import Box from "@mui/material/Box";
import { Html } from "@react-three/drei";
import { MutableRefObject, useMemo } from "react";
import { Vector3, Vector3Tuple } from "three";

/** Size, in pixels, of the icon used to render the collapsed measurement */
const ICON_SIZE = 30;

export type CollapsedAnalysisRendererProps = {
  /** True to show this collapsed analysis */
  visible: boolean;

  /** Analysis polygon selection */
  polygonSelection: Vector3Tuple[];

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Callback when this icon is clicked */
  onClick(position: Vector3): void;
};

/** @returns an icon placed in 3D in the middle of the polygon. */
export function CollapsedAnalysisRenderer({
  visible,
  polygonSelection,
  parentRef,
  onClick,
}: CollapsedAnalysisRendererProps): JSX.Element | null {
  const pointerEvents = visible ? "auto" : "none";

  const position = useMemo(() => {
    const position = new Vector3(0, 0, 0);
    for (const [x, y, z] of polygonSelection) {
      position.add(new Vector3(x, y, z));
    }
    position.divideScalar(polygonSelection.length);
    return position;
  }, [polygonSelection]);

  return (
    <Html
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.collapsed}
      eps={-1}
      position={position}
      portal={parentRef}
      style={{ pointerEvents }}
    >
      <Box
        aria-label="analysis-marker"
        component="div"
        sx={{
          pointerEvents,
          opacity: visible ? 1 : 0,
          cursor: "pointer",
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          onClick(position);
        }}
      >
        <AnalysisMarkerIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} />
      </Box>
    </Html>
  );
}
