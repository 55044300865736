import { useFlashScanIcons } from "@/components/r3f/renderers/odometry-paths/use-flash-scan-icons";
import { useMapPlaceholderPositions } from "@/hooks/use-map-placeholder-positions";
import { selectIsPanoExtractedFromData } from "@/store/project-selector";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  selectObjectVisibility,
  selectShouldColorWaypoints,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { offsetPlaceholders } from "@/utils/offset-placeholders";
import {
  INVALID_ALTITUDE_COLOR,
  MAX_ALTITUDE_COLOR,
  MIN_ALTITUDE_COLOR,
} from "@/utils/waypoints-color-gradient";
import {
  interpolateColor,
  isMapPlaceholdersUpdated,
  MapWaypointsRenderer,
  MayWaypointsRendererRef,
  selectAncestor,
  selectChildrenDepthFirst,
  selectIElementWorldPosition,
  State,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementImg360,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { PlaceholdersTexture } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Color } from "three";
import { useWaypointAltitudeRange } from "../mode-data-context";
import { SheetModeRenderOrders } from "./sheet-mode-render-orders";

const FLASH_SCANS_LABEL = "Flash Scans";

/** Size of the default flash scan icon, in screen pixels without accounting for DPR */
const FLASH_SCANS_DEFAULT_SZ = 24;
/** Size of the hovered flash scan icon, in screen pixels without accounting for DPR */
const FLASH_SCANS_HOVERED_SZ = 34;

type SheetWaypointsProps = {
  /** The list of paths to render */
  paths: IElementSection[];

  /** The list of panoramas currently showed */
  panos: IElementImg360[];

  /** Sheet to render */
  sheetElement?: IElementGenericImgSheet;

  /** Callback issued when a placeholder is clicked */
  clickOnPlaceholder(el: number | IElementImg360): void;

  /** Callback issued when a placeholder is hovered */
  onPlaceholderHovered(el?: number | IElementImg360): void;
};

/** @returns a component to instantiate the rendering of pano waypoints in sheet mode */
export function SheetWaypoints({
  paths,
  panos,
  sheetElement,
  clickOnPlaceholder,
  onPlaceholderHovered,
}: SheetWaypointsProps): JSX.Element {
  const placeholders = useMapPlaceholderPositions(panos, sheetElement);

  const colors = useWaypointsColors(panos);

  const { placeholdersOffset, shiftedPlaceholders } = useMemo(
    () => offsetPlaceholders(placeholders),
    [placeholders],
  );

  const panoOffset = usePanoOffset(paths, sheetElement);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  const flashScanIds = useAppSelector(selectFlashScanIds(panos), isEqual);

  const [mapPlaceholders, setMapPlaceholders] =
    useState<MayWaypointsRendererRef | null>();

  const { flashDefault, flashHovered } = useFlashScanIcons();

  const gl = useThree((s) => s.gl);

  useEffect(() => {
    if (!isMapPlaceholdersUpdated(mapPlaceholders, shiftedPlaceholders)) return;
    const dpr = gl.getPixelRatio();
    mapPlaceholders.setLabeledPlacehoders(FLASH_SCANS_LABEL, flashScanIds);
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Default,
      flashDefault,
    );
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Hovered,
      flashHovered,
    );
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Selected,
      flashHovered,
    );
    mapPlaceholders.setLabelSizes(FLASH_SCANS_LABEL, {
      default: FLASH_SCANS_DEFAULT_SZ * dpr,
      hovered: FLASH_SCANS_HOVERED_SZ * dpr,
      selected: FLASH_SCANS_HOVERED_SZ * dpr,
    });
  }, [
    mapPlaceholders,
    flashScanIds,
    flashDefault,
    flashHovered,
    gl,
    shiftedPlaceholders,
  ]);

  return (
    <>
      {/* Place panorama images above waypoints in odometric paths, to increase their visibility */}
      <group position-y={panoOffset}>
        <group position={placeholdersOffset}>
          <MapWaypointsRenderer
            customColors={colors}
            visible={shouldWayPointsBeVisible}
            waypoints={shiftedPlaceholders}
            onPlaceholderClick={clickOnPlaceholder}
            onPlaceholderHovered={onPlaceholderHovered}
            renderOrder={SheetModeRenderOrders.Waypoints}
            ref={setMapPlaceholders}
          />
        </group>
      </group>
    </>
  );
}

/**
 * @param paths The trajectories in the sheet
 * @param sheetElement The area sheet being displayed
 * @returns the offset to apply to the pano placeholders to ensure they are rendered above the trajectory panos
 */
function usePanoOffset(
  paths: IElementSection[],
  sheetElement?: IElementGenericImgSheet,
): number {
  const pathPanos = useAppSelector(
    (state) =>
      paths.flatMap((path) =>
        selectChildrenDepthFirst(path, isIElementImg360)(state),
      ),
    isEqual,
  );
  const pathPanoPositions = useMapPlaceholderPositions(pathPanos, sheetElement);
  // To avoid z-fighting and to account for additional layer offset of the trajectory panos
  const additionalOffset = 1;
  return (
    additionalOffset +
    useMemo(
      () => Math.max(0, ...pathPanoPositions.map((pos) => pos.y)),
      [pathPanoPositions],
    )
  );
}

/**
 * @returns The waypoints color based on altitude, if the option is enabled
 * @param panos The list of all panos in the scene
 */
function useWaypointsColors(panos: IElementImg360[]): Color[] | undefined {
  const shouldColorWaypoints = useAppSelector(selectShouldColorWaypoints);

  const range = useWaypointAltitudeRange();

  const { getState } = useAppStore();
  return useMemo(() => {
    if (!shouldColorWaypoints || !range) return;

    const state = getState();
    const positions = panos.map((p) => {
      if (selectIsPanoExtractedFromData(p)(state)) {
        return selectIElementWorldPosition(p.id)(state);
      }
    });

    return positions.map((p) => {
      if (!p) return new Color(INVALID_ALTITUDE_COLOR);

      const alpha = (p[1] - range.lowest) / (range.highest - range.lowest);
      return new Color(
        interpolateColor(MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR, alpha),
      );
    });
  }, [getState, panos, range, shouldColorWaypoints]);
}

/**
 * @returns Which panos of the input list are flash scans
 * @param panos The list of panos to filter
 */
function selectFlashScanIds(panos: IElementImg360[]) {
  return (state: State) => {
    const ret: number[] = [];

    for (let panoId = 0; panoId < panos.length; panoId++) {
      const pano = panos[panoId];
      const isFlash = !!selectAncestor(pano, (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.flash,
        ),
      )(state);
      if (isFlash) ret.push(panoId);
    }

    return ret;
  };
}
