import {
  PointCloudFormat,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

/** @returns the point cloud formats supported by the point cloud api or undefined while fetching */
export function usePointCloudFormats(): PointCloudFormat[] | undefined {
  const [formats, setFormats] = useState<PointCloudFormat[]>();

  const { pointCloudApiClient } = useApiClientContext();

  useEffect(() => {
    const ac = new AbortController();
    pointCloudApiClient.getFormats(ac.signal).then(setFormats);
    return () => ac.abort();
  }, [pointCloudApiClient]);

  return formats;
}
