import { Stack } from "@mui/system";
import { useState } from "react";
import { FilterMenuRow, FilterMenuRowProps } from "./filter-menu-row";
import { FilterToggle } from "./filter-toggle";
import {
  AnnotationSortMenuButtonProps,
  SortMenuButton,
} from "./sort-menu-button";

type FilterAndSortPanel = Omit<FilterMenuRowProps, "isVisible"> &
  AnnotationSortMenuButtonProps & { hasFilters: boolean };

/**
 * @returns the panel to define the sort and filter settings for the annotation list
 */
export function FilterAndSortPanel({
  onSortingChanged,
  hasFilters,
  ...props
}: FilterAndSortPanel): JSX.Element {
  const [isFilterRowVisible, toggleFilters] = useState(false);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <FilterToggle
          isChecked={isFilterRowVisible}
          onToggle={toggleFilters}
          hasFilters={hasFilters}
        />
        <SortMenuButton onSortingChanged={onSortingChanged} />
      </Stack>
      <FilterMenuRow isVisible={isFilterRowVisible} {...props} />
    </>
  );
}
