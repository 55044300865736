import {
  MarkupModelMetaData,
  isMarkupModelMetaData,
  useLotvDispose,
} from "@faro-lotv/app-component-toolbox";
import { IElementModel3D } from "@faro-lotv/ielement-types";
import { forwardRef, useEffect, useState } from "react";
import {
  Color,
  DoubleSide,
  FrontSide,
  Group,
  MeshBasicMaterial,
  Object3D,
  Texture,
  TextureLoader,
} from "three";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Model3dDataZip, fetchModel3DZip } from "../utils/zip-utils";

type ZipModel3dRendererProps = {
  /** IElement that contains the zip link */
  iElement: IElementModel3D;
};

/**
 * @returns Renderer for Model3d iElements containing the model data in a zipped format.
 */
export const ZipModel3dRenderer = forwardRef<Group, ZipModel3dRendererProps>(
  function ZipModel3dRenderer(
    { iElement }: ZipModel3dRendererProps,
    ref,
  ): JSX.Element | null {
    const [data, setData] = useState<Model3dDataZip>();
    const [metaData, setMetaData] = useState<MarkupModelMetaData>();

    useEffect(() => {
      const controller = new AbortController();

      fetchModel3DZip(iElement.uri, controller.signal).then(setData);

      return () => {
        controller.abort();
      };
    }, [iElement.uri]);

    useEffect(() => {
      if (iElement.metaDataMap && isMarkupModelMetaData(iElement.metaDataMap)) {
        setMetaData(iElement.metaDataMap);
      }
    }, [iElement.metaDataMap]);

    if (!data) {
      return null;
    }

    return <MarkupModelRenderer ref={ref} metaData={metaData} {...data} />;
  },
);

type MarkupModelRendererProps = Model3dDataZip & {
  /** Object that contains information about the color and material of the Markup */
  metaData?: MarkupModelMetaData;
};

const MarkupModelRenderer = forwardRef<Group, MarkupModelRendererProps>(
  function MarkupModelRenderer(
    { objUrl, mtlUrl, texturesData, metaData }: MarkupModelRendererProps,
    ref,
  ): JSX.Element | null {
    const [group, setGroup] = useState<Object3D>();
    useEffect(() => {
      // Async function to load the textures, the materials and the obj
      async function load(): Promise<void> {
        const texLoader = new TextureLoader();
        const textures = await Promise.all(
          texturesData.map((file) => texLoader.loadAsync(file.dataUrl)),
        );
        const texturesMap: Record<string, Texture> = {};
        texturesData
          .map((file) => `./${file.fileName}`)
          .forEach((element, index) => {
            texturesMap[element] = textures[index];
          });

        const objLoader = new OBJLoader();
        if (mtlUrl) {
          const matLoader = new MTLLoader();
          const materials = await matLoader.loadAsync(mtlUrl);
          materials.preload();

          // The material provided is a Phong material that we don't support very well in the walk mode.
          // For this reason a MeshBasicMaterial is used instead, taking into account the mapKd texture and
          // the color taken from the metaData
          for (const name in materials.materials) {
            const { map_kd: mapKd } = materials.materialsInfo[name];
            materials.materials[name] = new MeshBasicMaterial({
              color: metaData?.color ? new Color(metaData.color) : undefined,
              map:
                mapKd && mapKd in texturesMap ? texturesMap[mapKd] : undefined,
              side:
                metaData?.textureRenderingSides === 2 ? DoubleSide : FrontSide,
              transparent: true,
            });
          }
          objLoader.setMaterials(materials);
        }
        if (objUrl) {
          objLoader.loadAsync(objUrl).then((o) => {
            setGroup(o);
          });
        }
      }
      load();
    }, [metaData, mtlUrl, objUrl, texturesData]);

    useLotvDispose(group);

    // Do not render anything until all the fetching is done
    if (!group) return null;

    return (
      <group ref={ref}>
        <primitive object={group} />
      </group>
    );
  },
);
