import { runtimeConfig } from "@/runtime-config";
import { redirectToDashboard, redirectToWebEditor } from "@/utils/redirects";
import { ErrorAction, ErrorReason } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/ielement-types";
import { ReactNode } from "react";

const DEFAULT_ERROR_STATEMENT = "Something went wrong";
const DEFAULT_HELP_TEXT = "Please try again.";

type UnrecoverableErrorOptions = {
  /** The statement introducing the error to the user */
  errorStatement: string;

  /**
   * A message guiding the user to solve the problem.
   * It's preferred to keep this under 80 characters to make sure it fits in
   * less than three lines on the default screen size
   */
  helpText: ReactNode;

  /** A list of possible actions (links) the user can visit to help solve the error */
  actions: ErrorAction[];

  /** Special action with a long label to show as a link below the other actions */
  specialAction?: ErrorAction;
};

/**
 * An error the app can't recover from.
 * Gather all the needed information to display a useful error page
 * to the user with detail on how to manage the error and some
 * possible actions.
 */
export class UnrecoverableError extends Error {
  /** Source error that generated this error */
  #reason: ErrorReason;

  /** Options to customize this error instance */
  #options: UnrecoverableErrorOptions;

  /**
   * Wrap an application error with all the information needed to show an useful error page
   *
   * @param reason source error that generated this error
   * @param options to customize the error page for this error
   */
  constructor(
    reason: ErrorReason,
    options: Partial<UnrecoverableErrorOptions> = {},
  ) {
    super(`UnrecoverableError: ${JSON.stringify(reason)}`);
    this.#reason = reason;
    this.#options = {
      errorStatement: options.errorStatement ?? DEFAULT_ERROR_STATEMENT,
      helpText: options.helpText ?? DEFAULT_HELP_TEXT,
      actions: options.actions ?? [],
      specialAction: options.specialAction,
    };
  }

  /** @returns the statement introducing the error to the user */
  get errorStatement(): string {
    return this.#options.errorStatement;
  }

  /** @returns the long help text to guide the user to solve the problem */
  get helpText(): string | ReactNode {
    return this.#options.helpText;
  }

  /** @returns a list of possible actions the user can use to help solve the error */
  get actions(): ErrorAction[] {
    return this.#options.actions;
  }

  /** @returns an action with a long label to show separately from the other actions */
  get specialAction(): ErrorAction | undefined {
    return this.#options.specialAction;
  }

  /** @returns the source error name */
  get errorName(): string {
    if (typeof this.#reason === "string") {
      return "Generic Error";
    } else if (this.#reason instanceof Error) {
      return this.#reason.name;
    }
    return "Unknown Error";
  }

  /** @returns the source error message */
  get errorMessage(): string {
    if (typeof this.#reason === "string") {
      return this.#reason;
    } else if (this.#reason instanceof Error) {
      return this.#reason.message;
    }
    return "";
  }
}

/**
 * Create an ErrorAction to return to the home page taking into account
 * if the user is in HoloBuilder or Sphere
 *
 * @param primary true to mark this action a a primary action
 * @returns the ErrorAction
 */
function openHomeAction(primary: boolean): ErrorAction {
  const homeUrl = runtimeConfig.externalLinks.holoBuilderHome;
  const buttonLabel = "Open HoloBuilder";

  return {
    label: buttonLabel,
    action: new URL("", homeUrl),
    primary,
  };
}

/**
 * Create a specific error in case a project is not supported by the viewer
 *
 * Resolve actions offered to the user:
 * - Open the project in WebEditor
 * - Send a request to have the project converted
 *
 * @param reason the source error returned by the API
 * @param projectId ID of the unsupported project
 * @returns an UnrecoverableError instance with all the required information
 */
export function projectUnsupportedError(
  reason: ErrorReason,
  projectId: GUID,
): UnrecoverableError {
  const { externalLinks } = runtimeConfig;
  // Special logic in HoloBuilder to fallback to WebEditor
  if (externalLinks.webEditor && externalLinks.unsupportedProjectsForm) {
    return new UnrecoverableError(reason, {
      errorStatement:
        "This project is not yet compatible with the Sphere Viewer.",
      helpText:
        "However, you can open it in the HoloBuilder WebEditor as usual.",
      actions: [
        {
          label: "Open in Web Editor",
          action() {
            redirectToWebEditor(projectId);
          },
          primary: true,
        },
      ],
      specialAction: {
        label:
          "Want to see the project in the Sphere Viewer? Let us know here.",
        action: new URL("", externalLinks.unsupportedProjectsForm),
      },
    });
  }

  // Fallback to a generic error in Sphere as we can't offer a fallback
  return unknownError(reason);
}

/**
 * Create a specific error in case the project cannot be accessed due to missing permissions
 *
 * Resolve actions offered to the user:
 * - Go back to the Dashboard
 *
 * @param reason the source error returned by the API
 * @returns an UnrecoverableError instance with all the required information
 */
export function projectPermissionError(
  reason: ErrorReason,
): UnrecoverableError {
  return new UnrecoverableError(reason, {
    errorStatement: "You don’t have permission to access this project.",
    helpText: "Contact the Project Administrator for assistance.",
    actions: [
      {
        label: "Go to Dashboard",
        action: redirectToDashboard,
        primary: true,
      },
    ],
  });
}

/**
 * The CoreApi returned an error when requesting the current user info
 *
 * @param reason the core api error
 * @returns An help message to instruct the user to refresh or clear the cookies to force a re-login
 */
export function loginStatusCheckError(reason: ErrorReason): UnrecoverableError {
  return new UnrecoverableError(reason, {
    errorStatement: "Login failed",
    helpText:
      "Try to refresh the page or delete cookies. If the problem persists, contact the Sphere XG Support",
  });
}

/**
 * Create an UnrecoverableError when the viewer is opened on an invalid URL
 *
 * Resolve actions offered to the user:
 * - open the Sphere/HoloBuilder home page
 *
 * @param reason the source error returned by the API
 * @returns an UnrecoverableError instance with all the required information
 */
export function unknownPageError(reason: ErrorReason): UnrecoverableError {
  return new UnrecoverableError(reason, {
    helpText: "We're not able to find what you were searching for.",
    actions: [openHomeAction(true)],
  });
}

/**
 * Wrap any unknown errors that have no special handling for now
 *
 * Resolve actions offered to the user:
 * - reload the page
 *
 * @param reason the source error returned by the API
 * @returns an UnrecoverableError instance with all the required information
 */
export function unknownError(reason: ErrorReason): UnrecoverableError {
  return new UnrecoverableError(reason, {
    actions: [
      {
        label: "Reload",
        action: () => window.location.reload(),
        primary: true,
      },
    ],
  });
}
