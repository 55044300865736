import { selectCurrentUser } from "@/store/user-selectors";
import {
  fetchProjectIElements,
  selectChildDepthFirst,
  selectRootIElement,
} from "@faro-lotv/app-component-toolbox";
import { isInternalUserEmail } from "@faro-lotv/foreign-observers";
import { assert, getFileExtension } from "@faro-lotv/foundation";
import {
  IElementGroup,
  IElementType,
  IElementTypeHint,
  isIElementBimModelGroup,
} from "@faro-lotv/ielement-types";
import {
  ProjectApi,
  createMutationImportBimModel,
} from "@faro-lotv/service-wires";
import { UpdateProjectParam } from "./use-upload-element";

/**
 * This list of extensions is used to validate the files imported using the drag and drop,
 * and as the extension filter used in the file explorer when trying to import a cad.
 * Take note that the Import button should also support *.* (i.e. any file extension) as we will let
 * Autodesk APS figure out if the file is actually supported as a CAD or not.
 */
export enum SupportedCADFileExtensions {
  gltf = "gltf",
  glb = "glb",
  // Navisworks
  nwd = "nwd",
  nwc = "nwc",
  // AutoCAD/Autodesk DWG/DXF
  dwg = "dwg",
  dxf = "dxf",
  // IFC
  ifc = "ifc",
  // Revit
  rvt = "rvt",
  // STEP
  stp = "stp",
  step = "step",
  stpz = "stpz",
  // Rhino3D
  rhino3d = "3dm",
  // Autodesk FBX
  fbx = "fbx",
}

/** List of supported CAD file extensions for drag-n-drop but converted to strings */
const supportedExtensionList = Object.values(SupportedCADFileExtensions).map(
  (ext) => `${ext}`,
);

/**
 * @param fileName name of the file to check the extension of
 * @returns if the name passed has a CAD extension supported for drag-n-drop (manual import can support any extension)
 */
export function isSupportedCADFileExtension(fileName: string): boolean {
  const extension = getFileExtension(fileName)?.toLowerCase() ?? "";

  return supportedExtensionList.includes(extension);
}

/**
 * Fetch the BimModel Group at the root of the project
 *
 * @param projectApi Reference to the project api
 * @param signal To abort the request
 * @returns The BimModel Group element, undefined when no BimModel Group at root or if the request is aborted
 */
export async function fetchBimModelGroupAtRoot(
  projectApi: ProjectApi,
  signal?: AbortSignal,
): Promise<IElementGroup | undefined> {
  const cadGroups = await projectApi.getAllIElements({
    types: [IElementType.group],
    typeHints: [IElementTypeHint.bimModel],
    signal,
  });
  // Old projects may have BimModel Group under floors, need to filter them out
  // Following up ticket: https://faro01.atlassian.net/browse/CADBIM-123
  const rootCadGroups = cadGroups.filter((el) => el.parentId === el.rootId);
  if (!rootCadGroups.length) return;

  // Only one BimModel Group can be defined at the project root.
  // If there is more than one at the root, something wrong with the project.
  if (rootCadGroups.length > 1) {
    throw new Error("Project should have only one BimModel group under root");
  }

  assert(
    isIElementBimModelGroup(rootCadGroups[0]),
    "Unexpected BimModel group element type",
  );

  return rootCadGroups[0];
}

/**
 * Function that sends the ImportBimModel mutation to the project api and update the local copy of the project
 */
export async function updateProjectWithCad({
  appStore,
  dispatch,
  projectApi,
  file,
  name,
  createdAt,
  downloadUrl,
  md5Hash,
}: UpdateProjectParam): Promise<void> {
  const root = selectRootIElement(appStore.getState());
  assert(root, "Cannot find Root IElement of the project");

  const bimGroup = selectChildDepthFirst(
    root,
    isIElementBimModelGroup,
    1,
  )(appStore.getState());

  const isInternalUser = isInternalUserEmail(
    selectCurrentUser(appStore.getState())?.email,
  );

  // Add new bim model to the project
  // This mutation will use an already existing BimModel Group, but will create a new TimeSeries at each new import
  await projectApi.applyMutations([
    createMutationImportBimModel({
      name,
      createdAt: createdAt.toISOString(),
      rootId: root.id,
      type: IElementType.model3d,
      uri: downloadUrl,
      fileName: file.name,
      fileSize: file.size,
      md5Hash,
      groupId: bimGroup?.id,
      isInternalUser,
    }),
  ]);

  // Fetch the changed CAD sub-tree and update the local copy of the project
  dispatch(
    fetchProjectIElements({
      fetcher: async () => {
        // The BimModel Group does not exist before uploading the first CAD.
        // In this case, we need to fetch the newly created group at root and add to the store
        const bimModelGroup =
          bimGroup ?? (await fetchBimModelGroupAtRoot(projectApi));
        if (!bimModelGroup) {
          throw new Error(
            "Failed to create the BimModel Group at project root",
          );
        }

        // Refresh the BimModel group that contains all CADs in the project
        const bimElements = await projectApi.getAllIElements({
          ancestorIds: [bimModelGroup.id],
        });
        // If bimGroup is undefined, this means we need to re-fetch the ProjectRoot too
        if (!bimGroup) {
          const newRoot = await projectApi.getAllIElements({
            ids: [root.id],
          });
          bimElements.push(newRoot[0]);
        }
        return bimElements;
      },
    }),
  );
}
