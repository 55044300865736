import { TouchEvents } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo } from "react";
import { Vector3 } from "three";
import {
  NO_MOUSE_BUTTON,
  PointCloudManipulatorLogic,
} from "./point-cloud-manipulator-logic";

/**
 * Connects the canvas' pointer events to the manipulator logic
 *
 * @param manipulatorLogic Object responsible for the translation and rotation of the point cloud
 */
export function useEventsListener(
  manipulatorLogic: PointCloudManipulatorLogic,
): void {
  const camera = useThree((state) => state.camera);
  const gl = useThree((state) => state.gl);
  const pointer = useThree((state) => state.pointer);
  const handlePointerDown = useCallback(
    (button: number) => {
      manipulatorLogic.handlePointerDown(
        new Vector3(pointer.x, pointer.y, 0).unproject(camera),
        button,
      );
    },
    [manipulatorLogic, camera, pointer],
  );

  const handlePointerMove = useCallback(() => {
    manipulatorLogic.handlePointerMove(
      new Vector3(pointer.x, pointer.y, 0).unproject(camera),
    );
  }, [manipulatorLogic, camera, pointer]);

  const handlePointerUp = useCallback(
    (button: number) => {
      manipulatorLogic.handlePointerUp(button);
    },
    [manipulatorLogic],
  );

  const handleLongPress = useCallback(
    () => manipulatorLogic.handleLongPress(),
    [manipulatorLogic],
  );

  // Below an object is created that allows this component to receive
  // mouse and touch events.
  const events = useMemo(() => new TouchEvents(), []);

  // On mount, the event handler is attached to the canvas and the callbacks are
  // registered. On unmount, the event handler is detached and the callbacks are
  // unregistered.
  useEffect(() => {
    events.attach(gl.domElement);
    // listening to pointer down events
    const mousePressedDisposable = events.mousePressed.on((ev) =>
      handlePointerDown(ev.button),
    );
    // listening to pointer move events
    const mouseMovedDisposable = events.mouseMoved.on(handlePointerMove);
    // listening to pointer up events
    const mouseReleasedDisposable = events.mouseReleased.on((ev) =>
      handlePointerUp(ev.button),
    );
    // listening to touch events
    const touchStartedDisposable = events.touchStarted.on(() =>
      handlePointerDown(NO_MOUSE_BUTTON),
    );
    const singleTouchMovedDisposable =
      events.singleTouchMoved.on(handlePointerMove);
    const touchEndedDisposable = events.touchEnded.on(() =>
      handlePointerUp(NO_MOUSE_BUTTON),
    );
    const longPressDisposable = events.longPress.on(handleLongPress);

    return () => {
      // disposing and disconnecting the event handler
      mousePressedDisposable.dispose();
      mouseMovedDisposable.dispose();
      mouseReleasedDisposable.dispose();
      // disposing touch events
      touchStartedDisposable.dispose();
      singleTouchMovedDisposable.dispose();
      touchEndedDisposable.dispose();
      longPressDisposable.dispose();
      events.detach();
    };
  }, [
    events,
    gl,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleLongPress,
  ]);
}
