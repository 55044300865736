import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ScanColoring {
  /** Different color for each scan */
  byScan = "byScan",

  /** The original point cloud color */
  originalColor = "originalColor",
}

export enum RegistrationEdgeType {
  /** Display global results */
  globalResults = "globalResults",

  /** Display local results */
  localResults = "localResults",
}

type DataPreparationViewOptionsState = {
  /** Scan coloring to use for displaying multiple individual scans */
  scanColoring: ScanColoring;
  showConnectionLines: boolean;
  useQualityColorCoding: boolean;
  registrationEdgeType: RegistrationEdgeType;
};

/** Initial values for the ViewOptionsSlice */
export const DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES: DataPreparationViewOptionsState =
  {
    scanColoring: ScanColoring.byScan,
    showConnectionLines: true,
    useQualityColorCoding: true,
    registrationEdgeType: RegistrationEdgeType.globalResults,
  };

export const dataPreparationViewOptionsSlice = createSlice({
  initialState: DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES,
  name: "dataPreparationViewOptions",
  reducers: {
    /**
     * Updates the active scan coloring mode
     *
     * @param state current state
     * @param action new scan coloring mode
     */
    setScanColoring(state, action: PayloadAction<ScanColoring>) {
      state.scanColoring = action.payload;
    },

    /**
     * Updates whether to show connection lines
     *
     * @param state current state
     * @param action new value for showing connection
     */
    setShowConnectionLines(state, action: PayloadAction<boolean>) {
      state.showConnectionLines = action.payload;
    },

    /**
     * Updates whether to use quality color coding when rendering the edges
     *
     * @param state current state
     * @param action new value for quality color coding
     */
    setQualityColorCoding(state, action: PayloadAction<boolean>) {
      state.useQualityColorCoding = action.payload;
    },

    /**
     * Updates the RegistrationEdgeType to use for displaying the registration results
     *
     * @param state current state
     * @param action new value for global or local results
     */
    setRegistrationEdgeType(
      state,
      action: PayloadAction<RegistrationEdgeType>,
    ) {
      state.registrationEdgeType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      clearStore,
      () => DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES,
    );
  },
});

export const {
  setScanColoring,
  setShowConnectionLines,
  setQualityColorCoding,
  setRegistrationEdgeType,
} = dataPreparationViewOptionsSlice.actions;

export const dataPreparationViewOptionsReducer =
  dataPreparationViewOptionsSlice.reducer;
