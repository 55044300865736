import { UnknownObject } from "@/object-cache-type-guard";
import { useLotvDispose } from "@faro-lotv/app-component-toolbox";
import { useMemo } from "react";
import { Object3D } from "three";

/**
 * To make the below hook work, a 'Viewable' type is defined. It is a
 * concrete model type with a 'createView' method returning another view
 * on the same model.
 */
type Viewable<
  Source extends UnknownObject = UnknownObject,
  View extends Object3D = Object3D,
> = Source & {
  createView(): View;
};

/**
 * This hook is needed in splitscren scenarios in which the same LOD model is rendered by two distinct viewports.
 * In this case, the original model is typically rendered in the main (left) viewport, and a 'view' on the original
 * model is rendered in the secondary (right) viewport. A 'view' on a LOD model is typically a reference to the same
 * pool of LOD geometries, allowing the same 3D geometries to appear on the left and on the right viewport at the same
 * time without duplicating resources. Classes 'LodPano' and 'LodPointCloud' support the 'createView()' semantic,
 * allowing for the creation of secondary 'views' on the model to optimize usage of basic LOD geometry (pano tiles
 * for LOD pano and point nodes for LodPointCloud).
 *
 * This hook is similar to 'useObjectView' except that it also encapsulates the decision of whether a view should be
 * created or not. Similar to 'useObjectView', it automatically takes care of the disposal of the newly created view
 * on unmount.
 *
 * @summary Returns the source object if 'isSecondaryView' is false, or a view on the source object if 'isSecondaryView' is true.
 * @param source The source object
 * @param isSecondaryView Whether the view requiring the object is the secondary view or the main view
 * @returns The source object or a view on it.
 */
export function useViewIfNeeded<
  SourceObject extends Viewable<C, V>,
  C extends UnknownObject,
  V extends Object3D = ReturnType<SourceObject["createView"]>,
>(
  source: SourceObject | null,
  isSecondaryView: boolean,
): SourceObject | (V & { iElement: SourceObject["iElement"] }) | null {
  // Use the source point cloud or a view depending on input isSecondaryView prop
  const maybeView = useMemo(() => {
    if (!source) {
      return null;
    }
    if (!isSecondaryView) {
      return source;
    }
    return Object.assign(source.createView(), { iElement: source.iElement });
  }, [isSecondaryView, source]);
  useLotvDispose(isSecondaryView ? maybeView : null);
  return maybeView;
}
