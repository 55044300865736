import { EventType } from "@/analytics/analytics-events";
import { useCancelUpload } from "@/components/common/file-upload-context/use-cancel-upload";
import { BackgroundTask } from "@/utils/background-tasks";
import {
  FaroButton,
  FaroTooltip,
  FontWeights,
  RemainingTimeLabel,
  TranslateVar,
  blue,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { TreeData } from "@faro-lotv/project-source";
import {
  Box,
  LinearProgress,
  Stack,
  TooltipProps,
  Typography,
} from "@mui/material";
import { MouseEventHandler, useCallback } from "react";

type TreeTaskPopupProps = TooltipProps & {
  /** Name of the element */
  elementName: TreeData["label"];

  /** The task to track with this  */
  task: BackgroundTask | undefined;

  /** Callback called when the popup need to close */
  onClose?(): void;
};

/**
 * @returns the label to use in the pop-up for this task
 * @param task to compute the label for
 */
function taskLabel(task: BackgroundTask): string {
  switch (task.type) {
    case "FileUpload":
      return "Uploading";
    case "PointCloudExport":
      return "Exporting";
    case "PointCloudToPotree":
    case "PointCloudLazToPotree":
    case "PointCloudE57ToLaz":
    case "PointCloudGSToLaz":
    default:
      return "Processing";
  }
}

/**
 * @returns the message to use in the pop-up for this task
 * @param task to compute the label for
 * @param elementName this task is working on
 */
function taskMessage(
  task: BackgroundTask,
  elementName: TreeData["label"],
): string | JSX.Element {
  switch (task.type) {
    case "FileUpload":
      return (
        <>
          <TranslateVar name="elementName">{elementName}</TranslateVar> is being
          uploaded to{" "}
          <TranslateVar name="filename">{task.metadata.filename}</TranslateVar>
        </>
      );
    case "PointCloudExport":
      return (
        <>
          Exporting volume of{" "}
          <TranslateVar name="elementName">{elementName}</TranslateVar>
        </>
      );
    case "PointCloudToPotree":
    case "PointCloudLazToPotree":
    case "PointCloudE57ToLaz":
    case "PointCloudGSToLaz":
    default:
      return (
        <>
          <TranslateVar name="elementName">{elementName}</TranslateVar> is being
          processed
        </>
      );
  }
}

/**
 * @returns the tooltip for an on progress point cloud import tasks
 */
export function TreeTaskPopup({
  elementName,
  task,
  children,
  onClose,
  ...rest
}: TreeTaskPopupProps): JSX.Element {
  const cancelUpload = useCancelUpload();

  const onCancel = useCallback<MouseEventHandler>(
    (ev) => {
      Analytics.track(EventType.cancelUploadViaProjectTree);
      if (!task) return;
      ev.stopPropagation();
      onClose?.();
      cancelUpload(task.id);
    },
    [cancelUpload, onClose, task],
  );

  if (!task) return children;

  return (
    <FaroTooltip
      {...rest}
      title={
        <Stack>
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "bold",
              color: neutral[800],
            }}
          >
            {taskLabel(task)}
          </Typography>

          <Typography
            sx={{ fontSize: "inherit", mx: 0, my: 1, color: neutral[800] }}
          >
            {taskMessage(task, elementName)}
          </Typography>
          {task.expectedEnd && (
            <RemainingTimeLabel
              expectedEnd={task.expectedEnd}
              sx={{ color: neutral[800], fontWeight: FontWeights.SemiBold }}
            />
          )}
          <LinearProgress
            sx={{
              minWidth: "21.875rem",
              minHeight: "0.75rem",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "transparent",
                borderRadius: "0.75rem",
                background: `repeating-linear-gradient(-70deg, ${blue[500]}B2, ${blue[500]}B2 5px, ${blue[500]} 5px,${blue[500]} 15px)`,
              },
              backgroundColor: neutral[100],
              borderRadius: "0.75rem",
            }}
            value={task.progress}
            variant="determinate"
          />
          {task.canBeCanceled && (
            <Stack alignItems="flex-end" mt="0.625rem">
              <FaroButton
                aria-label="cancel"
                variant="destructiveGhost"
                size="m"
                onClick={onCancel}
              >
                Cancel
              </FaroButton>
            </Stack>
          )}
        </Stack>
      }
      componentsProps={{
        tooltip: {
          sx: {
            color: "white",
            backgroundColor: neutral[0],
            fontSize: "12px",
            fontWeight: "medium",
            px: 2,
            py: 1,
            maxWidth: 500,
            boxShadow: 3,
          },
        },
      }}
      disableInteractive={false}
    >
      {/* The box wrapping is necessary for the tooltip to appear */}
      <Box component="div">{children}</Box>
    </FaroTooltip>
  );
}
