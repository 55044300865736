import { selectActiveCadId } from "@/store/cad/cad-slice";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  ExclamationMarkIcon,
  GeoReferenceIcon,
  State,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  selectIsFloorWithoutScale,
  selectIsPointCloudAligned,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElement,
  isAreaAligned,
  isGeoReferencedElement,
  isIElementAreaSection,
  isIElementGenericPointCloudStream,
  isIElementOverviewImage,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { BadgeProps } from "@mui/material";
import { isEqual } from "lodash";

/**
 * Defines the data necessary for the display of an overlay badge
 */
export type OverlayBadgeData = {
  /** This the badge content that will be passed to Badge component*/
  badgeContent: React.ReactNode;
  /** The color to display at the background of the badge content*/
  badgeBackgroundColor: BadgeProps["color"];
  /** Indicates if this overlay icon could be propagated to the parent*/
  propagateToParent: boolean;
};

/**
 * @param id the id of the element for which we want to get the overlay badge
 * @param isClosed indicates if the tree node corresponding to element id is closed
 * @returns the overlay badge corresponding to the id or undefined if there is no badge
 */
export function useOverlayBadge(
  id: GUID,
  isClosed: boolean,
): OverlayBadgeData | undefined {
  return useAppSelector(selectTreeNodeBadgeData(id, isClosed), isEqual);
}

/**
 *
 * @param id the id of the element for which we want to get the overlay badge
 * @param isClosed indicates if the tree node corresponding to element id is closed
 * @returns the overlay badge corresponding to the id or undefined if there is no badge
 */
function selectTreeNodeBadgeData(id: GUID, isClosed: boolean) {
  return (state: RootState): OverlayBadgeData | undefined => {
    const element = selectIElement(id)(state);
    if (!element) return;

    const overlayData = selectNeedsOverlayBadge(element)(state);
    if (overlayData) {
      return overlayData;
    }

    if (isClosed) {
      return selectChildBadgePropagatingToParent(id)(state);
    }
  };
}

/**
 * @param cloud The point cloud IElementSection that is an IElementSectionLaserScans or a child to check
 * @returns true if the given cloud is geo-referenced (i.e the world transformation matrix of the cloud
 * is identical to the world transformation matrix of the bim model)
 */
function selectIsCloudGeoReferenced(
  cloud: IElement | undefined,
): (state: State) => boolean {
  return (state: State): boolean => {
    cloud = selectAncestor(cloud, isIElementSectionDataSession)(state);
    return isGeoReferencedElement(cloud);
  };
}

/**
 * @param element to check if an iElement should display an overlay badge
 * @returns an OverlayBadgeData type if the node requires an overlay badge otherwise it returns undefined
 */
function selectNeedsOverlayBadge(element: IElement | undefined) {
  return (state: RootState): OverlayBadgeData | undefined => {
    if (!element) return;

    const iElementIsDataSession = isIElementSectionDataSession(element);
    const activeCad = selectActiveCadId(state);

    if (iElementIsDataSession && selectIsCloudGeoReferenced(element)(state)) {
      return {
        badgeContent: <GeoReferenceIcon sx={{ width: "100%" }} />,
        badgeBackgroundColor: "default",
        propagateToParent: false,
      };
    }

    // indicate with warning badge not-aligned area
    if (
      activeCad &&
      isIElementAreaSection(element) &&
      !isAreaAligned(element) &&
      selectChildDepthFirst(element, isIElementOverviewImage)(state) ===
        undefined
    ) {
      return {
        badgeContent: <ExclamationMarkIcon sx={{ width: "100%" }} />,
        badgeBackgroundColor: "warning",
        propagateToParent: false,
      };
    }

    const isPointCloudAligned = (): boolean =>
      iElementIsDataSession &&
      !!selectChildDepthFirst(
        element,
        isIElementGenericPointCloudStream,
      )(state) &&
      !selectIsPointCloudAligned(element)(state);
    const isFloorWithoutScale = (): boolean =>
      isIElementAreaSection(element) &&
      selectIsFloorWithoutScale(element)(state);

    if (isPointCloudAligned() || isFloorWithoutScale()) {
      return {
        badgeContent: <ExclamationMarkIcon sx={{ width: "100%" }} />,
        badgeBackgroundColor: "warning",
        propagateToParent: true,
      };
    }
  };
}

/**
 * @param parentId The id of the node whose children should be checked for attention needed
 * @param maxDepth The maximum depth in the tree from the parent to check
 * @returns an OverlayBadgeData type if the node requires an overlay badge otherwise it returns undefined
 */
function selectChildBadgePropagatingToParent(
  parentId: GUID,
  maxDepth: number = Number.MAX_SAFE_INTEGER,
): (state: RootState) => OverlayBadgeData | undefined {
  return (state: RootState): OverlayBadgeData | undefined => {
    const parent = selectIElement(parentId)(state);
    const childWithOverlayBadge = selectChildDepthFirst(
      parent,
      (element) => !!selectNeedsOverlayBadge(element)(state),
      maxDepth,
    )(state);
    if (childWithOverlayBadge) {
      const overlayBadge = selectNeedsOverlayBadge(childWithOverlayBadge)(
        state,
      );
      if (overlayBadge?.propagateToParent) {
        return overlayBadge;
      }
    }
  };
}
