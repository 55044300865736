import { HtmlProps } from "@react-three/drei/web/Html";
import { useCallback, useRef } from "react";
import { Camera, Group, Object3D, Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";

/** A temporary vector to avoid re-allocation */
const TEMP_VEC_1 = new Vector3();

type PositionedHtmlProps = Omit<HtmlProps, "calculatePosition" | "position"> & {
  /** The 3D position of the HTML element */
  position: Vector3;
};

/** @returns A R3F's HTML component whose position is kept synchronized without the need for the position property object to change */
export function PositionedHtml({
  position,
  children,
  ...rest
}: PositionedHtmlProps): JSX.Element {
  // This callback is executed at every frame, so if the value of position changes, but the object containing
  // these values does not, the position is kept synchronized
  const calculatePosition = useCallback(
    (e: Object3D, camera: Camera, size: { width: number; height: number }) => {
      if (!groupRef.current) return [0, 0];

      const objectPos = TEMP_VEC_1.copy(position).applyMatrix4(
        groupRef.current.matrixWorld,
      );
      objectPos.project(camera);
      const widthHalf = size.width / 2;
      const heightHalf = size.height / 2;
      const pos = [
        objectPos.x * widthHalf + widthHalf,
        -(objectPos.y * heightHalf) + heightHalf,
      ];
      e.position.copy(position);
      e.updateWorldMatrix(true, false);
      return pos;
    },
    [position],
  );

  const groupRef = useRef<Group>(null);

  return (
    <group ref={groupRef}>
      <AppAwareHtml calculatePosition={calculatePosition} {...rest}>
        {children}
      </AppAwareHtml>
    </group>
  );
}
