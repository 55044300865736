import {
  CloudToCadAlignmentStep,
  setStepForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface AlignmentProgressBarProps {
  /** A callback that is called when the user wants to apply the alignment */
  apply(): void;
}

/**
 * @returns The bar that is shown in the top of cloud-to-cad alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function CloudToCadAlignmentProgressBar({
  apply,
}: AlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();

  /** defines steps for CloudToCad alignment progressbar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: CloudToCadAlignmentStep.setElevations,
        label: "Elevation",
        onBeforeNext: () => {
          dispatch(setStepForCloudAlignment(CloudToCadAlignmentStep.alignIn2d));
          return Promise.resolve();
        },
      },
      {
        key: CloudToCadAlignmentStep.alignIn2d,
        label: "Align",
        onBeforeBack: () => {
          dispatch(
            setStepForCloudAlignment(CloudToCadAlignmentStep.setElevations),
          );
          return Promise.resolve();
        },
      },
    ],
    [dispatch],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      onLastStepButtonClicked={apply}
      sideMargin={0}
      topMargin={0}
    />
  );
}
