import {
  selectIsRegistrationInProgress,
  selectLastRegistrationPose,
  selectPointCloudTransform,
} from "@/registration-tools/common/store/registration-selectors";
import {
  setLastRegistrationPoseUsed,
  setPointCloudTransform,
} from "@/registration-tools/common/store/registration-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { Banner } from "@faro-lotv/flat-ui";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Matrix4 } from "three";
import { getModelWorldTransform } from "../utils/registration";

type AutomaticRegistrationResetProps = {
  /** The reference point cloud */
  activeRefPointCloud: IElementGenericPointCloudStream;
};

/**
 * @returns Component that renders a banner if the adjustable point cloud was moved after an automatic registration result was applied,
 * allows users to reset point cloud pose to automatic registration result
 */
export function AutomaticRegistrationReset({
  activeRefPointCloud,
}: AutomaticRegistrationResetProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  const [isRegistrationResetBannerOpen, setIsRegistrationResetBannerOpen] =
    useState(false);
  const modelCloudTransformArray = useAppSelector(selectPointCloudTransform);
  const lastRegistrationPose = useAppSelector(selectLastRegistrationPose);
  const isRegistrationInProgress = useAppSelector(
    selectIsRegistrationInProgress,
  );

  const modelCloudMatrix4 = useMemo(() => {
    if (!modelCloudTransformArray) {
      return null;
    }
    return new Matrix4().fromArray(modelCloudTransformArray);
  }, [modelCloudTransformArray]);

  const lastRegistrationWorldTransform = useMemo(() => {
    if (!lastRegistrationPose) {
      return null;
    }
    return getModelWorldTransform(
      lastRegistrationPose,
      activeRefPointCloud,
      getState(),
    );
  }, [activeRefPointCloud, getState, lastRegistrationPose]);

  const currentPoseEqualsLastRegistrationResult = useMemo(() => {
    const equalsLastRegResult = isEqual(
      modelCloudMatrix4,
      lastRegistrationWorldTransform,
    );
    dispatch(
      setLastRegistrationPoseUsed(
        equalsLastRegResult && lastRegistrationPose !== undefined,
      ),
    );
    return equalsLastRegResult;
  }, [
    dispatch,
    lastRegistrationPose,
    lastRegistrationWorldTransform,
    modelCloudMatrix4,
  ]);

  const shouldRenderBanner = useMemo(() => {
    // Banner should be hidden while an automatic registration is running or if the point cloud was not moved
    if (isRegistrationInProgress || currentPoseEqualsLastRegistrationResult) {
      return false;
    }
    return true;
  }, [currentPoseEqualsLastRegistrationResult, isRegistrationInProgress]);

  // update the transform received from backend to be applied in the viewer
  const resetTransformToRegistrationResult = useCallback(() => {
    if (!lastRegistrationWorldTransform) {
      return;
    }
    dispatch(setPointCloudTransform(lastRegistrationWorldTransform.toArray()));
  }, [dispatch, lastRegistrationWorldTransform]);

  useEffect(() => {
    if (!lastRegistrationWorldTransform || !modelCloudMatrix4) {
      return;
    }
    setIsRegistrationResetBannerOpen(shouldRenderBanner);
  }, [
    activeRefPointCloud,
    dispatch,
    getState,
    isRegistrationInProgress,
    lastRegistrationPose,
    lastRegistrationWorldTransform,
    modelCloudMatrix4,
    shouldRenderBanner,
  ]);

  // Do not render component
  if (!isRegistrationResetBannerOpen) {
    return null;
  }

  // Using absolute position here with z-index to avoid resizing the canvas and render on top
  return (
    <Banner
      sx={{ position: "absolute", width: "100%", zIndex: 1, x: 0, y: 0 }}
      variant="warning"
      title="Manual adjustment detected"
      action={{
        label: "Revert",
        action: () => {
          resetTransformToRegistrationResult();
          setIsRegistrationResetBannerOpen(false);
        },
      }}
    >
      Adjustable point cloud was moved after automatic registration. You can
      revert to automatic registration result.
    </Banner>
  );
}
