import { assert } from "@faro-lotv/foundation";
import { quaternionToAxisAngle } from "@faro-lotv/lotv";
import { Quaternion, Vector3, Vector3Tuple, Vector4Tuple } from "three";
import { urlDecodeNumber, urlEncodeNumber } from "./url-encoding-utils";

/** A character used to separate the single camera values in the encoded string*/
const OBB_ENCODING_SEPARATOR = "_";

export type OrientedBoundingBox = {
  /** The center of the clipping box*/
  position: Vector3Tuple;

  /** The clipping box rotation, captured as the x, y, z, w components of a quaternion */
  rotation: Vector4Tuple;

  /** The size of the clipping box */
  size: Vector3Tuple;
};

/**
 * @param parameters to encode
 * @returns a string encoding the clipping parameters
 */
export function encodeOrientedBoundingBox(
  parameters: OrientedBoundingBox,
): string {
  const quaternionValues = quaternionToUrlValues(
    parameters.rotation,
    computeObbAngleFactor(parameters.size),
  );

  return [...parameters.position, ...quaternionValues, ...parameters.size]
    .map(urlEncodeNumber)
    .join(OBB_ENCODING_SEPARATOR);
}

export function decodeOrientedBoundingBox(payload: string): OrientedBoundingBox;
export function decodeOrientedBoundingBox(
  payload?: string,
): OrientedBoundingBox | undefined;
/**
 * @param payload to parse
 * @returns the ClippingParameters encoded in the payload
 * @throws if the payload can't be parsed
 */
export function decodeOrientedBoundingBox(
  payload?: string,
): OrientedBoundingBox | undefined {
  if (!payload) return;
  const values = payload.split(OBB_ENCODING_SEPARATOR).map(urlDecodeNumber);

  assert(
    values.length === 10,
    "Unable to parse payload as a set of ClippingParameters",
  );

  const position: Vector3Tuple = [values[0], values[1], values[2]];
  const quaternionValues: Vector4Tuple = [
    values[3],
    values[4],
    values[5],
    values[6],
  ];
  const size: Vector3Tuple = [values[7], values[8], values[9]];
  const rotation = quaternionFromUrlValues(
    quaternionValues,
    computeObbAngleFactor(size),
  );

  return {
    position,
    rotation,
    size,
  };
}

/**
 * @param sizes of the OBB
 * @returns a factor to multiply the angles for, to make sure we retain enough precision for big OBBs (min factor 1)
 */
function computeObbAngleFactor(sizes: number[]): number {
  return Math.max(1, ...sizes);
}

/**
 * @param quaternion to convert
 * @param factor to apply to all angles
 * @returns the values to encode in the url
 */
function quaternionToUrlValues(
  quaternion: Vector4Tuple,
  factor: number,
): Vector4Tuple {
  const [axis, angle] = quaternionToAxisAngle(
    new Quaternion().fromArray(quaternion),
  );

  return [axis.x, axis.y, axis.z, angle * factor];
}

/**
 * @param values for the quaternion encoded in the url (see @quaternionToUrlValues)
 * @param factor to apply to all angles
 * @returns the four quaternion values
 */
function quaternionFromUrlValues(
  values: Vector4Tuple,
  factor: number,
): Vector4Tuple {
  const axis = new Vector3().fromArray([values[0], values[1], values[2]]);
  const angle = values[3] / factor;
  const quat = new Quaternion().setFromAxisAngle(axis, angle);
  return [quat.x, quat.y, quat.z, quat.w];
}
