import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText, cyan, neutral } from "@faro-lotv/flat-ui";
import { removeExtension } from "@faro-lotv/foundation";
import {
  IElemLinkCommand,
  IElement,
  isIElemLinkCommand,
  isIElementAreaSection,
  isIElementImg360,
  isIElementWithFileUri,
} from "@faro-lotv/ielement-types";
import {
  isIElementVideoModeCopy,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { Link } from "@mui/material";
import { AnnotationWrapper } from "../annotation-wrapper";
import {
  AnnotationProps,
  isIElementLinkToExternal,
  isIElementWalkActiveElement,
} from "../annotations-types";

/** @returns A renderer for an annotation element */
export function GeneralLinkAnnotationRenderer({
  iElement,
  worldTransform,
  onTargetElementChanged,
}: AnnotationProps): JSX.Element {
  const linkToExternal = useAppSelector(
    selectChildDepthFirst(iElement, isIElementLinkToExternal),
  );
  const linkToIElement = useAppSelector(
    selectChildDepthFirst(iElement, isIElemLinkCommand),
  );
  if (linkToIElement) {
    return (
      <NavigationAnnotation
        iElement={iElement}
        linkToIElement={linkToIElement}
        worldTransform={worldTransform}
        onTargetElementChanged={onTargetElementChanged}
      />
    );
  }

  return (
    <AnnotationWrapper iElement={iElement} worldTransform={worldTransform}>
      {linkToExternal?.uri && (
        <Link href={linkToExternal.uri} target="_blank" color={cyan[500]}>
          <FaroText variant="hyperLink" noWrap>
            {isIElementWithFileUri(linkToExternal) && linkToExternal.fileName
              ? removeExtension(linkToExternal.fileName)
              : linkToExternal.uri}
          </FaroText>
        </Link>
      )}
    </AnnotationWrapper>
  );
}

interface NavigationAnnotationProps extends AnnotationProps {
  /** An iElement which links to another element in the project */
  linkToIElement: IElemLinkCommand;
}

/**
 * @returns An annotation element that links to another element in the project
 */
function NavigationAnnotation({
  iElement,
  linkToIElement,
  worldTransform,
  onTargetElementChanged,
}: NavigationAnnotationProps): JSX.Element {
  const targetElement = useAppSelector(
    selectIElement(linkToIElement.target_Id),
  );
  const target = useAppSelector(selectTargetElement(targetElement));

  return (
    <AnnotationWrapper
      iElement={iElement}
      worldTransform={worldTransform}
      onClick={() => {
        if (target) onTargetElementChanged?.(target);
      }}
    >
      <FaroText variant="buttonM" color={neutral[0]} noWrap>
        Go to:{" "}
        {targetElement?.name ??
          // Since HB adds "Go to" to the name of the annotation iElement by default, it is removed here to not have duplicate text
          linkToIElement.name.replace(/go to /i, "")}
      </FaroText>
    </AnnotationWrapper>
  );
}

/**
 * @returns the targetElement of a link annotation, an img360 if the target element is a video mode copy
 * otherwise returns a room section.
 * @param iElement iElement which contains the information of the linked element
 */
function selectTargetElement(iElement?: IElement) {
  return (state: RootState) => {
    // Check if the target element is a video mode copy
    const videoModeCopy = selectChildDepthFirst(
      iElement,
      isIElementVideoModeCopy,
    )(state);

    if (videoModeCopy) {
      const area = selectAncestor(videoModeCopy, isIElementAreaSection)(state);
      const targetElement360 = selectChildDepthFirst(
        area,
        (iElement) =>
          isIElementImg360(iElement) && iElement.targetId === videoModeCopy.id,
      )(state);

      if (targetElement360 && isIElementWalkActiveElement(targetElement360)) {
        return targetElement360;
      }
    }

    return selectChildDepthFirst(iElement, isIElementWalkActiveElement)(state);
  };
}
