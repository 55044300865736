import {
  getDefaultMaximumModelSize,
  setDefaultMaxByteToLoad,
} from "@/store/cad/cad-slice";
import { store } from "@/store/store";
import { LotvRenderer } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Scene, WebGLRenderer } from "three";
import { create } from "zustand";

export type SupportedRenderer = WebGLRenderer | LotvRenderer;

export type ThreeContext = {
  /** The current renderer used by the canvas element */
  renderer?: SupportedRenderer;

  /** Set the renderer value */
  setRenderer(renderer: SupportedRenderer): void;

  /** Current main scene */
  scene?: Scene;

  /** Set the current main scene */
  setScene(scene: Scene): void;
};

/** Context useful to share threeJS' main components to model loading functions and to other code modules. */
export const useThreeContext = create<ThreeContext>((set) => ({
  renderer: undefined,
  setRenderer: (renderer) => {
    set({ renderer });
    // update maxByteToLoad from new renderer
    store.dispatch(
      setDefaultMaxByteToLoad(getDefaultMaximumModelSize(renderer)),
    );
  },
  scene: undefined,
  setScene: (scene) => set({ scene }),
}));

/** @returns A component to place in a R3F canvas to capture the three context */
export function ThreeContextTracker(): null {
  const scene = useThree((s) => s.scene);
  const gl = useThree((s) => s.gl);

  const { setRenderer, setScene } = useThreeContext();

  useEffect(() => {
    setRenderer(gl);
  }, [gl, setRenderer]);

  useEffect(() => {
    setScene(scene);
  }, [scene, setScene]);

  return null;
}
