import {
  OrthoFrustum,
  UPDATE_CONTROLS_PRIORITY,
  isControlsWithCamera,
  parseVector3,
  useControlsLock,
} from "@faro-lotv/app-component-toolbox";
import {
  AnimationManager,
  ObjectPropertyAnimation,
  ParallelAnimation,
} from "@faro-lotv/lotv";
import {
  Camera,
  Vector3 as Vector3Prop,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { useEffect, useMemo, useState } from "react";
import { OrthographicCamera, PerspectiveCamera, Quaternion } from "three";

/**
 * What can be animated of a camera
 */
export type CameraAnimationData = {
  /** What position we want the camera to go to */
  position?: Vector3Prop;

  /** What rotation the camera should have */
  quaternion?: [number, number, number, number] | Quaternion;

  /** The target fov for the camera */
  fov?: number;

  /** The target zoom value for the camera */
  zoom?: number;

  /** The number of seconds the animation should take */
  duration?: number;

  /** The target frustum (top, right, bottom, left) if the camera is orthographic */
  frustum?: OrthoFrustum;
};

/**
 * Props for a CameraAnimation
 */
export type CameraAnimationProps = CameraAnimationData & {
  /** The camera to animate. @default the main r3f scene camera */
  camera?: Camera;

  /** Callback to signal the animation finished */
  onAnimationFinished?(): void;
};

/**
 * @returns a component to animate a camera to a specific pose
 */
export function CameraAnimation({
  camera: cameraProp,
  position,
  quaternion,
  fov,
  zoom,
  frustum,
  duration = 1,
  onAnimationFinished,
}: CameraAnimationProps): null {
  // The animation manager to update all the internal animations
  const animationManager = useMemo(() => new AnimationManager(), []);
  // The camera to animate
  const defaultCamera = useThree((s) => s.camera);
  // Getter of the R3F state, to get the controls
  const get = useThree((s) => s.get);

  const camera = cameraProp ?? defaultCamera;

  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  useControlsLock(isAnimationRunning);

  useEffect(() => {
    const animation = new ParallelAnimation([]);
    if (position) {
      animation.append(
        new ObjectPropertyAnimation(
          camera,
          "position",
          camera.position,
          parseVector3(position),
          { duration },
        ),
      );
    }
    if (quaternion) {
      animation.append(
        new ObjectPropertyAnimation(
          camera,
          "quaternion",
          camera.quaternion,
          Array.isArray(quaternion)
            ? new Quaternion().fromArray(quaternion)
            : quaternion,
          { duration },
        ),
      );
    }
    if (zoom) {
      animation.append(
        new ObjectPropertyAnimation(camera, "zoom", camera.zoom, zoom, {
          duration,
        }),
      );
    }
    if (fov && camera instanceof PerspectiveCamera) {
      animation.append(
        new ObjectPropertyAnimation(camera, "fov", camera.fov, fov, {
          duration,
        }),
      );
    }

    if (camera instanceof OrthographicCamera && frustum) {
      animation.append(
        new ObjectPropertyAnimation(camera, "left", camera.left, frustum.left, {
          duration,
        }),
      );
      animation.append(
        new ObjectPropertyAnimation(
          camera,
          "right",
          camera.right,
          frustum.right,
          { duration },
        ),
      );
      animation.append(
        new ObjectPropertyAnimation(camera, "top", camera.top, frustum.top, {
          duration,
        }),
      );
      animation.append(
        new ObjectPropertyAnimation(
          camera,
          "bottom",
          camera.bottom,
          frustum.bottom,
          { duration },
        ),
      );
    }

    const { controls } = get();

    const disposeOnStart = animation.started.on(() => {
      setIsAnimationRunning(true);
    });

    // When the animation finishes, sync the camera to the controls
    const disposeOnStop = animation.completed.on(() => {
      if (isControlsWithCamera(controls)) controls.camera = camera;
      if (onAnimationFinished && !animation.canceled) {
        onAnimationFinished();
      }

      setIsAnimationRunning(false);
    });
    animationManager.run(animation);
    return () => {
      animation.cancel();
      disposeOnStart.dispose();
      disposeOnStop.dispose();
    };
  }, [
    animationManager,
    camera,
    duration,
    fov,
    frustum,
    get,
    onAnimationFinished,
    position,
    quaternion,
    zoom,
  ]);

  // Update the animation at every frame and the camera projection too
  // if fov need to change
  useFrame((_, delta) => {
    animationManager.update(delta);
    camera.updateProjectionMatrix();
    // Setting below the UPDATE_CONTROLS_PRIORITY to ensure that
    // the projection is updated before any camera sync or LOD visibility computation.
  }, UPDATE_CONTROLS_PRIORITY);

  return null;
}
