import { ExtractCrossSectionIcon } from "@faro-lotv/flat-ui";
import { ToggleButton, Tooltip } from "@mui/material";

export type ToggleCrossSectionButtonProps = {
  /** state of the button: if true - enabled */
  isEnabled: boolean;

  /** callback to perform action on toggle */
  onToggleCrossSections(): void;

  /** cross-section elevation*/
  elevation: number;
};

/**
 * @returns tomographic view toggle cross-section vs full CAD/cloud
 */
export function CrossSectionToggleButton({
  isEnabled,
  elevation,
  onToggleCrossSections,
}: ToggleCrossSectionButtonProps): JSX.Element {
  const tooltip = isEnabled
    ? "Deactivate cross-section"
    : `Activate cross-section at elevation ${elevation.toFixed(3)}`;

  return (
    <Tooltip title={tooltip}>
      <ToggleButton
        value="cross-section"
        selected={isEnabled}
        onClick={onToggleCrossSections}
        sx={{
          width: 36,
          height: 36,
          position: "absolute",
          left: 10,
          top: "43%",
        }}
      >
        <ExtractCrossSectionIcon />
      </ToggleButton>
    </Tooltip>
  );
}
