import { selectDefaultModeFor } from "@/modes";
import { RootState } from "@/store/store";
import { selectCanReadPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import { GUID } from "@faro-lotv/foundation";
import {
  IElementTypeHint,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectIElement,
  selectIElementChildren,
  selectIsDataSessionEmpty,
} from "@faro-lotv/project-source";
import { TreeNodeDisabledReason } from "../tree-types";

/**
 * @returns a reason why a node is disabled or undefined otherwise
 * @param id the iElement id of the node
 */
export function selectTreeNodeDisabledReason(id: GUID) {
  return (state: RootState): TreeNodeDisabledReason | undefined => {
    const element = selectIElement(id)(state);
    if (!element) return;

    if (isIElementSectionDataSession(element)) {
      const isPointCloudOnly = selectIElementChildren(element.id)(state).every(
        (el) => el.typeHint === IElementTypeHint.dataSetPCloudUpload,
      );

      // Check if the data session only contains a point cloud with no 2d data
      // and the company does not have the point cloud bundle
      // So that we can display a specific message
      const isMissingPCPermission =
        isPointCloudOnly && !selectCanReadPointCloud(state);

      if (isMissingPCPermission) {
        return TreeNodeDisabledReason.missingPointCloudPermission;
      }

      const isDataSessionEmpty = selectIsDataSessionEmpty(element)(state);

      // Disable data session if it's empty (no path, nor stream, nor floorplan)
      if (isDataSessionEmpty) {
        return TreeNodeDisabledReason.notSupported;
      }
    }

    // Check if the viewer can display the element
    if (selectDefaultModeFor(element)(state) === undefined) {
      return TreeNodeDisabledReason.notSupported;
    }
  };
}
