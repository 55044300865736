import { Divider } from "@mui/material";
import { Fragment } from "react";
import { ToAlignCard } from "../other-cards/to-align-card";
import { TaskCard } from "../progress-overview-card";
import { ProcessInfoSource } from "../progress-overview-selectors";
import { ExportTaskCard } from "../tasks-cards/export-task-card";

type ProgressOverviewTabProps = {
  /** The information about the tasks showed in this tab */
  processes: ProcessInfoSource[];
};

/** @returns The tab used for import and registration tasks */
export function ProgressOverviewTab({
  processes,
}: ProgressOverviewTabProps): JSX.Element {
  return (
    <>
      {processes.map((process, i) => {
        let card: JSX.Element;
        switch (process.type) {
          case "ElementToAlignSection":
            card = <ToAlignCard iElementID={process.id} />;
            break;
          case "Export":
            card = <ExportTaskCard taskID={process.id} />;
            break;
          default:
            card = <TaskCard taskID={process.id} />;
            break;
        }

        return (
          <Fragment key={process.id}>
            {card}
            {i !== processes.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </>
  );
}
