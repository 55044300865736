import { alignmentReducer } from "@/alignment-tool/store/alignment-slice";
import { alignmentUiReducer } from "@/alignment-tool/store/alignment-ui/alignment-ui-slice";
import { dataPreparationUiReducer } from "@/data-preparation-tool/store/data-preparation-ui/data-preparation-ui-slice";
import { dataPreparationViewOptionsReducer } from "@/data-preparation-tool/store/data-preparation-view-options/data-preparation-view-options-slice";
import { revisionReducer } from "@/data-preparation-tool/store/revision-slice";
import { registrationParametersReducer } from "@/registration-tools/common/store/registration-parameters/registration-parameters-slice";
import { registrationReducer } from "@/registration-tools/common/store/registration-slice";
import { iElementsReducer } from "@faro-lotv/project-source";
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { backgroundTasksReducer } from "./background-tasks/background-tasks-slice";
import { cadReducer } from "./cad/cad-slice";
import { clippingBoxReducer } from "./clipping-box-slice";
import { createAnnotationReducer } from "./create-annotation-slice";
import { featuresReducer } from "./features/features-slice";
import { integrationsReducer } from "./integrations/integrations-slice";
import { measurementToolReducer } from "./measurement-tool-slice";
import { miniMapReducer } from "./minimap-slice";
import { changeMode, modeReducer } from "./mode-slice";
import { modesReducers } from "./modes";
import { pointCloudAnalysisToolReducer } from "./point-cloud-analysis-tool-slice";
import { projectReducer } from "./project-slice";
import { selectionsReducer, setActiveElement } from "./selections-slice";
import { subscriptionsReducer } from "./subscriptions/subscriptions-slice";
import { tagsReducer } from "./tags/tags-slice";
import { uiReducer } from "./ui/ui-slice";
import { userReducer } from "./user-slice";
import { viewOptionsReducer } from "./view-options/view-options-slice";

const actionCreators = {
  setActiveElement,
  changeMode,
};

export const rootReducer = combineReducers({
  backgroundTasks: backgroundTasksReducer,
  features: featuresReducer,
  iElements: iElementsReducer,
  measurementTool: measurementToolReducer,
  miniMap: miniMapReducer,
  mode: modeReducer,
  selections: selectionsReducer,
  cad: cadReducer,
  ui: uiReducer,
  user: userReducer,
  subscriptions: subscriptionsReducer,
  integrations: integrationsReducer,
  project: projectReducer,
  createAnnotation: createAnnotationReducer,
  clippingBox: clippingBoxReducer,
  tags: tagsReducer,
  viewOptions: viewOptionsReducer,
  pointCloudAnalysisTool: pointCloudAnalysisToolReducer,

  ...modesReducers,

  revision: revisionReducer,
  dataPreparationViewOptions: dataPreparationViewOptionsReducer,
  dataPreparationUi: dataPreparationUiReducer,

  // NOTE: Should only be used from within the AlignmentTool
  alignment: alignmentReducer,
  alignmentUi: alignmentUiReducer,

  // The reducer below should only be used from the manual alignment tool
  registration: registrationReducer,
  registrationParameters: registrationParametersReducer,
});

export const store = configureStore({
  reducer: rootReducer,

  // Add selectors to dropdown in the dev tools
  devTools: {
    actionCreators,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
