import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, activateTool, deactivateTool } from "@/store/ui/ui-slice";
import {
  HelpBanner,
  SheetSaturationToggleIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { Collapse, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import { useFloorScaleContext } from "./floor-scale-mode-context";

/** @returns The overlay elements of this mode */
export function FloorScaleModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const activeTool = useAppSelector(selectActiveTool);

  const { shouldShowHelpBanner } = useFloorScaleContext();

  /** Activates the desaturation tool by default on initial render */
  useEffect(() => {
    dispatch(activateTool(ToolName.desaturateSheet));

    return () => {
      dispatch(deactivateTool());
    };
  }, [dispatch]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {/* first empty column of a three column layout */}
      <div />
      <Collapse in={shouldShowHelpBanner}>
        <HelpBanner>
          Click two points on the sheet and specify the real-world distance.
        </HelpBanner>
      </Collapse>
      <Stack justifyContent="center">
        <Toolbar sx={{ height: "max-content" }}>
          <ToolGroup>
            <Tooltip title="Toggle desaturation of sheet" placement="left">
              <ToolButton
                selected={activeTool === ToolName.desaturateSheet}
                onClick={() =>
                  activeTool === ToolName.desaturateSheet
                    ? dispatch(deactivateTool())
                    : dispatch(activateTool(ToolName.desaturateSheet))
                }
              >
                <SheetSaturationToggleIcon
                  sx={{ width: "24px", height: "24px" }}
                />
              </ToolButton>
            </Tooltip>
          </ToolGroup>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
