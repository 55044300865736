import { ParametersMenu } from "@/registration-tools/common/parameters-menu";
import { ParameterMenuIcon } from "@/registration-tools/icons/manual-alignment-icons";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { FaroIconButton } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { useCallback, useRef, useState } from "react";

/** @returns dev-only menu to start a new registration with different parameters */
export function DataPrepParametersMenu(): JSX.Element | null {
  const parameterMenuButtonRef = useRef<HTMLButtonElement>(null);

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(
    () => setIsMenuOpen((prevOpen) => !prevOpen),
    [],
  );

  if (!hasRegistrationDevFeature) {
    return null;
  }

  return (
    <>
      <Stack direction="row">
        <FaroIconButton ref={parameterMenuButtonRef} onClick={toggleMenu}>
          <ParameterMenuIcon />
        </FaroIconButton>
      </Stack>

      <ParametersMenu
        open={isMenuOpen}
        anchorEl={parameterMenuButtonRef.current}
        onClose={toggleMenu}
      />
    </>
  );
}
