import {
  EventType,
  FilterAnnotationListEventProperties,
  SortAnnotationListEventProperties,
} from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { TextField } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  AnnotationStatus,
  GUID,
  IElement,
  IElementType,
  isIElementMarkup,
} from "@faro-lotv/ielement-types";
import { addIElements } from "@faro-lotv/project-source";
import { ProjectApiError, useApiClientContext } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { Dates } from "./annotation-filters/date-filter";
import { AnnotationList } from "./annotation-list";
import {
  AnnotationFilteringOptions,
  selectFilteredAndSortedMarkups,
} from "./annotation-panel-selector";
import { FilterAndSortPanel } from "./filter-and-sort-panel";
import { AnnotationSortingOptions } from "./sort-menu-button";

const MARKUPS_PER_PAGE = 50;

/**
 * Fetches all markups of the current project, regardless of the currently active area.
 *
 * @returns true if more data are still being fetched
 */
function useFetchAllMarkups(): boolean {
  const { projectApiClient: client } = useApiClientContext();

  const { handleErrorWithDialog } = useErrorHandlers();

  // The 'markupsLoading' state is initialized to 'true' because
  // initially the fetching still has to be performed, and the GUI
  // should already show convenient visual cues such as skeletons.
  const [markupsLoading, setMarkupsLoading] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setMarkupsLoading(true);
    const ac = new AbortController();

    // Callback executed when a new page of markups is fetched
    async function nextPageFetched(
      _: number,
      result: IElement[],
    ): Promise<void> {
      if (ac.signal.aborted) {
        return Promise.resolve();
      }

      // Fetch the children of the markups, so that all infos are available
      const markups = result.filter(isIElementMarkup);
      const elements = await client.getAllIElements({
        ancestorIds: markups.map((m) => m.id),
      });
      dispatch(addIElements(elements));
    }

    // When the app starts and this annotation panel is created,
    // all markups of the project are fetched and loaded into the panel,
    // regardless of the currently active area.
    client
      .getAllIElements({
        signal: ac.signal,
        types: [IElementType.markup],
        itemsPerPage: MARKUPS_PER_PAGE,
        onNextPageFetched: nextPageFetched,
      })
      .catch((e: ProjectApiError) => {
        if (ac.signal.aborted) {
          return;
        }
        handleErrorWithDialog({
          title: "Error while fetching all annotations",
          error: e,
        });
      })
      .finally(() => {
        // Do not set markups loading to false if the fetch was aborted
        // to not have a flashing loading notification when react re-mount the node
        if (!ac.signal.aborted) {
          setMarkupsLoading(false);
        }
      });

    return () => ac.abort();
  }, [client, dispatch, handleErrorWithDialog]);

  return markupsLoading;
}

/** @returns the Panel to search and interact with all the project annotations */
export function AnnotationPanel(): JSX.Element {
  const [assignee, setAssignee] = useState<GUID[]>([]);
  const [tags, setTags] = useState<GUID[]>([]);
  const [status, setStatus] = useState<AnnotationStatus[]>([]);
  const [date, setDate] = useState<Dates>();
  const [sorting, setSorting] = useState<AnnotationSortingOptions>();
  const [searchText, setSearchText] = useState<string>("");
  const filteringOptions = useMemo<AnnotationFilteringOptions>(
    () => ({
      assignee,
      tags,
      status,
      date,
      sorting,
      searchText,
    }),
    [assignee, date, sorting, status, tags, searchText],
  );

  const markupsLoading = useFetchAllMarkups();

  const markups = useAppSelector((state) =>
    selectFilteredAndSortedMarkups(state, filteringOptions),
  );

  useTrackFilteringOptions(filteringOptions);

  return (
    <Stack
      sx={{ p: 1, m: 0, width: "100%", height: "100%", overflow: "hidden" }}
    >
      <TextField
        placeholder="Search"
        fullWidth
        onTextChanged={setSearchText}
        text={searchText}
        controlStyle={{ pr: 2 }}
      />
      <FilterAndSortPanel
        hasFilters={
          assignee.length > 0 || tags.length > 0 || status.length > 0 || !!date
        }
        onSelectedAssigneesChanged={setAssignee}
        onSelectedTagsChanged={setTags}
        onSelectedStatusChanged={setStatus}
        onSelectedDateChanged={setDate}
        onSortingChanged={setSorting}
      />
      <AnnotationList markups={markups} markupsLoading={markupsLoading} />
    </Stack>
  );
}

function useTrackFilteringOptions(
  filteringOptions: AnnotationFilteringOptions,
): void {
  const hasSearchFilter = !!filteringOptions.searchText;
  const hasUserFilter = filteringOptions.assignee.length > 0;
  const hasTagsFilter = filteringOptions.tags.length > 0;
  const hasStatusFilter = filteringOptions.status.length > 0;
  const hasDueDateFilter = !!filteringOptions.date;

  // Track filtering changes
  useEffect(() => {
    if (
      hasSearchFilter ||
      hasUserFilter ||
      hasTagsFilter ||
      hasStatusFilter ||
      hasDueDateFilter
    ) {
      Analytics.track<FilterAnnotationListEventProperties>(
        EventType.sortAnnotationList,
        {
          hasSearchFilter,
          hasUserFilter,
          hasTagsFilter,
          hasStatusFilter,
          hasDueDateFilter,
        },
      );
    }
  }, [
    hasDueDateFilter,
    hasSearchFilter,
    hasStatusFilter,
    hasTagsFilter,
    hasUserFilter,
  ]);

  // Track sorting changes
  useEffect(() => {
    if (filteringOptions.sorting) {
      Analytics.track<SortAnnotationListEventProperties>(
        EventType.sortAnnotationList,
        {
          sortBy: filteringOptions.sorting,
        },
      );
    }
  }, [filteringOptions.sorting]);
}
