import {
  CurrentAreaData,
  CurrentScene,
  selectDataSet360s,
  selectIsElementViewablePointCloudStream,
} from "@/modes/mode-selectors";
import { selectClosestPanoToPosition } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { SceneFilter } from "@/types/scene-filter";
import {
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementDepthMap,
  IElementImg360,
  IElementPointCloudStream,
  isIElementDepthMap,
  isIElementImg360,
  isIElementPointCloudStream,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import { Vector3 } from "three";

/**
 * @param scene current active scene
 * @param cameraPosition position of the camera
 * @param shouldUseIntensityData true to prefer intensity panos if available
 * @returns the 360 in the scene that is closest to the camera
 */
function selectBest360(
  scene: CurrentScene,
  cameraPosition: Vector3,
  shouldUseIntensityData: boolean,
) {
  return (state: RootState): IElementImg360 | undefined => {
    let panos = selectDataSet360s(
      scene.activeElement,
      true,
      shouldUseIntensityData,
    )(state);
    if (panos.length === 0) {
      panos = [
        ...scene.panos,
        ...scene.paths.flatMap((path) =>
          selectChildrenDepthFirst(path, isIElementImg360)(state),
        ),
      ];
    }
    return selectClosestPanoToPosition(panos, cameraPosition)(state);
  };
}

/**
 * Given the current scene and area data, compute the new active element desired
 * based on the walk mode active type
 *
 * @param scene The current active scene
 * @param area The data available in the current area
 * @param cameraPosition The current camera position
 * @param newSceneFilter The new desired scene filter
 * @returns The best IElement for the current active type
 */
export function selectWalkSceneElements(
  scene: CurrentScene,
  area: CurrentAreaData,
  cameraPosition: Vector3,
  newSceneFilter?: SceneFilter,
) {
  return (state: RootState) => {
    function computePointCloud(): IElementPointCloudStream | undefined {
      if (isIElementPointCloudStream(scene.activeElement)) {
        return scene.activeElement;
      }

      const reference = scene.referenceElement ?? area.dataSessions.at(0);
      if (!reference) {
        return undefined;
      }

      const section = pickClosestInTime(reference, [
        ...area.dataSessions3d,
        ...area.dataSessions5d,
      ]);
      return section
        ? selectChildDepthFirst(section, (el): el is IElementPointCloudStream =>
            selectIsElementViewablePointCloudStream(el)(state),
          )(state)
        : undefined;
    }

    switch (newSceneFilter) {
      case SceneFilter.PointCloud: {
        return [computePointCloud()];
      }
      case SceneFilter.Cad: {
        return [scene.cad];
      }
      case SceneFilter.Pano: {
        // Compute closest pano to current camera position ignoring the hidden ones
        return [
          selectBest360(
            scene,
            cameraPosition,
            scene.shouldUseIntensityData,
          )(state),
        ];
      }
      case SceneFilter.Overlay: {
        const pointCloud = computePointCloud();
        const { cad } = scene;
        return [pointCloud, cad];
      }
      default:
        return [];
    }
  };
}

/**
 * Returns an IElementDepthMap associated to a given IElementImg360.
 *
 * @param pano The pano of which the depth data are queried
 * @returns Depth map associated to the give pano
 */
export function selectDepthMapForImg360(pano: IElementImg360 | undefined) {
  return (state: RootState): IElementDepthMap | undefined => {
    if (!pano) return;
    if (!pano.parentId) return;
    const parent = selectIElement(pano.parentId)(state);
    return selectChildDepthFirst(parent, isIElementDepthMap)(state);
  };
}

/**
 * Check if the current element is Img360 with depth information
 *
 * @param element The element of which the depth data are queried
 * @returns Depth map associated to the given element
 */
export function selectIsPanoWithDepth(element: IElement | undefined) {
  return (state: RootState): boolean => {
    if (!element || !isIElementImg360(element)) return false;
    return !!selectDepthMapForImg360(element)(state);
  };
}
