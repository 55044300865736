import {
  selectCrossSectionEnabled,
  selectSheetToCloudAlignmentSheetElevation,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import { setCrossSectionEnabled } from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";

/**
 * @returns sheet to cloud alignment overlay screen layout
 */
export function SheetToCloudAlignmentOverlayScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  const isCrossSectionEnabled = useAppSelector(selectCrossSectionEnabled);

  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CrossSectionToggleButton
        isEnabled={isCrossSectionEnabled}
        elevation={sheetElevation}
        onToggleCrossSections={() => {
          dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
        }}
      />
    </ViewDiv>
  );
}
