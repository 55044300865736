import { FaroTooltip, InfoIcon } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";

type AnnotationTypeOptionProps = {
  /** The text to display for the option */
  label: string;
  /** The logo to display for the option */
  logo?: JSX.Element;
};

/**
 * Defines how an annotation type option should look like inside the annotation type dropdown.
 *
 * @returns The JSX element representing the annotation type option.
 */
export function AnnotationTypeOption({
  label,
  logo,
}: AnnotationTypeOptionProps): JSX.Element {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {logo}
      {label}
    </Stack>
  );
}

type AnnotationTypeOptionHeaderProps = Pick<
  AnnotationTypeOptionProps,
  "label"
> & {
  /**
   * When a value is assigned a info icon is displayed and the value assigned will be
   * as tooltip when the user hovers over the info icon.
   */
  tooltipText?: string;
};

/**
 * Defines how an annotation type option header should look like inside the annotation type dropdown.
 *
 * @returns The JSX element representing the annotation type option header.
 */
export function AnnotationTypeOptionHeader({
  label,
  tooltipText,
}: AnnotationTypeOptionHeaderProps): JSX.Element {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {label}
      {tooltipText && (
        <FaroTooltip title={tooltipText}>
          <InfoIcon sx={{ height: "1rem", width: "1rem", marginRight: -0.5 }} />
        </FaroTooltip>
      )}
    </Stack>
  );
}
