import {
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface SheetToCloudAlignmentProgressBarProps {
  /** Method to call when the user confirms the alignment */
  applyAlignment(): void;
}

/**
 * @returns The bar that is shown in the top of the app
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function SheetToCloudAlignmentProgressBar({
  applyAlignment,
}: SheetToCloudAlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();

  /** defines steps for sheet to cloud alignment progressbar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: SheetToCloudAlignmentStep.setElevation,
        label: "Elevation",
        onBeforeNext: () => {
          dispatch(
            setStepForSheetToCloudAlignment(
              SheetToCloudAlignmentStep.alignIn2d,
            ),
          );
          return Promise.resolve();
        },
      },
      {
        key: SheetToCloudAlignmentStep.alignIn2d,
        label: "Align",
        onBeforeBack: () => {
          dispatch(
            setStepForSheetToCloudAlignment(
              SheetToCloudAlignmentStep.setElevation,
            ),
          );
          return Promise.resolve();
        },
      },
    ],
    [dispatch],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      onLastStepButtonClicked={applyAlignment}
      sideMargin={0}
      topMargin={0}
    />
  );
}
