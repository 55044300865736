import {
  EventType,
  SelectPointCloudFileEventProperties,
} from "@/analytics/analytics-events";
import { selectActiveArea } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { useToast } from "@faro-lotv/app-component-toolbox";
import {
  computeRotatedImageDimensions,
  parseImageFromFile,
  resizeImage,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, assert, getFileExtension } from "@faro-lotv/foundation";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  MAX_UPLOAD_CLOUD_FILE_SIZE_GB,
  MAX_UPLOAD_MODEL_FILE_SIZE_GB,
  PointCloudUploadDialog,
  SupportedPCFileExtensions,
  pointCloudTypeFromFileName,
} from "./point-cloud-upload-utils";
import { UploadElementType, useUploadElement } from "./use-upload-element";

type SetUploadFileCallback = (file: File, onFileUploaded?: () => void) => void;

type UploadAreaImageFileCallback = (
  name: string,
  areaId: GUID,
  rotationAngle: number,
  ...commonArgs: Parameters<SetUploadFileCallback>
) => void;

type ElementFileUploadContext = {
  /** Method allowing to set/change the currently selected Cad file */
  setCadFile: SetUploadFileCallback;

  /** Method allowing to set/change the currently selected PointCloud file */
  setPointCloudFile: SetUploadFileCallback;

  /** Method allowing to set/change the currently selected AreaImage file */
  uploadAreaImageFile: UploadAreaImageFileCallback;
};

/**
 * @param file image file
 * @param angle angle to rotate image in degrees
 * @returns rotated image
 *
 * Helper function to rotate image before saving to backend
 */
const rotateImage = async (file: File, angle: number): Promise<File> => {
  // Step 1: Create an image object from the file
  const img = await parseImageFromFile(file);

  const rotatedDimensions = computeRotatedImageDimensions(
    { width: img.width, height: img.height },
    angle,
  );

  // Step 2: Set up a canvas and rotate the image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  assert(
    ctx && canvas,
    "Unable to create an offscreen 2d context to render the pdf image",
  );

  canvas.width = rotatedDimensions.width;
  canvas.height = rotatedDimensions.height;

  ctx.translate(rotatedDimensions.width / 2, rotatedDimensions.height / 2);
  ctx.rotate((angle * Math.PI) / 180);
  ctx.drawImage(img, -img.width / 2, -img.height / 2);

  // Step 3: Convert the canvas back to a file (Blob or File)
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      assert(blob, "Unable to convert rotated image to blob");
      const rotatedFile = new File([blob], file.name, { type: file.type });
      resolve(rotatedFile);
    }, file.type);
  });
};

const ElementFileUploadContext = createContext<
  ElementFileUploadContext | undefined
>(undefined);

/**
 * @returns A context for storing the selected file by the user
 *
 * While such storage logic would be preferred to be done inside the Redux store, this can't be done for object that
 * can't be serialized (like File objects)
 */
export function ElementFileUploadContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { openToast } = useToast();

  const uploadCad = useUploadElement(UploadElementType.cad);
  const uploadAreaImage = useUploadElement(UploadElementType.areaImage);
  const [pointCloudFile, setPointCloudFile] = useState<File | undefined>();
  const [onPointCloudUploaded, setOnPointCloudUploaded] =
    useState<() => void>();

  const activeArea = useAppSelector(selectActiveArea);

  const handleCadFile = useCallback<SetUploadFileCallback>(
    (file, onFileUploaded) => {
      const fileSizeGB = file.size / 1024 ** 3;
      if (fileSizeGB > MAX_UPLOAD_MODEL_FILE_SIZE_GB) {
        openToast({
          title: `Input file larger than ${MAX_UPLOAD_MODEL_FILE_SIZE_GB}GB. Please try simplifying the model or uploading a subset of the model.`,
          variant: "error",
        });
        return;
      }

      uploadCad({
        file,
        name: file.name || "",
        createdAt: new Date(file.lastModified),
        onFileUploaded,
      });
    },
    [uploadCad, openToast],
  );

  /** Checking if the passed file object is a valid Point Cloud file (based on the extension) */
  const handlePointCloudFile = useCallback<SetUploadFileCallback>(
    (file, onFileUploaded) => {
      Analytics.track<SelectPointCloudFileEventProperties>(
        EventType.uploadPointCloud,
        {
          fileSize: file.size,
          extension: getFileExtension(file.name) ?? "",
        },
      );

      try {
        pointCloudTypeFromFileName(file.name);

        const fileSizeGB = file.size / 1024 ** 3;
        if (fileSizeGB > MAX_UPLOAD_CLOUD_FILE_SIZE_GB) {
          openToast({
            title: `File must be less than ${MAX_UPLOAD_CLOUD_FILE_SIZE_GB}GB`,
            variant: "error",
          });
          return;
        }

        setOnPointCloudUploaded(onFileUploaded);
        setPointCloudFile(file);
      } catch (error) {
        setOnPointCloudUploaded(undefined);
        setPointCloudFile(undefined);

        const supportedExtensions = new Intl.ListFormat("en-US").format(
          Object.values(SupportedPCFileExtensions),
        );
        openToast({
          title: "Failed to import file",
          message: `The file extension is not supported. Only ${supportedExtensions} files are supported.`,
          variant: "error",
        });
      }
    },
    [openToast],
  );

  const handleAreaImageFile = useCallback<UploadAreaImageFileCallback>(
    async (name, areaId, rotationAngle, file, onFileUploaded) => {
      try {
        const imageToSave =
          rotationAngle === 0 ? file : await rotateImage(file, rotationAngle);

        const thumbnailFile = await resizeImage(imageToSave);

        uploadAreaImage({
          file: imageToSave,
          additionalFiles: [thumbnailFile],
          areaId,
          name,
          createdAt: new Date(file.lastModified),
          onFileUploaded,
        });
      } catch (error) {
        openToast({
          title: "Upload of the sheet failed. Please, try again",
          variant: "error",
        });
      }
    },
    [openToast, uploadAreaImage],
  );

  return (
    <ElementFileUploadContext.Provider
      value={{
        setCadFile: handleCadFile,
        setPointCloudFile: handlePointCloudFile,
        uploadAreaImageFile: handleAreaImageFile,
      }}
    >
      {children}
      {pointCloudFile && activeArea && (
        <PointCloudUploadDialog
          area={activeArea}
          file={pointCloudFile}
          setPointCloudFile={setPointCloudFile}
          onFileUploaded={onPointCloudUploaded}
        />
      )}
    </ElementFileUploadContext.Provider>
  );
}

/**
 * @returns The ElementFileUploadContext context
 */
export function useElementFileUploadContext(): ElementFileUploadContext {
  const context = useContext(ElementFileUploadContext);

  if (!context) {
    throw Error(
      "useElementFileUploadContext() must be used within an ElementFileUploadContext",
    );
  }

  return context;
}
