import { UnknownObject, isPointCloudObject } from "@/object-cache-type-guard";
import { useEffect } from "react";

/**
 * Control if the object should use incremental real time raycasting or precise raycasting, will restore original value on unmount
 *
 * Realtime raycasting may not return the most precise point because it has a fixed budget for computing picking tree
 * and visting nodes, but the precision will improve with each raycasting. Useful while hovering on the point cloud in real time
 *
 * If the component is not hovering on the point cloud but only handling clicks it's better to use the precise raycasting that could take
 * more than 1 frame to complete but will always return the most precise point
 *
 * @param object to control the real time settings for
 * @param shouldPickInRealTime the condition to enable real time raycasting
 */
export function useRealtimeRaycasting(
  object: UnknownObject | null,
  shouldPickInRealTime: boolean,
): void {
  useEffect(() => {
    if (!object || !isPointCloudObject(object)) return;
    const previousValue = object.realTimeRaycasting;
    object.realTimeRaycasting = shouldPickInRealTime;
    return () => {
      object.realTimeRaycasting = previousValue;
    };
  }, [shouldPickInRealTime, object]);
}
