import { EnsureWriteAccess } from "@/permissions/ensure-project-access";
import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { CSSProperties, useMemo } from "react";
import { CreateAreaMainBody } from "./create-area-main-body";
import { CrateAreaTitle } from "./create-area-title";
import { InformationBox } from "./information-box";
import { LimitedAccess } from "./limited-access";

/** @returns The overlay elements of this mode */
export function CreateAreaModeOverlay(): JSX.Element | null {
  /** Style used to fit the entire page */
  const entirePageStyle = useMemo<CSSProperties>(
    () => ({
      position: "relative",
      // Set the size of the element to the entire page, minus the header bar
      width: "100%",
      height: "100%",
      overflow: "scroll",
    }),
    [],
  );

  return (
    <Stack sx={entirePageStyle}>
      <CreateAreaModeBody />
    </Stack>
  );
}

/**
 * @returns a component containing the entire body of the CreateArea mode Overlay
 */
function CreateAreaModeBody(): JSX.Element {
  const theme = useTheme();

  /** Style shared by the body of the overlay */
  const commonStyle = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: `${theme.palette.gray100}80`,
      width: "100%",
      py: 1.5,
      px: { xs: 2, md: 5 },
    }),
    [theme.palette.gray100],
  );

  return (
    <Stack alignSelf="center" width={{ xs: "90%", md: "80%" }}>
      <CrateAreaTitle
        sx={{
          mt: { xs: "1.5rem", md: "60px" },
        }}
      />
      <InformationBox sx={{ ...commonStyle, mt: { xs: "1rem", md: "24px" } }} />
      <EnsureWriteAccess
        fallback={<LimitedAccess sx={{ ...commonStyle, mt: "0.625rem" }} />}
      >
        <CreateAreaMainBody sx={{ ...commonStyle, mt: "24px" }} isFirstSheet />
      </EnsureWriteAccess>
    </Stack>
  );
}
