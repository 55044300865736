import {
  EventType,
  SwitchPointCloudStreamEventProperties,
} from "@/analytics/analytics-events";
import { useCurrentArea } from "@/modes/mode-data-context";
import {
  CurrentAreaData,
  selectActiveElementReference,
  selectIsPointCloudViewable,
} from "@/modes/mode-selectors";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroMenu,
  FaroMenuProps,
  PointCloudFlashSmallIcon,
  PointCloudIcon,
  PointCloudTrajectorySmallIcon,
  SingleSelectItem,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElement,
  IElementGenericPointCloudStream,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import {
  PointCloudType,
  selectChildrenDepthFirst,
  selectPointCloudType,
} from "@faro-lotv/project-source";
import { SvgIconProps } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback } from "react";

type PointCloudToggleMenuProps = FaroMenuProps & {
  /** Selector for getting the currently active element in this View */
  currentMainElement?: IElement;
  /** Callback for when the active element is changed */
  onActiveElementChanged(id: GUID): void;
};

/** @returns Toggle between the different point clouds within a dataset. */
export function PointCloudToggleMenu({
  currentMainElement,
  onActiveElementChanged,
  sx,
  ...rest
}: PointCloudToggleMenuProps): JSX.Element | null {
  const area = useCurrentArea();
  const pointCloudStreams = useAppSelector(
    selectViewablePointCloudStreamsInSameSession(currentMainElement, area),
    isEqual,
  );

  return (
    <FaroMenu
      dark
      sx={[
        {
          mt: 0.5,
          "& .MuiList-root": {
            display: "flex",
            flexFlow: "column",
            gap: 0.5,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {pointCloudStreams.map((pointCloudStream) => (
        <PointCloudToggleButton
          key={pointCloudStream.id}
          pointCloudStream={pointCloudStream}
          activeElement={currentMainElement}
          onActiveElementChanged={onActiveElementChanged}
        />
      ))}
    </FaroMenu>
  );
}

type PointCloudToggleButtonProps = {
  /** The point cloud to toggle to when clicking the button. */
  pointCloudStream: IElementGenericPointCloudStream;

  /** The currently selected element. */
  activeElement?: IElement;

  /** Callback for when the active element is changed */
  onActiveElementChanged(id: GUID): void;
};

function PointCloudToggleButton({
  pointCloudStream,
  activeElement,
  onActiveElementChanged,
}: PointCloudToggleButtonProps): JSX.Element {
  const displayName = usePointCloudStreamTypeName(pointCloudStream);

  const Icon = useCallback(
    (props: SvgIconProps) => (
      <PointCloudStreamIcon pointCloudStream={pointCloudStream} {...props} />
    ),
    [pointCloudStream],
  );

  const pointCloudType = useAppSelector(selectPointCloudType(pointCloudStream));

  return (
    <SingleSelectItem
      Icon={Icon}
      label={displayName}
      key={pointCloudStream.id}
      value={pointCloudStream.id}
      selectedValue={activeElement?.id}
      onClick={() => {
        Analytics.track<SwitchPointCloudStreamEventProperties>(
          EventType.switchPointCloudStream,
          {
            pointCloudType,
          },
        );
        onActiveElementChanged(pointCloudStream.id);
      }}
      dark
    />
  );
}

/**
 * @param element The currently active element to consider the dataset of.
 * @param area The data of the current area
 * @returns All point cloud streams in the data session of the given element.
 */
function selectPointCloudStreamsInSameSession(
  element: IElement | undefined,
  area: CurrentAreaData,
) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    const activeDataSession = selectActiveElementReference(
      element,
      area,
    )(state);

    if (!activeDataSession) return [];

    const pointCloudStreams = selectChildrenDepthFirst(
      activeDataSession,
      isIElementGenericPointCloudStream,
    )(state);

    // Return streams sorted by type instead of order in the project
    const streamAndType = [];
    for (const stream of pointCloudStreams) {
      const type = selectPointCloudType(stream)(state);
      streamAndType.push({ stream, type });
    }

    streamAndType.sort(
      (a, b) =>
        PointCloudTypesSortingOrder.indexOf(a.type) -
        PointCloudTypesSortingOrder.indexOf(b.type),
    );

    return streamAndType.map(({ stream }) => stream);
  };
}

/**
 * This selector returns all viewable point-clouds that should be selectable in the point cloud toggle.
 * This excludes, e.g. single scan point clouds.
 *
 * @param element The currently active element to consider the dataset of.
 * @param area The data of the current area
 * @returns All viewable point cloud streams in the data session of the given element.
 */
export function selectViewablePointCloudStreamsInSameSession(
  element: IElement | undefined,
  area: CurrentAreaData,
) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    return selectPointCloudStreamsInSameSession(
      element,
      area,
    )(state).filter((pc) => selectIsPointCloudViewable(pc)(state));
  };
}

/**
 * @param pointCloudStream The point cloud stream to determine the type name of.
 * @returns A user-suitable string to describe the type of point cloud scan.
 */
function usePointCloudStreamTypeName(
  pointCloudStream: IElementGenericPointCloudStream,
): string {
  const pointCloudType = useAppSelector(selectPointCloudType(pointCloudStream));

  switch (pointCloudType) {
    case PointCloudType.flash:
      return "Flash scan";
    case PointCloudType.mobile:
      return "Mobile scan";
    case PointCloudType.project:
    case PointCloudType.singleScan:
    case PointCloudType.other:
      return "Point cloud";
  }
}

type PointCloudIconProps = SvgIconProps & {
  /** The point cloud to generate the icon for. */
  pointCloudStream: IElementGenericPointCloudStream;
};

/**
 * @returns An icon representing the type of scan that the point cloud was produced with.
 */
export function PointCloudStreamIcon({
  pointCloudStream,
  ...rest
}: PointCloudIconProps): JSX.Element {
  const pointCloudType = useAppSelector(selectPointCloudType(pointCloudStream));

  switch (pointCloudType) {
    case PointCloudType.flash:
      return <PointCloudFlashSmallIcon {...rest} />;
    case PointCloudType.mobile:
      return <PointCloudTrajectorySmallIcon {...rest} />;
    case PointCloudType.project:
    case PointCloudType.singleScan:
    case PointCloudType.other:
      return <PointCloudIcon {...rest} />;
  }
}

/**
 * Order of Point Cloud types for sorting, to guarantee a deterministic order.
 * Roughly sorted from generic to specialized.
 */
const PointCloudTypesSortingOrder = [
  PointCloudType.other,
  PointCloudType.project,
  PointCloudType.singleScan,
  PointCloudType.mobile,
  PointCloudType.flash,
];
