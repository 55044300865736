import { useCallback } from "react";
import { useFileUploadContext } from "./file-uploads-context";
import { FileUploadParams } from "./upload-manager";

/**
 * Type of the callback returned by the useFileUploader hook
 */
type UploadFileFn = (params: FileUploadParams) => void;

/**
 * @returns This hook returns callback to perform the file upload.
 */
export function useFileUploader(): UploadFileFn {
  const { uploadManager } = useFileUploadContext();

  const uploadFile = useCallback<UploadFileFn>(
    (params) => uploadManager.startFileUpload(params),
    [uploadManager],
  );

  return uploadFile;
}

export type UploadedFile = {
  /** The unique id of the uploaded file */
  id: string;
  /** The url to download the file from the backend */
  downloadUrl: string;
  /** The md5 hash identifying file integrity */
  md5: string;
};

type UploadFileFnWithPromise = (
  params: FileUploadParams,
) => Promise<UploadedFile>;

/**
 * @returns callback to perform the file upload via promise.
 */
export function useFileUploaderWithPromise(): UploadFileFnWithPromise {
  const { uploadManager } = useFileUploadContext();

  return useCallback<UploadFileFnWithPromise>(
    (params) =>
      new Promise((resolve, reject) => {
        const { onUploadCompleted, onUploadFailed, ...rest } = params;
        uploadManager.startFileUpload({
          ...rest,
          onUploadCompleted(id, downloadUrl, md5) {
            onUploadCompleted?.(id, downloadUrl, md5);
            resolve({ id, downloadUrl, md5 });
          },
          onUploadFailed(id, error) {
            onUploadFailed?.(id, error);
            reject(error);
          },
        });
      }),
    [uploadManager],
  );
}
