import { AppAwareHtml } from "@/components/r3f/renderers/app-aware-html";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import {
  DeleteIcon,
  EditIcon,
  FaroText,
  MeasurementUnits,
  MeasurementUnitsInfo,
  metersToFormattedFractional,
  metersToLabel,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { Paper, Stack, Tooltip } from "@mui/material";
import { useMemo, useRef } from "react";
import { Vector3 } from "three";

/** Props for the embedded toolbar to interact with the line */
export type LineToolbarProps = {
  /** Reference point used to place the toolbar in the 3d scene */
  anchor: Vector3;

  /** Current value of the measurement in meters */
  distanceInMeters: number;

  /** The unit of measure selected by the user */
  unit: MeasurementUnits;

  /** Callback executed when the user want to edit the measurement value */
  onEditMeasure(): void;

  /** Callback executed when the user want to remove the measurement line and re-start */
  onRemoveMeasure(): void;
};

/** @returns a toolbar with options to handle the scale line */
export function LineToolbar({
  anchor,
  distanceInMeters,
  unit,
  onEditMeasure,
  onRemoveMeasure,
}: LineToolbarProps): JSX.Element {
  const eventTarget = useThreeEventTarget();

  if (!eventTarget.parentElement) {
    throw new Error("Invalid DOM structure");
  }

  // Attaching to the parent otherwise the canvas will steal all the events
  const labelContainer = useRef(eventTarget.parentElement);

  const distanceLabel = useMemo(() => {
    if (unit === MeasurementUnits.feet) {
      return metersToFormattedFractional({
        meters: distanceInMeters,
        precision: 1,
      });
    }
    return `${parseFloat(metersToLabel(distanceInMeters, unit))} ${
      MeasurementUnitsInfo[unit].unit
    }`;
  }, [distanceInMeters, unit]);

  return (
    <AppAwareHtml
      position={anchor}
      portal={labelContainer}
      style={{ pointerEvents: "none" }}
    >
      <Stack
        direction="column"
        sx={{ ml: 2, transform: "translate(0, -75%)", pointerEvents: "none" }}
        gap={1}
        alignItems="flex-start"
      >
        <EmbeddedToolbar isActive style={{ pointerEvents: "auto" }}>
          <Tooltip title="Edit value" placement="top">
            <EmbeddedToolbarButton
              aria-label="edit distance"
              value="edit"
              onClick={() => {
                onEditMeasure();
              }}
            >
              <EditIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Remove" placement="top">
            <EmbeddedToolbarButton
              aria-label="remove distance"
              value="remove"
              onClick={() => {
                onRemoveMeasure();
              }}
            >
              <DeleteIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
        </EmbeddedToolbar>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{ pointerEvents: "none", width: "max-content" }}
        >
          <FaroText
            variant="bodyM"
            align="center"
            sx={{ mx: 0.5, pointerEvents: "none" }}
          >
            {distanceLabel}
          </FaroText>
        </Paper>
      </Stack>
    </AppAwareHtml>
  );
}
