/** The possible filtering of the current scene */
export enum SceneFilter {
  /** Show only the 360 */
  Pano = "360",

  /** Show only the Point Cloud */
  PointCloud = "pc",

  /** Show only the Cad model */
  Cad = "cad",

  /** Show an overlay view of PointCloud + CadModel */
  Overlay = "overlay",
}
