import {
  HelpDrawer,
  HelpHeader,
  HelpParagraph,
  HelpSection,
} from "@/components/ui/quick-help-components";
import { QuickHelpHeader } from "@/components/ui/quick-help-header";
import { FaroText } from "@faro-lotv/app-component-toolbox";
import { Box, Divider } from "@mui/material";

/**
 * @returns content for the quick help drawer in the manual alignment tool
 */
function PublishHelpContent(): JSX.Element {
  return (
    <>
      <HelpSection>
        <HelpHeader>
          <FaroText variant="heading20">Inspect and publish</FaroText>
        </HelpHeader>
        <HelpParagraph>
          <FaroText variant="bodyM">
            This workflow allows merging several already registered point clouds
            into one single point cloud. The view shows you all point clouds
            contained in the respective sheet in different colors. If you have
            registered your point clouds pairwise before with{" "}
            <b>Register to...</b>
            in the structure menu, inspect now visually if they are positioned
            correctly towards each other. If the positions are correct, click
            the <b>Approve</b> button to start the publishing process.
          </FaroText>
        </HelpParagraph>
      </HelpSection>
      <Divider sx={{ marginY: 3 }} />
      <HelpSection>
        <HelpHeader>
          <FaroText variant="heading20">CONTROLS</FaroText>
        </HelpHeader>

        <FaroText variant="bodyM">
          (Mobile device instructions in parentheses.)
        </FaroText>

        <HelpParagraph header="Approve and publish">
          <FaroText variant="bodyM">
            Left-click (tap) the <b>Approve</b> button to open a dialog where
            you can specify a name and a time point for the newly generated
            point cloud. Left-Click (tap) <b>Publish</b> in the dialog to start
            the publishing process. A new point cloud with the given name and
            time point will be generated, the original point clouds will remain
            unchanged.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Translate camera (move canvas)">
          <FaroText variant="bodyM">
            Right-click (two-finger touch) and drag to move the canvas.
            Alternative: click and hold the middle mouse button, then drag to
            move the canvas.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Recenter View">
          <FaroText variant="bodyM">
            Click the <b>Recenter View</b> button in the toolbar to zoom the
            canvas to display all point clouds.
          </FaroText>
        </HelpParagraph>
      </HelpSection>
    </>
  );
}

export type QuickHelpProps = {
  /** Function to call when user requests to close the drawer */
  onClose(): void;
};

/**
 * @returns A drawer showing help for the inspect & publish workflow
 */
export function InspectAndPublishQuickHelp({
  onClose,
}: QuickHelpProps): JSX.Element {
  return (
    <HelpDrawer>
      <QuickHelpHeader title="Quick Help" onClose={onClose} />

      <Box
        component="div"
        sx={{
          overflow: "auto",
          ml: 2,
          pr: 2,
          mt: 2,
          height: "100%",
        }}
      >
        <PublishHelpContent />
      </Box>
    </HelpDrawer>
  );
}
