import {
  IElementGenericImgSheet,
  IElementSection,
} from "@faro-lotv/ielement-types";
import { create } from "zustand";

export type FloorScaleContext = {
  /** The current sheet */
  sheet?: IElementGenericImgSheet;
  setSheet(area: IElementGenericImgSheet): void;
  /** The current area */
  area?: IElementSection;
  setArea(area: IElementSection): void;
  /** The distance manually measured by the user */
  measuredDistance?: number;
  setMeasuredDistance(measuredDistance: number): void;
  /** The value inserted by the user in the combo box */
  userDefinedDistance?: number;
  setUserDefinedDistance(userDefinedDistance: number): void;
  /** If true the help banner will be shown */
  shouldShowHelpBanner: boolean;
  setShouldShowHelpBanner(shouldShow: boolean): void;
};

/** Context containing useful data for the Floor Scale mode */
export const useFloorScaleContext = create<FloorScaleContext>((set) => ({
  shouldShowHelpBanner: true,
  setSheet: (sheet: IElementGenericImgSheet) => set({ sheet }),
  setArea: (area: IElementSection) => set({ area }),
  setMeasuredDistance: (measuredDistance: number) => set({ measuredDistance }),
  setUserDefinedDistance: (userDefinedDistance: number) =>
    set({ userDefinedDistance }),
  setShouldShowHelpBanner: (shouldShow: boolean) =>
    set({ shouldShowHelpBanner: shouldShow }),
}));
