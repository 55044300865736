import { EventType } from "@/analytics/analytics-events";
import { Mode } from "@/modes/mode";
import { Analytics } from "@faro-lotv/foreign-observers";
import { ExportModeDrawer } from "./export-mode-drawer";
import { ExportModeOverlay } from "./export-mode-overlay";
import { ExportModeScene } from "./export-mode-scene";
import { ExportModeTransition } from "./export-mode-transition";
import { returnToPreviousMode } from "./export-mode-utils";

export const exportMode: Mode = {
  name: "export",
  ModeScene: ExportModeScene,
  ModeOverlay: ExportModeOverlay,
  ModeTransition: ExportModeTransition,
  ModeDrawer: ExportModeDrawer,
  exclusive: {
    title: "Export",
    onBack({ dispatch, store }) {
      Analytics.track(EventType.exitExportTool);

      returnToPreviousMode(store, dispatch);
      return Promise.resolve();
    },
  },
};
