import { useAppSelector } from "@/store/store-hooks";
import { FaroText, neutral } from "@faro-lotv/flat-ui";
import { isIElementMarkupCommand } from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { AnnotationWrapper } from "../annotation-wrapper";
import { AnnotationProps } from "../annotations-types";

/** @returns A renderer for an annotation element */
export function InfoAnnotationRenderer({
  iElement,
  worldTransform,
}: AnnotationProps): JSX.Element | null {
  const descriptiveElement = useAppSelector(
    selectChildDepthFirst(iElement, isIElementMarkupCommand),
  );

  // If there is no description in the annotation element, do not render anything
  if (!descriptiveElement?.descr) return null;

  return (
    <AnnotationWrapper
      iElement={iElement}
      worldTransform={worldTransform}
      style={{ maxWidth: 300 }}
    >
      <FaroText variant="buttonM" color={neutral[0]}>
        {descriptiveElement.descr}
      </FaroText>
    </AnnotationWrapper>
  );
}
