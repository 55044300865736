/**
 * All events that we want to log in the alignment tool. Each event may have its own properties. For example,
 * alignmentTransforChanged is logged with an AlignmentTransformChangedProperties variable.
 * alignment next/previos step events also specify the current step as a logged property.
 */
export enum EventType {
  alignmentNextStep = "click alignment next step",
  alignmentPreviousStep = "click alignment previous step",
  alignmentProjectSelected = "select project to align",
  alignmentCloudSelected = "select cloud to align",
  alignmentSheetSelected = "select floorplan to align",
  alignmentConfirmed = "confirm alignment",
  alignmentTransformChanged = "modify alignment transform",
  alignmentPinsCountChanged = "change alignment pins count",

  openQuickHelp = "open quick help",
  closeQuickHelp = "close quick help",

  recenterPointCloudOnSheet = "recenter point-cloud on sheet",

  enablePointCloudScale = "enable point-cloud scale",
  confirmEnableScale = "confirm enable scale",
  cancelEnableScale = "cancel enable scale",
}

/** In which way the alignment transform was edited */
export enum AlignmentTransformChangedProperties {
  heightChangedViaClick = "heightChangedViaClick",
  heightChangedViaControl = "heightChangedViaControl",
  pose2Dchanged = "2DposeChanged",
}

/**
 * Properties for the openQuickHelp event
 */
export type OpenQuickHelpEventProperties = {
  /** How the quick help was opened */
  via: "header bar" | "toast";
};
