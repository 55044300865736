import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  ToolName,
  ToolVisibility,
  changeToolVisibility,
  deactivateTool,
} from "@/store/ui/ui-slice";
import { useCallback, useEffect } from "react";

/**
 * Set the tool visibility and hide everything when unmounting the component
 *
 * @param name The tool whose visibility we want to change
 * @param isEnabled Flag specifying if the tool can be enabled by the user
 * @param isVisible Flag specifying if the tool can be shown to the user.
 * @param isWaiting Flag specifying if the tool is waiting for something in order to be available.
 */
export function useToggleToolVisibility(
  name: ToolName,
  isEnabled: boolean,
  isVisible: boolean,
  isWaiting: boolean,
): void {
  const activeTool = useAppSelector(selectActiveTool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isVisible) return;

    function setToolVisibility(visibility: ToolVisibility): void {
      dispatch(
        changeToolVisibility({
          name,
          visibility,
        }),
      );
    }

    if (isWaiting) {
      setToolVisibility(ToolVisibility.waiting);
    }

    if (!isEnabled) {
      setToolVisibility(ToolVisibility.disabled);
    }

    if (!isWaiting && isEnabled) {
      setToolVisibility(ToolVisibility.visible);
    }

    // When a tool is disabled, it should also get deactivated.
    if (name === activeTool && !isEnabled) {
      dispatch(deactivateTool());
    }

    return () => {
      setToolVisibility(ToolVisibility.hidden);
    };
  }, [activeTool, dispatch, isEnabled, isVisible, isWaiting, name]);
}

/**
 * Deactivate the active tool when unmounting the component
 *
 * @param toNotDisable tools to not disable
 */
export function useDeactivateToolOnUnmount(
  toNotDisable: ToolName[] = [],
): void {
  const dispatch = useAppDispatch();
  const appStore = useAppStore();

  const disableToolIfNeeded = useCallback(() => {
    // Checking the store at unmount
    const activeTool = selectActiveTool(appStore.getState());
    if (activeTool && toNotDisable.includes(activeTool)) {
      return;
    }
    dispatch(deactivateTool());
  }, [appStore, dispatch, toNotDisable]);

  useEffect(() => {
    return () => {
      disableToolIfNeeded();
    };
    // We don't want to re-trigger this when the active tool changes only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
