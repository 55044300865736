import {
  HelpDrawer,
  HelpHeader,
  HelpParagraph,
  HelpSection,
} from "@/components/ui/quick-help-components";
import { QuickHelpHeader } from "@/components/ui/quick-help-header";
import { FaroText } from "@faro-lotv/app-component-toolbox";
import { Box, Divider } from "@mui/material";

/**
 * @returns content for the quick help drawer in the manual alignment tool
 */
function PairwiseHelpContent(): JSX.Element {
  return (
    <>
      <HelpSection>
        <HelpHeader>
          <FaroText variant="heading20">Point cloud registration</FaroText>
        </HelpHeader>
        <HelpParagraph>
          <FaroText variant="bodyM">
            This workflow allows visually registering an adjustable point cloud
            to a fixed point cloud (called reference point cloud). Insert an
            anchor point to translate and rotate the adjustable point cloud
            manually. After creating a visual overlay, click the{" "}
            <b>Run automatic registration</b> button to improve.
          </FaroText>
        </HelpParagraph>
      </HelpSection>
      <Divider sx={{ marginY: 3 }} />
      <HelpSection>
        <HelpHeader>
          <FaroText variant="heading20">CONTROLS</FaroText>
        </HelpHeader>

        <FaroText variant="bodyM">
          (Mobile device instructions in parentheses.)
        </FaroText>

        <HelpParagraph header="Add and remove anchor point">
          <FaroText variant="bodyM">
            Left-click (tap) the adjustable point cloud to add the anchor point
            at the position you want. Right-click (long press) the anchor point
            to remove it. You must remove the anchor point before you can place
            it in a new position.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Translate adjustable point cloud">
          <FaroText variant="bodyM">
            Left-click (tap) and drag the anchor point to move the point cloud
            to the position you want.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Rotate adjustable point cloud">
          <FaroText variant="bodyM">
            Left-click (tap) and drag to rotate the point cloud. The anchor
            point is your rotation point.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Automatic registration">
          <FaroText variant="bodyM">
            Click (tap) the <b>Run automatic registration</b> button to start an
            automatic refinement of the current registration.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Translate camera (move canvas)">
          <FaroText variant="bodyM">
            Right-click (two-finger touch) and drag to move the canvas.
            Alternative: click and hold the middle mouse button, then drag to
            move the canvas.
          </FaroText>
        </HelpParagraph>

        <HelpParagraph header="Recenter View">
          <FaroText variant="bodyM">
            Click the <b>Recenter View</b> button in the toolbar to zoom the
            canvas to display both point clouds.
          </FaroText>
        </HelpParagraph>
      </HelpSection>
    </>
  );
}

export type QuickHelpProps = {
  /** Function to call when user requests to close the drawer */
  onClose(): void;
};

/**
 * @returns A drawer showing help for the pairwise workflow
 */
export function PairwiseRegistrationQuickHelp({
  onClose,
}: QuickHelpProps): JSX.Element {
  return (
    <HelpDrawer>
      <QuickHelpHeader title="Quick Help" onClose={onClose} />

      <Box
        component="div"
        sx={{
          overflow: "auto",
          ml: 2,
          pr: 2,
          mt: 2,
          height: "100%",
        }}
      >
        <PairwiseHelpContent />
      </Box>
    </HelpDrawer>
  );
}
