import { runtimeConfig } from "@/runtime-config";

/**
 * Enum representing the static resources used in the application.
 */
export enum StaticResource {
  // Videos for the help section in alignment
  help2FirstPinAnimation = "2_first_pin.webm",
  help2SecondPinAnimation = "2_second_pin.webm",
  help3GroundClickAnimation = "3_ground_click.webm",
  help3MoveArrowsAnimation = "3_move_arrows.webm",

  // Videos for the help section in path alignment
  alignTrajectoryVideo = "1_align_trajectory.webm",
}

/**
 *  @param resource The StaticResource to get the url for
 *  @returns The url for the given StaticResource
 */
export function getStaticResourceUrl(resource: StaticResource): string {
  return `${runtimeConfig.externalLinks.staticResourcesBaseUrl}${resource}`;
}
