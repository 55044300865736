import { useSearchParams } from "react-router-dom";

/** The list of supported query parameters */
export type QueryParams = {
  /** The id of the area to which cloud will be aligned */
  alignAreaId: string | null;

  /** id of the point cloud to be aligned with area */
  alignCloudId: string | null;
};

/** @returns The supported query parameters in the url */
export function useQueryParams(): QueryParams {
  const [queries] = useSearchParams();

  return {
    alignAreaId: queries.get("areaId"),
    alignCloudId: queries.get("cloudId"),
  };
}
