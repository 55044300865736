/**
 * Download a file
 *
 * @param url The url to the file to download
 * @param name Optional name of the downloaded file
 * @param newTab Setting this to true will download the file in a new tab, keeping the content of the current tab.
 *  This is helpful for downloads that the browser would otherwise open in the same tab (e.g., PDF files).
 */
export function downloadFile(
  url: string,
  name?: string,
  newTab?: boolean,
): void {
  const anchor = document.createElement("a");
  anchor.href = url;
  if (name) anchor.download = name;
  if (newTab) anchor.target = "_blank";

  anchor.click();
  anchor.remove();
}
