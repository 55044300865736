import { IElementObject } from "@/object-cache";
import { useLotvDispose } from "@faro-lotv/app-component-toolbox";
import { IElement } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Object3D } from "three";

/**
 * Type of an object managed by the app object-cache that has a createView member
 * that can be used to create views on it
 */
export type ObjectWithViewSupport<
  ThreeObject extends Object3D,
  Desc extends IElement,
> = IElementObject<ThreeObject, Desc> & {
  createView(): ThreeObject;
};

/**
 * Create a view object on a cached object that can be safely used for animations
 * and multi view workflows without "stealing" the source object from the main scene
 *
 * @param source the source object we want a view of
 * @returns a view object on the same data source of the source object
 */
export function useObjectView<
  ThreeObject extends Object3D,
  Desc extends IElement,
>(
  source: ObjectWithViewSupport<ThreeObject, Desc>,
): IElementObject<ThreeObject, Desc> {
  const view = useMemo(
    () => Object.assign(source.createView(), { iElement: source.iElement }),
    [source],
  );
  useLotvDispose(view);
  return view;
}
