import {
  selectIcpParams,
  selectNdrParams,
  selectRegistrationAlgorithm,
  selectTvbParams,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import {
  ICP_DEFAULT,
  NDR_DEFAULT,
  TVB_DEFAULT,
  setIcpParameters,
  setNdrParameters,
  setRegistrationAlgorithm,
  setTvbParameters,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { Dropdown, FaroButton, FaroText } from "@faro-lotv/flat-ui";
import {
  NdrParams,
  Open3dIcpParams,
  RegistrationAlgorithm,
  TvbParams,
  isRegistrationAlgorithm,
} from "@faro-lotv/service-wires";
import { FormControlLabel, Menu, Stack, Switch } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { RegistrationParameterField } from "./parameter-menu-field";

type RegistrationParameterInputProps = {
  /** The minimum value*/
  min: number;
  /** The maximum value*/
  max: number;
  /** Incremental values that are valid*/
  step: number;
};

/**
 * All min, max and step values have been determined experimentally
 * The values define the trade-off between accuracy and performance
 */
// Voxel size for downsampling. The smaller the value, the more accurate the registration, but the longer it takes
const ICP_VOXEL_SIZE_INPUT_PROPS: RegistrationParameterInputProps = {
  min: 0.02,
  max: 0.4,
  step: 0.01,
};

// Voxel size for downsampling. The smaller the value, the more accurate the registration, but the longer it takes
const RLY_VOXEL_SIZE_INPUT_PROPS: RegistrationParameterInputProps = {
  min: 0.02,
  max: 0.4,
  step: 0.01,
};

// Number of iterations. Higher values lead to more accurate registration, but take longer.
const ICP_MAX_ITERATIONS_INPUT_PROPS: RegistrationParameterInputProps = {
  min: 1000,
  max: 100000,
  step: 1000,
};

// The relative error stops the algorithm, when it has converged.
// The smaller the value, the more precise the registration, but the longer it takes.
const ICP_RELATIVE_ERROR_INPUT_PROPS: RegistrationParameterInputProps = {
  min: 0.000001,
  max: 0.0001,
  step: 0.000001,
};

// The factor threshold helps determine the search radius.
const ICP_FACTOR_THRESHOLD_INPUT_PROPS: RegistrationParameterInputProps = {
  min: 0.5,
  max: 20,
  step: 0.1,
};

/** Let the backend automatically select a registration algorithm. */
const AUTOMATIC = "auto";

// Registration algorithm option
export const REG_OPTIONS = [
  {
    key: AUTOMATIC,
    value: AUTOMATIC,
    label: "Automatic",
  },
  {
    key: RegistrationAlgorithm.Open3dIcp,
    value: RegistrationAlgorithm.Open3dIcp,
    label: "Iterative Closest Point (ICP)",
  },
  {
    key: RegistrationAlgorithm.Ndr,
    value: RegistrationAlgorithm.Ndr,
    label: "Cloud to Cloud (NDR)",
  },
  {
    key: RegistrationAlgorithm.Tvb,
    value: RegistrationAlgorithm.Tvb,
    label: "Top View Based (TVB)",
  },
];

type NdrParameterFieldsProps = {
  /** Algorithm definition for these fields */
  algorithm: RegistrationAlgorithm.Ndr;
  /** Set of parameters for the NDR algorithm */
  ndrParameters: NdrParams;
  /** Event triggered when user changes voxelSize */
  onNdrParametersChanged(parameters: NdrParams): void;
};

type IcpParameterFieldsProps = {
  /** Algorithm definition for these fields */
  algorithm: RegistrationAlgorithm.Open3dIcp;
  /** Set of parameters for the ICP algorithm */
  icpParameters: Open3dIcpParams;
  /** Event triggered when user changes a parameter */
  onIcpParametersChanged(parameters: Open3dIcpParams): void;
};

type TvbParameterFieldsProps = {
  /** Algorithm definition for these fields */
  algorithm: RegistrationAlgorithm.Tvb;
  /** Set of parameters for the TVB algorithm */
  tvbParameters: TvbParams;
  /** Event triggered when user changes voxelSize */
  onTvbParametersChanged(parameters: TvbParams): void;
};

type RegistrationParameterFieldsProps =
  | IcpParameterFieldsProps
  | NdrParameterFieldsProps
  | TvbParameterFieldsProps;

/**
 * @returns The controls to edit ICP-related parameters
 */
function TvbParameterFields({
  tvbParameters,
  onTvbParametersChanged,
}: TvbParameterFieldsProps): JSX.Element {
  return (
    <RegistrationParameterField
      label="Voxel size [m]"
      value={tvbParameters.voxel_size}
      inputProps={RLY_VOXEL_SIZE_INPUT_PROPS}
      onValueChanged={(value: number) => {
        onTvbParametersChanged({ ...tvbParameters, voxel_size: value });
      }}
    />
  );
}

/**
 * @returns The controls to edit ICP-related parameters
 */
function IcpParameterFields({
  icpParameters,
  onIcpParametersChanged,
}: IcpParameterFieldsProps): JSX.Element {
  return (
    <>
      <RegistrationParameterField
        label="Deterministic Registration"
        controlSwitchProps={{
          checked: icpParameters.use_large_pc_as_reference,
          label: "Internally use larger point cloud as reference",
        }}
        onControlSwitchValueChanged={(value: boolean) => {
          onIcpParametersChanged({
            ...icpParameters,
            use_large_pc_as_reference: value,
          });
        }}
      />
      <RegistrationParameterField
        label="Cascade voxel size"
        controlSwitchProps={{
          checked: icpParameters.cascade_voxel_size,
          label: "Use cascade voxel size",
        }}
        onControlSwitchValueChanged={(value: boolean) => {
          onIcpParametersChanged({
            ...icpParameters,
            cascade_voxel_size: value,
          });
        }}
      />
      <RegistrationParameterField
        label="Voxel size [m]"
        value={icpParameters.voxel_size}
        inputProps={ICP_VOXEL_SIZE_INPUT_PROPS}
        controlSwitchProps={{
          checked: icpParameters.auto_voxel_size,
          label: "Calculate automatically",
        }}
        onValueChanged={(value: number) => {
          onIcpParametersChanged({ ...icpParameters, voxel_size: value });
        }}
        onControlSwitchValueChanged={(value: boolean) => {
          onIcpParametersChanged({ ...icpParameters, auto_voxel_size: value });
        }}
      />
      <RegistrationParameterField
        label="Search scale (factor threshold)"
        value={icpParameters.factor_threshold}
        inputProps={ICP_FACTOR_THRESHOLD_INPUT_PROPS}
        controlSwitchProps={{
          checked: icpParameters.auto_factor_threshold,
          label: "Calculate automatically",
        }}
        onValueChanged={(value: number) => {
          onIcpParametersChanged({ ...icpParameters, factor_threshold: value });
        }}
        onControlSwitchValueChanged={(value: boolean) => {
          onIcpParametersChanged({
            ...icpParameters,
            auto_factor_threshold: value,
          });
        }}
      />
      <RegistrationParameterField
        label="Relative error [m]"
        value={icpParameters.relative_error}
        inputProps={ICP_RELATIVE_ERROR_INPUT_PROPS}
        onValueChanged={(value: number) => {
          onIcpParametersChanged({ ...icpParameters, relative_error: value });
        }}
      />
      <RegistrationParameterField
        label="Max. number of iterations"
        value={icpParameters.max_iterations}
        inputProps={ICP_MAX_ITERATIONS_INPUT_PROPS}
        onValueChanged={(value: number) => {
          onIcpParametersChanged({ ...icpParameters, max_iterations: value });
        }}
      />
    </>
  );
}

/**
 * @returns The controls to edit NDR-related parameters
 */
function NdrParameterFields({
  ndrParameters,
  onNdrParametersChanged,
}: NdrParameterFieldsProps): JSX.Element {
  return (
    <>
      <RegistrationParameterField
        label="Voxel size [m]"
        value={ndrParameters.voxel_size}
        inputProps={RLY_VOXEL_SIZE_INPUT_PROPS}
        onValueChanged={(value: number) => {
          onNdrParametersChanged({ ...ndrParameters, voxel_size: value });
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={ndrParameters.tvb}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onNdrParametersChanged({
                ...ndrParameters,
                tvb: event.target.checked,
              });
            }}
            color="primary"
          />
        }
        label={
          <FaroText variant="bodyL">
            Run 'Top View Based' preregistration
          </FaroText>
        }
      />
    </>
  );
}

type RegistrationAlgorithmFieldsProps = {
  /** props used to construct the controls for each parameter  */
  parameterProps?: RegistrationParameterFieldsProps;
};

/**
 * @returns The controls to edit parameters for the selected registration algorithm
 */
function RegistrationAlgorithmFields({
  parameterProps,
}: RegistrationAlgorithmFieldsProps): JSX.Element | null {
  if (!parameterProps) {
    return null;
  }

  switch (parameterProps.algorithm) {
    case RegistrationAlgorithm.Open3dIcp:
      return <IcpParameterFields {...parameterProps} />;
    case RegistrationAlgorithm.Ndr:
      return <NdrParameterFields {...parameterProps} />;
    case RegistrationAlgorithm.Tvb:
      return <TvbParameterFields {...parameterProps} />;
  }
}

type ParametersMenuProps = {
  /** if `true`, the component is shown */
  open: boolean;

  /** An HTML element that is used to set the position of the menu*/
  anchorEl?: Element | null;

  /** Callback fired when the menu is closed */
  onClose?(): void;
};

/**
 * @returns a menu with controls that allows users to set parameters for all supported registration algorithms
 */
export function ParametersMenu({
  open,
  anchorEl,
  onClose,
}: ParametersMenuProps): JSX.Element {
  const dispatch = useAppDispatch();
  const regAlg = useAppSelector(selectRegistrationAlgorithm) ?? AUTOMATIC;
  const icpStoreParams = useAppSelector(selectIcpParams);
  const ndrStoreParams = useAppSelector(selectNdrParams);
  const tvbStoreParams = useAppSelector(selectTvbParams);

  const [icpLocalParams, setIcpLocalParams] = useState(icpStoreParams);
  const [ndrLocalParams, setNdrLocalParams] = useState(ndrStoreParams);
  const [tvbLocalParams, setTvbLocalParams] = useState(tvbStoreParams);

  // We only save to store when the menu is about to be closed
  const saveChangesToStore = useCallback(() => {
    dispatch(setIcpParameters(icpLocalParams));
    dispatch(setNdrParameters(ndrLocalParams));
    dispatch(setTvbParameters(tvbLocalParams));
  }, [dispatch, icpLocalParams, ndrLocalParams, tvbLocalParams]);

  const currentAlgorithmFieldsProps = useMemo(():
    | RegistrationParameterFieldsProps
    | undefined => {
    switch (regAlg) {
      case RegistrationAlgorithm.Open3dIcp:
        return {
          algorithm: RegistrationAlgorithm.Open3dIcp,
          icpParameters: icpLocalParams,
          onIcpParametersChanged: setIcpLocalParams,
        };
      case RegistrationAlgorithm.Ndr:
        return {
          algorithm: RegistrationAlgorithm.Ndr,
          ndrParameters: ndrLocalParams,
          onNdrParametersChanged: setNdrLocalParams,
        };
      case RegistrationAlgorithm.Tvb:
        return {
          algorithm: RegistrationAlgorithm.Tvb,
          tvbParameters: tvbLocalParams,
          onTvbParametersChanged: setTvbLocalParams,
        };
    }
  }, [regAlg, icpLocalParams, ndrLocalParams, tvbLocalParams]);

  function resetParameters(): void {
    switch (regAlg) {
      case RegistrationAlgorithm.Open3dIcp:
        setIcpLocalParams(ICP_DEFAULT);
        break;
      case RegistrationAlgorithm.Ndr:
        setNdrLocalParams(NDR_DEFAULT);
        break;
      case RegistrationAlgorithm.Tvb:
        setTvbLocalParams(TVB_DEFAULT);
        break;
    }
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={() => {
        saveChangesToStore();
        onClose?.();
      }}
    >
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="left"
        gap={3}
        sx={{
          p: 2,
          backgroundColor: "white",
        }}
      >
        <FaroText variant="heading20">Define Custom Parameters</FaroText>
        <Dropdown
          label="Registration Algorithm"
          options={REG_OPTIONS}
          value={regAlg}
          dark={false}
          onChange={(e) => {
            if (isRegistrationAlgorithm(e.target.value)) {
              dispatch(setRegistrationAlgorithm(e.target.value));
            } else {
              dispatch(setRegistrationAlgorithm(undefined));
            }
          }}
        />
        <RegistrationAlgorithmFields
          parameterProps={currentAlgorithmFieldsProps}
        />
        <FaroButton
          variant="ghost"
          onClick={resetParameters}
          disabled={!regAlg}
        >
          Reset to default values
        </FaroButton>
      </Stack>
    </Menu>
  );
}
