import { RootState } from "@/store/store";
import { IPose } from "@faro-lotv/ielement-types";
import { Matrix4Tuple } from "three";
import { Perspective, RegistrationJobStatus } from "./registration-datatypes";

/**
 * @param root The root state
 * @returns The cached model point cloud transform
 */
export function selectPointCloudTransform(
  root: RootState,
): Matrix4Tuple | undefined {
  return root.registration.pointCloudTransform;
}

/**
 * @param root The root state
 * @returns The cached pose of the last automatic registration result
 */
export function selectLastRegistrationPose(root: RootState): IPose | undefined {
  return root.registration.lastRegistrationPose;
}

/**
 * @param root The root state
 * @returns The current perspective
 */
export function selectCurrentPerspective(
  root: RootState,
): Perspective | undefined {
  return root.registration.currentPerspective;
}

/**
 * @returns The ID of the currently running registration job
 * @param root The root state
 */
export function selectRegistrationJobId(root: RootState): string | undefined {
  return root.registration.registrationDetails.jobId;
}

/**
 * @returns true if the registration job is in progress
 * @param root The root state
 */
export function selectIsRegistrationInProgress(root: RootState): boolean {
  return (
    root.registration.registrationDetails.status !==
    RegistrationJobStatus.NotInitiated
  );
}
/**
 * @returns true if the calculated pose of the last auto registration is currently used to transform the model point cloud in view
 * @param root The root state
 */
export function selectLastRegistrationPoseUsed(root: RootState): boolean {
  return root.registration.lastRegistrationPoseUsed;
}
