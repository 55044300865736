import { RootState } from "@/store/store";
import {
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectRootIElement,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElementModel3dStream,
  isIElementBimModelTimeSeries,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";

/**
 *
 * @param state Current state
 * @returns array of all IElementModel3dStream in the project
 */
export function selectAllCadModels(state: RootState): IElementModel3dStream[] {
  const root = selectRootIElement(state);
  assert(root, "Invalid project without a root");

  const bimTimeSeries = selectChildrenDepthFirst(
    root,
    isIElementBimModelTimeSeries,
    4,
  )(state);

  return bimTimeSeries.flatMap(
    (ts) => selectChildDepthFirst(ts, isIElementModel3dStream)(state) ?? [],
  );
}
