import {
  EventType,
  OpenQuickHelpEventProperties,
} from "@/alignment-tool/analytics/analytics-events";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { ExclusiveModeProps } from "@/modes/mode";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  selectExclusiveModeCompletionAction,
  selectIsQuickHelpOpen,
} from "@/store/ui/ui-selectors";
import {
  setExclusiveModeCompletionAction,
  setIsQuickHelpOpen,
} from "@/store/ui/ui-slice";
import {
  FaroButton,
  FaroButtonProps,
  FaroIconButton,
  QuestionMarkInCircleIcon,
  useDialog,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useCallback } from "react";
import { SphereViewerToolHeaderBar } from "./tool-header-bar";

export type ExclusiveHeaderBarProps = ExclusiveModeProps & {
  /** true to show the QuickHelp toggle button */
  hasQuickHelp: boolean;
};

/** @returns the ExclusiveMode specific HeaderBar with the back and apply buttons */
export function ExclusiveHeaderBar({
  onBack,
  onApply,
  title,
  hasQuickHelp,
}: ExclusiveHeaderBarProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithDialog } = useErrorHandlers();
  const { openToast } = useToast();
  const { createDialog } = useDialog();

  const currentAction = useAppSelector(selectExclusiveModeCompletionAction);

  const onBackClick = useCallback(() => {
    if (!onBack) return;
    dispatch(setExclusiveModeCompletionAction("back"));

    onBack({ store, dispatch, projectApi, createDialog })
      .catch((error) => {
        handleErrorWithDialog({ title, error });
      })
      .finally(() => {
        dispatch(setExclusiveModeCompletionAction(undefined));
      });
  }, [
    createDialog,
    dispatch,
    handleErrorWithDialog,
    onBack,
    projectApi,
    store,
    title,
  ]);

  const onApplyClick = useCallback(() => {
    if (!onApply) return;
    dispatch(setExclusiveModeCompletionAction("apply"));

    onApply({ store, dispatch, projectApi, createDialog })
      .then((successMessage) =>
        successMessage
          ? openToast({ title: successMessage, variant: "success" })
          : undefined,
      )
      .catch((error) => {
        handleErrorWithDialog({ title, error });
      })
      .finally(() => {
        dispatch(setExclusiveModeCompletionAction(undefined));
      });
  }, [
    createDialog,
    dispatch,
    handleErrorWithDialog,
    onApply,
    openToast,
    projectApi,
    store,
    title,
  ]);

  return (
    <SphereViewerToolHeaderBar
      toolName={title}
      disableNavigation={!!currentAction}
      onExit={onBackClick}
      contentRight={
        <>
          {onApply && (
            <ApplyButton
              onClick={onApplyClick}
              isProcessing={currentAction === "apply"}
              isEnabled={!currentAction}
            />
          )}
          {hasQuickHelp && <QuickHelpToggle isEnabled={!currentAction} />}
        </>
      }
    />
  );
}

type ApplyButtonProps = {
  /** Callback called when the ApplyButton is clicked */
  onClick: FaroButtonProps["onClick"];

  /** True to show a spinner inside the button*/
  isProcessing: boolean;

  /** True if the button should be enabled */
  isEnabled: boolean;
};

/** @returns the button to close the exclusive mode and apply the required changes */
function ApplyButton({
  onClick,
  isProcessing,
  isEnabled,
}: ApplyButtonProps): JSX.Element {
  return (
    <FaroButton
      onClick={onClick}
      disabled={isProcessing && !isEnabled}
      aria-label="apply"
      isLoading={isProcessing}
    >
      Apply Changes
    </FaroButton>
  );
}

type QuickHelpToggleProps = {
  /** True to enable this button */
  isEnabled: boolean;
};

/** @returns a toggle button to show/hide the quick help sidebar */
function QuickHelpToggle({ isEnabled }: QuickHelpToggleProps): JSX.Element {
  const dispatch = useAppDispatch();

  const isQuickHelpOpen = useAppSelector(selectIsQuickHelpOpen);
  const onToggleQuickHelp = useCallback(() => {
    Analytics.track<OpenQuickHelpEventProperties>(EventType.openQuickHelp, {
      via: "header bar",
    });

    dispatch(setIsQuickHelpOpen(!isQuickHelpOpen));
  }, [dispatch, isQuickHelpOpen]);

  return (
    <FaroIconButton
      aria-label="toggle quick help"
      onClick={onToggleQuickHelp}
      disabled={!isEnabled}
      color={isQuickHelpOpen ? "primary.main" : "secondary.main"}
    >
      <QuestionMarkInCircleIcon />
    </FaroIconButton>
  );
}
