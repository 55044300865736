import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  HelpBanner,
  ResetIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { Collapse, Stack, Tooltip } from "@mui/material";
import { useCurrentScene } from "../mode-data-context";

/** @returns The overlay for the clipping box mode */
export function ClippingBoxModeOverlay(): JSX.Element {
  const { resetBoxEvent, hasUserInteracted } = useBoxControlsContext();

  const pointCloud = useCurrentScene().main;
  assert(
    pointCloud && isIElementGenericPointCloudStream(pointCloud),
    "An active pointcloud is required for the ClippingBox tool",
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {/* first empty column of a three column layout */}
      <div />
      <Collapse in={!hasUserInteracted}>
        <HelpBanner>
          Use the clipping box to define the area volume. Make sure to check and
          enter the area information in the panel.
        </HelpBanner>
      </Collapse>
      <Stack justifyContent="center">
        <Toolbar>
          <ToolGroup>
            <Tooltip
              title="Reset clipping box"
              placement="left"
              aria-label="reset clipping box"
            >
              <ToolButton onClick={() => resetBoxEvent.emit()}>
                <ResetIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </Tooltip>
          </ToolGroup>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
