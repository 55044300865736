import { CustomOrthoCamera } from "@/components/r3f/cameras/custom-ortho-camera";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { Mode, ModeSceneProps } from "@/modes/mode";
import { useAppSelector } from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import {
  useDeactivateToolOnUnmount,
  useToggleToolVisibility,
} from "@/tools/use-toggle-tool-visibility";
import { selectHasElementAccurateWorldScale } from "@faro-lotv/project-source";
import { useCurrentScene } from "../mode-data-context";
import { SheetOverlay } from "./sheet-overlay";
import { SheetScene } from "./sheet-scene";
import { SHEET_MODE_INITIAL_STATE, SheetModeInitialState } from "./sheet-state";
import { SheetTransition } from "./sheet-transition";

export const sheetMode: Mode<SheetModeInitialState> = {
  name: "sheet",
  initialState: SHEET_MODE_INITIAL_STATE,
  customCamera: CustomOrthoCamera,
  ModeScene({ initialState }: ModeSceneProps<SheetModeInitialState>) {
    const { sheet } = useCurrentScene();
    const hasSheetAccurateWorldScale = useAppSelector(
      selectHasElementAccurateWorldScale(sheet),
    );

    const { canWriteAnnotations } = useAnnotationPermissions();

    useToggleToolVisibility(ToolName.desaturateSheet, true, true, false);
    useToggleToolVisibility(
      ToolName.measurement,
      hasSheetAccurateWorldScale,
      true,
      false,
    );
    useToggleToolVisibility(
      ToolName.annotation,
      hasSheetAccurateWorldScale && canWriteAnnotations,
      true,
      false,
    );
    useDeactivateToolOnUnmount();
    return <SheetScene initialState={initialState} />;
  },
  ModeTransition: SheetTransition,
  ModeOverlay: SheetOverlay,
};
