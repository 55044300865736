import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { MultiCloudRegistrationReportView } from "@/registration-tools/common/registration-report/multicloud-registration-report";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import {
  CloseIcon,
  FaroIconButton,
  FaroText,
  neutral,
} from "@faro-lotv/flat-ui";
import { isDefinedAndLoaded, MaybeLoading } from "@faro-lotv/foundation";
import { Box, Stack } from "@mui/system";

interface InspectAndPublishSidebarRightProps {
  /** The registration report to show */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the report sidebar should be shown */
  isReportOpen: boolean;

  /** Callback to change the report visibility */
  onToggleReport(shouldOpen: boolean): void;
}

/** @returns a right sidebar containing a registration report */
export function DataPreparationReportSidebar({
  isReportOpen,
  registrationReport,
  onToggleReport,
}: InspectAndPublishSidebarRightProps): JSX.Element {
  return (
    <>
      {/* Quality report */}
      {isReportOpen && isDefinedAndLoaded(registrationReport) && (
        <Box
          component="div"
          sx={{
            width: 0,
            height: "100%",
            position: "relative",
          }}
        >
          <HorizontalResizeContainer
            initialWidth={600}
            minWidth={350}
            maxWidth={600}
            handleSide="left"
            sx={{
              position: "absolute",
              right: 0,
              height: "100%",
            }}
          >
            <Stack
              sx={{
                p: 1,
                overflowX: "auto",
                width: "100%",
                height: "100%",
                backgroundColor: neutral[0],
              }}
              gap={1}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FaroText variant="heading16">Quality Report</FaroText>
                <FaroIconButton onClick={() => onToggleReport(false)}>
                  <CloseIcon />
                </FaroIconButton>
              </Stack>
              <MultiCloudRegistrationReportView {...registrationReport} />
            </Stack>
          </HorizontalResizeContainer>
        </Box>
      )}
    </>
  );
}
