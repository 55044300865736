import {
  EventType,
  SetAreaScaleUnitProperties,
} from "@/analytics/analytics-events";
import { AppAwareHtml } from "@/components/r3f/renderers/app-aware-html";
import {
  MeasurementInput,
  MeasurementInputActions,
} from "@/components/ui/measurement-input";
import {
  MeasurementUnits,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { FaroText } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Paper, Stack } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useFloorScaleContext } from "./floor-scale-mode-context";
import { LineToolbarProps } from "./line-toolbar";

type EditMeasureProps = Pick<
  LineToolbarProps,
  "anchor" | "distanceInMeters" | "unit"
> & {
  /** Callback executed when the user finalizes the editing*/
  onMeasureAccepted(measure: number, unit: MeasurementUnits): void;
};

/** @returns a component to edit the measure of the line */
export function EditMeasure({
  anchor,
  distanceInMeters,
  unit,
  onMeasureAccepted,
}: EditMeasureProps): JSX.Element | null {
  const eventTarget = useThreeEventTarget();

  if (!eventTarget.parentElement) {
    throw new Error("Invalid DOM structure");
  }

  // Attaching to the parent otherwise the canvas will steal all the events
  const labelContainer = useRef(eventTarget.parentElement);

  // Accept the current measurement, if valid, when clicking outside the panel
  const actions = useRef<MeasurementInputActions>(null);
  useEffect(() => {
    const onPointerDown = (): void => {
      if (!actions.current) return;
      actions.current.acceptMeasurement();
    };
    eventTarget.addEventListener("pointerdown", onPointerDown);
    return () => {
      eventTarget.removeEventListener("pointerdown", onPointerDown);
    };
  }, [eventTarget]);

  // Keep the value in the context so that when the user click on "Apply changes"
  // the latest valid value is used
  const { setUserDefinedDistance } = useFloorScaleContext();
  const onChange = useCallback(
    (distanceInMeters: number) => {
      setUserDefinedDistance(distanceInMeters);
    },
    [setUserDefinedDistance],
  );

  return (
    <AppAwareHtml
      position={anchor}
      portal={labelContainer}
      zIndexRange={[0, 1]}
      // Below, if 'position: relative' is added to the style,
      // the Drei Html component creates an additional parent div with
      // pointerEvents: auto, invalidating the 'pointerEvents: none' below.
      style={{ pointerEvents: "none" }}
    >
      <Paper
        elevation={2}
        sx={{
          pointerEvents: "auto",
          transform: "translate(0, -50%)",
          ml: 1,
        }}
      >
        <Stack padding={2} spacing={1} sx={{ width: "23.75rem" }}>
          <FaroText variant="heading16">
            Enter the real-world distance of the line
          </FaroText>
          <MeasurementInput
            aria-label="enter distance"
            distanceInMeters={distanceInMeters}
            unit={unit}
            onChange={onChange}
            onAccepted={onMeasureAccepted}
            actions={actions}
            onValueChanged={() => Analytics.track(EventType.setAreaScaleValue)}
            onUnitChanged={(unit) =>
              Analytics.track<SetAreaScaleUnitProperties>(
                EventType.setAreaScaleUnit,
                { unit },
              )
            }
          />
        </Stack>
      </Paper>
    </AppAwareHtml>
  );
}
