import { encodeCameraParameters } from "@/components/r3f/utils/camera-parameters";
import { changeMode } from "@/store/mode-slice";
import {
  selectReturnCamera,
  selectReturnMode,
} from "@/store/modes/export-mode-selectors";
import { AppDispatch, AppStore } from "@/store/store";
import { deactivateTool } from "@/store/ui/ui-slice";

/**
 * Ends the export mode and return to the previous mode restoring the previous camera
 *
 * @param store to access the stored mode/camera information
 * @param dispatch to update the app state
 */
export function returnToPreviousMode(
  store: AppStore,
  dispatch: AppDispatch,
): void {
  const returnMode = selectReturnMode(store.getState());
  const returnCamera = selectReturnCamera(store.getState());
  const initialState = returnCamera
    ? { camera: encodeCameraParameters(returnCamera) }
    : undefined;
  dispatch(changeMode({ mode: returnMode, initialState }));
  dispatch(deactivateTool());
}
