import { useAppSelector } from "@/store/store-hooks";
import { OrthophotoTask } from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import { selectIElement } from "@faro-lotv/project-source";
import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { useCallback, useMemo } from "react";
import { CardProgress } from "../../card-progress";
import { GenericCardLayout } from "../../layouts/generic-card-layout";

type OrthophotoExportCardProps = {
  task: OrthophotoTask;
};

/** @returns The card for the orthophoto generation task */
export function OrthophotoExportCard({
  task,
}: OrthophotoExportCardProps): JSX.Element {
  const { iElementId, metadata, state } = task;
  const refElement = useAppSelector(selectIElement(iElementId));
  const name = useMemo(
    () => refElement?.name ?? "Orthophoto",
    [refElement?.name],
  );

  const downloadOrthophoto = useCallback(() => {
    if (metadata.downloadUrl !== undefined) {
      downloadFile(metadata.downloadUrl, `${name}.png`);
    }
  }, [metadata.downloadUrl, name]);

  // TODO: add Cancel button https://faro01.atlassian.net/browse/SWEB-5837
  return (
    <GenericCardLayout
      name={name}
      subText="Orthophoto"
      startTime={task.createdAt}
      action={
        metadata.downloadUrl
          ? {
              name: "Download",
              callback: downloadOrthophoto,
            }
          : undefined
      }
    >
      {isBackgroundTaskActive(state) && (
        <CardProgress label="Generating..." task={task} />
      )}
    </GenericCardLayout>
  );
}
