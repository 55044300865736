import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  PointCloudObject,
  useCached3DObjectIfExists,
  useCached3DObjectsIfReady,
} from "@/object-cache";
import { useAlignmentOverlay } from "@/registration-tools/common/alignment-overlay-context";
import { usePointCloudMaterials } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import { centerCameraOnPointClouds } from "@/registration-tools/utils/camera-views";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  RegisterMultiCloudDataSetTask,
  SuccessfullyCompleted,
} from "@/utils/background-tasks";
import {
  Map2DControls,
  TransformOverrides,
  computeCachedWorldTransform,
  useReproportionCamera,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElementGenericImgSheet,
  IElementGenericPointCloudStream,
  IPose,
} from "@faro-lotv/ielement-types";
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useCallback, useMemo, useRef, useState } from "react";
import { OrthographicCamera } from "three";
import { PointCloudPinControls } from "../common/interaction/point-cloud-pin-controls";
import { PositionedPointCloud } from "../common/rendering/positioned-point-cloud";
import { useMultiCloudPointBudgetManager } from "../common/rendering/use-multi-cloud-point-budget-manager";
import { Perspective } from "../common/store/registration-datatypes";
import { selectCurrentPerspective } from "../common/store/registration-selectors";
import { setLastRegistrationPoseUsed } from "../common/store/registration-slice";

type InspectAndPublishSceneProps = {
  /** Pointcloud objects to render in the multi-registration view */
  pointClouds: IElementGenericPointCloudStream[];

  /** The point cloud which is currently selected by the user, if any. */
  selectedPointCloud?: IElementGenericPointCloudStream;

  /**
   * The sheet both pointclouds are related to.
   *
   * If available, it will be rendered as a reference below the point clouds.
   */
  activeSheet?: IElementGenericImgSheet;

  /** The registration task to display the results of. */
  registrationTask?: SuccessfullyCompleted<RegisterMultiCloudDataSetTask>;
};

/**
 * @returns null until point cloud objects are loaded, then returns rendered point cloud objects and all related interaction
 */
export function InspectAndPublishScene({
  pointClouds,
  selectedPointCloud,
  ...rest
}: InspectAndPublishSceneProps): JSX.Element | null {
  const pointCloudObjects = useCached3DObjectsIfReady(pointClouds);

  // useCached3DObjectIfExists does not trigger a re-render when all objects are loaded, this is why we need to force it
  // TODO: https://faro01.atlassian.net/browse/NRT-615
  if (pointCloudObjects.length !== pointClouds.length) {
    return null;
  }

  return (
    <InspectAndPublishSceneImpl
      pointCloudObjects={pointCloudObjects}
      selectedPointCloud={selectedPointCloud}
      {...rest}
    />
  );
}

type InspectAndPublishSceneImplProp = Omit<
  InspectAndPublishSceneProps,
  "pointClouds"
> & {
  /** Pointcloud objects to render in the multi-registration view  */
  pointCloudObjects: PointCloudObject[];
};

/**
 * Separate component for the scene which is needed due to the current caching approach.
 * For the hooks we need the point cloud objects to be non-null, but we can't render hooks conditionally.
 * So we need to return null if some point clouds are still loading and then use this inner component
 * to call the hooks.
 *
 * @returns The components involved in translating and rotating the point cloud
 * The Point Cloud Manipulator
 */
function InspectAndPublishSceneImpl({
  activeSheet,
  pointCloudObjects,
  registrationTask,
  selectedPointCloud,
}: InspectAndPublishSceneImplProp): JSX.Element | null {
  const dispatch = useAppDispatch();
  // The current view direction: top, front, or side
  const perspective = useAppSelector(selectCurrentPerspective);

  const camera = useThree((state) => state.camera);
  useReproportionCamera(camera);
  const sheet = useCached3DObjectIfExists(activeSheet);
  const hasVisualRegistrationFeature = useAppSelector(
    selectHasFeature(Features.VisualRegistration),
  );

  const { centerCameraOnPerspective } = useAlignmentOverlay();

  // Use the positions calculated during registration
  const registrationOverrides: TransformOverrides["local"] = useMemo(() => {
    const updatedLocalIElementPoses =
      registrationTask?.result.updatedLocalIElementPoses;
    const local: Record<GUID, IPose> = {};

    if (updatedLocalIElementPoses) {
      for (const updatedPose of updatedLocalIElementPoses) {
        local[updatedPose.id] = updatedPose.pose;
      }
    }

    return local;
  }, [registrationTask]);
  const [manualOverrides, setManualOverrides] = useState<
    TransformOverrides["global"]
  >({});
  const transformOverrides = useMemo(() => {
    return { local: registrationOverrides, global: manualOverrides };
  }, [manualOverrides, registrationOverrides]);

  const highlightedIds = useMemo(
    () => (selectedPointCloud?.id ? [selectedPointCloud.id] : []),
    [selectedPointCloud],
  );

  usePointCloudMaterials({
    pointClouds: pointCloudObjects,
    highlightedIds,
  });

  useMultiCloudPointBudgetManager(pointCloudObjects);

  const controlsRef = useRef<Map2DControlsImpl>(null);

  const recenterCameraOnPointClouds = useCallback(
    (newPerspective: Perspective | null) => {
      if (camera instanceof OrthographicCamera && newPerspective) {
        centerCameraOnPointClouds(pointCloudObjects, camera, newPerspective);

        if (controlsRef.current) {
          // Whenever the view type (perspective) changes,
          // the camera is re-assigned to the controls so its
          // pose is not changed by the controls.
          controlsRef.current.camera = camera;
        }
      }
    },
    [camera, pointCloudObjects],
  );

  // Sets callback function in the ManualAlignmentOverlay context.
  useTypedEvent(centerCameraOnPerspective, recenterCameraOnPointClouds);

  const selectedPointCloudObject = useMemo(
    () =>
      pointCloudObjects.find(
        (obj) => obj.iElement.id === selectedPointCloud?.id,
      ),
    [pointCloudObjects, selectedPointCloud?.id],
  );

  return (
    <>
      {sheet && <SheetRenderer sheet={sheet} />}
      {hasVisualRegistrationFeature ? (
        <PointCloudPinControls
          manipulatedPointCloud={selectedPointCloudObject}
          onTransform={(worldMatrix) => {
            // The user manually transformed the point cloud
            dispatch(setLastRegistrationPoseUsed(false));

            if (selectedPointCloud) {
              setManualOverrides({
                ...manualOverrides,
                [selectedPointCloud.id]:
                  computeCachedWorldTransform(worldMatrix),
              });
            }
          }}
          transformOverrides={transformOverrides}
        />
      ) : (
        <Map2DControls ref={controlsRef} enabled />
      )}
      {pointCloudObjects.map((pointCloudObject) => (
        <PositionedPointCloud
          key={pointCloudObject.iElement.id}
          pointCloudObject={pointCloudObject}
          transformOverrides={transformOverrides}
          onInitialPositioning={() =>
            recenterCameraOnPointClouds(perspective ?? Perspective.topView)
          }
        />
      ))}
    </>
  );
}
