import { IPose } from "@faro-lotv/ielement-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Matrix4Tuple } from "three";
import {
  Perspective,
  RegistrationJobDetails,
  RegistrationJobStatus,
} from "./registration-datatypes";

type RegistrationState = {
  /** The current transformation of the adjustable point cloud */
  pointCloudTransform?: Matrix4Tuple;

  /** The pose of the last automatic registration result */
  lastRegistrationPose?: IPose;

  /** The current Perspective */
  currentPerspective?: Perspective;

  /** The details of the registration job */
  registrationDetails: RegistrationJobDetails;

  /** Whether the calculated pose of the last auto registration is currently used to transform the model point cloud in view  */
  lastRegistrationPoseUsed: boolean;
};

const REGISTRATION_NOT_INTIATED = {
  jobId: undefined,
  status: RegistrationJobStatus.NotInitiated,
};

const REGISTRATION_REQUESTED = {
  jobId: undefined,
  status: RegistrationJobStatus.Requested,
};

export const initialState: Readonly<RegistrationState> = Object.freeze({
  pointCloudTransform: undefined,
  lastRegistrationTransformation: undefined,
  currentPerspective: Perspective.topView,
  registrationDetails: REGISTRATION_NOT_INTIATED,
  lastRegistrationPoseUsed: false,
});

export const registrationSlice = createSlice({
  initialState,
  name: "registration",

  reducers: {
    /**
     * Set the cached transform of the model point cloud
     *
     * @param state The current state
     * @param action The payload containing the updated transform
     */
    setPointCloudTransform(
      state,
      action: PayloadAction<Matrix4Tuple | undefined>,
    ) {
      state.pointCloudTransform = action.payload;
    },

    /**
     * Set the pose of the last successfully executed automatic registration
     *
     * @param state The current state
     * @param action The payload containing the updated pose
     */
    setLastRegistrationPose(state, action: PayloadAction<IPose | undefined>) {
      state.lastRegistrationPose = action.payload;
    },

    /**
     * Set the current Perspective
     *
     * @param state The current state
     * @param action The payload containing the material
     */
    setCurrentPerspective(
      state,
      action: PayloadAction<Perspective | undefined>,
    ) {
      state.currentPerspective = action.payload;
    },

    /**
     * reset the registration job details to default value
     *
     * @param state The current state
     */
    resetRegistrationJob(state) {
      state.registrationDetails = REGISTRATION_NOT_INTIATED;
    },

    /**
     * set the registration job details on request
     *
     * @param state The current state
     */
    requestRegistrationJob(state) {
      state.registrationDetails = REGISTRATION_REQUESTED;
    },

    /**
     * set the registration job details on request
     *
     * @param state The current state
     * @param action The job id of the registration job
     */
    initiateRegistrationJob(state, action: PayloadAction<string>) {
      state.registrationDetails.jobId = action.payload;
      state.registrationDetails.status = RegistrationJobStatus.Initiated;
    },

    /**
     * Set whether the calculated pose of the last auto registration is currently used
     *
     * @param state The current state
     * @param action The payload containing whether the calculated pose of the last auto registration is currently used
     */
    setLastRegistrationPoseUsed(state, action: PayloadAction<boolean>) {
      state.lastRegistrationPoseUsed = action.payload;
    },
  },
});

export const {
  setPointCloudTransform,
  setLastRegistrationPose,
  setCurrentPerspective,
  resetRegistrationJob,
  requestRegistrationJob,
  initiateRegistrationJob,
  setLastRegistrationPoseUsed,
} = registrationSlice.actions;

export const registrationReducer = registrationSlice.reducer;
