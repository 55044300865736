import {
  IElementObject,
  MeshObject,
  PanoObject,
  PointCloudObject,
  SheetObject,
} from "@/object-cache";
import { isIElementValidPointCloudStream } from "@/types/type-guards";
import {
  IElement,
  isIElementGenericImgSheet,
  isIElementGenericModel3d,
  isIElementImg360,
  validateIElement,
} from "@faro-lotv/ielement-types";
import { FloorPlanTile } from "@faro-lotv/lotv";
import { Object3D } from "three";

export type UnknownObject = IElementObject<Object3D, IElement>;

/**
 * @returns True if the input object is a PanoObject
 * @param o The object to check
 */
export function isPanoObject(o: UnknownObject | null): o is PanoObject {
  return !!o && isIElementImg360(o.iElement);
}

/**
 * @returns True if the input object is a PointCloudObject
 * @param o The object to check
 */
export function isPointCloudObject(
  o: UnknownObject | null,
): o is PointCloudObject {
  return !!o && isIElementValidPointCloudStream(o.iElement);
}

/**
 * @returns True if the input object is a MeshObject
 * @param o The object to check
 */
export function isMeshObject(o: UnknownObject | null): o is MeshObject {
  return !!o && isIElementGenericModel3d(o.iElement);
}

/**
 * @returns True if the input object is a SheetObject
 * @param o The object to check
 */
export function isSheetObject(o: Object3D | null): o is SheetObject {
  return (
    !!o &&
    "iElement" in o &&
    validateIElement(o.iElement) &&
    isIElementGenericImgSheet(o.iElement)
  );
}

/**
 * @param o The object to check
 * @returns true if it's a sheet object or a tile of a sheet object
 */
export function isFromSheetObject(o: Object3D | null): boolean {
  return isSheetObject(o) || o instanceof FloorPlanTile;
}
