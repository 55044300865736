import { RootState } from "@/store/store";
import {
  FaroSvgIcon,
  FaroText,
  FontWeights,
  NO_TRANSLATE_CLASS,
} from "@faro-lotv/flat-ui";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  PointCloudType,
  selectPointCloudType,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { FunctionComponent, PropsWithChildren, SVGAttributes } from "react";
import { ReactComponent as QualityStatusGood } from "../../icons/quality-status-good.svg";
import { ReactComponent as QualityStatusMedium } from "../../icons/quality-status-medium.svg";
import { ReactComponent as QualityStatusPoor } from "../../icons/quality-status-poor.svg";
import { UnitType, displayUnitValue } from "../../utils/units";

/**
 * A set of thresholds which determine the quality of metric values for the registration tools.
 *
 * Different scan types might require different threshold sets,
 * as they provide different levels of accuracy.
 */
export type RegistrationThresholdSet = {
  /**
   * The name of the threshold set, which is displayed to the user.
   *
   * This value must be unique per threshold set and is suitable to use as a key.
   */
  name: string;

  /** A description of the threshold set to display to the user. */
  description: string;

  /** The thresholds to use for point cloud overlap. */
  overlap: MetricThresholds;

  /** The thresholds to use for point distance values. */
  pointDistance: MetricThresholds;
};

/**
 *  Overlap Thresholds:
 *  if value is > 25%  => overlap is good
 *  if value is in between 25-10%  => overlap is medium
 *  if value is < 10%  => overlap is poor
 */
const OVERLAP_THRESHOLDS: Readonly<MetricThresholds> = Object.freeze({
  good: 0.25,
  poor: 0.1,
  unit: UnitType.fraction,
} as const);

/** Metric threshold for mobile scanners with lower precision, e.g. mobile Orbis scans. */
export const THRESHOLD_SET_MOBILE: Readonly<RegistrationThresholdSet> =
  Object.freeze({
    name: "Mobile mapping capture",
    description:
      "Threshold set for point clouds captured with mobile mapping devices, e.g. FARO Orbis.",

    overlap: OVERLAP_THRESHOLDS,

    /**
     *  Point Distance Thresholds:
     *  if value is < 11-27 mm  => points distance is good
     *  if value is in between 11-27 mm  => points distance is medium
     *  if value is > 27 mm  => points distance is poor
     */
    pointDistance: {
      good: 0.011,
      poor: 0.027,
      unit: UnitType.length,
    },
  } as const);

/** Metric threshold for stationary scanners with high precision, e.g. FARO Focus. */
export const THRESHOLD_SET_STATIONARY: Readonly<RegistrationThresholdSet> =
  Object.freeze({
    name: "Stationary scan capture",
    description:
      "Threshold set for point clouds captured with stationary laser scanners, e.g. FARO Focus.",

    overlap: OVERLAP_THRESHOLDS,

    /**
     *  Point Distance Thresholds:
     *  if value is < 8 mm  => points distance is good
     *  if value is in between 8-20 mm  => points distance is medium
     *  if value is > 10 mm  => points distance is poor
     */
    pointDistance: {
      good: 0.008,
      poor: 0.02,
      unit: UnitType.length,
    },
  } as const);

/** All predefined threshold sets offered in the registration tools. */
export const THRESHOLD_SETS = Object.freeze([
  THRESHOLD_SET_MOBILE,
  THRESHOLD_SET_STATIONARY,
] as const);

/** Metric limits for a metric*/
export interface MetricThresholds {
  /** metric threshold value that determines (based on the condition) whether the quality is good or medium */
  good: number;
  /** metric threshold value that determines (based on the condition) whether the quality is medium or poor */
  poor: number;
  /** measure unit of the metric */
  unit: UnitType;
}

/** @returns the thresholds which are used to determine the quality of the metric result. */
export function MetricThresholdsView({
  good: valueGood,
  poor: valuePoor,
  unit,
}: MetricThresholds): JSX.Element {
  return (
    <>
      <ThresholdCondition icon={QualityStatusGood}>
        {valueGood > valuePoor ? ">" : "<"}
        {displayUnitValue(valueGood, unit)}
      </ThresholdCondition>
      <ThresholdCondition icon={QualityStatusMedium}>
        {displayUnitValue(valueGood, unit)} -{" "}
        {displayUnitValue(valuePoor, unit)}
      </ThresholdCondition>
      <ThresholdCondition icon={QualityStatusPoor}>
        {valueGood > valuePoor ? "<" : ">"}
        {displayUnitValue(valuePoor, unit)}
      </ThresholdCondition>
    </>
  );
}

/** params for displaying Threshold conditions */
interface ThresholdProps {
  /** icon when the value falls in the corresponding threshold condition */
  icon: FunctionComponent<SVGAttributes<SVGElement>>;
}

/**
 * @returns a single condition for a threshold, which shows an icon representing the corresponding quality
 *  and the condition to reach that quality.
 *
 * Example: ●<8mm
 */
export function ThresholdCondition({
  icon,
  children,
}: PropsWithChildren<ThresholdProps>): JSX.Element {
  return (
    <Stack direction="row" spacing={0.25} alignItems="center">
      <FaroSvgIcon
        sx={{
          color: "gray500",
          width: "10px",
          height: "10px",
        }}
        source={icon}
      />
      <FaroText
        variant="labelS"
        className={NO_TRANSLATE_CLASS}
        sx={{ color: "gray500", fontWeight: FontWeights.Regular }}
        noWrap
      >
        {children}
      </FaroText>
    </Stack>
  );
}

/**
 *
 * @param pointClouds The point clouds to determine the default threshold set for.
 * @returns The threshold set which is most likely to be applicable for the point clouds.
 *  This selects the threshold set for the point cloud with the lowest capture precision.
 */
export function selectDefaultThresholdSet(
  pointClouds: IElementGenericPointCloudStream[],
) {
  return (state: RootState): RegistrationThresholdSet => {
    const hasMobilePointCloud = pointClouds.some(
      (pointCloud) =>
        selectPointCloudType(pointCloud)(state) === PointCloudType.mobile,
    );

    return hasMobilePointCloud
      ? THRESHOLD_SET_MOBILE
      : THRESHOLD_SET_STATIONARY;
  };
}
