import { BackgroundTask } from "@/utils/background-tasks";
import { GUID } from "@faro-lotv/ielement-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type BackgroundTasksState = {
  /** List of background task tasks */
  backgroundTasks: BackgroundTask[];
};

const initialState: BackgroundTasksState = {
  backgroundTasks: [],
};

const backgroundTasksSlice = createSlice({
  name: "backgroundTasks",
  initialState,
  reducers: {
    /**
     * Add a background task to existing list of tasks
     *
     * @param state Current state
     * @param action A pont cloud task
     */
    addBackgroundTask(state, action: PayloadAction<BackgroundTask>) {
      const backgroundTask = action.payload;

      /** A check to not add duplicate tasks */
      const isPresent = state.backgroundTasks.some(
        ({ id }) => id === backgroundTask.id,
      );
      if (!isPresent) {
        state.backgroundTasks.push(backgroundTask);
      }
    },

    /**
     * Remove the background task whose ID has been given
     *
     * @param state Current state
     * @param action ID of the background task to remove
     */
    removeBackgroundTask(state, action: PayloadAction<GUID>) {
      const id = action.payload;
      state.backgroundTasks = state.backgroundTasks.filter(
        (backgroundTask) => backgroundTask.id !== id,
      );
    },

    /**
     * Update an existing background task
     *
     * @param state Current state
     * @param action An updated pont cloud task
     */
    updateBackgroundTask(state, action: PayloadAction<BackgroundTask>) {
      const updatedBackgroundTask = action.payload;
      const index = state.backgroundTasks.findIndex(
        ({ id }) => id === updatedBackgroundTask.id,
      );
      if (index > -1) state.backgroundTasks[index] = updatedBackgroundTask;
    },
  },
});

export const backgroundTasksReducer = backgroundTasksSlice.reducer;

export const { addBackgroundTask, removeBackgroundTask, updateBackgroundTask } =
  backgroundTasksSlice.actions;
