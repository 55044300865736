import { Theme } from "@emotion/react";
import {
  FaroButton,
  FaroIconButton,
  FaroText,
  FaroTooltip,
  ThreeDotsIcon,
  TruncatedText,
  neutral,
} from "@faro-lotv/flat-ui";
import { ISOTimeString } from "@faro-lotv/ielement-types";
import { ButtonProps, Menu, MenuItem, Typography } from "@mui/material";
import { Stack, SxProps } from "@mui/system";
import { DateTime } from "luxon";
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ElementIcon, ElementIconType } from "../../icons";

export type GenericCardLayoutProps = {
  /** Name of the uploaded file */
  name: ReactNode;

  /** Optional text describing the task */
  subText?: ReactNode;

  /** Action to offer to the user inside the card */
  action?: CardAction;

  /** Start time of the task */
  startTime: ISOTimeString;

  /** A menu with multiple actions */
  menu?: CardAction[];

  /** An icon to show before the name if available */
  icon?: ElementIconType;

  /** Optional style to apply to the root element */
  sx?: SxProps<Theme>;

  /** Optional style to apply the the wrapper of the title */
  titleWrapperSx?: SxProps<Theme>;
};

/** @returns the main layout of a menu card */
export function GenericCardLayout({
  name,
  subText,
  action,
  startTime,
  menu,
  icon,
  sx,
  titleWrapperSx,
  children,
}: PropsWithChildren<GenericCardLayoutProps>): JSX.Element {
  const date = useMemo(() => DateTime.fromISO(startTime), [startTime]);

  const dateString = date.toLocaleString({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });
  const [relativeTime, setRelativeTime] = useState(date.toRelative());

  // Updating the relative time every second to make sure it stays accurate
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRelativeTime(date.toRelative());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return (
    <Stack sx={{ py: 1.5, px: 0.75, ...sx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={0.375}
        sx={titleWrapperSx}
      >
        <Stack gap="2px">
          <Stack direction="row" alignItems="center" gap={0.75}>
            {icon && <ElementIcon icon={icon} sx={{ fontSize: "1.125em" }} />}
            <TruncatedText fontSize="0.875em" color="darkGrey" flex="1">
              {name}
            </TruncatedText>
            <FaroTooltip title={dateString}>
              <FaroText
                variant="bodyS"
                fontSize="0.65rem"
                color={neutral[600]}
                sx={{ display: "inherit" }}
              >
                {relativeTime}
              </FaroText>
            </FaroTooltip>
          </Stack>

          {subText && <FaroText variant="helpText">{subText}</FaroText>}
        </Stack>

        {action && <CardActionButton {...action} />}
        {!!menu?.length && <CardActionMenu menu={menu} />}
      </Stack>

      {!!children && (
        <Stack component="div" sx={{ pb: 1.5 }} gap={0.75}>
          {children}
        </Stack>
      )}
    </Stack>
  );
}

export type CardAction = {
  /** Name of the action button */
  name: string;

  /** Color for the action button */
  color?: ButtonProps["color"];

  /** Callback executed when the action is triggered */
  callback(): void;

  /** Allow to disable to the action button by providing a message to show */
  disableMessage?: string;
};

/** @returns the card button to trigger an action */
function CardActionButton({
  name,
  callback,
  disableMessage,
}: CardAction): JSX.Element {
  return (
    <FaroTooltip title={disableMessage}>
      <FaroButton
        disabled={!!disableMessage}
        variant="ghost"
        sx={{ p: 0 }}
        onClick={callback}
      >
        {name}
      </FaroButton>
    </FaroTooltip>
  );
}

/** @returns a three dot menu for a card */
function CardActionMenu({
  menu,
}: Required<Pick<GenericCardLayoutProps, "menu">>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FaroIconButton ref={button} onClick={() => setIsOpen(true)} size="xs">
        <ThreeDotsIcon />
      </FaroIconButton>

      <Menu
        anchorEl={button.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {menu.map(({ name, callback, color }) => (
          <MenuItem key={name} onClick={callback}>
            <Typography component="span" color={color}>
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
