import { CameraRestorer, Mode, ModeNames } from "@/modes/mode";
import { selectModeInitialState } from "@/store/mode-selectors";
import { setActiveAnalysisId } from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import {
  collapseAllAnnotations,
  setAnnotationExpansion,
} from "@/store/ui/ui-slice";
import {
  selectIElement,
  useEnvironmentMap,
} from "@faro-lotv/app-component-toolbox";
import { isIElementAnalysis } from "@faro-lotv/ielement-types";
import { Camera, RootState, useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import { PerspectiveCamera } from "three";
import { StoreApi } from "zustand";
import { useViewRuntimeContext } from "../../common/view-runtime-context";
import { SnapshotRenderer } from "../renderers/snapshot-renderer";
import { SuspenseLoadNotifier } from "./suspense-load-notifier";

type ModeTransitionManagerProps = {
  /** The 3d scene for the current active mode */
  mode: Mode;

  /** Signal out when a transition start and stop */
  onTransitionEnd(): void;
};

/**
 * It starts rendering a 3d scene but allow animated transition between 3d scenes
 *
 * It injects a TransitionContext with two functions
 * startTransition(JSX) - to allow the scene to start a transition scene that will be rendered instead
 * endTransition() - to allow the transition scene to notify it has finished
 *
 * @returns A component to manage the lifecycle of 3d scenes and transition between scenes
 */
export function ModeTransitionManager({
  mode,
  onTransitionEnd,
}: ModeTransitionManagerProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  // Local state to store the previous rendered scene (mode or transition)
  const [modeName, setModeName] = useState<ModeNames>(mode.name);
  const [transition, setTransition] = useState<JSX.Element | null>(null);
  const [modeScene, setModeScene] = useState<JSX.Element | null>(null);
  const [restoreCamera, setRestoreCamera] = useState<CameraRestorer>();
  const { envMap, updateEnvMap } = useEnvironmentMap();

  const { camera, set } = useThree();
  const [defaultCamera] = useState<PerspectiveCamera>(() => {
    if (!(camera instanceof PerspectiveCamera)) {
      throw new Error(
        "By design we enforce that the default camera must be perspective.",
      );
    }
    return camera;
  });

  const { setCameraData } = useViewRuntimeContext();
  const store = useAppStore();

  useLayoutEffect(() => {
    // Determine the camera to use for the next mode
    const nextCamera = mode.customCamera?.camera() ?? defaultCamera;
    // Function to apply all changes needed when the transition and and the new mode is ready
    const endTransition = (): void => {
      setTransition(null);
      onTransitionEnd();
      // Update the camera once the transition is done
      changeActiveCamera(camera, nextCamera, set);
      // Here we we pass a function and not a value, because if we pass
      // directly the 'restoreGlobalCamera' function, React will call it!
      setRestoreCamera(() => mode.customCamera?.restoreGlobalCamera);
      setCameraData([nextCamera]);
    };
    restoreCamera?.(camera, defaultCamera);
    // a transition may begin, and it will go with the default camera,
    // so let's update it.
    changeActiveCamera(camera, defaultCamera, set);
    dispatch(collapseAllAnnotations());

    const nextModeInitialState = selectModeInitialState(store.getState());
    const initialState =
      mode.initialState && nextModeInitialState
        ? mode.initialState.parse(store.getState(), nextModeInitialState)
        : undefined;

    if (nextModeInitialState?.lookAtId) {
      const lookAtElement = selectIElement(nextModeInitialState.lookAtId)(
        store.getState(),
      );
      if (lookAtElement) {
        if (isIElementAnalysis(lookAtElement)) {
          dispatch(setActiveAnalysisId(nextModeInitialState.lookAtId));
        } else {
          dispatch(
            setAnnotationExpansion({
              id: nextModeInitialState.lookAtId,
              expanded: true,
            }),
          );
        }
      }
    }

    if (mode.ModeTransition) {
      updateEnvMap(camera.position);
      setTransition(
        <SuspenseLoadNotifier>
          <mode.ModeTransition
            previousMode={modeName}
            previousEnvMap={envMap}
            defaultCamera={defaultCamera}
            modeCamera={nextCamera}
            initialState={initialState}
            onCompleted={() => {
              endTransition();
            }}
          />
        </SuspenseLoadNotifier>,
      );
    } else {
      // Update the camera directly
      endTransition();
    }

    setModeScene(<mode.ModeScene initialState={initialState} />);

    setModeName(mode.name);

    // We want to update only when mode changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Render a transition if one is running, if no one is running then render the current mode
  const sceneToRender = transition ?? modeScene;

  // While the useLayoutEffect hook is computing the new scene, we render a full copy of the current scene.
  // This way we allow for a nice and smooth animation between modes, while avoiding incompatibilities
  // between the mode scene and the current state.
  if (modeName !== mode.name) {
    return <SnapshotRenderer />;
  }

  return <SuspenseLoadNotifier>{sceneToRender}</SuspenseLoadNotifier>;
}

function changeActiveCamera(
  curr: Camera,
  next: Camera,
  set: StoreApi<RootState>["setState"],
): void {
  if (curr !== next) {
    set({ camera: next });
  }
}
