import { EventType } from "@/analytics/analytics-events";
import { LENS_CENTER, LensActive, LensDefault } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { memberWithPrivateData } from "@faro-lotv/lotv";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Camera, OrthographicCamera, Vector2, Vector3 } from "three";

/** Utilities, states and callbacks to communicate with the interaction logic. */
export type InteractionLogic = {
  /** The start point of the scaling segment, in 3D coordinates */
  startPoint: Vector3 | undefined;

  /** The end point of the scaling segment, in 3D coordinates */
  endPoint: Vector3 | undefined;

  /** Callback to run when the pointer is pressed */
  onPointerDown(ev: ThreeEvent<PointerEvent>): void;

  /** Callback to run when the pointer is released */
  onPointerUp(ev: ThreeEvent<PointerEvent>): void;

  /** Callback to run when the pointer is moved */
  onPointerMove(ev: ThreeEvent<PointerEvent>): void;

  /** Whether the scaling line is horizontal or vertical */
  isVertical: boolean;

  /** Callback to run when the user removes the scaling measurement */
  onMeasureRemoved(): void;

  /** Callback to run when the user undoes the removal of the scaling measurement */
  onUndoMeasureRemoval(): void;

  /** Whether the pointer controls to move the floorplan are enabled */
  controlsEnabled: boolean;

  /** Whether the measurement line is being dragged */
  isDraggingMeasureLine: boolean;
};

type InteractionLogicProps = {
  /** Callback to run when the user completes a measurement */
  onMeasurementCreated(distance: number): void;
  /** Callback issue when the first point is placed, to reset any previously set distance value */
  resetDistance(): void;
};

/** @returns pointer interaction logic for the floor scale mode */
export function useInteractionLogic({
  onMeasurementCreated,
  resetDistance,
}: InteractionLogicProps): InteractionLogic {
  const camera = useThree((s) => s.camera);
  const size = useThree((s) => s.size);

  assert(camera instanceof OrthographicCamera);

  // Start and end point of the defined line, allow only vertical or horizontal lines
  const [startPoint, setStartPoint] = useState<Vector3>();
  const [endPoint, setEndPoint] = useState<Vector3>();
  const [isVertical, setIsVertical] = useState<boolean>(false);

  // Current interaction state.
  const [interactionState, setInteractionState] = useState<InteractionState>(
    InteractionState.NoPointPlaced,
  );

  // Show custom cursor while drawing the line
  useEffect(() => {
    const noCursor = interactionState === InteractionState.SecondPointPlaced;

    // When the user has already a valid line show the default cursor
    if (noCursor) {
      return;
    }

    const activeCursor =
      interactionState === InteractionState.FirstPointPlaced ||
      interactionState === InteractionState.MovingEndPoint ||
      interactionState === InteractionState.MovingStartPoint;

    // Use a custom cursor while the line is not yet defined
    const originalCursor = document.body.style.cursor;
    document.body.style.cursor = `url("${
      activeCursor ? LensActive : LensDefault
    }") ${LENS_CENTER} ${LENS_CENTER},auto`;

    return () => {
      document.body.style.cursor = originalCursor;
    };
  }, [interactionState]);

  const onClick = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      ev.stopPropagation();
      switch (interactionState) {
        case InteractionState.NoPointPlaced: {
          setStartPoint(ev.point);
          setInteractionState(InteractionState.FirstPointPlaced);
          resetDistance();
          break;
        }
        case InteractionState.FirstPointPlaced: {
          assert(
            startPoint,
            "In FirstPointPlaced, startPoint should be defined",
          );
          setInteractionState(InteractionState.SecondPointPlaced);
          const { adjustedEnd, isVertical } = computeMeasureEnd(
            startPoint,
            ev.point,
            camera,
          );
          setIsVertical(isVertical);
          setEndPoint(adjustedEnd);

          const distance = adjustedEnd.distanceTo(startPoint);
          onMeasurementCreated(distance);
          break;
        }
        case InteractionState.MovingEndPoint:
        case InteractionState.MovingStartPoint: {
          Analytics.track(EventType.adjustAreaScaleLine);
          setInteractionState(InteractionState.SecondPointPlaced);
          setControlsEnabled(true);
          break;
        }
      }
    },
    [interactionState, resetDistance, startPoint, camera, onMeasurementCreated],
  );

  const onDragRelease = useCallback(() => {
    switch (interactionState) {
      case InteractionState.MovingEndPoint:
      case InteractionState.MovingStartPoint:
        setInteractionState(InteractionState.SecondPointPlaced);
        setControlsEnabled(true);
        break;
    }
  }, [interactionState]);

  const pointerDownCoords = useMemo(() => new Vector2(), []);

  const [isPointerDown, setIsPointerDown] = useState<boolean>(false);

  const [controlsEnabled, setControlsEnabled] = useState<boolean>(true);

  const onPointerDown = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      pointerDownCoords.set(ev.clientX, ev.clientY);
      setIsPointerDown(true);

      switch (interactionState) {
        case InteractionState.HoveringEndPoint: {
          setInteractionState(InteractionState.MovingEndPoint);
          setControlsEnabled(false);
          break;
        }
        case InteractionState.HoveringStartPoint: {
          setInteractionState(InteractionState.MovingStartPoint);
          setControlsEnabled(false);
          break;
        }
      }
    },
    [pointerDownCoords, interactionState],
  );

  const onPointerUp = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      setIsPointerDown(false);
      const isClick =
        Math.abs(pointerDownCoords.x - ev.clientX) +
          Math.abs(pointerDownCoords.y - ev.clientY) <
        5;
      if (isClick) {
        onClick(ev);
      } else {
        onDragRelease();
      }
    },
    [pointerDownCoords, onClick, onDragRelease],
  );

  const moveEndPoint = useCallback(
    (point: Vector3) => {
      assert(startPoint, "Now the start point should be defined");
      const { adjustedEnd, isVertical } = computeMeasureEnd(
        startPoint,
        point,
        camera,
      );
      setIsVertical(isVertical);
      setEndPoint(adjustedEnd);
    },
    [camera, startPoint],
  );

  const moveStartPoint = useCallback(
    (point: Vector3) => {
      assert(endPoint, "In this moment the end point should be defined");
      const { adjustedEnd, isVertical } = computeMeasureEnd(
        endPoint,
        point,
        camera,
      );
      setIsVertical(isVertical);
      setStartPoint(adjustedEnd);
    },
    [camera, endPoint],
  );

  const checkHoveringStarted = useCallback(
    (pointerCoord: Vector3) => {
      if (isPointerDown) return;
      assert(startPoint);
      assert(endPoint);
      // check whether the pointer is hovering the endpoints
      if (isHovering(startPoint, pointerCoord, camera, size.height)) {
        setInteractionState(InteractionState.HoveringStartPoint);
      } else if (isHovering(endPoint, pointerCoord, camera, size.height)) {
        setInteractionState(InteractionState.HoveringEndPoint);
      }
    },
    [isPointerDown, camera, size, startPoint, endPoint],
  );

  const checkHoveringEnded = useCallback(
    (point: Vector3 | undefined, pointerCoord: Vector3) => {
      assert(point);
      if (
        !isPointerDown &&
        !isHovering(point, pointerCoord, camera, size.height)
      ) {
        setInteractionState(InteractionState.SecondPointPlaced);
      }
    },
    [isPointerDown, camera, size],
  );

  /** Update the line end point based on the cursor movements during live editing */
  const onPointerMove = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      ev.stopPropagation();
      // Handling here the case in which the pointer down event was propagated here but the
      // pointer up event wasn't, maybe because it was issed on the toolbar or on another component
      const isDown =
        ev.pointerType === "touch" ||
        (ev.pointerType === "mouse" && ev.buttons !== 0);
      if (isDown !== isPointerDown) {
        if (isDown) onPointerDown(ev);
        else onPointerUp(ev);
        return;
      }

      switch (interactionState) {
        case InteractionState.FirstPointPlaced:
          moveEndPoint(ev.point);
          break;
        case InteractionState.SecondPointPlaced:
          checkHoveringStarted(ev.point);
          break;
        case InteractionState.HoveringEndPoint:
          checkHoveringEnded(endPoint, ev.point);
          break;
        case InteractionState.HoveringStartPoint:
          checkHoveringEnded(startPoint, ev.point);
          break;
        case InteractionState.MovingEndPoint:
          moveEndPoint(ev.point);
          break;
        case InteractionState.MovingStartPoint:
          moveStartPoint(ev.point);
          break;
      }
    },
    [
      interactionState,
      startPoint,
      isPointerDown,
      endPoint,
      moveEndPoint,
      moveStartPoint,
      onPointerDown,
      onPointerUp,
      checkHoveringStarted,
      checkHoveringEnded,
    ],
  );

  const onMeasureRemoved = useCallback(() => {
    setStartPoint(undefined);
    setEndPoint(undefined);
    setInteractionState(InteractionState.NoPointPlaced);
  }, []);

  const onUndoMeasureRemoval = useCallback(() => {
    setStartPoint(startPoint);
    setEndPoint(endPoint);
    setInteractionState(InteractionState.SecondPointPlaced);
  }, [startPoint, endPoint]);

  return {
    startPoint,
    endPoint,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    isVertical,
    onMeasureRemoved,
    onUndoMeasureRemoval,
    controlsEnabled,
    isDraggingMeasureLine:
      interactionState === InteractionState.MovingEndPoint ||
      interactionState === InteractionState.MovingStartPoint,
  };
}

enum InteractionState {
  NoPointPlaced = "noPointPlaced",
  FirstPointPlaced = "firstPointPlaced",
  SecondPointPlaced = "secondPointPlaced",
  HoveringStartPoint = "hoveringStartPoint",
  HoveringEndPoint = "hoveringEndPoint",
  MovingStartPoint = "movingStartPoint",
  MovingEndPoint = "movingEndPoint",
}

/**
 * Compute the end point of the line allowing only vertical or horizontal ones
 *
 * @param start point of the line
 * @param cursor position to draw the line towards
 * @param camera the camera used to render the scene
 * @returns the snapped end point of the line
 */
const computeMeasureEnd = memberWithPrivateData(() => {
  const DISPLACEMENT = new Vector3();
  const CAMERA_UP = new Vector3();
  const CAMERA_RIGHT = new Vector3();
  const RELATIVE_START = new Vector3();
  const RELATIVE_CURSOR = new Vector3();

  return (
    start: Vector3,
    cursor: Vector3,
    camera: Camera,
  ): { adjustedEnd: Vector3; isVertical: boolean } => {
    // Extract the up and right direction of the camera
    CAMERA_UP.setFromMatrixColumn(camera.matrixWorld, 1).normalize();
    CAMERA_RIGHT.setFromMatrixColumn(camera.matrixWorld, 0).normalize();

    // Compute the start and cursor positions relative to the camera
    // position and orientation
    RELATIVE_START.copy(start).applyMatrix4(camera.matrixWorldInverse);
    RELATIVE_CURSOR.copy(cursor).applyMatrix4(camera.matrixWorldInverse);
    DISPLACEMENT.subVectors(cursor, start);

    // Allow only vertical or horizontal lines
    const xDiff = Math.abs(RELATIVE_CURSOR.x - RELATIVE_START.x);
    const zDiff = Math.abs(RELATIVE_CURSOR.y - RELATIVE_START.y);
    const isVertical = zDiff > xDiff;

    // Compute the adjusted position by projecting the displacement
    const adjustedEnd = isVertical
      ? start.clone().add(CAMERA_UP.multiplyScalar(DISPLACEMENT.dot(CAMERA_UP)))
      : start
          .clone()
          .add(CAMERA_RIGHT.multiplyScalar(DISPLACEMENT.dot(CAMERA_RIGHT)));
    return { adjustedEnd, isVertical };
  };
});

/** Hovering threshold in pixels */
const HOVERING_THRESHOLD = 12;

/**
 * Check whether 3D points A and B are close enough at the current camera and resolution to issue an hovering state.
 *
 * @param A First point, 3D coordinates
 * @param B Second point, 3D coordinates
 * @param camera current camera
 * @param sizeY current viewport height
 * @returns whether point A is close enough to B at the current camera and resolution to issue an hovering state.
 */
function isHovering(
  A: Vector3,
  B: Vector3,
  camera: OrthographicCamera,
  sizeY: number,
): boolean {
  const distanceMeters = Math.max(Math.abs(A.x - B.x) + Math.abs(A.z - B.z));
  const distancePixels =
    (distanceMeters * sizeY * camera.zoom) / (camera.top - camera.bottom);
  return distancePixels < HOVERING_THRESHOLD;
}
