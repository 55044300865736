import { LotvRenderer } from "@faro-lotv/lotv";
import JSZip from "jszip";
import {
  Box3,
  Mesh,
  MeshBasicMaterial,
  OrthographicCamera,
  PlaneGeometry,
  Quaternion,
  Scene,
  Vector3,
} from "three";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
import { Line2 } from "three/examples/jsm/lines/Line2";

const OBJ_FILE_NAME = "model.obj";
const TEXTURE_FILE_NAME = "t_0.png";
const MTL_FILE_NAME = "modelmaterial.mtl";
const MTL_MATERIAL_NAME = "mat_0";

/**
 * @param annotation mesh object of the annotation to create
 * @returns the data urls used to download the zip file and its content
 */
export function createAnnotationZip(annotation: Mesh): Promise<Blob> {
  // Create an object to render offscreen
  const mesh = annotation.clone();

  mesh.position.set(0, 0, 0);
  mesh.rotation.setFromQuaternion(new Quaternion());

  // Get size of the annotation's mesh
  const bbox = new Box3();
  bbox.expandByObject(mesh);
  const size = new Vector3();
  bbox.getSize(size);
  const aspectRatio = size.x / size.y;

  // Create a camera
  const camera = new OrthographicCamera(
    -size.x / 2,
    size.x / 2,
    size.y / 2,
    -size.y / 2,
  );

  // Move the camera back a little so that the annotation is visible
  camera.position.set(0, 0, 1);

  // Create an offscreen canvas
  const canvas = document.createElement("canvas");

  // Create the renderer
  const renderer = new LotvRenderer({ canvas, alpha: true });
  const scene = new Scene();

  // Set the size of the canvas (the texture file will be the same size)
  canvas.height = 200;
  canvas.width = Math.round(canvas.height * aspectRatio);
  renderer.setSize(canvas.width, canvas.height);

  if (mesh instanceof Line2) {
    // Cloning the material to make sure not to alter the original annotation
    mesh.material = mesh.material.clone();

    // Set the line resolution so its size is equal on the entire border
    mesh.material.resolution.set(canvas.width, canvas.height);
  }

  // Add the annotation to the scene and render it
  scene.add(mesh);
  renderer.render(scene, camera);

  const textureData = renderer.domElement.toDataURL();

  // Create a plane as big as the annotation's mesh
  const plane = new Mesh(new PlaneGeometry(1, 1), new MeshBasicMaterial());

  // Create the obj data and prepend which mtl file and material to use
  const objExporter = new OBJExporter();
  const objData = `mtllib ${MTL_FILE_NAME}\nusemtl ${MTL_MATERIAL_NAME}\n${objExporter.parse(
    plane,
  )}`;

  // Remove the data prefix from the texture data string, so that it can be saved as a file
  const textureBase64 = textureData.split(",")[1];

  // Create MTL file data
  const mtlData = createMtlContent(TEXTURE_FILE_NAME);

  // Create the zip file data
  const jsZip = new JSZip();
  jsZip.file(OBJ_FILE_NAME, objData);
  jsZip.file(TEXTURE_FILE_NAME, textureBase64, { base64: true });
  jsZip.file(MTL_FILE_NAME, mtlData);

  return jsZip.generateAsync({ type: "blob" });
}

/**
 * @param textureName name of the texture file to reference (it must include the extension)
 * @returns the content of a MTL file. For now it only reference a texture
 */
function createMtlContent(textureName: string): string {
  let content = "newmtl mat_0\n";
  content += `\tmap_Kd ./${textureName}\n`;
  return content;
}
