import {
  IElementBase,
  IElementGenericPointCloudStream,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";

/**
 * @returns True if the given iElement is a streamed point cloud
 * @param iElement element to check
 */
export function isIElementValidPointCloudStream(
  iElement: IElementBase,
): iElement is IElementGenericPointCloudStream {
  return isIElementGenericPointCloudStream(iElement);
}
