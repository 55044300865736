import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementImg360,
  isIElementGenericDataset,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import {
  GenericUserInfo,
  ProjectPermissions,
  isRegisteredUserInfo,
} from "@faro-lotv/service-wires";
import { RootState } from "./store";

/**
 * @returns list of users with current project access
 * @param state app state
 */
export function selectProjectUsers(state: RootState): GenericUserInfo[] {
  return state.project.users;
}

/**
 * @param id ID of the user to select
 * @returns user details of the user with the given ID
 */
export function selectProjectUser(id?: GUID) {
  return (state: RootState): GenericUserInfo | undefined =>
    state.project.users.find((user) => user.id === id);
}

/**
 * @param allowViewers true to allow viewers to be assigned to annotations
 * @returns list of users that can be assigned to an annotation (sorted alphabetically by name)
 */
export function selectAnnotationAssignableUsers(allowViewers: boolean) {
  return (state: RootState): GenericUserInfo[] =>
    state.project.users
      // User without a userId have not accepted the invite yet, can't be assigned to annotations
      .filter(isRegisteredUserInfo)
      .filter(
        (user) =>
          // The permission level required to read annotations change by project
          !!user.projectPermissions?.includes(
            allowViewers ? ProjectPermissions.read : ProjectPermissions.write,
          ),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
}

/**
 * @param imageElement the image element to check
 * @returns true if the 360/pano was extracted from a data or scan (i.e. user should not be allowed to edit rotation)
 */
export function selectIsPanoExtractedFromData(
  imageElement: IElementImg360 | undefined,
) {
  return (state: RootState): boolean => {
    return (
      selectAncestor(
        imageElement,
        (element: IElement) =>
          isIElementSectionDataSession(element) ||
          isIElementGenericDataset(element),
      )(state) !== undefined
    );
  };
}
