import { degToRad } from "@faro-lotv/lotv";

/**
 * @param oHeight The given orhographic frustum vertical size, in meters
 * @param fov The given perspective frustum FOV angle, in degrees
 * @returns The distances at which the perspective camera should be placed, to
 * have the same frustum height as the given ortho vertical size.
 */
export function perspCameraDistance(oHeight: number, fov: number): number {
  return (oHeight * 0.5) / Math.tan(degToRad(fov) * 0.5);
}
