import {
  EventType,
  ToggleTagFilterMenuProperties,
  ToggleTagProperties,
} from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectSelectedTags, selectTags } from "@/store/tags/tags-selectors";
import { UNTAGGED, setSelectedTags } from "@/store/tags/tags-slice";
import {
  FilterIcon,
  FilterMenu,
  FilterMenuOptionType,
  NoTranslate,
  ToolBadge,
  ToolButton,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { PopoverProps } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

export type TagsFilterToolProps = {
  /** Reference to the element to anchor the popover to */
  anchorEl: PopoverProps["anchorEl"];
};

/**
 * @returns an icon button that open up a menu that allows the user to filter the scene based on the selected tags
 */
export function TagsFilterTool({ anchorEl }: TagsFilterToolProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const tags = useAppSelector(selectTags);
  const selectedTags = useAppSelector(selectSelectedTags);

  // List of tag to show in the filter menu, sorted alphabetically
  const tagOptions = useMemo(
    () =>
      tags
        .map((tag) => ({
          key: tag.id,
          value: tag.name,
          label: <NoTranslate>{tag.name}</NoTranslate>,
        }))
        // Keep Untagged on top of the list
        .sort((a, b) => {
          if (a.value === UNTAGGED.name) {
            return -1;
          } else if (b.value === UNTAGGED.name) {
            return 1;
          }
          return a.value.localeCompare(b.value);
        }),
    [tags],
  );

  const onChangeSelectedTags = useCallback(
    (selectedTags: FilterMenuOptionType[], enabledOption: boolean) => {
      Analytics.track<ToggleTagProperties>(EventType.toggleTag, {
        enabling: enabledOption,
        numberOfSelectedTags: selectedTags.length,
      });

      dispatch(
        setSelectedTags(
          selectedTags.map((tag) => ({ id: tag.key, name: tag.value })),
        ),
      );
    },
    [dispatch],
  );

  const resetSelectedTags = useCallback(() => {
    Analytics.track(EventType.clearSelectedTags);

    dispatch(setSelectedTags([]));
  }, [dispatch]);

  // Initial list of selected options in the filter menu.
  // Use useState since it's only an initial value and does not need to be recomputed
  const [initialSelectedOptions] = useState<FilterMenuOptionType[]>(
    selectedTags.map((tag) => ({
      key: tag.id,
      value: tag.name,
      label: tag.name,
    })),
  );

  const toggleFilterMenu = useCallback((shouldOpen: boolean) => {
    Analytics.track<ToggleTagFilterMenuProperties>(
      EventType.toggleTagFilterMenu,
      {
        opening: shouldOpen,
      },
    );

    setIsMenuOpen(shouldOpen);
  }, []);

  return (
    <>
      <ToolButton
        onClick={() => toggleFilterMenu(!isMenuOpen)}
        selected={isMenuOpen}
      >
        <ToolBadge badgeContent={selectedTags.length}>
          <FilterIcon />
        </ToolBadge>
      </ToolButton>
      <FilterMenu
        open={isMenuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: -2,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        options={tagOptions}
        initialSelectedOptions={initialSelectedOptions}
        headingLabel="FILTER MENU"
        dark
        onFilterMenuClose={() => toggleFilterMenu(false)}
        onFilterChange={onChangeSelectedTags}
        onResetFilter={resetSelectedTags}
      />
    </>
  );
}
