import { computeDeepLink } from "@/components/common/deep-link/deep-link-utils";
import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { useAppStore } from "@/store/store-hooks";
import { useToast } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { useCallback } from "react";

/**
 * @param id of the annotation or analysis to share
 * @returns a function to copy a deep link to the annotation or analysis in the clipboard
 */
export function useShareAnnotationOrAnalysis(id?: GUID): () => void {
  const { openToast } = useToast();
  const store = useAppStore();
  const viewContext = useViewRuntimeContext();

  return useCallback(() => {
    const url = computeDeepLink(store.getState(), viewContext, id);
    navigator.clipboard.writeText(url.toString());
    openToast({
      title: "Link copied",
    });
  }, [id, openToast, store, viewContext]);
}
