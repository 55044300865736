import { clearStore } from "@faro-lotv/app-component-toolbox";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AlignmentUIState = {
  /** Open/Collapsed state quick help panel */
  isQuickHelpOpen: boolean;
};

export const initialState: Readonly<AlignmentUIState> = Object.freeze({
  isQuickHelpOpen: false,
});

const alignmentUiSlice = createSlice({
  name: "alignment-ui",
  initialState,
  reducers: {
    /**
     * Set the visibility of the quick help panel
     *
     * @param state Current state
     * @param action The target state of the quick help panel
     */
    setIsQuickHelpOpen(state, action: PayloadAction<boolean>) {
      state.isQuickHelpOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const alignmentUiReducer = alignmentUiSlice.reducer;

export const { setIsQuickHelpOpen } = alignmentUiSlice.actions;
