import { Box } from "@mui/system";
import { LargeCard } from "./large-card";
import { MediumCard } from "./medium-card";
import { ResponsiveCardProps } from "./responsive-card-types";
import { ResponsiveCardVariant } from "./responsive-card-variant";

/**
 * @returns A card with different layouts based on the panel width
 */
export function ResponsiveCard({
  variant,
  sx,
  ...props
}: ResponsiveCardProps): JSX.Element {
  return (
    <Box component="div" sx={{ width: "100%", lineHeight: 0, ...sx }}>
      {variant === ResponsiveCardVariant.medium && <MediumCard {...props} />}
      {variant === ResponsiveCardVariant.large && <LargeCard {...props} />}
    </Box>
  );
}
