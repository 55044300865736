import { OrientedBoundingBox as OrientedBoundingBoxTuples } from "@/components/r3f/utils/oriented-bounding-box";
import { selectClippingBox } from "@/store/clipping-box-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { OrientedBoundingBox } from "@faro-lotv/lotv";
import { useState } from "react";
import { Plane, Quaternion, Vector3 } from "three";

export type ClippingPlanesReturn = {
  /** The stored clipping planes */
  clippingPlanes: Plane[];
  /** The function to modify the clipping planes */
  setClippingPlanes(p: Plane[]): void;
};

/**
 *
 * @returns an interface to read and write an array of clipping planes to a storage in memory.
 */
export function useClippingPlanes(): ClippingPlanesReturn {
  const clippingBox = useAppSelector(selectClippingBox);

  const [clippingPlanes, setClippingPlanes] = useState(() =>
    obbTuplesToPlanes(clippingBox),
  );

  return {
    clippingPlanes,
    setClippingPlanes,
  };
}

/**
 * @returns the clipping planes for an OrientedBoundingBox
 * @param box the OrientedBoundingBox in tuple form to get the clipping planes for
 */
function obbTuplesToPlanes(box?: OrientedBoundingBoxTuples): Plane[] {
  if (!box) {
    return [];
  }

  return obbToPlanes({
    position: new Vector3().fromArray(box.position),
    quaternion: new Quaternion().fromArray(box.rotation),
    size: new Vector3().fromArray(box.size),
  });
}

/**
 * @returns the clipping planes for an OrientedBoundingBox
 * @param box the OrientedBoundingBox to get the clipping planes for
 */
export function obbToPlanes(box: OrientedBoundingBox): Plane[] {
  // Main direction axes and their distance from the box origin
  const axes: Array<[Vector3, number]> = [
    [new Vector3(1, 0, 0), box.size.x / 2],
    [new Vector3(-1, 0, 0), box.size.x / 2],
    [new Vector3(0, 1, 0), box.size.y / 2],
    [new Vector3(0, -1, 0), box.size.y / 2],
    [new Vector3(0, 0, 1), box.size.z / 2],
    [new Vector3(0, 0, -1), box.size.z / 2],
  ];

  // Compute plane for each main direction
  return axes.map(([axe, distance]) => {
    // The inverted plane normal, going from the box center to the plane
    const invertedNormal = axe.applyQuaternion(box.quaternion);
    // A point on the plane
    const point = box.position
      .clone()
      .add(invertedNormal.clone().multiplyScalar(distance));

    return new Plane().setFromNormalAndCoplanarPoint(
      invertedNormal.negate(),
      point,
    );
  });
}
