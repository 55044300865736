import { selectHasCreatedNewAnnotation } from "@/store/create-annotation-slice";
import {
  selectActiveMeasurement,
  selectSessionMeasurements,
} from "@/store/measurement-tool-selector";
import { selectNumberOfSessionAnalyses } from "@/store/point-cloud-analysis-tool-selector";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { HelpBanner } from "@faro-lotv/app-component-toolbox";
import { SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { AvailableAnnotationTools } from "../../r3f/renderers/annotations/annotations-types";

type Props = {
  /** Additional styling props */
  sx?: SxProps<Theme>;
};

type GeneralHelpBannerProps = Props & {
  /** Text to show in the banner */
  text: ReactNode;

  /** Name of the tool to check. When the tool is activated, the banner will be shown */
  toolToCheck: ToolName;

  /** If  true, then the banner will be hidden */
  hidden: boolean;
};

function GeneralHelpBanner({
  text,
  toolToCheck,
  hidden,
  sx,
}: GeneralHelpBannerProps): JSX.Element | null {
  const activeTool = useAppSelector(selectActiveTool);

  if (activeTool !== toolToCheck || hidden) return null;
  return <HelpBanner sx={sx}>{text}</HelpBanner>;
}

/**
 * @returns a help message banner which can be used for informing how the measurement can be performed
 */
export function MeasurementHelpBanner({ sx }: Props): JSX.Element | null {
  const activeMeasure = useAppSelector(selectActiveMeasurement);
  const isFirstMeasurement = useAppSelector(selectSessionMeasurements) === 0;

  return (
    <GeneralHelpBanner
      text={
        <>
          To complete the measurement, press Esc key, or close a polygon by
          clicking the first point.
        </>
      }
      toolToCheck={ToolName.measurement}
      hidden={!!activeMeasure || !isFirstMeasurement}
      sx={sx}
    />
  );
}

interface CreateAnnotationHelpBannerProps extends Props {
  /** Available tool/s to which the help text needs to be updated */
  availableTool: AvailableAnnotationTools;
}

/**
 * @returns a help message banner which can be used for informing how to create a new Annotation
 */
export function CreateAnnotationHelpBanner({
  availableTool,
  sx,
}: CreateAnnotationHelpBannerProps): JSX.Element | null {
  const hasCreatedNewAnnotation = useAppSelector(selectHasCreatedNewAnnotation);

  let helpText = "";
  switch (availableTool) {
    case AvailableAnnotationTools.rectangle:
      helpText = "Click and drag to draw the annotation rectangle.";
      break;
    case AvailableAnnotationTools.singlePoint:
      helpText =
        "Click the point at which you want to insert a point annotation.";
      break;
    case AvailableAnnotationTools.all:
      helpText =
        "Click to insert a point annotation or click and drag to insert an area annotation.";
      break;
  }
  return (
    <GeneralHelpBanner
      text={helpText}
      toolToCheck={ToolName.annotation}
      hidden={hasCreatedNewAnnotation}
      sx={sx}
    />
  );
}

/**
 * @returns a help message banner which can be used for informing how the analysis can be performed
 */
export function AnalysisHelpBanner({ sx }: Props): JSX.Element | null {
  const isFirstAnalysis = useAppSelector(selectNumberOfSessionAnalyses) === 0;

  return (
    <GeneralHelpBanner
      text={<>Close the polygon by clicking the first point.</>}
      toolToCheck={ToolName.analysis}
      hidden={!isFirstAnalysis}
      sx={sx}
    />
  );
}
