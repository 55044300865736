import { createContext, useContext, useState } from "react";

/** All the overlay elements we need in the sheet-cloud alignment tool */
export type AlignmentOverlayElements = {
  /** Left screen in split screen */
  leftScreen: HTMLDivElement | null;

  /** Change the div used for the left split screen */
  setLeftScreen(div: HTMLDivElement | null): void;

  /** Right screen in split screen */
  rightScreen: HTMLDivElement | null;

  /** Change the div used for the left split screen */
  setRightScreen(div: HTMLDivElement | null): void;
};

/** A context to pass to R3F the overlay elements we need in the sheet-cloud alignment tool */
export const AlignmentOverlayElementsContext = createContext<
  AlignmentOverlayElements | undefined
>(undefined);

/**
 * @returns The registered overlay elements to be used in the sheet-cloud alignment tool
 */
export function useAlignmentOverlayElements(): AlignmentOverlayElements {
  const ctx = useContext(AlignmentOverlayElementsContext);
  if (!ctx) {
    throw Error(
      "useAlignmentOverlayElements called outside an AlignmentOverlayElementsContext",
    );
  }
  return ctx;
}

/**
 * @returns The initial state for the OverlayElementsContext
 */
export function useAlignmentOverlayElementsInitialState(): AlignmentOverlayElements {
  const [leftScreen, setLeftScreen] = useState<HTMLDivElement | null>(null);
  const [rightScreen, setRightScreen] = useState<HTMLDivElement | null>(null);

  return {
    leftScreen,
    setLeftScreen,
    rightScreen,
    setRightScreen,
  };
}
