import { FaroToast } from "@faro-lotv/flat-ui";
import { Snackbar } from "@mui/material";
import { useCallback, useState } from "react";

/** @returns A toast showed at the center of the reference html element if the pano has no depth information */
export function PanoNoDepthInfoToast(): JSX.Element {
  // snackbar open/close state
  const [isOpen, setIsOpen] = useState(true);

  // Close the toast when clicking on the X button
  const closeToast = useCallback(() => setIsOpen(false), []);

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
      }}
      autoHideDuration={null}
    >
      <FaroToast
        variant="info"
        title="Measurement on 360° photo not available"
        message="Depth information is required to query coordinates from 360° photos"
        onClose={closeToast}
      />
    </Snackbar>
  );
}
