import { ScaleReset, useLotvDispose } from "@faro-lotv/app-component-toolbox";
import { BillboardScreenSprite } from "@faro-lotv/lotv";
import { Vector3 as Vector3Prop } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useMemo } from "react";
import { CircleGeometry, ColorRepresentation, Texture } from "three";
import {
  INACTIVE_MEASURES_OPACITY,
  MeasureDepthOffsets,
} from "./measure-constants";

// Common geometry to reuse for the handlers
const geometry = new CircleGeometry(0.1);

type HandlerRendererProps = EventHandlers & {
  /** Position of the handler */
  position: Vector3Prop;

  /** Size in px of the diameter of the renderer */
  size: number;

  /** Color of the handler */
  color: ColorRepresentation;

  /** A custom texture to use for the handler */
  texture?: Texture;

  /** True if this label should be transparent at INACTIVE_MEASURES_OPACITY, false if it should be opaque. */
  transparent?: boolean;
};

/**
 * @returns a billboard, circular 3d object that has a fixed screen size in pixels.
 */
export function HandlerRenderer({
  position,
  size,
  color,
  texture,
  transparent,
  ...rest
}: HandlerRendererProps): JSX.Element {
  const sprite = useMemo(() => new BillboardScreenSprite(geometry), []);

  useLotvDispose(sprite);

  return (
    <ScaleReset position={position}>
      <primitive
        object={sprite}
        opacity={transparent ? INACTIVE_MEASURES_OPACITY : 1.0}
        size={size}
        material-color={color}
        /* eslint-disable react/no-unknown-property */
        material-map={texture}
        material-zOffset={MeasureDepthOffsets.handler}
        /* eslint-enable react/no-unknown-property */
        {...rest}
      />
    </ScaleReset>
  );
}
