import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  LodPointCloudRendererBase,
  LodPointCloudRendererBaseProps,
  TransformOverrides,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { isEqual } from "lodash";
import { useRef, useState } from "react";
import { Group, Matrix4 } from "three";

export type PositionedPointCloudProps = Omit<
  LodPointCloudRendererBaseProps,
  "pointCloud"
> & {
  /** The point cloud to render with the correct position. */
  pointCloudObject: PointCloudObject;

  /** Overrides to respect when positioning the point cloud. */
  transformOverrides?: TransformOverrides;

  /** Callback to execute when the initial position of the point cloud is applied. */
  onInitialPositioning?(): void;

  /**
   * Whether the point cloud should be rendered.
   *
   * @default true
   */
  isVisible?: boolean;
};

/** @returns The rendered point cloud, positioned according to the world position and the transform overrides. */
export function PositionedPointCloud({
  pointCloudObject,
  transformOverrides,
  onInitialPositioning,
  isVisible = true,
  ...rest
}: PositionedPointCloudProps): JSX.Element | null {
  const [groupRef, setGroupRef] = useState<Group | null>(null);
  const isInitialPosition = useRef(true);

  const transformMatrix = useAppSelector(
    selectIElementWorldMatrix4(
      pointCloudObject.iElement.id,
      transformOverrides,
    ),
    isEqual,
  );

  // Apply the transform to the group
  useNonExhaustiveEffect(() => {
    if (groupRef) {
      applyTransformToGroup(groupRef, transformMatrix);

      if (isInitialPosition.current) {
        isInitialPosition.current = false;
        onInitialPositioning?.();
      }
    }
  }, [groupRef, transformMatrix]);

  if (!isVisible) {
    if (groupRef) {
      setGroupRef(null);
    }
    return null;
  }

  return (
    <group ref={setGroupRef}>
      {
        // Don't render before the position can be applied
        groupRef && (
          <LodPointCloudRendererBase pointCloud={pointCloudObject} {...rest} />
        )
      }
    </group>
  );
}

/**
 * Apply the given transform to the group and update its world matrix.
 *
 * @param group A reference to the group to apply the transform on.
 * @param transform The new transform of the point cloud.
 */
function applyTransformToGroup(group: Group, transform: Matrix4): void {
  transform.decompose(group.position, group.quaternion, group.scale);
  group.updateWorldMatrix(true, true);
}
