import {
  MeasurementUnits,
  dynamicDistanceLabel,
} from "@faro-lotv/app-component-toolbox";

export enum UnitType {
  /** A length, given in meters. */
  length = "length",

  /** A fractional value between 0 and 1. */
  fraction = "fraction",
}

/** Number of decimals to display. */
const DECIMALS = 1;

/**
 * @param value The value, in the given unit.
 * @param unit The unit the value is given in.
 * @returns A string representing the unit value.
 */
export function displayUnitValue(
  value: number | undefined,
  unit: UnitType,
): string {
  if (value === undefined) return "--";
  if (value === Number.MAX_VALUE) return "Infinity";

  switch (unit) {
    case UnitType.length:
      return dynamicDistanceLabel({
        value,
        from: MeasurementUnits.meters,
        metricPrecision: DECIMALS,
        // In the future, we can also display values in US units, based on the user's preference
        system: "metric",
      });
    case UnitType.fraction:
      return displayFraction(value);
  }
}

/**
 *
 * @param value The fraction to display. A value between 0 and 1.
 * @returns A string representing the fraction.
 */
function displayFraction(value: number): string {
  const percentage = value * 100;
  return `${percentage.toFixed(DECIMALS)}%`;
}
