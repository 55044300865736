import { IElement } from "@faro-lotv/ielement-types";
import { useLayoutEffect } from "react";

type ModelToModelProp = {
  /** The target model element of the animation */
  targetElement: IElement;
  /** The callback executed when the animation finishes */
  onAnimationFinished(target: IElement): void;
};

/** @returns The model to model (fake) animation for the walk scene */
export function ModelToModel({
  targetElement,
  onAnimationFinished,
}: ModelToModelProp): null {
  useLayoutEffect(() => {
    onAnimationFinished(targetElement);
  }, [onAnimationFinished, targetElement]);
  return null;
}
