import { Object3D, PerspectiveCamera, Points } from "three";

/**
 * @returns Check if the provided Three.js Object3D is a PerspectiveCamera
 * @param object3D Object to check
 */
export function isPerspectiveCamera(
  object3D: Object3D,
): object3D is PerspectiveCamera {
  return "isPerspectiveCamera" in object3D;
}

/**
 * @param obj The 3D object to check for being a point cloud point.
 * @returns true, if the given 3D object is a point cloud point.
 */
export function isObjPointCloudPoint(obj: Object3D): obj is Points {
  return obj instanceof Points;
}
