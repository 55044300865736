import {
  ChangeIndicatorBadge,
  FaroButton,
  FilterIcon,
} from "@faro-lotv/flat-ui";
import { useCallback } from "react";

export type FilterToggleProps = {
  /** true if the toggle is checked */
  isChecked: boolean;

  /** true if at least one filter is defined */
  hasFilters: boolean;

  /** notify the user clicked on the toggle */
  onToggle(checked: boolean): void;
};

/** @returns a toggle to show/hide the row with annotations filters */
export function FilterToggle({
  isChecked,
  hasFilters,
  onToggle,
}: FilterToggleProps): JSX.Element {
  const reportToggle = useCallback(() => {
    onToggle(!isChecked);
  }, [isChecked, onToggle]);

  return (
    <FaroButton
      sx={{ color: "black" }}
      icon={<FilterToggleIcon hasFilters={hasFilters} />}
      variant="ghost"
      onClick={reportToggle}
    >
      Filters
    </FaroButton>
  );
}

/** @returns the toggle icon with an optional badge if filters are defined */
function FilterToggleIcon({
  hasFilters,
}: Pick<FilterToggleProps, "hasFilters">): JSX.Element {
  return (
    <ChangeIndicatorBadge showZero={false} badgeContent={hasFilters ? 1 : 0}>
      <FilterIcon />
    </ChangeIndicatorBadge>
  );
}
