import { CircularProgress } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";
import { Box } from "@mui/material";

type RevisionLoadingSpinnerProps = {
  /** The current state of the revision. */
  revisionState: RegistrationState;
};

/** @returns A loading spinner while the registration is in progress. */
export function RevisionLoadingSpinner({
  revisionState,
}: RevisionLoadingSpinnerProps): JSX.Element | null {
  switch (revisionState) {
    case RegistrationState.started:
    case RegistrationState.cloudRegistrationStarted:
      return (
        <Box
          component="div"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          sx={{ display: "flex", height: "100%", pointerEvents: "none" }}
        >
          <CircularProgress size={60} />
        </Box>
      );
    default:
      return null;
  }
}
