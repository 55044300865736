import { runtimeConfig } from "@/runtime-config";
import { useAppSelector } from "@/store/store-hooks";
import { selectDashboardUrl } from "@faro-lotv/app-component-toolbox";
import { DisabledIcon, FaroButton, FaroText } from "@faro-lotv/flat-ui";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { Link, Stack, SxProps, Theme } from "@mui/material";

type LimitedAccessProps = {
  /** Optional styling applied to the component */
  sx?: SxProps<Theme>;
};

/** @returns information about the limited access of the user to the project */
export function LimitedAccess({ sx }: LimitedAccessProps): JSX.Element {
  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const { requestLogin, isLoggedIn } = useAuthContext();

  return (
    <Stack sx={sx}>
      <Stack
        sx={{
          p: 4,
          maxWidth: 800,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        <Stack
          component="div"
          sx={{
            width: 180,
            height: 180,
            mt: 5,
            background: "white",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DisabledIcon sx={{ width: 60, height: 60, color: "gray500" }} />
        </Stack>
        <FaroText variant="heading24" sx={{ mt: 5 }}>
          Limited access to the project
        </FaroText>
        <FaroText variant="bodyL" sx={{ mt: 3 }}>
          You currently don't have the ability to upload files. You can request
          additional permission to actively participate in the project by
          contacting the Project Manager.
        </FaroText>
        {isLoggedIn ? (
          <Link href={dashboardUrl ?? runtimeConfig.externalLinks.dashboardUrl}>
            <FaroButton variant="ghost" sx={{ mt: 5 }}>
              Go back to Project Overview
            </FaroButton>
          </Link>
        ) : (
          <FaroButton variant="ghost" sx={{ mt: 5 }} onClick={requestLogin}>
            Login
          </FaroButton>
        )}
      </Stack>
    </Stack>
  );
}
