import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { CadModelObject, PointCloudObject } from "@/object-cache";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { store } from "@/store/store";
import { useAppDispatch } from "@/store/store-hooks";
import {
  ToolName,
  ToolVisibility,
  changeToolVisibility,
  setActiveSettingsMenu,
} from "@/store/ui/ui-slice";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";

/**
 * Takes care of showing or hiding toolbar buttons available only when the CAD and point cloud
 * are rendered together in overlay.
 *
 * @param mainModel The main object rendered
 * @param overlayModel The optional overlay model
 */
export function useOverlayToolsVisibility(
  mainModel: PointCloudObject | CadModelObject | null,
  overlayModel: CadModelObject | null,
): void {
  const dispatch = useAppDispatch();

  const transparency = useTransparencySettingsContext();

  // Here a non-exhaustive useEffect is used, with the purpose
  // that the effect should not be run when the `transparency`
  // context changes, but only when the model changes.
  // Usage of a normal 'useEffect' causes the opacity sliders to
  // close abruptly when the user sets the two sliders to full opaque.
  useNonExhaustiveEffect(() => {
    const visible = !!mainModel && !!overlayModel;
    const isHeatMapEnabled = selectHasFeature(
      Features.CloudToCadHeatmapAvailable,
    )(store.getState());

    if (visible) {
      dispatch(
        changeToolVisibility({
          name: ToolName.opacity,
          visibility: ToolVisibility.visible,
        }),
      );
      dispatch(
        changeToolVisibility({
          name: ToolName.heatmap,
          visibility: isHeatMapEnabled
            ? ToolVisibility.visible
            : ToolVisibility.hidden,
        }),
      );
      return () => {
        dispatch(
          changeToolVisibility({
            name: ToolName.opacity,
            visibility: ToolVisibility.hidden,
          }),
        );
        dispatch(
          changeToolVisibility({
            name: ToolName.heatmap,
            visibility: ToolVisibility.hidden,
          }),
        );
        dispatch(setActiveSettingsMenu(null));
        transparency.resetOpacities();
      };
    }
  }, [mainModel, overlayModel, dispatch]);
}
