import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "./sheet-to-cad-alignment-mode-slice";

/* reference that is used for alignment, to support both cloud-to-cad and cad-to-cloud alignment */
export enum AlignmentReference {
  bimModel = "bimModel",
  pointCloud = "pointCloud",
}

/** This defines all the steps to align cloud and a 3D model */
export enum CloudToCadAlignmentStep {
  setElevations = "setElevations",
  alignIn2d = "alignIn2d",
}

/** State managing data exchange during cloud and cad alignment session */
type CloudToCadAlignmentToolState = {
  /** reference type for cloud and cad alignment */
  alignmentReference: AlignmentReference;

  /** reference to cloud ID used during current alignment session */
  cloudIdToAlignWithCad?: GUID;

  /** level elevation in 3D model */
  modelElevation: number;

  /** level elevation in point cloud */
  cloudElevation: number;

  /** Alignment step */
  step: CloudToCadAlignmentStep;

  /** if true clipping box in Vertical alignment step is enabled */
  isModelClippingBoxEnabled: boolean;

  /** if true, extract cross section at elevation defined on first step for cad model tomographic view */
  isModelCrossSectionEnabled: boolean;

  /** if true, extract cross section at elevation defined on first step for cloud tomographic view */
  isCloudCrossSectionEnabled: boolean;

  /**
   * Incremental transform of the cloud to cad model (in case alignmentReference is "bimModel") OR
   * cad model to cloud (in case alignmentReference is "pointCloud"). Translations are in meters.
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /**
   * anchor positions defined in split screen alignment
   * In case that alignmentReference is "bimModel", reference anchors are from cad model and moving anchors are from point cloud.
   * In case that alignmentReference is "pointCloud", reference anchors are from point cloud and moving anchors are from cad model.
   */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;

  /** cloud to CAD alignment screen layout */
  alignmentLayout: AlignmentViewLayout;

  /** the alignment screen split direction */
  splitDirection: SplitDirection;
};

const initialState: CloudToCadAlignmentToolState = {
  alignmentReference: AlignmentReference.bimModel,
  step: CloudToCadAlignmentStep.setElevations,
  isModelClippingBoxEnabled: false,
  alignmentAnchorPositions: {},
  isModelCrossSectionEnabled: true,
  isCloudCrossSectionEnabled: true,
  alignmentLayout: AlignmentViewLayout.splitScreen,
  splitDirection: SplitDirection.horizontalSplit,
  modelElevation: 0,
  cloudElevation: 0,
};

export const cloudToCadAlignmentModeSlice = createSlice({
  initialState,
  name: "cloudToCadAlignmentMode",
  reducers: {
    setAlignmentReference(state, action: PayloadAction<AlignmentReference>) {
      state.alignmentReference = action.payload;
    },
    setStepForCloudAlignment(
      state,
      action: PayloadAction<CloudToCadAlignmentStep>,
    ) {
      state.step = action.payload;
    },

    setModelClippingBoxEnabled(state, action: PayloadAction<boolean>) {
      state.isModelClippingBoxEnabled = action.payload;
    },

    setModelCrossSectionEnabled(state, action: PayloadAction<boolean>) {
      state.isModelCrossSectionEnabled = action.payload;
    },

    setCloudCrossSectionEnabled(state, action: PayloadAction<boolean>) {
      state.isCloudCrossSectionEnabled = action.payload;
    },

    setCloudForCloudAlignment(state, action: PayloadAction<GUID>) {
      state.cloudIdToAlignWithCad = action.payload;
    },

    setModelElevationForCloudAlignment(state, action: PayloadAction<number>) {
      state.modelElevation = action.payload;
    },

    setCloudElevationForCloudAlignment(state, action: PayloadAction<number>) {
      state.cloudElevation = action.payload;
    },

    setCloudToCadIncrementalTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setMovingElementAnchor1ForCloudAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setMovingElementAnchor2ForCloudAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },

    setReferenceElementAnchor1ForCloudAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setReferenceElementAnchor2ForCloudAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },

    resetCloudToCadAlignment(state) {
      state.alignmentReference = AlignmentReference.bimModel;
      state.step = CloudToCadAlignmentStep.setElevations;
      state.cloudIdToAlignWithCad = undefined;
      state.modelElevation = 0;
      state.cloudElevation = 0;
      state.isModelClippingBoxEnabled = false;
      state.incrementalTransform = undefined;
      state.alignmentAnchorPositions = {};
      state.alignmentLayout = AlignmentViewLayout.splitScreen;
      state.splitDirection = SplitDirection.horizontalSplit;
    },

    setCloudToCadAlignmentLayout(
      state,
      action: PayloadAction<AlignmentViewLayout>,
    ) {
      state.alignmentLayout = action.payload;
    },

    setCloudToCadAlignmentSplitDirection(
      state,
      action: PayloadAction<SplitDirection>,
    ) {
      state.splitDirection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  setAlignmentReference,
  resetCloudToCadAlignment,
  setStepForCloudAlignment,
  setCloudForCloudAlignment,
  setCloudToCadIncrementalTransform,
  setModelElevationForCloudAlignment,
  setCloudElevationForCloudAlignment,
  setModelClippingBoxEnabled,
  setModelCrossSectionEnabled,
  setCloudCrossSectionEnabled,
  setCloudToCadAlignmentLayout,
  setMovingElementAnchor1ForCloudAlignment,
  setMovingElementAnchor2ForCloudAlignment,
  setReferenceElementAnchor1ForCloudAlignment,
  setReferenceElementAnchor2ForCloudAlignment,
  setCloudToCadAlignmentSplitDirection,
} = cloudToCadAlignmentModeSlice.actions;

export const cloudToCadAlignmentModeReducer =
  cloudToCadAlignmentModeSlice.reducer;
