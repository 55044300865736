import { PropsWithChildren, createContext, useContext, useState } from "react";
import { TypedEvent } from "@faro-lotv/foundation";
import { QualityStatus } from "../utils/metrics";

// TODO: Refactor callbacks and setting callbacks: https://faro01.atlassian.net/browse/NRT-443
export type Registration = {
  /** Signal emitted when the registration job has finished successfully. */
  registrationCompleted: TypedEvent<void>;

  /** the quality of the registration result */
  registrationQuality: QualityStatus;

  /** set the quality of the registration on successful run */
  setRegistrationQuality(quality: QualityStatus): void;
};

/** A context for the registration process */
export const RegistrationContext = createContext<Registration | undefined>(
  undefined,
);

/**
 * @returns The registered context to be used in the manual alignment workflow
 */
export function useRegistrationContext(): Registration {
  const ctx = useContext(RegistrationContext);
  if (!ctx) {
    throw Error("useRegistrationContext called outside Registration");
  }
  return ctx;
}

/**
 * @returns The initial state for the RegistrationContext
 */
export function RegistrationContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [registrationQuality, setRegistrationQuality] = useState<QualityStatus>(
    QualityStatus.UNKNOWN,
  );

  const [registrationCompleted] = useState(new TypedEvent<void>());
  return (
    <RegistrationContext.Provider
      value={{
        registrationQuality,
        setRegistrationQuality,
        registrationCompleted,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}
