import { RootState } from "@/store/store";
import {
  RegistrationEdgeType,
  ScanColoring,
} from "./data-preparation-view-options-slice";

/** @returns the current scan coloring mode*/
export function selectScanColoring({
  dataPreparationViewOptions,
}: RootState): ScanColoring {
  return dataPreparationViewOptions.scanColoring;
}

/** @returns whether to show connection lines */
export function selectShowConnectionLines({
  dataPreparationViewOptions,
}: RootState): boolean {
  return dataPreparationViewOptions.showConnectionLines;
}

/** @returns whether to use quality color coding */
export function selectQualityColorCoding({
  dataPreparationViewOptions,
}: RootState): boolean {
  return dataPreparationViewOptions.useQualityColorCoding;
}

/** @returns whether to use global or local results */
export function selectRegistrationEdgeType({
  dataPreparationViewOptions,
}: RootState): RegistrationEdgeType {
  return dataPreparationViewOptions.registrationEdgeType;
}
