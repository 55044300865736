import { Alert, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";

export type DeleteElementDialogContentProps = {
  /** name of the element to remove, @default "the element" */
  name?: string;

  /** If defined add some extra information to the dialog */
  extra?: string;

  /**
   * If true the component will use a style that it's visible on a dark background
   *
   * @default false
   */
  dark?: boolean;
};

/** @returns the content for a dialog used to delete an element from the project */
export function DeleteElementDialogContent({
  name = "the element",
  extra,
  dark = false,
}: DeleteElementDialogContentProps): JSX.Element {
  return (
    <Stack gap={3}>
      <FaroText
        variant="bodyM"
        sx={{ color: dark ? neutral[100] : neutral[800] }}
      >
        This will remove {name} from your project. <br />
        {extra && (
          <FaroText
            variant="bodyM"
            sx={{ color: dark ? neutral[100] : neutral[800] }}
          >
            {extra}
          </FaroText>
        )}
      </FaroText>
      <Alert
        variant="warning"
        title="The operation cannot be undone."
        dark={dark}
      />
    </Stack>
  );
}
