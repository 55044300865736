import {
  CompassGoToIcon,
  FaroChipList,
  FaroChipStatus,
  FaroChipTag,
  FaroIconButton,
  FaroMenuSeparator,
  FaroTooltip,
  NO_TRANSLATE_CLASS,
  NoTranslate,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { CardAttachmentIcon } from "./card-attachment-icon";
import { CardContextMenu } from "./card-context-menu";
import { CardUserAvatar } from "./card-user-avatar";
import { AnnotationCardProps } from "./responsive-card-types";
import {
  RESPONSIVE_CARDS_HEIGHT,
  ResponsiveCardVariant,
} from "./responsive-card-variant";

/** @returns A card with a layout on two different lines */
export function MediumCard({
  name,
  attachments,
  tags,
  state,
  mainAssignee,
  markup,
  dueDate,
  onGoTo,
}: AnnotationCardProps): JSX.Element {
  return (
    <Stack height={RESPONSIVE_CARDS_HEIGHT[ResponsiveCardVariant.medium]}>
      <Stack sx={{ p: 1.5 }} gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={0.75}
        >
          <TruncatedFaroText
            variant="heading12"
            className={NO_TRANSLATE_CLASS}
            containerSx={{ flexGrow: 1 }}
          >
            {name}
          </TruncatedFaroText>
          <CardAttachmentIcon count={attachments.length} />
          <FaroTooltip title="Go to annotation">
            <FaroIconButton
              size="s"
              aria-label="go to annotation"
              onClick={onGoTo}
              margin={0}
            >
              <CompassGoToIcon />
            </FaroIconButton>
          </FaroTooltip>
          <CardContextMenu
            markup={markup}
            mainAssignee={mainAssignee}
            state={state}
            dueDate={dueDate}
          />
        </Stack>
        <Grid container rowSpacing={0.5} columnSpacing={1} alignItems="center">
          <Grid item xs="auto" sx={{ minWidth: "100px" }}>
            <FaroChipStatus status={state} />
          </Grid>
          <Grid item xs sx={{ minWidth: 0 }}>
            <Tags tags={tags} />
          </Grid>
          <Grid item xs="auto">
            <CardUserAvatar userDisplayInfo={mainAssignee} />
          </Grid>
        </Grid>
      </Stack>
      <FaroMenuSeparator />
    </Stack>
  );
}

/** @returns a single tag, or a list of tags that will collapse if needed */
function Tags({ tags }: Pick<AnnotationCardProps, "tags">): JSX.Element | null {
  if (!tags) return null;
  return (
    <FaroChipList
      size="small"
      sx={{ flexGrow: 1 }}
      chips={tags.map((t) => (
        <FaroChipTag
          tooltip={<NoTranslate>{t.label}</NoTranslate>}
          className={NO_TRANSLATE_CLASS}
          key={t.id}
          {...t}
        />
      ))}
    />
  );
}
