import { runtimeConfig } from "@/runtime-config";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { APP_VERSION, IS_PROD } from "@/utils/env-constants";
import { selectProjectId } from "@faro-lotv/app-component-toolbox";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

/**
 * Initialize the logging system (Sentry for now)
 */
export function useLogging(): void {
  // Initialize Sentry
  useEffect(() => {
    Sentry.init({
      dsn: runtimeConfig.backendEndpoints.sentryDsn,
      release: APP_VERSION,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      environment: runtimeConfig.deployment.environment,

      tracesSampleRate: IS_PROD ? 0.5 : 1,
    });
  }, []);

  // For logged in users add the user info to sentry context
  const currentUser = useAppSelector(selectCurrentUser);
  useEffect(() => {
    if (!currentUser) return;
    Sentry.setUser({
      id: currentUser.id,
      email: currentUser.email,
    });
  }, [currentUser]);

  // Add project id to the sentry context
  const projectId = useAppSelector(selectProjectId);
  useEffect(() => {
    if (projectId === undefined) return;
    Sentry.setContext("project", {
      id: projectId,
    });
  }, [projectId]);
}

/**
 * Log an Error in the logging system
 *
 * @param error to log
 */
export function logError(error: Error | unknown): void {
  Sentry.captureException(error);
}
