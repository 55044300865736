import { PositionedHtml } from "@/components/r3f/renderers/measurements/positioned-html";
import {
  Map2DControls,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { CursorStyle, FaroSvgIcon, useCustomCursor } from "@faro-lotv/flat-ui";
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { Camera, useThree } from "@react-three/fiber";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Matrix4, Vector3 } from "three";
import { ReactComponent as LocationSvg } from "../../icons/location.svg";
import { SinglePinInteraction } from "./single-pin-interaction";

/** The size of the pin graphic. */
const PIN_SIZE = 40;

type SinglePinControlsProps = {
  /** Whether the pin manipulation is enabled. If false, only the map controls are active */
  isManipulationEnabled: boolean;

  /** Function to get the current transform */
  getObjectTransform(): Matrix4;

  /** Function to get the center of the object */
  getObjectCenter(): Vector3;

  /** The callback to execute when the object is manipulated by the user. */
  onTransform(worldTransform: Matrix4): void;

  /** The camera used for the controls */
  camera?: Camera;

  /** A ref for the map controls used with the pin controls */
  mapControlsRef?: Ref<Map2DControlsImpl>;
};

/** Imperative API for the controls available via the ref */
export type SinglePinControlsImperativeApi = {
  /** Re-centers the pin */
  recenterPin(): void;
};

/**
 * @returns A set of controls for the user to manipulate the transform of an object.
 *
 * The user can left-click on anywhere to place a pin.
 * - When dragging *on* the pin, the object is translated
 * - When dragging *around* the pin, the object is rotated.
 * - When right-clicking on the pin, the pin is removed
 */
export const SinglePinControls = forwardRef<
  SinglePinControlsImperativeApi,
  SinglePinControlsProps
>(function SinglePinControls(
  {
    isManipulationEnabled,
    mapControlsRef,
    camera: cameraProp,
    getObjectTransform,
    getObjectCenter,
    onTransform,
  },
  ref,
): JSX.Element {
  const defaultCamera = useThree((three) => three.camera);
  const camera = cameraProp ?? defaultCamera;

  const canvasElement = useThree((three) => three.gl.domElement);
  const domElement = canvasElement.parentElement ?? canvasElement;
  // A ref wrapper is needed for the portal
  const domElementRef = useRef(domElement);
  domElementRef.current = domElement;

  const [pinInteraction] = useState(
    () => new SinglePinInteraction(camera, getObjectTransform, getObjectCenter),
  );
  pinInteraction.isManipulationEnabled = isManipulationEnabled;
  pinInteraction.getObjectTransform = getObjectTransform;
  pinInteraction.getObjectCenter = getObjectCenter;

  const [pinPosition, setPinPosition] = useState(new Vector3());
  const [isPinVisible, setIsPinVisible] = useState(false);
  const [isOverPin, setIsOverPin] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      recenterPin() {
        const center = pinInteraction.centerPin();
        // For some reason, the `setPinEvent` is not received here, so the position needs to be updated manually
        setPinPosition(center);
      },
    }),
    [pinInteraction],
  );

  // Connect the callbacks to the interaction logic
  useEffect(() => {
    const disposeSetPin = pinInteraction.setPinEvent.on((newPinPosition) => {
      setIsPinVisible(true);
      setPinPosition((oldPosition) => oldPosition.copy(newPinPosition));
    });
    const disposeRemovePin = pinInteraction.removePinEvent.on(() => {
      setIsPinVisible(false);
    });
    const disposeTransform = pinInteraction.transformEvent.on(onTransform);
    const disposeMouseMoved = pinInteraction.mouseMovedEvent.on(
      (movedOverPin) => {
        if (isOverPin !== movedOverPin) {
          setIsOverPin(movedOverPin);
        }
      },
    );

    return () => {
      disposeSetPin.dispose();
      disposeRemovePin.dispose();
      disposeTransform.dispose();
      disposeMouseMoved.dispose();
    };
  }, [pinInteraction, camera, canvasElement, onTransform, isOverPin]);

  // Attach event listeners to the correct dom Element
  useEffect(() => {
    pinInteraction.attach(domElement);

    return () => {
      pinInteraction.detach();
    };
  }, [domElement, pinInteraction]);

  usePinAlignmentCursor(isManipulationEnabled, isPinVisible, isOverPin);

  return (
    <>
      <PositionedHtml center portal={domElementRef} position={pinPosition}>
        {isPinVisible && (
          <FaroSvgIcon
            source={LocationSvg}
            sx={{ width: PIN_SIZE, height: PIN_SIZE }}
          />
        )}
      </PositionedHtml>
      <Map2DControls
        ref={mapControlsRef}
        isPrimaryControl={!isManipulationEnabled}
      />
    </>
  );
});

function usePinAlignmentCursor(
  enable: boolean,
  isPinSet: boolean,
  isOverPin: boolean,
): void {
  let cursorToShow = CursorStyle.default;

  if (isPinSet) {
    cursorToShow = isOverPin ? CursorStyle.translation : CursorStyle.rotation;
  }

  const domElement = useThreeEventTarget();
  useCustomCursor({
    enable,
    cursorToShow,
    domElement,
  });
}
