import { RenderDispatch } from "@/modes/overview-mode/render-dispatch";
import {
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  FilteredRenderPass,
  TomographicModelPass,
} from "@faro-lotv/app-component-toolbox";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { FloorPlanTile } from "@faro-lotv/lotv";
import { useCallback } from "react";
import { Camera, Object3D, Points } from "three";

type TomographicOverlayViewPipelineProps = {
  /** The active sheet to be aligned to the CAD model */
  sheet: IElementGenericImgSheet;

  /** The camera that is rendering the scene */
  camera?: Camera;

  /** Set the bias used for thresholding the number of points */
  bias?: number;
};

/** @returns the tomographic view for align the area to cad in 2d */
export function TomographicOverlayViewPipeline({
  sheet,
  camera,
  bias,
}: TomographicOverlayViewPipelineProps): JSX.Element {
  const isSheetObject = useCallback(
    (o: Object3D) => o.name === sheet.id || o instanceof FloorPlanTile,
    [sheet.id],
  );

  const isOverlayObject = useCallback(
    (o: Object3D) =>
      !isSheetObject(o) &&
      !(o instanceof Points) &&
      o.userData.type !== RenderDispatch.CadModel,
    [isSheetObject],
  );

  return (
    <EffectPipeline>
      <FilteredRenderPass filter={isSheetObject} />
      <DesaturatePass />
      <TomographicModelPass camera={camera} bias={bias} />
      <FilteredRenderPass
        filter={isOverlayObject}
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipeline>
  );
}
