import { Mode } from "@/modes/mode";
import { ProjectConversionModeOverlay } from "./project-conversion-mode-overlay";

export const projectConversionMode: Mode = {
  name: "projectConversion",
  ModeScene: () => null,
  ModeOverlay: ProjectConversionModeOverlay,
  exclusive: {
    title: "Project conversion",
    useDefaultHeader: true,
  },
};
