import { useAppDispatch } from "@/store/store-hooks";
import { collapseAllAnnotations } from "@/store/ui/ui-slice";
import { useEffect } from "react";

/** Register a global key handler for the Escape key, for now to collapse all annotations */
export function useHandleGlobalEscape(): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleGlobalEscape = (ev: KeyboardEvent): void => {
      if (ev.key !== "Escape") return;

      dispatch(collapseAllAnnotations());
    };
    window.document.addEventListener("keydown", handleGlobalEscape);

    return () =>
      window.document.removeEventListener("keydown", handleGlobalEscape);
  }, [dispatch]);
}
