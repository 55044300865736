import { getMode } from "@/modes";
import { selectActiveCadId } from "@/store/cad/cad-slice";
import { selectModeName } from "@/store/mode-selectors";
import { selectActiveElementId } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { GUID, assert } from "@faro-lotv/foundation";
import { ViewRuntimeContextData } from "../view-runtime-context";
import { DeepLink, getFullDeepLinkURL } from "./deep-link-encoding";

/**
 * @param state The current state of the app
 * @param view information of the current app 3d view
 * @param lookAtId an optional element o look at
 * @returns the URL for the generate deep link
 */
export function computeDeepLink(
  state: RootState,
  view: ViewRuntimeContextData,
  lookAtId?: GUID,
): URL {
  const id = selectActiveElementId(state);
  assert(id, "Can't create a deep link without an active element");
  const cadId = selectActiveCadId(state);
  const mode = selectModeName(state);
  const initialState = getMode(mode).initialState?.compute(state, view) ?? {};

  const linkData: DeepLink & Record<string, unknown> = {
    ...initialState,
    id,
    mode,
    ...(cadId ? { cadId } : {}),
    ...(lookAtId ? { lookAtId } : {}),
  };

  return getFullDeepLinkURL(linkData);
}
