import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import { setVisibilityDistance } from "@/store/view-options/view-options-slice";
import { FaroSlider } from "@faro-lotv/flat-ui";

/** @returns a radio button group to select the visibility distance. */
export function VisibilityDistanceSlider(): JSX.Element {
  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const dispatch = useAppDispatch();

  return (
    <FaroSlider
      dark
      step={0.1}
      min={0}
      max={11}
      value={Math.log2(visibilityDistance)}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          return;
        }
        dispatch(setVisibilityDistance(Math.pow(2, value)));
      }}
    />
  );
}
