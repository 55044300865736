import { ODOMETRY_PATH_OBJECT_NAME } from "@/components/r3f/renderers/odometry-paths/odometry-path-renderer";
import {
  CadModelObject,
  MeshObject,
  PointCloudObject,
  SheetObject,
} from "@/object-cache";
import { isPointCloudObject } from "@/object-cache-type-guard";
import {
  GRID_PLANE_NAME,
  isExplorationPivot,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { BOX_CONTROLS_NAME, OverviewPlaceholders } from "@faro-lotv/lotv";
import { Mesh, Object3D } from "three";

/**
 * Tagging enum type to determine which subobject of a 3js scene is dispatched
 * to which rendering step.
 */
export enum RenderDispatch {
  LodPointCloud = 0,
  CadModel = 1,
  Dollhouse = 2,
  Floorplan = 3,
  Placeholders = 4,
  OdometryPath = 5,
  Lines = 6,
  Other = 7,
  Pivot = 8,
  BoxControls = 9,
  GridPlane = 10,
  /** Explicitly defining None type when an object should not be dispatched to any rendering pass. */
  None = 11,
}

/**
 *
 * @param obj The root of a 3js scene subtree
 * @param flag The flag to assign to the userData.type field of all objects in the subtree
 */
export function flagSubtree(obj: Object3D, flag: RenderDispatch): void {
  obj.traverse((o) => (o.userData.type = flag));
}

/**
 * Traverses the input threejs scene and classifies all subobjects to express
 * the rendering step they belong to. Classification is done by tagging each object
 * assigning a tag to the field 'object.userData.type'.
 *
 * The scene is traversed depth-first. The assumption here is that some models are
 * described by sub-trees of the scene. For example: the Lod point cloud is a sub.group
 * in the scene who is father of all point nodes. Also, the floorplan may also be a
 * LodFloorplan object whose children are the single tiles. Therefore, when a main
 * scene object is met during the scene traversal, its entire children subtree is flagged
 * in the corresponding way. Therefore, whenever a LOD object loads new children, these children
 * are automatically flagged correctly for dispatch.
 *
 * @param scene The 3js scene to process
 * @param sheet The floorplan in the scene
 * @param dollhouse The dollhouse in the scene
 * @param cadModel The cad model in the scene
 * @param pointCloud The point cloud in the scene
 */
export function dispatchRenderObjects(
  scene: Object3D,
  sheet: SheetObject | null,
  dollhouse: MeshObject | null,
  cadModel: CadModelObject | null,
  pointCloud: PointCloudObject | null,
): void {
  const stack = [scene];
  while (stack.length > 0) {
    const obj = stack.pop();
    assert(obj);
    switch (obj) {
      case sheet:
        flagSubtree(obj, RenderDispatch.Floorplan);
        break;
      case dollhouse:
        flagSubtree(obj, RenderDispatch.Dollhouse);
        break;
      case cadModel:
        flagSubtree(obj, RenderDispatch.CadModel);
        break;
      case pointCloud:
        flagSubtree(obj, RenderDispatch.LodPointCloud);
        break;
      default: {
        if (isExplorationPivot(obj)) {
          obj.userData.type = RenderDispatch.Pivot;
        } else if (isObjLine(obj)) {
          obj.userData.type = RenderDispatch.Lines;
        } else if (isObjPlaceholders(obj)) {
          obj.userData.type = RenderDispatch.Placeholders;
        } else if (isObjOdometryPath(obj)) {
          flagSubtree(obj, RenderDispatch.OdometryPath);
          break;
        } else if (obj.name === BOX_CONTROLS_NAME) {
          flagSubtree(obj, RenderDispatch.BoxControls);
          break;
        } else if (obj.name === GRID_PLANE_NAME) {
          obj.userData.type = RenderDispatch.GridPlane;
        } else {
          obj.userData.type = RenderDispatch.Other;
        }
        stack.push(...obj.children);
        break;
      }
    }
  }
}

/**
 * Returns which item type should be dispatched to the model subscene of the
 * overview mode's rendering pipeline, according to state information.
 *
 * @param currentModel Which model is selected by the user
 * @returns An item type to dispatch to the model subscene.
 */
export function selected2dispatchedModel(
  currentModel: PointCloudObject | CadModelObject | null,
): RenderDispatch {
  if (!currentModel) {
    return RenderDispatch.None;
  } else if (isPointCloudObject(currentModel)) {
    return RenderDispatch.LodPointCloud;
  }
  return RenderDispatch.CadModel;
}

// TODO: Pull these functions into different file or to the corresponding objects
// They should then be used consistently in all scenes
// See https://faro01.atlassian.net/browse/SWEB-1771

/**
 * @param obj The 3D object to check for being a measurement line.
 * @returns true, if the given 3D object is a measurement line.
 */
function isObjLine(obj: Object3D): obj is Mesh {
  return obj instanceof Mesh && obj.material.type === "LineMaterial";
}

/**
 * @param obj The object to check for being the placeholders.
 * @returns whether this object 3D is an instance of the overview placeholders.
 */
function isObjPlaceholders(obj: Object3D): obj is OverviewPlaceholders {
  return obj instanceof OverviewPlaceholders;
}

/**
 * @param obj The object to check for being an odometry path.
 * @returns whether this object 3D is an odometry path.
 */
function isObjOdometryPath(obj: Object3D): boolean {
  return obj.name === ODOMETRY_PATH_OBJECT_NAME;
}

/**
 * Set correct dispatch type for the CAD model object.
 *
 * @param cadModel The cad model object to set the dispatch type for
 */
export function setCadModelRenderDispatch(cadModel: CadModelObject): void {
  flagSubtree(cadModel, RenderDispatch.CadModel);
}
