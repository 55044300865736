import {
  PROJECT_OUTDATED_MESSAGE,
  useProjectStatus,
} from "@/hooks/use-project-status";
import { TooltipLight, WarningIcon } from "@faro-lotv/app-component-toolbox";
import { ProjectStatus } from "@faro-lotv/service-wires";
import { SxProps, Theme } from "@mui/material";

interface ProjectStatusWarningIconProps {
  /** Additional styles applied to the warning icon */
  sx?: SxProps<Theme>;
}

/**
 * @returns A hover-able warning icon informing the users about limitations of the current project's status.
 */
export function ProjectStatusWarningIcon({
  sx,
}: ProjectStatusWarningIconProps): JSX.Element | null {
  const projectStatus = useProjectStatus();

  if (projectStatus !== ProjectStatus.outdated) {
    return null;
  }

  return (
    <TooltipLight title={PROJECT_OUTDATED_MESSAGE} placement="bottom-end">
      {/** Additional span is needed because the Tooltip cannot attach it's listeners to the icon component directly */}
      <span style={{ display: "flex", alignItems: "center" }}>
        <WarningIcon
          sx={{
            fill: "yellow600",
            ...sx,
          }}
        />
      </span>
    </TooltipLight>
  );
}
