import { ElementIconType } from "@/components/ui/icons";
import { isIElementGenericImgSheet } from "@faro-lotv/ielement-types";
import {
  compatibilityMessage,
  selectDisableCaptureTreeAlignment,
} from "../../cad-model-tree/use-disable-capture-tree-alignment";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";
import { redirectToFloorScalingTool } from "../utils";

export const SCALE_FLOOR_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.scaleFloor,
  label: "Set scale",
  disabledMessageForNode(iElement, state) {
    if (selectDisableCaptureTreeAlignment(iElement)(state)) {
      return compatibilityMessage;
    }
    // TODO: remove this if statement https://faro01.atlassian.net/browse/SWEB-5901
    if (isIElementGenericImgSheet(iElement)) {
      return "TODO: SWEB-5901";
    }
  },
  icon: ElementIconType.ResetScaleIcon,
  handler: redirectToFloorScalingTool,
};
