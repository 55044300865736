import { selectCloudToCadAlignmentLayout } from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericPointCloudStream,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { Box3 } from "three";
import { useOverlayElements } from "../overlay-elements-context";
import { AlignCloudToCadOverlayScreen } from "./cloud-to-cad-alignment-overlay-scene";
import { AlignCloudToCadSplitScreen } from "./cloud-to-cad-split-scene";

type AlignCloudToCadSceneProps = {
  /** The selected cloud to be aligned to the CAD model */
  activeCloud: IElementGenericPointCloudStream;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

/** @returns the scene for align the cloud to cad in 2d */
export function AlignCloudToCadScene({
  activeCloud,
  activeCad,
  cadBox,
}: AlignCloudToCadSceneProps): JSX.Element {
  const alignmentLayout = useAppSelector(selectCloudToCadAlignmentLayout);
  const { firstScreen, secondScreen } = useOverlayElements();

  return alignmentLayout === AlignmentViewLayout.splitScreen &&
    firstScreen &&
    secondScreen ? (
    <AlignCloudToCadSplitScreen
      {...{ activeCloud, activeCad, cadBox, firstScreen, secondScreen }}
    />
  ) : (
    <AlignCloudToCadOverlayScreen {...{ activeCloud, activeCad, cadBox }} />
  );
}
