import {
  PointCloudProps,
  PointCloudRenderer,
} from "@/components/r3f/renderers/pointcloud-renderer";
import { animated, useSpring } from "@react-spring/three";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";

const AnimatedPointCloud = animated(PointCloudRenderer);

type OverviewPointCloudSceneProps = EventHandlers &
  Omit<PointCloudProps, "opacity"> & {
    /** True, if the point cloud should be visible on the scene */
    visible: boolean;
  };

/** @returns a scene to render the pointcloud */
export function PointCloudWithOpacity({
  visible,
  ...rest
}: OverviewPointCloudSceneProps): JSX.Element | null {
  const { opacity } = useSpring({
    opacity: visible ? 1 : 0,
  });

  if (!visible) return null;

  return <AnimatedPointCloud opacity={opacity} {...rest} />;
}
