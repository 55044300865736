import { SupportedCamera, reproportionCamera } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { isObject } from "lodash";
import { useEffect } from "react";
import { OrthographicCamera, PerspectiveCamera, Vector3 } from "three";
import { urlDecodeNumber, urlEncodeNumber } from "./url-encoding-utils";

/** Parameters shared between Orthographic and Perspective cameras */
export type BasicCameraParameters = {
  /** The near plane */
  near: number;

  /** The far plane */
  far: number;

  /** The camera position */
  pos: [number, number, number];

  /** The point the camera is looking at, used to compute the camera direction and for advanced controls like ExplorationsControls */
  target: [number, number, number];
};

/** Parameters for Orthographic cameras */
type OrthoCameraParameters = BasicCameraParameters & {
  /** The vertical size (i.e. top - bottom) of the camera*/
  vsize: number;
};

/** Parameters for Perspective cameras */
type PerspectiveCameraParameters = BasicCameraParameters & {
  /** The field of view of the camera */
  fov: number;
};

/** The parameters describing a camera in a deep link */
export type CameraParameters =
  | OrthoCameraParameters
  | PerspectiveCameraParameters;

/**
 * Extract camera parameters from a camera
 *
 * @param camera The input camera
 * @param target An optional camera target to use
 * @returns The
 */
export function getCameraParameters(
  camera: SupportedCamera,
  target?: Vector3,
): CameraParameters {
  target ??= camera.position
    .clone()
    .add(camera.getWorldDirection(new Vector3()));
  const parameters: BasicCameraParameters = {
    near: camera.near,
    far: camera.far,
    pos: camera.position.toArray(),
    target: target.toArray(),
  };
  if (camera instanceof OrthographicCamera) {
    return {
      ...parameters,
      vsize: (camera.top - camera.bottom) / camera.zoom,
    };
  }
  return {
    ...parameters,
    fov: camera.getEffectiveFOV(),
  };
}

/**
 * @returns True if the input element is an object containing valida parameters for a camera
 * @param o The object to validate
 */
export function isValidCameraParameters(o: unknown): o is CameraParameters {
  if (!isObject(o)) {
    return false;
  }
  const camera: Partial<CameraParameters> = o;
  return (
    typeof camera.near === "number" &&
    typeof camera.far === "number" &&
    Array.isArray(camera.pos) &&
    // Allow check for type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    camera.pos.length === 3 &&
    Array.isArray(camera.target) &&
    // Allow check for type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    camera.target.length === 3 &&
    (("vsize" in camera && typeof camera.vsize === "number") ||
      ("fov" in camera && typeof camera.fov === "number"))
  );
}

/**
 * @returns True if the parameters represent an orthographic camera
 * @param params The camera parameters to check
 */
export function isOrthoCameraParameters(
  params: CameraParameters,
): params is OrthoCameraParameters {
  return "vsize" in params;
}

/**
 * @returns True if the parameters represent a perspective camera
 * @param params The camera parameters to check
 */
export function isPerspectiveCameraParameters(
  params: CameraParameters,
): params is PerspectiveCameraParameters {
  return "fov" in params;
}

/**
 * Update the camera parameters based on what's written inside the deep link
 *
 * @param camera The camera to update
 * @param params The camera information extracted from a deep link
 * @param onCameraUpdated The optional callback executed when the update finishes
 */
export function useCameraParametersIfAvailable(
  camera: OrthographicCamera | PerspectiveCamera,
  params: CameraParameters | undefined,
  onCameraUpdated?: () => void,
): void {
  const size = useThree((s) => s.size);
  useEffect(() => {
    if (!params) return;
    const { pos, target, far, near } = params;
    camera.position.fromArray(pos);
    camera.lookAt(new Vector3().fromArray(target));

    // Let's pick the specific properties to avoid injection of random data
    camera.far = far;
    camera.near = near;
    if (
      camera instanceof OrthographicCamera &&
      isOrthoCameraParameters(params)
    ) {
      const { vsize } = params;
      camera.bottom = -vsize * 0.5;
      camera.top = vsize * 0.5;
    } else if (
      camera instanceof PerspectiveCamera &&
      isPerspectiveCameraParameters(params)
    ) {
      const { fov } = params;
      camera.fov = fov;
    }
    camera.updateMatrix();
    reproportionCamera(camera, size.width / size.height);
    onCameraUpdated?.();
  }, [camera, params, onCameraUpdated, size]);
}

/** A character used to separate the single camera values in the encoded string*/
const CAMERA_ENCODING_SEPARATOR = "_";

/** A character used to identify an orthographic camera in the encoded string */
const CAMERA_ENCODING_ORTHO_TAG = "O";

/** A character used to identify an perspective camera in the encoded string */
const CAMERA_ENCODING_PERSP_TAG = "P";

/** Number of tokens in the encoded camera parameters string */
const CAMERA_ENCODING_TOKENS = 10;

/**
 * Encode the state of a camera in a single short string.
 *
 * Format is
 * <code>(P|O)_near_far_posX_posY_posZ_targetX_targetY_targetZ_(fov|vsize)</code>
 *
 * * P|O -> a character for the type of the camera
 * * 10 numbers, rounded to 3 decimals
 *
 * @param params the camera parameters to encode in a string
 * @returns a string that encode the passed camera parameters
 */
export function encodeCameraParameters(params: CameraParameters): string {
  const baseValues = [
    params.near,
    params.far,
    ...params.pos,
    ...params.target,
    isPerspectiveCameraParameters(params) ? params.fov : params.vsize,
  ].map(urlEncodeNumber);
  const typeTag = isPerspectiveCameraParameters(params)
    ? CAMERA_ENCODING_PERSP_TAG
    : CAMERA_ENCODING_ORTHO_TAG;
  return [typeTag, ...baseValues].join(CAMERA_ENCODING_SEPARATOR);
}

export function decodeCameraParameters(params: string): CameraParameters;
export function decodeCameraParameters(
  params?: string,
): CameraParameters | undefined;
/**
 * Decode a string in the format defined in {@link encodeCameraParameters} to an object
 * wit all the camera parameters
 *
 * @param params a string that encodes the camera parameters
 * @returns the parsed camera parameters, or undefined if the string was missing
 * @throws an error if the string exists but the format is invalid
 */
export function decodeCameraParameters(
  params?: string,
): CameraParameters | undefined {
  // Empty strings should return an error, only undefined values should return undefined
  if (params === undefined) return;

  const tokens = params.split(CAMERA_ENCODING_SEPARATOR);
  if (tokens.length !== CAMERA_ENCODING_TOKENS) {
    throw new Error(
      "Invalid camera parameters url encoding: invalid number of tokens",
    );
  }
  const [typeTag, ...valuesStrings] = tokens;
  const values = valuesStrings.map(urlDecodeNumber);
  if (values.some(isNaN)) {
    throw new Error(
      "Invalid camera parameters url encoding: some values is not a number",
    );
  }
  const [near, far, posX, posY, posZ, targetX, targetY, targetZ, fovOrVSize] =
    values;
  const commonData: BasicCameraParameters = {
    near,
    far,
    pos: [posX, posY, posZ],
    target: [targetX, targetY, targetZ],
  };
  if (typeTag === CAMERA_ENCODING_PERSP_TAG) {
    return {
      ...commonData,
      fov: fovOrVSize,
    };
  } else if (typeTag === CAMERA_ENCODING_ORTHO_TAG) {
    return {
      ...commonData,
      vsize: fovOrVSize,
    };
  }
  throw new Error(
    "Invalid camera parameters url encoding: invalid camera type",
  );
}
