import { changeMode } from "@/store/mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { useToggleToolVisibility } from "@/tools/use-toggle-tool-visibility";
import { SceneFilter } from "@/types/scene-filter";
import { includes } from "@faro-lotv/foundation";
import { IElementTypeHint, isIElementImg360 } from "@faro-lotv/ielement-types";
import { useEffect } from "react";
import { useAnnotationPermissions } from "../../../hooks/use-annotation-permissions";
import { useIsPanoWithDepthAndReady } from "../walk-mode-hooks";
import { selectDepthMapForImg360 } from "../walk-scene-selectors";
import { WalkSceneActiveElement } from "../walk-types";

/**
 * Compute the visibility of toolbar tools
 *
 * @param activeElement the current active element
 * @param sceneFilter the current active scene filter
 */
export function useWalkModeToolVisibility(
  activeElement?: WalkSceneActiveElement,
  sceneFilter?: SceneFilter,
): void {
  const depthMap = useAppSelector(
    selectDepthMapForImg360(
      activeElement && isIElementImg360(activeElement)
        ? activeElement
        : undefined,
    ),
  );
  const isPanoFromVideo =
    activeElement &&
    isIElementImg360(activeElement) &&
    activeElement.typeHint === IElementTypeHint.odometryPath;

  const { canWriteAnnotations } = useAnnotationPermissions();
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const dispatch = useAppDispatch();
  const activeTool = useAppSelector(selectActiveTool);
  useEffect(() => {
    if (activeTool === ToolName.export) {
      dispatch(changeMode("export"));
    }
  }, [activeTool, dispatch]);

  // Export tool require write permission to store in the project the exported point cloud file
  useToggleToolVisibility(
    ToolName.export,
    hasWritePermission &&
      includes([SceneFilter.PointCloud, SceneFilter.Overlay], sceneFilter),
    true,
    false,
  );

  /**
   * Allow or disallow measurement tool to be activated according to whether the active element is a point cloud
   * or a pano with depth information
   */
  const { isPanoWithDepth, isDepthReady } =
    useIsPanoWithDepthAndReady(activeElement);
  useToggleToolVisibility(
    ToolName.measurement,
    sceneFilter === SceneFilter.PointCloud ||
      sceneFilter === SceneFilter.Overlay ||
      (sceneFilter === SceneFilter.Pano && !!depthMap),
    true,
    isPanoWithDepth && !isDepthReady,
  );

  /** Allow or disallow annotation creation */
  useToggleToolVisibility(
    ToolName.annotation,
    canWriteAnnotations && !isPanoFromVideo,
    true,
    false,
  );

  /** Use clipscene tool */
  useToggleToolVisibility(
    ToolName.clipScene,
    sceneFilter !== SceneFilter.Pano,
    true,
    false,
  );
}
