import { RootState } from "@/store/store";
import {
  IElementGenericImgSheet,
  IElementGenericPointCloudStream,
  IElementSection,
  IElementTypeHint,
  WithHint,
  isIElementPointCloudStream,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
  isIElementWithPose,
  isValidPose,
} from "@faro-lotv/ielement-types";
import {
  CachedWorldTransform,
  selectAncestor,
  selectChildDepthFirst,
  selectIElementChildren,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";

/**
 * Select the best initial transform for the point cloud among all the transforms
 * of the point cloud already added to the sheet
 *
 * @param activeSheet The sheet which the point cloud belongs to
 * @param activePointCloud The input point cloud
 * @returns The best transformation if there are other point clouds in the sheet
 */
export function selectPointCloudInitialTransform(
  activeSheet: IElementGenericImgSheet | undefined,
  activePointCloud: IElementGenericPointCloudStream | undefined,
) {
  return (state: RootState): CachedWorldTransform | undefined => {
    if (!activeSheet || !activePointCloud) return;

    const pointCloudSection = selectAncestor(
      activePointCloud,
      isIElementSectionDataSession,
    )(state);

    // If the section has already a pose (i.e. it was already aligned once)
    // just return the stream transform
    if (isValidPose(pointCloudSection?.pose)) {
      return selectIElementWorldTransform(activePointCloud.id)(state);
    }

    const sheet = selectAncestor(activeSheet, isIElementSection)(state);
    if (!sheet) return;

    const timeSeries = selectChildDepthFirst(
      sheet,
      isIElementTimeseriesDataSession,
    )(state);
    if (!timeSeries) return;

    // Collect all the sections inside the laser scans time series
    // (i.e the sections containing the point clouds)
    const sections = selectIElementChildren(timeSeries.id)(state);

    const pointClouds = sections.map((p) =>
      selectChildDepthFirst(p, isIElementSectionDataSession)(state),
    );

    // Remove from the point clouds list the once that are undefined
    // or that don't have a pose already defined
    const validPointClouds = pointClouds.filter(
      (pc): pc is WithHint<IElementSection, IElementTypeHint.dataSession> =>
        isIElementWithPose(pc) && pc !== pointCloudSection,
    );

    const transforms = validPointClouds.map((p) => {
      const stream = selectChildDepthFirst(
        p,
        isIElementPointCloudStream,
      )(state);
      if (stream) return selectIElementWorldTransform(stream.id)(state);
      return selectIElementWorldTransform(p.id)(state);
    });

    // Pick from all point clouds the one with a transform that has a scale
    // factor that is the closest to the inverse of the sheet scale
    let minDistance = Number.POSITIVE_INFINITY;
    let minTransform: CachedWorldTransform | undefined;
    for (const transform of transforms) {
      const scale = transform.scale[0];
      const distance = Math.abs(1 - scale);
      if (distance < minDistance) {
        minDistance = distance;
        minTransform = transform;
      }
    }
    // If we find a suitable section to use as a reference we still need to account
    // for the local stream transformation on top of it
    if (minTransform) {
      return {
        ...minTransform,
      };
    }
  };
}
