import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "@/registration-tools/common/registration-report/threshold-set-context";
import {
  selectRegistrationAlgorithm,
  selectRegistrationAlgorithmSettings,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  generateViewerUrl,
  redirectToDataManagementUrl,
} from "@/utils/redirects";
import { useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  getProjectPreparedDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { SphereViewerToolHeaderBar } from "../components/ui/tool-header-bar";
import {
  DataPreparationStepKey,
  useLatestDataPreparationStep,
} from "./data-preparation-stepper";
import { useLoadRegistrationReport } from "./loading/use-load-registration-report";
import { useLoadRevisionPointCloudStreams } from "./loading/use-load-revision-point-cloud-streams";
import { RegistrationEditHelpBanner } from "./registration/registration-edit-help-banner";
import { RevisionLoadingSpinner } from "./registration/registration-revision-loading-spinner";
import { RevisionScansScene } from "./rendering/revision-scans-scene";
import {
  selectPointCloudStreamIdsForHoveredEntity,
  selectPointCloudStreamIdsForSelectedEntity,
} from "./store/data-preparation-ui/data-preparation-ui-selectors";
import { selectRevisionScans } from "./store/revision-selectors";
import { clearEntityTransformOverrides } from "./store/revision-slice";
import { DataPreparationActions } from "./ui/data-preparation-actions";
import { DataPreparationBanners } from "./ui/data-preparation-banners";
import { DataPreparationReportSidebar } from "./ui/data-preparation-report-sidebar";
import { DataPreparationSidebar } from "./ui/data-preparation-sidebar";
import { Projection } from "./ui/projection-switch";

export type DataPreparationUiProps = {
  /** The revision that is used for the data preparation. */
  revision: RegistrationRevision;
};

/**
 * @returns UI for the data preparation page.
 */
export function DataPreparationUi({
  revision,
}: DataPreparationUiProps): JSX.Element {
  const { projectApiClient, registrationApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();
  const dispatch = useAppDispatch();

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApiClient.projectId);

  const [isEditRegistrationEnabled, setIsEditRegistrationEnabled] =
    useState(false);

  const [isRegistrationRestarted, setRestartRegistration] = useState(false);

  const defaultStepKey = useLatestDataPreparationStep(revision.state);
  const [activeStepKey, setActiveStepKey] = useState(defaultStepKey);

  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const scanEntities = useAppSelector(selectRevisionScans);
  const pointCloudStreams = useLoadRevisionPointCloudStreams(scanEntities);

  const hoveredPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntity,
  );
  const selectedPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntity,
  );

  const registrationReport = useLoadRegistrationReport(revision.reportUri);

  const [isReportOpen, setIsReportOpen] = useState(false);

  const { openToast } = useToast();

  const sphereProjectUrl = useMemo(
    () => generateViewerUrl(projectApiClient.projectId),
    [projectApiClient.projectId],
  );

  const exitHref = dashboardUrl
    ? getProjectPreparedDataPage(dashboardUrl, projectApiClient.projectId)
    : sphereProjectUrl;

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  const registrationAlgorithm = useAppSelector(selectRegistrationAlgorithm);
  const registrationSettings = useAppSelector(
    selectRegistrationAlgorithmSettings,
  );

  // Callback to allow us to edit the registration when connections were missing during the registration
  const toggleEditRegistrationWhenConnectionsMissing = useCallback(() => {
    // first we need to come back to the registration step
    setActiveStepKey(DataPreparationStepKey.registration);

    // then we can toggle the edit registration
    setIsEditRegistrationEnabled(!isEditRegistrationEnabled);

    if (isEditRegistrationEnabled) {
      dispatch(clearEntityTransformOverrides());
    }
  }, [isEditRegistrationEnabled, dispatch]);

  // Callback to allow us to rerun a registration if it failed to be published
  const rerunRegistrationWhenPublishFailed = useCallback(async () => {
    setRestartRegistration(true);
    try {
      await registrationApiClient?.startCaptureTreeRegistration({
        revisionId: revision.id,
        registrationAlgorithm: hasRegistrationDevFeature
          ? registrationAlgorithm
          : undefined,
        parameters: hasRegistrationDevFeature
          ? registrationSettings
          : undefined,
      });
      assert(projectApiClient.projectId, "Project ID is undefined");

      redirectToDataManagementUrl(projectApiClient.projectId, dashboardUrl);
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to Rerun Registration",
      });
    }

    setRestartRegistration(false);
  }, [
    registrationApiClient,
    revision.id,
    hasRegistrationDevFeature,
    registrationAlgorithm,
    registrationSettings,
    projectApiClient.projectId,
    dashboardUrl,
    handleErrorWithToast,
  ]);

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SphereViewerToolHeaderBar
        toolName="Data Preparation"
        exitHref={exitHref}
      />
      <ThresholdSetProvider defaultThresholdSet={THRESHOLD_SET_STATIONARY}>
        <Stack sx={{ height: "100%", width: "100%" }}>
          <DataPreparationBanners
            revision={revision}
            scanEntities={scanEntities}
            pointCloudStreams={pointCloudStreams}
            registrationReport={registrationReport}
            isRegistrationRerunning={isRegistrationRestarted}
            onBackToRegistrationStepKey={
              toggleEditRegistrationWhenConnectionsMissing
            }
            onRerunRegistration={rerunRegistrationWhenPublishFailed}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <DataPreparationSidebar activeStepKey={activeStepKey} />

            <RevisionScansScene
              scanEntities={scanEntities}
              pointCloudStreams={pointCloudStreams}
              hoveredPointCloudIds={hoveredPointCloudIds}
              selectedPointCloudIds={selectedPointCloudIds}
              registrationReport={registrationReport}
              overlay={
                <>
                  {activeStepKey === DataPreparationStepKey.registration ? (
                    <>
                      <RevisionLoadingSpinner revisionState={revision.state} />

                      <RegistrationEditHelpBanner
                        isEditRegistrationEnabled={isEditRegistrationEnabled}
                        isEntitySelected={!!selectedPointCloudIds?.length}
                      />
                    </>
                  ) : undefined}
                  <Stack
                    direction="row"
                    gap={1}
                    justifyContent="flex-end"
                    alignItems="start"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "100%",
                    }}
                  >
                    <DataPreparationActions
                      revision={revision}
                      registrationReport={registrationReport}
                      onToggleReport={setIsReportOpen}
                      isEditRegistrationEnabled={isEditRegistrationEnabled}
                      onEnableEditRegistration={() => {
                        setIsEditRegistrationEnabled(true);
                      }}
                      onCancelEditRegistration={() => {
                        setIsEditRegistrationEnabled(false);
                        dispatch(clearEntityTransformOverrides());
                      }}
                    />
                  </Stack>
                </>
              }
              isEditRegistrationEnabled={isEditRegistrationEnabled}
              onProjectionChanged={(projection) => {
                if (
                  isEditRegistrationEnabled &&
                  projection !== Projection.orthographic
                ) {
                  setIsEditRegistrationEnabled(false);
                  openToast({
                    title: "Edit registration disabled",
                    message:
                      "Registration editing is only available in 2D projection.",
                    variant: "warning",
                  });
                }
              }}
            />

            <DataPreparationReportSidebar
              registrationReport={registrationReport}
              isReportOpen={
                activeStepKey === DataPreparationStepKey.inspectAndPublish &&
                isReportOpen
              }
              onToggleReport={setIsReportOpen}
            />
          </Stack>
        </Stack>
      </ThresholdSetProvider>
    </Stack>
  );
}
