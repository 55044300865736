import { useEffect, useState } from "react";
import defaultGrid from "./sheet-grid-default-image.png";

/**
 * @returns a file containing the sheet default grid image to be uploaded
 */
export function useDefaultGrid(): File | undefined {
  const [gridImageFile, setGridImageFile] = useState<File>();

  useEffect(() => {
    async function fetchGrid(): Promise<void> {
      const ret = await fetch(defaultGrid);
      const blob = await ret.blob();
      const file = new File([blob], "sheet-grid-default-image.png", {
        type: blob.type,
      });
      setGridImageFile(file);
    }
    fetchGrid();
  }, []);

  return gridImageFile;
}
