import { useSceneEvents } from "@/components/common/scene-events-context";
import {
  selectActiveElementForAnnotation,
  selectModeFromElement,
  selectModeName,
} from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppStore } from "@/store/store-hooks";
import {
  collapseAllAnnotations,
  setAnnotationExpansion,
} from "@/store/ui/ui-slice";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericAnnotation,
  MarkupIElement,
} from "@faro-lotv/ielement-types";
import {
  addIElements,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { Vector3 } from "three";

export type GoToAnnotationFunction = () => void;

/**
 * @param markup to go to
 * @returns a function that will update the app state/scene to go to the specified markup
 */
export function useGoToAnnotation(
  markup: MarkupIElement,
): GoToAnnotationFunction {
  const { lookAt } = useSceneEvents();
  const store = useAppStore();

  const { projectApiClient } = useApiClientContext();

  return useCallback(async () => {
    let annotation = selectIElement(markup.parentId)(store.getState());

    // If the area that contains this markup is not loaded yet we don't have its parent
    // The parent elements of the markup needs to be loaded to be able to visualize it
    if (!annotation) {
      const parentTree = await projectApiClient.getAllIElements({
        descendantIds: [markup.id],
      });
      store.dispatch(addIElements(parentTree));
      annotation = selectIElement(markup.parentId)(store.getState());
    }

    assert(
      annotation && isIElementGenericAnnotation(annotation),
      "A markup should always be part of an annotation",
    );

    const activeElement = selectActiveElementForAnnotation(annotation)(
      store.getState(),
    );
    assert(
      activeElement,
      "Failed to compute the best element to use to look at the annotation",
    );

    const mode = selectModeFromElement(
      activeElement,
      annotation.id,
    )(store.getState());
    assert(
      mode,
      "Failed to compute the best mode to use to look at the annotation",
    );

    store.dispatch(setActiveElement(activeElement.id));

    if (selectModeName(store.getState()) === mode) {
      // If the app is already in the best mode to look at the annotation move the camera to look at the annotation
      const position = new Vector3().fromArray(
        selectIElementWorldPosition(annotation.id)(store.getState()),
      );

      // Wait for the pano-pano animation to start to not risk the event to be lost in the previous pano rendering
      const ANIMATION_START_DELAY = 500;
      setTimeout(() => lookAt.emit(position), ANIMATION_START_DELAY);

      // and expand only the annotation to look at
      store.dispatch(collapseAllAnnotations());
      store.dispatch(setAnnotationExpansion({ id: markup.id, expanded: true }));
    } else {
      // Transition to the best mode to look at the annotation
      store.dispatch(
        changeMode({ mode, initialState: { lookAtId: markup.id } }),
      );
    }
  }, [lookAt, markup, projectApiClient, store]);
}
