import { Stack } from "@mui/system";
import {
  AssigneeFilter,
  AssigneeFilterProps,
} from "./annotation-filters/assignee-filter";
import { DateFilter, DateFilterProps } from "./annotation-filters/date-filter";
import {
  AnnotationStatusFilterProps,
  StatusFilter,
} from "./annotation-filters/status-filter";
import {
  AnnotationTagFilterProps,
  TagFilter,
} from "./annotation-filters/tag-filter";

export type FilterMenuRowProps = {
  /** True if the filter menu row should be visible */
  isVisible: boolean;
} & AssigneeFilterProps &
  AnnotationTagFilterProps &
  AnnotationStatusFilterProps &
  DateFilterProps;

/** @returns the row of filter menus for the annotation list */
export function FilterMenuRow({
  isVisible,
  onSelectedAssigneesChanged,
  onSelectedTagsChanged,
  onSelectedStatusChanged,
  onSelectedDateChanged,
}: FilterMenuRowProps): JSX.Element {
  return (
    <Stack
      direction="row"
      sx={{
        my: 1,
        gap: 0.5,
        height: isVisible ? "40px" : 0,
        transition: "height 0.25s ease-in",
        transitionBehavior: "allow-discrete",
        overflowX: "hidden",
        overflowY: "clip",
        "&:hover": {
          overflowX: "auto",
        },
      }}
    >
      <AssigneeFilter onSelectedAssigneesChanged={onSelectedAssigneesChanged} />
      <TagFilter onSelectedTagsChanged={onSelectedTagsChanged} />
      <StatusFilter onSelectedStatusChanged={onSelectedStatusChanged} />
      <DateFilter onSelectedDateChanged={onSelectedDateChanged} />
    </Stack>
  );
}
