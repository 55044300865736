import { CadModelObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  CadModelRendererBase,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect } from "react";
import { Plane } from "three";

export type CadRendererProps = EventHandlers & {
  /** The CAD model to be rendered */
  cadModel: CadModelObject;

  /**
   * Whether the cad model should respond to pointer events via raycasting
   *
   * @default true By default the Cad model handles pointer events returning the ID of the interested CAD part
   */
  raycastEnabled?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /**
   * Optional clipping in local transform
   *
   * @default false set to true only for tomographic view of cad model clipped at given elevation (in sheet to cad alignment)
   */
  clippingInLocalTransform?: boolean;
};

/** @returns The CAD in the correct world position */
export function CadRenderer({
  cadModel,
  raycastEnabled = true,
  clippingPlanes = [],
  clippingInLocalTransform = false,
  ...rest
}: CadRendererProps): JSX.Element | null {
  const transform = useAppSelector(
    selectIElementWorldTransform(cadModel.iElement.id),
  );

  useEffect(() => {
    const oldIntersection = cadModel.clipIntersection;
    const oldClipping = cadModel.clipping;
    const oldClippingInLocalTransform = cadModel.clippingInLocalTransform;

    cadModel.clippingPlanes = clippingPlanes;
    cadModel.clipIntersection = false;
    cadModel.clipping = clippingPlanes.length > 0;
    cadModel.clippingInLocalTransform = clippingInLocalTransform;

    return () => {
      cadModel.clippingPlanes = [];
      cadModel.clipIntersection = oldIntersection;
      cadModel.clipping = oldClipping;
      cadModel.clippingInLocalTransform = oldClippingInLocalTransform;
    };
  }, [clippingPlanes, cadModel, clippingInLocalTransform]);

  // Wrapping the model in a group because applying the event handlers directly
  // to the primitive tag does not work all the time
  return (
    <group {...transform}>
      <CadModelRendererBase
        cadModel={cadModel}
        raycastEnabled={raycastEnabled}
        {...rest}
      />
    </group>
  );
}
