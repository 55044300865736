import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  AutoClipCurrentViewIcon,
  FaroTooltip,
  ResetIcon,
  Toolbar,
  ToolButton,
  ToolGroup,
} from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";

/**
 * @returns the overlay for the export mode
 */
export function ExportModeOverlay(): JSX.Element {
  const { autoBoxEvent, resetBoxEvent } = useBoxControlsContext();

  return (
    <Stack
      // Use row-reverse to make the reset button appear on the right
      direction="row-reverse"
      justifyContent="space-between"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack justifyContent="center">
        <Toolbar>
          <ToolGroup>
            <FaroTooltip
              title="Reset clipping box"
              placement="left"
              aria-label="reset clipping box"
            >
              <ToolButton onClick={() => resetBoxEvent.emit()}>
                <ResetIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </FaroTooltip>
            <FaroTooltip title="Auto-clip to current view">
              <ToolButton onClick={() => autoBoxEvent.emit()}>
                <AutoClipCurrentViewIcon />
              </ToolButton>
            </FaroTooltip>
          </ToolGroup>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
