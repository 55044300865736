import { useDialog } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useFileUploadContext } from "./file-uploads-context";

/**
 * Type of the callback returned by the hook
 *
 * @param id The IDs of the upload tasks to cancel
 */
export type CancelUploadFn = (...ids: GUID[]) => Promise<boolean>;

/**
 * @returns This hook returns a function to cancel a given file upload task.
 *
 * TODO: Generalize the task concept so we can cancel task and not only uploads https://faro01.atlassian.net/browse/SWEB-1350
 */
export function useCancelUpload(): CancelUploadFn {
  const { uploadManager } = useFileUploadContext();

  const { createDialog } = useDialog();

  const cancelFn = useCallback(
    async (...ids: GUID[]) => {
      const hasAccepted = await createDialog({
        title: "Cancel Import?",
        confirmText: "Cancel Import",
        cancelText: "Resume Import",
        content: (
          <Typography>
            Are you sure you want to cancel the import?
            <br />
            Your progress wil be lost.
          </Typography>
        ),
        variant: "danger",
      });
      if (hasAccepted) {
        return uploadManager.cancelFileUpload(ids[0]);
      }
      return false;
    },
    [createDialog, uploadManager],
  );
  return cancelFn;
}
