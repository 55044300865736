import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch } from "@/store/store-hooks";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { fetchProjectIElements } from "@faro-lotv/project-source";
import {
  GetIElementsParams,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useState } from "react";

/**
 * Create requests to load IElements and save them to the store.
 *
 * @param requests The requests to load the IElements.
 *  They will be executed in parallel, but dispatched to the store together.
 * @returns `true` if the requests are still loading, else `false`.
 */
export function useLoadIElements(
  requests: Array<Omit<GetIElementsParams, "signal">>,
): boolean {
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();
  const { handleErrorWithPage } = useErrorHandlers();
  const [isLoading, setIsLoading] = useState(true);

  // Make the loading independent from the request object reference
  const requestKey = JSON.stringify(requests);

  useNonExhaustiveEffect(() => {
    if (!requests.length) {
      return;
    }

    const abortController = new AbortController();

    const apiRequests = Promise.all(
      requests.map((request) =>
        projectApiClient.getAllIElements({
          signal: abortController.signal,
          ...request,
        }),
      ),
    ).then((results) => results.flat());

    dispatch(
      fetchProjectIElements({
        fetcher: () => apiRequests,
      }),
    )
      .then(() => {
        // `getAllIElements` just returns [] when the request is aborted
        // To handle double mounts, we need to special case the abort
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      })
      .catch(handleErrorWithPage);

    return () => {
      // Cancel the request on unmount
      abortController.abort.bind(abortController);
      abortController.abort("useLoadIElements unmounted");
    };
  }, [requestKey]);

  return isLoading && !!requests.length;
}
