import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** State managing data exchange during alignment Wizard session */
type AlignmentWizardToolState = {
  /** id of element which should be aligned; value is undefined when aligned element is not yet selected by user */
  elementToAlignId?: GUID;

  /** id of element which should used as reference for alignment; value is undefined when reference element is not yet selected by user */
  referenceElementId?: GUID;
};

const initialState: AlignmentWizardToolState = {};

export const alignmentWizardModeSlice = createSlice({
  initialState,
  name: "alignmentWizardMode",
  reducers: {
    setWizardElementToAlignId(state, action: PayloadAction<GUID>) {
      state.elementToAlignId = action.payload;
    },

    setWizardReferenceElementId(state, action: PayloadAction<GUID>) {
      state.referenceElementId = action.payload;
    },

    resetWizardReferenceElementId(state) {
      state.referenceElementId = undefined;
    },

    resetAlignmentWizard(state) {
      state.elementToAlignId = undefined;
      state.referenceElementId = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  setWizardElementToAlignId,
  setWizardReferenceElementId,
  resetWizardReferenceElementId,
  resetAlignmentWizard,
} = alignmentWizardModeSlice.actions;

export const alignmentWizardModeReducer = alignmentWizardModeSlice.reducer;
