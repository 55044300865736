import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useCached3DObject } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import {
  selectCloudForCadAlignment,
  selectCloudToCadAlignmentStep,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import { CloudToCadAlignmentStep } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useEffect } from "react";
import { AlignCloudToCadScene } from "./cloud-to-cad-alignment-scene";
import { CloudToCadElevationScene } from "./cloud-to-cad-elevation-scene";

/** @returns Rendered element for both steps of the cloud to CAD alignment */
export function CloudToCadAlignmentModeScene(): JSX.Element | null {
  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  const activeCloud = useAppSelector(selectIElement(activeCloudID));

  const activeCad = useAppSelector(selectActiveCadModel);
  assert(
    activeCad && activeCloud && isIElementGenericPointCloudStream(activeCloud),
    "CAD Alignment Mode requires a CAD model and cloud",
  );

  const cadModel = useCached3DObject(activeCad);
  useEffect(() => {
    // reset any highlight state from the cadModel
    for (const objectId of cadModel.objectIds) {
      cadModel.setObjectHighlighted(objectId, false);
    }
  }, [cadModel]);

  // get the cad model bounding box in world
  const cadBox = useObjectBoundingBox(cadModel, cadModel.iElement.id);
  assert(cadBox, "Expected CAD model has bounding box computed on loading");

  const step = useAppSelector(selectCloudToCadAlignmentStep);
  switch (step) {
    case CloudToCadAlignmentStep.setElevations:
      return (
        <CloudToCadElevationScene
          activeCad={activeCad}
          cadBox={cadBox}
          selectedCloud={activeCloud}
        />
      );
    case CloudToCadAlignmentStep.alignIn2d:
      return (
        <AlignCloudToCadScene
          activeCloud={activeCloud}
          activeCad={activeCad}
          cadBox={cadBox}
        />
      );
  }
}
