import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { useCenterCameraOnPointCloud } from "@/hooks/use-center-camera-on-pointcloud";
import { useCurrentScene } from "@/modes/mode-data-context";
import { PointCloudWithOpacity } from "@/modes/overview-mode/overview-point-cloud";
import { useCached3DObject } from "@/object-cache";
import { selectClippingBox } from "@/store/clipping-box-selectors";
import {
  selectInitialExportTarget,
  selectSelectedExportModeTab,
} from "@/store/modes/export-mode-selectors";
import { setSelectedOrthophotoSide } from "@/store/modes/export-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { useAutoClipBox } from "@/tools/use-auto-clip-box";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  BoxControls,
  BoxControlsRef,
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  StoreFboPass,
  StoreFboPassObj,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Matrix4, Object3D, Plane, Vector3 } from "three";
import { OBB } from "three/examples/jsm/math/OBB";
import { ExportModeTab } from "./export-mode-types";

const TEMP_OBB = new OBB();
const TEMP_POS = new Vector3();
const TEMP_SIZE = new Vector3();

/** @returns the scene for the export mode */
export function ExportModeScene(): JSX.Element {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "Export Mode requires a PointCloud as the main element",
  );

  const dispatch = useAppDispatch();

  const selectedTab = useAppSelector(selectSelectedExportModeTab);

  // Reference to an offscreen FBO to store the depth buffer
  const storeFboRef = useRef<StoreFboPassObj>(null);

  const camera = useThree((s) => s.camera);

  const pointCloudObject = useCached3DObject(main);

  const {
    resetBoxEvent,
    autoBoxEvent,
    hasUserInteracted,
    setHasUserInteracted,
  } = useBoxControlsContext();

  const { box: modelBB, cloudTransform } = useCenterCameraOnPointCloud(
    camera,
    pointCloudObject,
  );
  const target = useAppSelector(selectInitialExportTarget);

  /** Position of the clipping box */
  const [boxPosition, setBoxPosition] = useState(
    modelBB.getCenter(new Vector3()),
  );

  /** Scale of the clipping box */
  const [boxScale, setBoxScale] = useState(
    modelBB.max.clone().sub(modelBB.min),
  );

  // Function that will create a clipping box by sampling the depth buffer
  const autoClipBoxFnc = useAutoClipBox(storeFboRef, (obb) => {
    // Update the current clipping box with the autoClippedBox
    // Creating a new vector is used to trigger properly the reactivity since the object reference would be otherwise the same
    setBoxPosition(new Vector3().fromArray(obb.position));
    setBoxScale(new Vector3().fromArray(obb.size));
  });

  // Assign the auto clipping box function to the autoClipBox event
  // This event is triggered when the user clicks on the auto clip button
  useTypedEvent(autoBoxEvent, autoClipBoxFnc);

  /** Function to call when the reset clipping box button is clicked */
  useTypedEvent(
    resetBoxEvent,
    useCallback(() => {
      setBoxPosition(modelBB.getCenter(new Vector3()));
      setBoxScale(modelBB.max.clone().sub(modelBB.min));
    }, [modelBB]),
  );

  const [controlsEnabled, setControlsEnabled] = useState(true);

  // Reset the boolean to check if the user has interacted with the box controls on cleanup,
  // So that the help message can be shown on every entry to the export mode
  useEffect(() => {
    return () => {
      setHasUserInteracted(false);
    };
  }, [setHasUserInteracted]);

  const onInteractionStarted = useCallback(() => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }
    setControlsEnabled(false);
  }, [hasUserInteracted, setHasUserInteracted]);

  const [clippingPlanesPreview, setClippingPlanesPreview] = useState<Plane[]>();
  const { setClippingPlanes } = useBoxControlsContext();
  // Clipping box generated from the auto clipping event
  const clippingBox = useAppSelector(selectClippingBox);

  // Update the box position and scale when the clippingBox changes
  // The clippingBox changes when the event for the auto clipping is triggered
  useEffect(() => {
    if (!clippingBox) return;

    setBoxPosition(TEMP_POS.fromArray(clippingBox.position));
    setBoxScale(TEMP_SIZE.fromArray(clippingBox.size));
  }, [clippingBox]);

  const boxRef = useRef<BoxControlsRef>();

  // Updates the preview clipping box and calculates the local clipping planes for the clipping box state
  const onClippingPlanesChanged = useCallback(
    (clippingPlanes: Plane[]) => {
      // The clipping planes for the preview should always be defined and in world space
      setClippingPlanesPreview(clippingPlanes);

      if (!boxRef.current) return;
      // Compute an OBB (Oriented Bounding Box) to check if the user defined box is valid and intersect the PC box
      const trx = boxRef.current.boxMatrixWorld;
      TEMP_OBB.center.set(0, 0, 0);
      TEMP_OBB.halfSize.set(0.5, 0.5, 0.5);
      TEMP_OBB.rotation.identity();
      TEMP_OBB.applyMatrix4(trx);
      // If any of the obb halfSize is 0.01 then the box is too small
      // 0.01 is the distance at which two box handle will overlap
      const MIN_SIZE = 0.01;
      if (
        TEMP_OBB.halfSize.x < MIN_SIZE ||
        TEMP_OBB.halfSize.y < MIN_SIZE ||
        TEMP_OBB.halfSize.z < MIN_SIZE
      ) {
        setClippingPlanes(undefined);
        return;
      }
      const hit = TEMP_OBB.intersectsBox3(modelBB);
      // If there's no intersection we don't have a valid set of planes to extract a volume
      if (!hit) {
        setClippingPlanes(undefined);
        return;
      }

      const invModelTransform = new Matrix4()
        .fromArray(cloudTransform.worldMatrix)
        .invert();
      const planes = clippingPlanes.map((plane) =>
        plane.clone().applyMatrix4(invModelTransform),
      );
      setClippingPlanes(planes);
    },
    [modelBB, cloudTransform.worldMatrix, setClippingPlanes],
  );

  const interactiveObjects = useMemo(
    () => [pointCloudObject],
    [pointCloudObject],
  );

  // TODO: uncomment the different parts when we have controls for them.
  // I left them in to make it easier for the next dev to  know what they are.
  return (
    <>
      <PointCloudWithOpacity
        pointCloud={pointCloudObject}
        visible
        clippingPlanes={clippingPlanesPreview}
      />
      <BoxControls
        ref={boxRef}
        position={boxPosition}
        size={boxScale}
        clipInside={false}
        enableRotateX={false}
        enableRotateY={false}
        onInteractionStarted={onInteractionStarted}
        onInteractionStopped={() => setControlsEnabled(true)}
        clippingPlanesChanged={onClippingPlanesChanged}
        enableSideSelection={selectedTab === ExportModeTab.orthophoto}
        onSideSelected={(side) => dispatch(setSelectedOrthophotoSide(side))}
      />
      <ExplorationControls
        target={target}
        enabled={controlsEnabled}
        obstacles={interactiveObjects}
      />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={pointCloudObject} />
        <StoreFboPass ref={storeFboRef} />
        <FilteredRenderPass
          filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
