import {
  RegisterMultiCloudDataSetTask,
  SuccessfullyCompleted,
} from "@/utils/background-tasks";
import { Canvas } from "@faro-lotv/app-component-toolbox";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { Box } from "@mui/material";
import { AlignmentCanvasOverlay } from "../common/alignment-overlay";
import { InspectAndPublishScene } from "./inspect-and-publish-scene";

type InspectAndPublishCanvasProps = {
  /** Pointcloud objects to render in the multi-registration view */
  pointClouds: IElementGenericPointCloudStream[];

  /** The point cloud which is currently selected by the user, if any. */
  selectedPointCloud?: IElementGenericPointCloudStream;

  /** The registration task to display the results of. */
  registrationTask?: SuccessfullyCompleted<RegisterMultiCloudDataSetTask>;
};

/**
 * @returns R3F Canvas for the inspect & publish tool
 */
export function InspectAndPublishCanvas({
  pointClouds,
  registrationTask,
  selectedPointCloud,
}: InspectAndPublishCanvasProps): JSX.Element | null {
  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Canvas style={{ position: "absolute" }} orthographic>
        <InspectAndPublishScene
          pointClouds={pointClouds}
          registrationTask={registrationTask}
          selectedPointCloud={selectedPointCloud}
        />
      </Canvas>
      <AlignmentCanvasOverlay showResetTransformation={false} />
    </Box>
  );
}
