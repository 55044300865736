import { useEffect } from "react";

/**
 * Set the title of the browser tab to the given text.
 *
 * @param title The title to set for the browser tab
 */
export function useDocumentTitle(title?: string): void {
  useEffect(() => {
    if (!title) return;

    const previousTitle = document.title;
    document.title = title;

    // Restore previous title on unmount
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}
