import {
  selectAlignmentArea,
  selectElementToAlignTransform,
} from "@/alignment-tool/store/alignment-selectors";
import {
  AlignmentTransform,
  setElementToAlignTransform,
} from "@/alignment-tool/store/alignment-slice";
import { selectPointCloudInitialTransform } from "@/alignment-tool/store/select-model-initial-transform";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { PointCloudObject, SheetObject } from "@/object-cache";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectIElementWorldTransform,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { isEqual } from "lodash";
import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * Compute the AlignmentTransform to use to center a PointCloud over a Sheet
 *
 * @param matrixWorld The current world matrix of the point cloud
 * @param pointCloud to center over the sheet
 * @param sheetCenter the world position of the center of the sheet
 * @returns the AlignmentTransform to use
 */
function computePointCloudCenteringTransformation(
  matrixWorld: AlignmentTransform,
  pointCloud: PointCloudObject,
  sheetCenter: Vector3,
): AlignmentTransform {
  const pcWorldMatrix = alignmentTransformToMatrix4(matrixWorld);

  const pcCenter = pointCloud.tree.boundingBox.getCenter(new Vector3());
  pcCenter.applyMatrix4(pcWorldMatrix.setPosition(0, 0, 0));

  const matrix = pcWorldMatrix.setPosition(
    sheetCenter.x - pcCenter.x,
    sheetCenter.y - pcCenter.y,
    sheetCenter.z - pcCenter.z,
  );

  const position = new Vector3(),
    quaternion = new Quaternion(),
    scale = new Vector3();
  matrix.decompose(position, quaternion, scale);

  // Move pointCloud position to the center of the sheet
  return {
    position: [position.x, position.y, position.z],
    quaternion: [quaternion.x, quaternion.y, quaternion.z, quaternion.w],
    scale: [scale.x, scale.y, scale.z],
  };
}

/**
 * @returns The world position of the center of the sheet
 * @param sheet The sheet whose center we want to compute
 */
function computeSheetCenter(sheet: SheetObject): Vector3 {
  const sheetCenter = new Vector3();
  sheet.geometry.computeBoundingBox();
  sheet.geometry.boundingBox?.getCenter(sheetCenter);
  sheet.localToWorld(sheetCenter);
  return sheetCenter;
}

/**
 * Compute the AlignmentTransform to use to center the element to align over a Sheet
 *
 * @param matrixWorld The current world matrix of the point cloud
 * @param element to center over the sheet
 * @param sheet to use as a reference
 * @returns the AlignmentTransform to use
 */
export function computeCloudCenteringTransformation(
  matrixWorld: AlignmentTransform | undefined,
  element: PointCloudObject,
  sheet: SheetObject,
): AlignmentTransform | undefined {
  return computePointCloudCenteringTransformation(
    matrixWorld ?? matrix4ToAlignmentTransform(new Matrix4()),
    element,
    computeSheetCenter(sheet),
  );
}

/**
 * Apply a default initial transformation to the element to align or compute the
 * best one to center it on the sheet
 *
 * @param sheet to center onto
 * @param cloud element to initialize
 */
export function useCloudToAlignInitialPosition(
  sheet: SheetObject | null,
  cloud: PointCloudObject,
): void {
  const pcTransform = useAppSelector(selectElementToAlignTransform);
  const dispatch = useAppDispatch();

  const activeArea = useAppSelector(selectAlignmentArea);

  const pcInitialTransform = useAppSelector(
    selectPointCloudInitialTransform(sheet?.iElement, cloud.iElement),
    isEqual,
  );

  const worldMatrix = useAppSelector(
    selectIElementWorldTransform(cloud.iElement.id),
  );

  // Adjusting the initial position of the pointCloud to be the center of the sheet
  // we want to run this hook only the first time and not if pcTransform changes
  // as this hook will set pcTransform value depending on it would create a loop
  useNonExhaustiveEffect(() => {
    if (!activeArea || !sheet || pcTransform) return;
    if (pcInitialTransform) {
      dispatch(setElementToAlignTransform(pcInitialTransform));
      return;
    }
    dispatch(
      setElementToAlignTransform(
        computePointCloudCenteringTransformation(
          worldMatrix,
          cloud,
          computeSheetCenter(sheet),
        ),
      ),
    );
  }, [dispatch, cloud, sheet]);
}
