import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import { setVisibilityDistance } from "@/store/view-options/view-options-slice";
import { useCallback } from "react";
import { Camera, Vector3 } from "three";

/** Increase the object distance by 10% to make sure the object is visible */
const DISTANCE_FACTOR = 1.1;

export type VisibilityDistanceUpdater = (
  camera: Camera,
  target: Vector3,
) => void;

/** @returns a function to update the visibility distance to ensure a specific point is visible */
export function useUpdateVisibilityDistance(): VisibilityDistanceUpdater {
  const store = useAppStore();
  const dispatch = useAppDispatch();

  return useCallback(
    (camera: Camera, target: Vector3): void => {
      const distance = camera.position.distanceTo(target) * DISTANCE_FACTOR;
      const visibilityDistance = selectVisibilityDistance(store.getState());
      if (visibilityDistance < distance) {
        dispatch(setVisibilityDistance(distance));
      }
    },
    [dispatch, store],
  );
}
