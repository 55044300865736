import { useElementFileUploadContext } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import {
  MAX_UPLOAD_CLOUD_FILE_SIZE_GB,
  useCanConnectPointCloud,
} from "@/components/common/point-cloud-file-upload-context/point-cloud-upload-utils";
import { ImportDataMenu } from "./import-data-menu";

/**
 * @returns Management of point cloud imports. Will show a dialog for adjusting the meta data
 * of the Point Cloud once a file is provided.
 */
export function ConnectPointCloud(): JSX.Element | null {
  const canConnectPointCloud = useCanConnectPointCloud();

  const { setPointCloudFile, setCadFile } = useElementFileUploadContext();

  if (!canConnectPointCloud) {
    return null;
  }

  return (
    <ImportDataMenu
      onPointCloudToUploadSelected={setPointCloudFile}
      onCADToUploadSelected={setCadFile}
      maxCloudFileSizeGB={MAX_UPLOAD_CLOUD_FILE_SIZE_GB}
      dark
    />
  );
}
