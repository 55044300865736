import { selectActiveArea } from "@/store/selections-selectors";
import {
  Canvas,
  selectChildDepthFirst,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericPointCloudStream,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import { Box } from "@mui/material";
import { useAppSelector } from "../../store/store-hooks";
import { AlignmentCanvasOverlay } from "../common/alignment-overlay";
import { AutomaticRegistrationReset } from "./automatic-registration-reset";
import { PairwiseRegistrationScene } from "./pairwise-registration-scene";

type PairwiseRegistrationCanvasProps = {
  /** The model point cloud which will be aligned to the reference point cloud. */
  activeModelPointCloud: IElementGenericPointCloudStream;
  /** The immutable reference point cloud. */
  activeRefPointCloud: IElementGenericPointCloudStream;
  /** Callback to execute when the user manually manipulates the point cloud positions. */
  onVisualRegistration(): void;
};

/**
 * @returns R3F Canvas for the pairwise registration workflow
 */
export function PairwiseRegistrationCanvas({
  activeModelPointCloud,
  activeRefPointCloud,
  onVisualRegistration,
}: PairwiseRegistrationCanvasProps): JSX.Element | null {
  const area = useAppSelector(selectActiveArea);

  const activeSheet = useAppSelector(
    selectChildDepthFirst(area, isIElementGenericImgSheet),
  );

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <AutomaticRegistrationReset activeRefPointCloud={activeRefPointCloud} />

      <Canvas style={{ position: "absolute" }} orthographic>
        <PairwiseRegistrationScene
          activeModelPointCloud={activeModelPointCloud}
          activeRefPointCloud={activeRefPointCloud}
          activeSheet={activeSheet}
          onVisualRegistration={onVisualRegistration}
        />
      </Canvas>
      <AlignmentCanvasOverlay showResetTransformation />
    </Box>
  );
}
