import Box from "@mui/material/Box";
import { StepIconProps } from "@mui/material/StepIcon";

/**
 * @returns Icon used for showing the state of the current step
 */
export function StepIcon({
  active,
  completed,
  icon,
}: StepIconProps): JSX.Element {
  return (
    <Box
      component="span"
      sx={({ palette }) => {
        let backgroundColor = palette.neutralGray;
        if (!!completed || !!active) {
          backgroundColor = palette.primary.main;
        }
        return {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          width: "32px",
          height: "32px",

          backgroundColor,
          color: palette.white,
        };
      }}
    >
      {icon}
    </Box>
  );
}
