import { selectSelectedOrthophotoSide } from "@/store/modes/export-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { BoxGizmoSide, OrientedBoundingBox } from "@faro-lotv/lotv";
import { useMemo } from "react";
import { Vector3 } from "three";

/**
 * @returns The axis in world coordinates based on the side selected in the BoxControls
 * @param volume The oriented bounding box described by the box controls
 */
export function useOrthophotoViewDirection(
  volume: OrientedBoundingBox | undefined,
): Vector3 | undefined {
  const selectedOrthophotoSide = useAppSelector(selectSelectedOrthophotoSide);

  return useMemo(() => {
    if (!volume) return;
    switch (selectedOrthophotoSide) {
      case BoxGizmoSide.xPositive: {
        return new Vector3(-1, 0, 0);
      }
      case BoxGizmoSide.xNegative: {
        return new Vector3(1, 0, 0);
      }
      case BoxGizmoSide.yPositive: {
        return new Vector3(0, 0, 1);
      }
      case BoxGizmoSide.yNegative: {
        return new Vector3(0, 0, -1);
      }
      case BoxGizmoSide.zPositive: {
        return new Vector3(0, -1, 0);
      }
      case BoxGizmoSide.zNegative: {
        return new Vector3(0, 1, 0);
      }
    }
  }, [selectedOrthophotoSide, volume]);
}
