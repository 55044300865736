import { useDashboardAccountUrl } from "@/utils/redirects";
import { useCallback } from "react";

/** @returns a method to open the account and security settings in a new tab */
export function useOpenAccountAndSecurity(): () => void {
  const accountAndSecurityUrl = useDashboardAccountUrl();

  return useCallback(() => {
    window.open(accountAndSecurityUrl, "_blank");
  }, [accountAndSecurityUrl]);
}
