import { PanoPanoAnimation } from "@/components/r3f/animations/pano-pano-animation";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectHasValidPose,
  selectIElementWorldPosition,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import { PanoToPanoProps } from "./pano-to-pano-types";

/** @returns The pano to pano animation for the walk scene */
export function PanoToPanoDirect({
  currentElement,
  targetElement,
  targetQuaternion,
  sheet,
  isSecondaryView,
  onCameraMoved,
  onAnimationFinished,
}: PanoToPanoProps): JSX.Element {
  const currentPano = useCached3DObject(currentElement);
  const panoHasValidPose = useAppSelector(selectHasValidPose(targetElement));
  const targetPosition = useAppSelector(
    selectIElementWorldPosition(targetElement.id),
  );

  const camera = useThree((s) => s.camera);

  /** Trigger the camera movement after mounting the animation so the minimap can update during the animation  */
  useNonExhaustiveEffect(() => {
    if (panoHasValidPose) {
      onCameraMoved?.(targetPosition);
    } else {
      onCameraMoved?.(camera.getWorldPosition(new Vector3()));
    }
  }, [targetElement]);

  return (
    <PanoPanoAnimation
      isSecondaryView={isSecondaryView}
      pano={currentPano}
      targetQuaternion={targetQuaternion}
      sheet={sheet}
      targetElement={targetElement}
      onCompleted={() => {
        onAnimationFinished(targetElement);
      }}
    />
  );
}
