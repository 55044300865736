import { useAppDispatch } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { addEntities } from "../store/revision-slice";

/**
 * @param revisionId The ID of the revision to load the entities of.
 * @returns The Capture Tree entities of the given revision.
 *  While the entities are loading, `undefined` is returned.
 */
export function useLoadRevisionEntities(
  revisionId: GUID,
): CaptureTreeEntityRevision[] | undefined {
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();

  const [entities, setEntities] = useState<CaptureTreeEntityRevision[]>();

  useEffect(() => {
    const abortController = new AbortController();

    projectApiClient
      .getCaptureTreeForRegistrationRevision(revisionId, abortController.signal)
      .then((entities) => {
        // Add the entities to the store
        dispatch(addEntities(entities));
        // Set the return parameter to finish loading
        setEntities(entities);
      });

    return () => {
      abortController.abort("useLoadEntities hook unmounted");
    };
  }, [projectApiClient, revisionId, dispatch]);

  return entities;
}
