import { assert } from "@faro-lotv/foundation";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { RegistrationThresholdSet } from "./registration-thresholds";

type ThresholdSetContext = {
  /** The threshold set to use to derive the quality of metric values. */
  thresholdSet: RegistrationThresholdSet;

  /** Change the threshold set to derive the quality of metric values with. */
  setThresholdSet(thresholdSet: RegistrationThresholdSet): void;
};

export const ThresholdSetContext = createContext<
  ThresholdSetContext | undefined
>(undefined);

type ThresholdSetProviderProps = PropsWithChildren<{
  /** The threshold set to use by default until the user changes it. */
  defaultThresholdSet: RegistrationThresholdSet;
}>;

/**
 * @returns A context to store the threshold set to use to determine the quality of metric values.
 *  Can be selected by the user, with a fallback to a default value.
 */
export function ThresholdSetProvider({
  defaultThresholdSet,
  children,
}: ThresholdSetProviderProps): JSX.Element {
  const [thresholdSet, setThresholdSet] = useState(defaultThresholdSet);

  return (
    <ThresholdSetContext.Provider
      value={{
        thresholdSet,
        setThresholdSet,
      }}
    >
      {children}
    </ThresholdSetContext.Provider>
  );
}

/**
 * @returns The current threshold set and a function to change it.
 *  Must be used inside a `ThresholdSetProvider`.
 */
export function useThresholdSetContext(): ThresholdSetContext {
  const context = useContext(ThresholdSetContext);
  assert(
    context,
    "useThresholdSetContext must be used inside a ThresholdSetProvider",
  );
  return context;
}
