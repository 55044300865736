import { useEffect, useRef } from "react";

/**
 * A hook to check if a component is re-drawing unnecessarily, and log an error in the console if that is happening
 *
 * @param componentName name of the component that is being checked
 * @param maxRedraws the number of redraws in the @see checkSpanMs time frame to trigger the warning @default 10
 * @param checkSpanMs the number of milliseconds to check for redraws @default 5000
 */
export function useDetectTooManyRedraws(
  componentName: string,
  maxRedraws: number = 10,
  checkSpanMs: number = 5000,
): void {
  // Counter for the number of redraws
  const redrawCounter = useRef(0);

  useEffect(() => {
    // Increase the counter at each redraw
    redrawCounter.current++;
  });

  // Reset the counter on an interval
  useEffect(() => {
    const interval = setInterval(() => {
      // Issue the console error if we passed the limit
      if (redrawCounter.current > maxRedraws) {
        console.error(
          `${componentName} was re-drawn ${redrawCounter.current} times, more than the max configured of ${maxRedraws} in the last ${checkSpanMs} seconds`,
        );
      }

      redrawCounter.current = 0;
    }, checkSpanMs);

    return () => {
      clearInterval(interval);
    };
  }, [checkSpanMs, maxRedraws, componentName]);
}
