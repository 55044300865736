import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { selectMarkupsDisplayForViewers } from "@faro-lotv/project-source";

interface AnnotationPermissions {
  /** Whether the user is allowed to create new or edit existing annotations */
  canWriteAnnotations: boolean;

  /** Whether the user is allowed to see existing annotations */
  canReadAnnotations: boolean;
}

/**
 * @returns the permission of the current user regarding annotations
 */
export function useAnnotationPermissions(): AnnotationPermissions {
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const displayForViewers = useAppSelector(selectMarkupsDisplayForViewers);

  const canReadAnnotations = hasWritePermission || !!displayForViewers;

  return {
    canWriteAnnotations: canReadAnnotations && hasWritePermission,
    canReadAnnotations,
  };
}
