import { useCancelUpload } from "@/components/common/file-upload-context/use-cancel-upload";
import { isSupportedCADFileExtension } from "@/components/common/point-cloud-file-upload-context/cad-upload-utils";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FileUploadTask } from "@/utils/background-tasks";
import { NoTranslate } from "@faro-lotv/flat-ui";
import { selectIElement } from "@faro-lotv/project-source";
import { ElementIconType } from "../../icons";
import {
  BackgroundTaskCardProps,
  ProgressCardLayout,
} from "../layouts/progress-card-layout";

/** @returns the upload menu card to track a PointCloud upload */
export function UploadTaskCard({
  task,
}: BackgroundTaskCardProps<FileUploadTask>): JSX.Element {
  const cancelUpload = useCancelUpload();
  const refElement = useAppSelector((state) =>
    task.iElementId ? selectIElement(task.iElementId)(state) : undefined,
  );
  const activeArea = useAppSelector(selectActiveAreaOrThrow(refElement));

  const icon = isSupportedCADFileExtension(task.metadata.filename)
    ? ElementIconType.ViewInArIcon
    : ElementIconType.PointCloudIcon;

  return (
    <ProgressCardLayout
      name={task.metadata.filename}
      subText={<NoTranslate>{activeArea.name}</NoTranslate>}
      upload={task}
      icon={icon}
      startTime={task.createdAt}
      menu={[
        {
          name: "Cancel import",
          callback: () => cancelUpload(task.id),
          color: "error",
        },
      ]}
    />
  );
}
