import { GUID } from "@faro-lotv/foundation";
import { RootState } from "../store";
import {
  ExclusiveModeCompletionAction,
  ToolName,
  ToolVisibility,
} from "./ui-slice";

/**
 * @returns true if the modal spinner must be shown
 */
export function selectShowSpinner({ ui }: RootState): boolean {
  return ui.showSpinner;
}

/**
 * @returns The name of the active tool
 */
export function selectActiveTool({ ui }: RootState): ToolName | null {
  return ui.activeTool;
}

/** @returns The active settings menu */
export function selectActiveSettingsMenu({ ui }: RootState): ToolName | null {
  return ui.activeSettingsMenu;
}

/**
 * @returns The visibility status of all tools
 */
export function selectToolVisibility({
  ui,
}: RootState): Record<ToolName, ToolVisibility> {
  return ui.toolVisibility;
}

/**
 * @returns Visibility of a specific tool
 * @param toolName Name of the tool
 */
export function selectVisibilityOfTool(toolName: ToolName) {
  return ({ ui }: RootState): ToolVisibility => ui.toolVisibility[toolName];
}

/**
 * @returns true if the project overview should be open, false if collapsed
 */
export function selectIsProjectOverviewExpanded({ ui }: RootState): boolean {
  return ui.isProjectOverviewExpanded;
}

/**
 * @returns true if the quick help panel is open, false if collapsed
 */
export function selectIsQuickHelpOpen({ ui }: RootState): boolean {
  return ui.isQuickHelpOpen;
}

/**
 * @returns the current executing exclusive mode completion action
 */
export function selectExclusiveModeCompletionAction({
  ui,
}: RootState): `${ExclusiveModeCompletionAction}` | undefined {
  return ui.exclusiveModeCompletionAction;
}

/**
 * @param id of the annotation to check
 * @returns true if that annotation is expanded
 */
export function selectIsAnnotationExpanded(id: GUID) {
  return ({ ui }: RootState): boolean => {
    return ui.expandedAnnotationsIds.includes(id);
  };
}
