import {
  DeepLink,
  encodeDeepLink,
} from "@/components/common/deep-link/deep-link-encoding";
import { runtimeConfig } from "@/runtime-config";
import { userControlledFeatures } from "@/store/features/features";
import { changeMode } from "@/store/mode-slice";
import {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { AppDispatch, RootState, store } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectChildDepthFirst,
  selectCompanyId,
  selectDashboardUrl,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  isIElementGenericImgSheet,
  isIElementGenericModel3d,
} from "@faro-lotv/ielement-types";
import { useMemo } from "react";

type RedirectToAlignmentToolArgs = {
  /** Id of the project we want to do the alignment for */
  projectId: GUID;

  /** Id of the element to align */
  elementId: GUID;

  /** Id of the floor where to align the PointCloud to */
  floorId: GUID;

  /** Current state of the application. */
  state?: RootState;

  /** Function to update the state. */
  dispatch?: AppDispatch;
};

type RedirectToRegistrationToolArgs = {
  /** Id of the project we want to do the manual alignment/registration for */
  projectId: GUID;
  /** Id of the Reference PointCloud*/
  pointCloudId1: GUID;
  /** Id of the model PointCloud*/
  pointCloudId2: GUID;
};

type RedirectToMultiRegistrationToolArgs = {
  /** Id of the project we want to see the registration result for */
  projectId: GUID;
  /** Id of the Dataset containing the registered point clouds */
  datasetId: GUID;
  /** The ID of the completed registration task to display the results of. */
  registrationTaskId?: GUID;
};

/**
 * Redirect the user to the alignment tool
 */
export function redirectToAlignmentTool({
  projectId,
  elementId,
  floorId,
  state,
  dispatch,
}: RedirectToAlignmentToolArgs): void {
  assert(projectId.length > 0, "Need a non empty projectId to redirect");

  if (state && dispatch) {
    const element = selectIElement(elementId)(state);
    if (element && isIElementGenericModel3d(element)) {
      dispatch(
        setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation),
      );
      const floor = selectIElement(floorId)(state);
      // If any section area have multiple sheets, the first one found will always be selected here
      // At the time I was writing these lines there is no way to select a specific sheet in a section area the UI
      const sheet = selectChildDepthFirst(
        floor,
        isIElementGenericImgSheet,
      )(state);
      if (!sheet) {
        throw new Error("Sheet not found for selected floor.");
      }
      dispatch(setSheetForCadAlignment(sheet.id));
      dispatch(changeMode("sheetToCadAlignment"));

      return;
    }
  }

  const params = new URLSearchParams();
  params.append("areaId", floorId);
  params.append("cloudId", elementId);

  redirectWithFeatureFlags(
    `${runtimeConfig.externalLinks.alignmentTool}/project/${projectId}`,
    params,
  );
}

/**
 * Redirect the user to the feedback form
 */
export function redirectToGetEarlyAccessForm(): void {
  if (runtimeConfig.externalLinks.getEarlyAccessForm) {
    window.open(runtimeConfig.externalLinks.getEarlyAccessForm, "_blank");
  }
}

/**
 * Redirect the user to the WebEditor page for the current project
 *
 * @param projectId of the project we want to open in the WebEditor
 */
export function redirectToWebEditor(projectId: GUID): void {
  if (runtimeConfig.externalLinks.webEditor) {
    const parameters = new URLSearchParams({
      m: "editor",
      p: projectId,
    });

    window.location.href = `${
      runtimeConfig.externalLinks.webEditor
    }?${parameters.toString()}`;
  }
}

/**
 * Redirects to the projects details page, or one of it's tabs, in the Dashboard.
 *
 * @param projectId - Id of the project that is currently open
 * @param subPage - optional string which describes the redirect target within the dashboards
 *  projects page. Can also include feature flags. (e.g. "data" or "data?enableBeta=true).
 *  If not used or empty, it defaults to "details", which is the projects main page.
 * @param dashboardUrl - optional URL to the dashboard, as it can be obtained by selectDashboardUrl.
 *  If not provided this will fallback to the one from the runtime config.
 */
export function redirectToDashboardProjectPage(
  projectId: GUID,
  subPage?: string,
  dashboardUrl: string = runtimeConfig.externalLinks.dashboardUrl,
): void {
  assert(projectId, "Need a non empty projectId to redirect");

  // workaround for a bug in the dashboard, see https://faro01.atlassian.net/browse/ST-1729
  if (!subPage) {
    subPage = "details";
  }

  window.location.href = `${dashboardUrl}/projects/${projectId}/${subPage}`;
}

/**
 * Redirects to the (most fitting) Dashboard for the user
 */
export function redirectToDashboard(): void {
  // Check if the store has a Dashboard stored
  const dashboardUrl =
    selectDashboardUrl(store.getState()) ??
    runtimeConfig.externalLinks.dashboardUrl;

  window.location.href = dashboardUrl;
}

/** @returns the url to use to open the dashboard addon settings page if available */
export function useDashboardAddOnUrl(): string | undefined {
  const companyId = useAppSelector(selectCompanyId);

  return useMemo(() => {
    if (!runtimeConfig.externalLinks.dashboardUrl || !companyId) return;
    return new URL(
      `${companyId}/settings/addOns-and-services`,
      runtimeConfig.externalLinks.dashboardUrl,
    ).toString();
  }, [companyId]);
}

/** @returns the url to open the account page for the current user (opens the Account & Security tab in Dashboard v2)*/
export function useDashboardAccountUrl(): string | undefined {
  const companyId = useAppSelector(selectCompanyId);
  const dashboardUrl =
    useAppSelector(selectDashboardUrl) ??
    runtimeConfig.externalLinks.dashboardUrl;

  return useMemo(() => {
    if (!dashboardUrl || !companyId) {
      return;
    }
    return new URL(
      // This is a Dashboard v1 route that is forwarded to the Account & Security page in Dashboard v2
      `${companyId}/me`,
      dashboardUrl,
    ).toString();
  }, [companyId, dashboardUrl]);
}

/**
 * Redirect to the manual alignment/registration tool deployed in HoloBuilder for the selected project
 */
export function redirectToRegistrationTool({
  projectId,
  pointCloudId1,
  pointCloudId2,
}: RedirectToRegistrationToolArgs): void {
  assert(projectId !== "", "Need a non empty projectId to redirect");

  const params = new URLSearchParams({
    refCloudId: pointCloudId1,
    modelCloudId: pointCloudId2,
  });

  redirectWithFeatureFlags(`/manual-alignment/project/${projectId}`, params);
}

/**
 * Redirect to the manual alignment/registration tool deployed in HoloBuilder for the selected project
 */
export function redirectToMultiRegistrationTool({
  projectId,
  datasetId,
  registrationTaskId,
}: RedirectToMultiRegistrationToolArgs): void {
  assert(projectId, "Need a non empty projectId to redirect");

  const params = new URLSearchParams();
  params.append("datasetId", datasetId);
  if (registrationTaskId) {
    params.append("taskId", registrationTaskId);
  }

  redirectWithFeatureFlags(
    `/multi-registration-result/project/${projectId}`,
    params,
  );
}

/**
 * Redirect the user to the Sphere Viewer page for the current project
 *
 * @param projectId of the project we want to open in the Sphere Viewer
 * @param state optional information to populate deepLink to correctly restore view
 */
export function redirectToViewer(projectId: GUID, state?: DeepLink): void {
  const params = new URLSearchParams();

  // This will populate the searchParameters of the url with all the data required to generate a deep link
  if (state) {
    encodeDeepLink(state, params);
  }

  redirectWithFeatureFlags(`/project/${projectId}`, params);
}

/**
 * @param projectId of the project we want to open in the Sphere Viewer
 * @param state optional information to populate deepLink to correctly restore view
 * @returns URL to the Sphere Viewer page for the current project
 */
export function generateViewerUrl(projectId: GUID, state?: DeepLink): string {
  const path = `/project/${projectId}`;
  const params = new URLSearchParams();

  // This will populate the searchParameters of the url with all the data required to generate a deep link
  if (state) {
    encodeDeepLink(state, params);
  }

  // Retain the enabled feature flags during the redirect
  addEnabledFeatureFlags(params);

  // Add the search parameters to the URL, if there are any
  return params.size ? `${path}?${params.toString()}` : path;
}

/**
 * Redirect to the data management page for the current project
 *
 * @param projectId of the project we want to open in the Data Management page
 * @param dashboardUrl optional URL to the dashboard, as it can be obtained by selectDashboardUrl.
 *  If not provided this will fallback to the one from the runtime config.
 */
export function redirectToDataManagementUrl(
  projectId: GUID,
  dashboardUrl: string | undefined,
): void {
  const baseUrl = dashboardUrl ?? runtimeConfig.externalLinks.dashboardUrl;
  const path = `/projects/${projectId}/data-management?type=preparedData`;

  window.location.href = `${baseUrl}${path}`;
}

/**
 * Redirect to the given path and add the params and selected feature flags to the URL.
 *
 * @param path The path to redirect to, contrary to `URL`, this can be a relative path.
 * @param params Search parameters to add to the URL.
 */
function redirectWithFeatureFlags(
  path: string,
  params?: URLSearchParams,
): void {
  const featureParams = params ?? new URLSearchParams();
  // Retain the enabled feature flags during the redirect
  addEnabledFeatureFlags(featureParams);

  // Add the search parameters to the URL, if there are any
  const url = featureParams.size ? `${path}?${featureParams.toString()}` : path;
  window.location.assign(url);
}

/**
 * @param searchParams The URL search params to add the currently enabled feature flags to.
 */
function addEnabledFeatureFlags(searchParams: URLSearchParams): void {
  const currentUrl = new URL(window.location.href);
  const urlParams = [...currentUrl.searchParams.keys()].map((x) =>
    x.toLowerCase(),
  );

  for (const feature of userControlledFeatures()) {
    if (urlParams.includes(feature.toLowerCase())) {
      searchParams.append(feature, "");
    }
  }
}
