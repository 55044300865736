import {
  CadMetadataDescription,
  Model3dStreamDescription,
  isCadMetadataDescription,
} from "@faro-lotv/ielement-types";
import {
  cadImporterTaskErrorToUserMessage,
  isCadImporterErrorCode,
} from "@faro-lotv/service-wires";

/**
 * The (known) UI message types coming from the CAD importer.
 *
 * See
 * - https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3639967903/Format+of+json+data+stored+in+the+Model3DStream+node.
 */
export enum CadImporterUIMessage {
  /**
   * Unknown message.
   * Would typically require debugging.
   */
  cadImporterUnknownMessage = "CADImporter_UnknownMessage",

  /**
   * CAD importer error message.
   * Message text contains CadImporterErrorCode and should be converted to user message by cadImporterTaskErrorToUserMessage().
   */
  cadImporterErrorMessage = "CADImporter_ErrorMessage",

  /**
   * CAD importer warning message.
   * The message will be reported to the end-user as is.
   */
  cadImporterWarningMessage = "CADImporter_WarningMessage",

  /**
   * Message retrieved from Autodesk Platform Service.
   * The message will be reported to the end-user as is.
   */
  cadImporterAPSMessage = "CADImporter_APSMessage",

  /**
   * 3D model loading error message
   */
  cadModelLoadingErrorMessage = "ModelLoading_ErrorMessage",
}

/**
 * @returns Wether or not the provided string is a valid CAD importer UI message type
 * @param messageType String to check
 */
export function isCadImporterUIMessage(
  messageType?: string,
): messageType is CadImporterUIMessage {
  return (
    !!messageType &&
    Object.values<string>(CadImporterUIMessage).includes(messageType)
  );
}

/**
 * Convert UI message from CAD Importer for the user.
 *
 * @param messageText UI message text.
 * @param messageType The UI Message type.
 *   If not given, a generic message will be shown.
 * @returns A message explaining the error to the user.
 */
export function cadImporterUIMessageToUserMessage(
  messageText: string,
  messageType?: CadImporterUIMessage,
): string {
  switch (messageType) {
    case undefined:
    case CadImporterUIMessage.cadImporterUnknownMessage:
      return `(Unknown message) ${messageText}`;
    case CadImporterUIMessage.cadImporterAPSMessage:
      return `(Conversion service message) ${messageText}`;
    case CadImporterUIMessage.cadImporterWarningMessage:
      return `(Conversion service message) ${messageText}`;
    case CadImporterUIMessage.cadModelLoadingErrorMessage:
      return `(3D Model loading error) ${messageText}`;
    case CadImporterUIMessage.cadImporterErrorMessage:
      if (isCadImporterErrorCode(messageText)) {
        const message = cadImporterTaskErrorToUserMessage("", messageText);
        return `(Conversion service failure) ${message}`;
      }
      return `(Unknown message) ${messageText}`;
  }
}

/**
 * Async function to get the CAD model metadata (conversion messages and Levels) for a given IElementModel3dStream.
 *
 * @param model3dStreamDescription The descriptor of the IElementModel3dStream associated with the CAD
 * @returns metadata if any, or undefined if there is no messages and levels.
 */
export async function loadCadModelMetadata(
  model3dStreamDescription: Model3dStreamDescription,
): Promise<CadMetadataDescription | undefined> {
  if (!model3dStreamDescription.metadataUrl) {
    return;
  }

  const res = await fetch(model3dStreamDescription.metadataUrl);
  if (!res.ok) {
    throw new Error("Invalid metadataUrl uri");
  }
  const metadata = await res.json();
  if (!isCadMetadataDescription(metadata)) {
    throw new Error("Invalid MetadataDescription url");
  }

  return metadata;
}
