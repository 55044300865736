import {
  GUID,
  IElementDateTimeMarkupField,
  IElementDropDownMarkupField,
  IElementGenericMarkupField,
  IElementType,
  IElementTypeHint,
  IElementUserDirectoryMarkupField,
  createIElement,
} from "@faro-lotv/ielement-types";
import { AdvancedMarkupTemplateIds } from "@faro-lotv/project-source";
import { CreateIElement } from "@faro-lotv/service-wires";
import { AnnotationProps } from "./annotation-props";

export type CreateAnnotationFieldsArgs = Pick<
  AnnotationProps,
  "assignee" | "status" | "dueDate"
> &
  Omit<AdvancedMarkupTemplateIds, "templateId"> & {
    /** Id of the Markup IElement to append the fields to */
    markupId: GUID;

    /** Id of the project root */
    rootId: GUID;

    /** Id of the current user */
    currentUserId: GUID;
  };

/**
 * @returns all the IElementGenericMarkupField needed to encode the user defined AnnotationProps
 */
export function createAnnotationFields({
  assignee,
  status,
  dueDate,
  assigneeTemplateId,
  dueDateTemplateId,
  statusTemplateId,
  rootId,
  markupId,
  currentUserId,
}: CreateAnnotationFieldsArgs): Array<
  CreateIElement<IElementGenericMarkupField>
> {
  const markupFields: Array<CreateIElement<IElementGenericMarkupField>> = [];

  if (assignee) {
    markupFields.push(
      createIElement<IElementUserDirectoryMarkupField>({
        type: IElementType.userDirectoryMarkupField,
        typeHint: IElementTypeHint.markupAssigneeId,
        name: "Assignee",
        rootId,
        parentId: markupId,
        createdBy: currentUserId,
        templateId: assigneeTemplateId,
        values: [assignee],
      }),
    );
  }

  if (status) {
    markupFields.push(
      createIElement<IElementDropDownMarkupField>({
        type: IElementType.dropDownMarkupField,
        typeHint: IElementTypeHint.markupIssueStatus,
        name: "Status",
        rootId,
        parentId: markupId,
        createdBy: currentUserId,
        templateId: statusTemplateId,
        value: status,
      }),
    );
  }

  if (dueDate) {
    markupFields.push(
      createIElement<IElementDateTimeMarkupField>({
        type: IElementType.dateTimeMarkupField,
        typeHint: IElementTypeHint.markupIssueDueDate,
        name: "Due Date",
        rootId,
        parentId: markupId,
        createdBy: currentUserId,
        templateId: dueDateTemplateId,
        value: dueDate.toISOString(),
      }),
    );
  }
  return markupFields;
}
