import { roundedCalculatePosition } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { Box2, Camera, Vector2, Vector3 } from "three";

/** Default segments count */
const DEFAULT_SEGMENTS_COUNT = 4;

/** Initial estimate of width of a measurement label, in screen pixels */
export const LABEL_WIDTH = 70;

/** Initial estimate of height of a measurement label, in screen pixels */
export const LABEL_HEIGHT = 35;

/** Minimum margin between a replaced label and the previously colliding label, in pixels */
const LABELS_MARGIN = 5;

/** Initial estimate of width of the measurement toolbar, in pixels */
export const TOOLBAR_WIDTH = 120;

/** Initial estimate of height of the measurement toolbar, in pixels */
export const TOOLBAR_HEIGHT = 50;

/**
 * How a label is anchored to its 3D position in the scene. By its middle point (e.g. measurement
 * labels), or by its bottom right point (e.g. measurement toolbar).
 */
export enum LabelAnchored {
  Center = 0,
  BottomRight = 1,
}

/**
 * This class is responsible of computing convenient screen positions for the measurement labels
 * so that they do not overlap the mouse, do not overlap each other, and yet they remain as close as possible
 * to their original position.
 *
 * When the labels are repositioned because of overlapping, care is taken to leave them as close as possible to their
 * original positions. Functions are provided to add other fixed colliders: the moving mouse, the toolbar, in the future
 * also the zoom lens.
 */
export class LabelsScreenPositionsComputer {
  /** How many segments are handled by this collider class */
  #segmentsCount: number;

  /**
   * Collider anchoring flag. The collider may be either the mouse cursor when
   * the measure is live, or the measure toolbar when the measure is active.
   */
  #colliderAnchored: LabelAnchored = LabelAnchored.Center;

  /**
   * The label bounding boxes at screen, in pixel coordinates
   * The first 'box' in the array is a placeholder for the mouse position,
   * so other labels do not overlap it.
   * The other 'segmentsCount' boxes are the labels for the distance and its X, Y, and Z components respectively.
   */
  #labelBoxes: Box2[];

  /** The centers of the labels' bounding boxes, in screen pixels */
  #boxCenters: Vector2[];

  /** The box sizes */
  #boxSizes: Vector2[];

  /**
   * The centers of the labels' bounding boxes, in screen pixels,
   * if no label intersects any other
   */
  #originalBoxCenters: Vector2[];

  /** For each label we remember whether is visible or snapped */
  #boxVisible: boolean[];

  /** A boolean dirty flag to remember whether the screen positions are fine or we should update them. */
  #dirty = true;

  /** Temporary variables */
  #displacement = new Vector2();

  #collider3DPos = new Vector3();
  #numVisibleBoxes = 0;
  #visibleBoxesIndices: number[];
  #candidateCenter = new Vector2();
  #candidateBox = new Box2();

  /**
   *
   * @param segmentsCount How many segments should this collider handle.
   */
  constructor(segmentsCount = DEFAULT_SEGMENTS_COUNT) {
    this.#segmentsCount = segmentsCount;
    const colliders = segmentsCount + 1;
    this.#labelBoxes = new Array<Box2>(colliders);
    this.#boxCenters = new Array<Vector2>(colliders);
    this.#boxSizes = new Array<Vector2>(colliders);
    this.#originalBoxCenters = new Array<Vector2>(colliders);
    this.#boxVisible = new Array<boolean>(colliders);
    this.#visibleBoxesIndices = new Array<number>(colliders);

    for (let i = 0; i < colliders; ++i) {
      this.#labelBoxes[i] = new Box2();
      this.#boxCenters[i] = new Vector2();
      this.#boxSizes[i] = new Vector2(LABEL_WIDTH, LABEL_HEIGHT);
      this.#originalBoxCenters[i] = new Vector2();
      this.#boxVisible[i] = true;
      this.#visibleBoxesIndices[i] = 0;
    }
  }

  /**
   * Sets the i-th bounding box from its center and the predefined size.
   *
   * @param i the box ID from 0 to 4 included.
   */
  #setBox(i: number): void {
    this.#labelBoxes[i].setFromCenterAndSize(
      this.#boxCenters[i],
      this.#boxSizes[i],
    );
  }

  /**
   * Compute the screen positions of the labels simply projecting their 3D positions,
   * without accounting for any collisions yet.
   *
   * @param labelPositions 3D anchors of the four measurement labels
   * @param camera The camera that is rendering the scene
   * @param size The viewport size, in pixels.
   * @param size.width viewport width
   * @param size.height viewport height
   */
  #computeInitialPositions(
    labelPositions: Vector3[],
    camera: Camera,
    size: { width: number; height: number },
  ): void {
    this.#numVisibleBoxes = 0;
    // Initializing the boxes positions with their 3D positions projected on screen.
    if (this.#boxVisible[0]) {
      let [x, y] = roundedCalculatePosition(this.#collider3DPos, camera, size);
      if (this.#colliderAnchored === LabelAnchored.BottomRight) {
        const colliderBoxSize = this.#boxSizes[0];
        x -= colliderBoxSize.x * 0.5;
        y -= colliderBoxSize.y * 0.5;
      }
      this.#boxCenters[0].set(x, y);
      this.#setBox(0);
      this.#originalBoxCenters[0].copy(this.#boxCenters[0]);
      this.#visibleBoxesIndices[this.#numVisibleBoxes] = 0;
      this.#numVisibleBoxes++;
    }
    for (let i = 1; i <= this.#segmentsCount; ++i) {
      if (this.#boxVisible[i]) {
        const [x, y] = roundedCalculatePosition(
          labelPositions[i - 1],
          camera,
          size,
        );
        this.#boxCenters[i].set(x, y);
        this.#setBox(i);
        this.#originalBoxCenters[i].copy(this.#boxCenters[i]);
        this.#visibleBoxesIndices[this.#numVisibleBoxes] = i;
        this.#numVisibleBoxes++;
      }
    }
  }

  /**
   * Returns whether the candidate box solves all collisions with labels with ID inferior to labelIdx.
   *
   * @param labelIdx Index of label to solve, in the 'visible labels' list
   * @returns Whether the candidate box solves all collisions
   */
  #collisionsSolved(labelIdx: number): boolean {
    for (let idx = 0; idx < labelIdx; ++idx) {
      const label = this.#visibleBoxesIndices[idx];
      if (this.#candidateBox.intersectsBox(this.#labelBoxes[label])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Tests whether the position (x, y) solves all collisions of the given label with the others.
   * If all collisions are solved, then the box is moved to the candidate position.
   *
   * @param x Screen candidate center X coord, pixels
   * @param y Screen candidate center Y coord, pixels
   * @param labelIdx Index of label to solve, in the 'visible labels' list
   * @param label Index of label to solve, in the 'boxLabels' list
   * @returns Whether the candidate box solved all collisions
   */
  #testCandidateBox(
    x: number,
    y: number,
    labelIdx: number,
    label: number,
  ): boolean {
    this.#candidateCenter.set(x, y);
    this.#candidateBox.setFromCenterAndSize(
      this.#candidateCenter,
      this.#boxSizes[label],
    );
    if (this.#collisionsSolved(labelIdx)) {
      this.#boxCenters[label].copy(this.#candidateCenter);
      this.#setBox(label);
      return true;
    }
    return false;
  }

  /**
   * Try solving a collision among labels by moving the current label around X axis
   *
   * @param labelIdx ID of the current label in the 'visible labels' list
   * @param intersectingLabel ID of the first intersecting label in the 'label boxes' list
   * @returns Whether all collisions were solved.
   */
  #placeAlongX(labelIdx: number, intersectingLabel: number): boolean {
    const intersectingBox = this.#labelBoxes[intersectingLabel];
    const labelWidth = intersectingBox.max.x - intersectingBox.min.x;
    const label = this.#visibleBoxesIndices[labelIdx];
    const currentBox = this.#labelBoxes[label];
    const currentBoxWidth = currentBox.max.x - currentBox.min.x;

    const safeDistance = (labelWidth + currentBoxWidth) * 0.5 + LABELS_MARGIN;
    const x1 = this.#boxCenters[intersectingLabel].x + safeDistance;
    const x2 = this.#boxCenters[intersectingLabel].x - safeDistance;
    const reference = this.#originalBoxCenters[label].x;
    let candidateX1 = x1;
    let candidateX2 = x2;
    if (Math.abs(x1 - reference) > Math.abs(x2 - reference)) {
      candidateX1 = x2;
      candidateX2 = x1;
    }
    const Ycoord = this.#boxCenters[label].y;
    if (this.#testCandidateBox(candidateX1, Ycoord, labelIdx, label)) {
      return true;
    }
    if (this.#testCandidateBox(candidateX2, Ycoord, labelIdx, label)) {
      return true;
    }

    return false;
  }

  /**
   * Try solving a collision among labels by moving the current label around Y axis
   *
   * @param labelIdx ID of the current label in the 'visible labels' list
   * @param intersectingLabel ID of the first intersecting label in the 'label boxes' list
   * @returns Whether all collisions were solved.
   */
  #placeAlongY(labelIdx: number, intersectingLabel: number): boolean {
    const intersectingBox = this.#labelBoxes[intersectingLabel];
    const labelHeight = intersectingBox.max.y - intersectingBox.min.y;
    const label = this.#visibleBoxesIndices[labelIdx];
    const currentBox = this.#labelBoxes[label];
    const currentBoxHeight = currentBox.max.y - currentBox.min.y;

    const safeDistance = (labelHeight + currentBoxHeight) * 0.5 + LABELS_MARGIN;
    let y1 = this.#boxCenters[intersectingLabel].y + safeDistance;
    let y2 = this.#boxCenters[intersectingLabel].y - safeDistance;
    const reference = this.#originalBoxCenters[label].y;
    let candidateY1 = y1;
    let candidateY2 = y2;
    if (Math.abs(y1 - reference) > Math.abs(y2 - reference)) {
      candidateY1 = y2;
      candidateY2 = y1;
    }
    const Xcoord = this.#boxCenters[label].x;
    if (this.#testCandidateBox(Xcoord, candidateY1, labelIdx, label)) {
      return true;
    }
    if (this.#testCandidateBox(Xcoord, candidateY2, labelIdx, label)) {
      return true;
    }

    y1 += currentBoxHeight;
    y2 -= currentBoxHeight;
    if (this.#testCandidateBox(Xcoord, y1, labelIdx, label)) return true;
    if (this.#testCandidateBox(Xcoord, y2, labelIdx, label)) return true;

    y1 += currentBoxHeight;
    y2 -= currentBoxHeight;
    if (this.#testCandidateBox(Xcoord, y1, labelIdx, label)) return true;
    if (this.#testCandidateBox(Xcoord, y2, labelIdx, label)) return true;

    return false;
  }

  /**
   * Collision avoidance algorithm.
   * Each label is checked for intersection against all others. If there is intersection,
   * four options are evaluated: move the 'moving' label on top, bottom, left or right of the
   * intersected label. The option that puts the label closest to its original position is picked.
   */
  #avoidCollisions(): void {
    for (let labelIdx = 1; labelIdx < this.#numVisibleBoxes; ++labelIdx) {
      const label = this.#visibleBoxesIndices[labelIdx];
      const currentBox = this.#labelBoxes[label];

      let intersectingLabel = -1;
      for (let otherLabelIdx = 0; otherLabelIdx < labelIdx; ++otherLabelIdx) {
        const otherLabel = this.#visibleBoxesIndices[otherLabelIdx];
        const otherBox = this.#labelBoxes[otherLabel];
        if (currentBox.intersectsBox(otherBox)) {
          intersectingLabel = otherLabel;
          break;
        }
      }

      if (intersectingLabel >= 0) {
        // If we enter here, one or more labels collide with the current label.
        this.#displacement.subVectors(
          this.#boxCenters[label],
          this.#boxCenters[intersectingLabel],
        );
        if (Math.abs(this.#displacement.x) > Math.abs(this.#displacement.y)) {
          // if most of displacement is along X, then try to solve the collision
          // by moving label along X under the hypothesis that the smallest movement is needed
          const ret = this.#placeAlongX(labelIdx, intersectingLabel);
          // if placement along X didn't work, try along Y
          if (!ret) this.#placeAlongY(labelIdx, intersectingLabel);
        } else {
          // Again, try to solve the collision along the smallest-movement axis
          const ret = this.#placeAlongY(labelIdx, intersectingLabel);
          // if some label still colides, solve along x
          if (!ret) this.#placeAlongX(labelIdx, intersectingLabel);
        }
      }
    }
  }

  /**
   *
   * @param position 3D position of the collider box
   * @param width Width in pixels of the collider box
   * @param height Height in pixels of the collider box
   * @param anchor Where is the input position anchored to the label: to the label's center, bottom right, ...
   */
  setCollider(
    position: Vector3,
    width: number,
    height: number,
    anchor: LabelAnchored,
  ): void {
    this.#boxVisible[0] = true;
    this.#collider3DPos.copy(position);
    this.#boxSizes[0].set(width, height);
    this.#colliderAnchored = anchor;
  }

  /** Tells the computer that there is no collider to account of. */
  resetCollider(): void {
    this.#boxVisible[0] = false;
  }

  /**
   * Computes the screen positions of the measurement labels, ensuring that they are
   * close to their anchor 3D point and that they never overlap each other nor overlap the
   * mouse.
   *
   * @param labelPositions 3D anchors of the four measurement labels
   * @param camera The camera that is rendering the scene
   * @param size The viewport size, in pixels.
   * @param size.width viewport width
   * @param size.height viewport height
   */
  compute(
    labelPositions: Vector3[],
    camera: Camera,
    size: { width: number; height: number },
  ): void {
    if (labelPositions.length !== this.#segmentsCount) return;

    this.#computeInitialPositions(labelPositions, camera, size);

    this.#dirty = false;

    if (this.#numVisibleBoxes < 2) return;

    this.#avoidCollisions();
  }

  /** Invalidates the computed screen positions. */
  setDirty(): void {
    this.#dirty = true;
  }

  /** @returns whether the screen positions computed before are still valid. */
  get dirty(): boolean {
    return this.#dirty;
  }

  /**
   * @param l the label ID from 0 to 3 included.
   * @returns the computed screen position of label with index l
   */
  position(l: number): number[] {
    const pos = this.#boxCenters[l + 1];
    return [pos.x, pos.y];
  }

  /**
   * Sets which labels are visibile and which are hidden.
   *
   * @param segmsVisible LIst of booleans indicating which of the labels are visible.
   */
  setSegmentsVisible(segmsVisible: boolean[]): void {
    if (segmsVisible.length !== this.#segmentsCount) return;
    for (let i = 0; i < this.#segmentsCount; ++i) {
      this.#boxVisible[i + 1] = segmsVisible[i];
    }
  }

  /**
   *
   * @param index The label index from 0 to segmentsCount
   * @param width new label 2D width in pixels
   * @param height new label 2D height in pixels
   */
  setLabelSize(index: number, width: number, height: number): void {
    assert(
      index >= 0 || index < this.#segmentsCount,
      "Wrong label index parameter to setLabelSize.",
    );
    this.#boxSizes[index + 1].set(width, height);
    this.setDirty();
  }

  /** @returns how many segments' labels this collider is handling. */
  get segmentsCount(): number {
    return this.#segmentsCount;
  }
}
