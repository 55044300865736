import {
  LockRotationIcon,
  ToggleIconButton,
} from "@faro-lotv/app-component-toolbox";
import { Tooltip } from "@mui/material";

interface IProps {
  /** Indicates whether Splitscreen is active, which will result in different styling  */
  isActive: boolean;

  /**
   * Flag to enable/disable this button
   *
   * @default true
   */
  isEnabled?: boolean;

  /** Handler to call when the button is clicked */
  onClick(isActive: boolean): void;
}

/**
 * @returns Button with a link icon
 */
export function SplitscreenLockButton({
  isActive,
  isEnabled = true,
  onClick,
}: IProps): JSX.Element {
  const tooltip = isEnabled
    ? "Lock the two views in orientation"
    : "Locking for point clouds will be added soon";

  return (
    <Tooltip title={tooltip}>
      {/** A span is needed to allow the tooltip to work on disabled buttons */}
      <span>
        <ToggleIconButton
          value="splitscreen-lock"
          selected={isActive}
          disabled={!isEnabled}
          onClick={() => onClick(isActive)}
        >
          <LockRotationIcon sx={{ color: "white" }} />
        </ToggleIconButton>
      </span>
    </Tooltip>
  );
}
