import { selectAreaFor } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { ProcessingTask } from "@/utils/background-tasks";
import { NoTranslate } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import {
  ProgressApiSupportedTaskTypes,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useMemo, useState } from "react";
import { ElementIconType } from "../../icons";
import {
  BackgroundTaskCardProps,
  ProgressCardLayout,
} from "../layouts/progress-card-layout";

/** @returns the upload menu card to track a backend processing of a PointCloud or CAD/Bim */
export function ProcessingTaskCard({
  task,
}: BackgroundTaskCardProps<ProcessingTask>): JSX.Element {
  const element = useAppSelector(selectIElement(task.iElementId ?? ""));
  const activeArea = useAppSelector(selectAreaFor(element));

  const elementName = useElementName(task.iElementId);

  const name = useMemo(() => {
    if (elementName) {
      return elementName;
    }

    // Use custom name for tasks that do not have a reference id
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "SCENE conversion";
    }
    if (task.type === ProgressApiSupportedTaskTypes.pointCloudLazToPotree) {
      return "Scan processing";
    }

    return "Unknown";
  }, [elementName, task.type]);

  const icon = useMemo(() => {
    switch (task.type) {
      case ProgressApiSupportedTaskTypes.videoMode:
        return ElementIconType.VideoCameraIcon;
      case ProgressApiSupportedTaskTypes.bimModelImport:
        return ElementIconType.ViewInArIcon;
      case ProgressApiSupportedTaskTypes.sceneConversion:
        return ElementIconType.FolderIcon;
      default:
        return ElementIconType.PointCloudIcon;
    }
  }, [task.type]);

  return (
    <ProgressCardLayout
      name={name}
      // Avoid putting the floor name if the task does not reference an element
      subText={
        element ? <NoTranslate>{activeArea?.name}</NoTranslate> : undefined
      }
      processing={task}
      upload={null}
      icon={icon}
      startTime={task.createdAt}
    />
  );
}

/**
 * @returns The name of an iElement. If the iElement is not yet in the store,
 * it's fetched from the ProjectAPI
 * @param id The id of the iElement
 */
function useElementName(id: GUID | null): string | undefined {
  const { projectApiClient } = useApiClientContext();
  const [name, setName] = useState<string>();

  const element = useAppSelector(selectIElement(id));
  useEffect(() => {
    async function fetchName(): Promise<string | undefined> {
      if (!id) return;

      const elements = await projectApiClient.getAllIElements({
        ids: [id],
      });

      setName(elements.at(0)?.name);
    }

    if (element) {
      setName(element.name);
      return;
    }

    fetchName();
  }, [element, id, projectApiClient]);

  return name;
}
