import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { ProjectStatus } from "@faro-lotv/service-wires";
import { useState } from "react";

export const PROJECT_OUTDATED_MESSAGE =
  "Your project is being finalized, therefore some data might still be missing. To use all features, please try to re-open the project later.";

/**
 * @returns the project status of the current project
 */
export function useProjectStatus(): ProjectStatus | undefined {
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();

  const client = useCurrentProjectApiClient();

  useNonExhaustiveEffect(
    () => {
      async function checkProjectStatus(): Promise<void> {
        setProjectStatus(await client.getProjectStatus());
      }
      checkProjectStatus();
    },
    // Should only update once when the project changes
    [client.projectId],
  );

  return projectStatus;
}
