import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { unknownPageError } from "@/errors/unrecoverable-error";

/**
 * @returns Standard page to show for routes not provided by the router
 */
export function NotFoundPage(): null {
  const { handleErrorWithPage } = useErrorHandlers();
  handleErrorWithPage(unknownPageError("The url is not valid"));
  return null;
}
