import { parseVector3 } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { Vector3 as Vector3Prop } from "@react-three/fiber";
import { Camera, Vector3 } from "three";

export type CameraAnimationTimeOptions = {
  /** The shortest move duration in seconds (default 1) */
  min?: number;

  /** The longest move duration in seconds (default 3) */
  max?: number;

  /** The distance multiplier for scaling camera move speeds (default 8) */
  scale?: number;
};

/**
 * Compute the time it should take to animate the camera to a new position
 * calculated based on the distance from the camera to the target position
 * scaled by @see CameraAnimationTimeOptions.scale
 * clamped between @see CameraAnimationTimeOptions.min
 * and @see CameraAnimationTimeOptions.max
 * if targetPosition is not valid or is the same as the cameras position the time will be 0
 *
 * @param camera the camera that will be animating
 * @param targetPosition the destination of the camera
 * @returns the desired animation duration in seconds
 */
export function getCameraAnimationTime(
  camera: Camera,
  targetPosition?: Vector3Prop,
  { min = 1, max = 3, scale = 8 }: CameraAnimationTimeOptions = {},
): number {
  assert(min > 0, "The min duration should be greater than 0");
  assert(max > 0, "The max duration should be greater than 0");
  assert(scale > 0, "The scale should be greater than 0");
  assert(max > min, "The max duration should be greater than the min duration");

  if (!targetPosition) return 0;
  const dist = camera
    .getWorldPosition(new Vector3())
    .distanceTo(parseVector3(targetPosition));
  return Math.max(min, Math.min(max, dist / scale));
}
