import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { resetSheetToCadAlignment } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { SheetToCadAlignmentModeOverlay } from "./sheet-to-cad-alignment-mode-overlay";
import { SheetToCadAlignmentModeScene } from "./sheet-to-cad-alignment-mode-scene";

export const sheetToCadAlignmentMode: Mode = {
  name: "sheetToCadAlignment",
  ModeScene: SheetToCadAlignmentModeScene,
  ModeOverlay: SheetToCadAlignmentModeOverlay,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align sheet to 3D model",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      // if user canceled alignment by pressing back we need to reset
      // temporary data to prevent reusing it in the next session of alignment
      dispatch(resetSheetToCadAlignment());

      dispatch(changeMode("overview"));
    },
  },
};
