import { blue, purple, yellow } from "@faro-lotv/flat-ui";

/** Color used for the minimum value of the color bar */
export const MIN_ALTITUDE_COLOR = yellow[400];

/** Color used for the maximum value of the color bar */
export const MAX_ALTITUDE_COLOR = purple[700];

/** Color used for the elements with invalid height */
export const INVALID_ALTITUDE_COLOR = blue[500];
