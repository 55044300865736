import {
  C2CRegistrationResult,
  C2CRegistrationTask,
} from "@/utils/background-tasks";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { useMemo } from "react";

/**
 * @param registrationTask The currently running registration task.
 * @returns The result of the registration task, or `undefined` if not available.
 */
export function useRegistrationResult(
  registrationTask?: C2CRegistrationTask,
): C2CRegistrationResult | undefined {
  return useMemo(() => {
    if (registrationTask?.state === BackgroundTaskState.succeeded) {
      return registrationTask.result;
    }
  }, [registrationTask]);
}
