/** The three views for aligning two pointclouds */
export enum Perspective {
  topView = "topView",
  frontView = "frontView",
  sideView = "sideView",
}

/** The different states of registration job */
export enum RegistrationJobStatus {
  /** state when the user does not request for any registration */
  NotInitiated = "notInitiated",
  /** state immediately when the user requests for registration */
  Requested = "requested",
  /** state whe registration job is running */
  Initiated = "initiated",
}

/** type for registration job details */
export type RegistrationJobDetails = {
  /** the id of the registration job */
  jobId?: string;
  /** the status of tthe registration job */
  status: RegistrationJobStatus;
};
