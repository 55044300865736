import { Card, Dialog } from "@mui/material";
import { useMemo } from "react";

type ImagePreviewProps = {
  /** Image file to display */
  file: File;

  /** Function to call when the dialog should close */
  onClosePreview?(): void;
};

/**
 * @returns a dialog that displays an image
 */
export function ImagePreview({
  file,
  onClosePreview,
}: ImagePreviewProps): JSX.Element | null {
  const fileURL = useMemo(() => URL.createObjectURL(file), [file]);

  return (
    <Dialog
      open
      onClose={onClosePreview}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
        },
      }}
    >
      <Card
        component="img"
        src={fileURL}
        alt={file.name}
        sx={{
          objectFit: "contain",
          boxShadow: "none",
        }}
      />
    </Dialog>
  );
}
