import { CloseIcon, FaroIconButton, FaroText } from "@faro-lotv/flat-ui";
import { Stack, StackProps } from "@mui/material";

type QuickHelpHeaderProps = StackProps & {
  /** Text displayed as the title in the quick help */
  title: string;

  /** Function to call when the X button is clicked */
  onClose?(): void;
};

/**
 * @returns an header composed of a title and a button to close the quick help
 */
export function QuickHelpHeader({
  title,
  onClose,
  ...rest
}: QuickHelpHeaderProps): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <FaroText variant="heading20" sx={{ my: 1, mx: 2 }}>
        {title}
      </FaroText>
      <FaroIconButton onClick={onClose} size="s">
        <CloseIcon />
      </FaroIconButton>
    </Stack>
  );
}
