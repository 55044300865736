import { clearStore } from "@faro-lotv/project-source";
import { GenericUserInfo } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ProjectState = {
  /** List of users of the project */
  users: GenericUserInfo[];
};

const initialState: ProjectState = {
  users: [],
};

/** Slice to store and manage the current project information */
const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    /**
     * Update the users with current project access data stored in the slice
     *
     * @param state current state
     * @param action the list of users with current project access
     */
    setProjectUsers(state, action: PayloadAction<GenericUserInfo[]>) {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const projectReducer = projectSlice.reducer;

export const { setProjectUsers } = projectSlice.actions;
