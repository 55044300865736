import { EventType } from "@/analytics/analytics-events";
import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { useAppDispatch } from "@/store/store-hooks";
import { useDialog, useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID } from "@faro-lotv/foundation";
import { removeIElement } from "@faro-lotv/project-source";
import {
  ApiResponseError,
  createMutationDeleteElement,
  getMutationErrorMessages,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";

export type UseDeleteAnnotationReturn = {
  /** Function to call when the annotation needs to be removed */
  removeAnnotation(): Promise<void>;

  /** True while the mutation is in progress */
  isRemovingAnnotation: boolean;
};

/**
 * Return a function to remove an annotation and a flag to know when the project is mutating
 *
 * @param id of the annotation to remove (Model3D/MarkupPolygon/MeasurePolygon)
 * @returns the function to call to remove the annotation and a flag to check for project mutations
 */
export function useDeleteAnnotation(
  id: GUID | undefined,
): UseDeleteAnnotationReturn {
  const { projectApiClient } = useApiClientContext();
  const { createDialog } = useDialog();
  const [isRemovingAnnotation, setIsRemoving] = useState(false);
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const removeAnnotation = useCallback(async () => {
    if (!id) return;

    const shouldRemove = await createDialog({
      title: "Delete Element?",
      confirmText: "Delete",
      content: <DeleteElementDialogContent name="the annotation" dark />,
      variant: "danger",
      dark: true,
    });

    if (!shouldRemove) {
      Analytics.track(EventType.cancelAnnotationDeletion);
      return;
    }

    Analytics.track(EventType.confirmAnnotationDeletion);

    // Preemptive hide the annotation
    setIsRemoving(true);
    try {
      await projectApiClient.applyMutations([createMutationDeleteElement(id)]);
      dispatch(removeIElement(id));
    } catch (error) {
      const messages =
        error instanceof ApiResponseError
          ? getMutationErrorMessages(error)
          : [JSON.stringify(error)];
      openToast({
        title: "Removal Failed",
        message: (
          <>
            {messages.map((mess) => (
              <>
                {mess}
                <br />
              </>
            ))}
          </>
        ),
      });
      // Show again if removal failed
      setIsRemoving(false);
    }
  }, [createDialog, dispatch, id, openToast, projectApiClient]);

  return {
    isRemovingAnnotation,
    removeAnnotation,
  };
}
